import styled from "@emotion/styled";
import { colors, views } from "../../../common/styles/theme.styles";
import VyzerAssistanceCover from '../assets/vyzer-assistance-cover.png'
import bgImg from '../assets/welcome-modal-bg.jpg';

export const PageWrapper = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    position:relative;
    overflow:hidden;
    padding: 16px 20px 24px 20px;

    ${views.desktop}{
        background-color: ${colors.lightGray2};
    }
`;

export const PageContent = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
    max-width: 960px;
    height: 100%;
    width: 100%;

    border-radius: 12px;
    background: #FFF;
`;

export const AssistanceHeroWrapper = styled.div`
    background: url(${VyzerAssistanceCover});
    background-blend-mode: exclusion, normal;

    width: 100%;
    height: 227px;

    @media (max-height: 800px) {
        height: 140px;
    }

    border-radius: 12px 12px 0px 0px;

    display: flex;
    flex-direction: column;

    
    padding: 16px 16px 0 48px;
    gap: 12px;
`;

export const AssistanceHeroHeader = styled.div`
    display: flex;
    width: 100%;
`;

export const AssistanceHeroHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 100%;
`;

export const AssistanceHeroLogoWrapper = styled.div`
    display: inline-flex;
    height: 17px;
    padding: 0px 5px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 6px;
    border: 1px solid #546481;
    background: linear-gradient(88deg, #000 74.88%, rgba(128, 141, 174, 0.40) 100%);
    box-shadow: 1px -1px 4px 0px rgba(255, 255, 255, 0.25);
`;

export const AssistanceHeroLogoText = styled.div`
    color: var(--White, #FFF);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 1px;
`;

export const AssistanceHeroInfoWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding-bottom: 3px;

    z-index: 1;
`;

export const AssistanceHeroInfoBox = styled.div`
    display: flex;
    height: 38px;
    padding: 8px 10px 8px 8px;
    justify-content: center;
    align-items: center;
    gap: 6px;

    border-radius: 6px;
    background: #2F3236;

    color: var(--Main-Colors-Light-Grey-2, #F1F3F5);
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;

export const AssistanceHeroInfoBoxWhite = styled(AssistanceHeroInfoBox)`
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid var(--Main-Colors-White, #FFF);
    background: var(--Main-Colors-Light-Grey-2, #F1F3F5);

    color: var(--Main-Colors-Dark-Grey-1, #1A1B1D);

    :hover {
        cursor: pointer;
    }

    ${({ disabled }) => disabled && `
        filter: brightness(0.6);
        pointer-events: none;
    `});
`;

export const AssistanceLearnIconWrapper = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4.8px;
    justify-content: center;
    align-items: center;
`;

export const AssistanceLearnIcon = styled.div`
    display: flex;
    width: 14.4px;
    height: 14.4px;
    padding: 0px 4.156px 0px 4.244px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 30.4px;
    border: 1.543px solid var(--Main-Colors-Light-Grey-2, #F1F3F5);

    color: var(--Main-Colors-Light-Grey-2, #F1F3F5);
    font-size: 11.2px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    ::before {
        content: '?';
    }
`;

export const AssistanceHeroTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;

    color: var(--Main-Colors-White, #FFF);
`;

export const AssistanceHeroTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 120.5%; /* 28.92px */
`;

export const AssistanceHeroSubtitle = styled.div`
    width: 480px;
    font-size: 14px;
    font-weight: 450;
    line-height: 140.336%; /* 19.647px */

    @media (max-height: 800px) {
        display: flex;
        width: 100%;
        gap: 4px;
    }
`;

export const AssistanceEmptyStateWrapper = styled.div`
    display: flex;
    width: 100%;
    padding-top: 40px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
`;

export const AssistanceEmptyStateBoxContainer = styled.div`
    display: flex;
    padding: 8px 0px;
    align-items: flex-start;
    gap: 8px;
`
export const AssistanceEmptyStateBoxIconWrapper = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: flex-start;
    align-items: center;
`;

export const AssistanceEmptyStateBox = styled.div`
    display: flex;
    width: 188px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    border-left: 1px solid var(--Main-Colors-Grey-3, #DDE3EB);

    color: var(--Main-Colors-Dark-Grey-2, #585D66);

`;

export const AssistanceEmptyStateBoxTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
`;

export const AssistanceEmptyStateBoxContent = styled.div`
    font-size: 14px;
    font-weight: 450;
    line-height: 20px; /* 142.857% */
`;

export const AssistanceChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    gap: 16px;
    flex: 1;
    width: 100%;

    overflow: scroll;
`;

export const AssistanceChatWrapper = styled.div`
    display: flex;
    flex-direction: column;

    flex: 1;
    overflow: scroll;
`;

export const AssistanceMessagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
`;

export const AssistanceMessage = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    width: 100%;
    padding: 16px 0px;
    gap: 16px;
`;

export const AssistanceMessagesWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${({ isLast }) => isLast &&
        `
        min-height: 100%;
    `
    } 
`;

export const AssistanceMessageContainer = styled.div`
    display: flex;
    width: 600px;
    padding: 16px 0;

    ${({ isUser }) => isUser ?
        'justify-content: flex-end;' :
        'justify-content: flex-start;'
    }

    align-items: start;
    gap: 12px;
    width: 100%;

    padding: 40px 15%;

    ${views.tablet}{
        padding: 24px 16px;
    }
`;

export const AssistanceMessageAvatar = styled.div`
    display: flex;
    height: 28px;
    padding: 6px 9px 6px 3px;
    align-items: center;
    
    border-radius: 28px;
    background: linear-gradient(44deg, #000 26.5%, rgba(83, 91, 113, 0.87) 56.46%, rgba(128, 141, 174, 0.80) 72.2%, rgba(232, 232, 157, 0.60) 84.2%, #E8E89D 86.41%);
`;

export const AssistanceMessageUserAvatarWrapper = styled.div`
    padding: 6px 0;

    ${views.tablet}{
        padding: 6px 0 0 0;
    }
`;

export const AssistanceMessageUserAvatar = styled.div`
    width: 34px;
    height: 34px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 12px;
    border: 1px solid var(--Main-Colors-Grey-3, #DDE3EB);
    background: #FFF;

    color: var(--Main-Colors-Dark-Grey-1, #1A1B1D);
    text-align: center;
    font-family: Domine;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    ${views.tablet}{
        background: none;
        color: var(--White, #FFF);
        border: 1px solid var(--Main-Colors-Dark-Grey-2, #585D66);
    }

`;

export const AssistanceUserMessageWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   gap: 8px;
   flex: 1 0 0;
`;

export const AssistanceUserMessage = styled.div`
   display: flex;
   flex-direction: column;

   padding: 11px 24px 13px 24px;
   align-items: start;

   border-radius: 40px;
   background: var(--Main-Colors-Light-Grey-2, #F1F3F5);

   p {
    margin: 0;
   }

   ${views.tablet}{
      border: 1px solid var(--Main-Colors-Dark-Grey-2, #585D66);
       border-radius: 24px;
       background:none;
       color: var(--White, #FFF);
   }
`;

export const AssistanceChatMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
`;

export const AssistanceChatMessage = styled.div`
    padding-bottom: 4px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 21px; /* 131.25% */

    flex: 1 0 0;
`;

export const AssistanceChatMessageText = styled.div`
    color: var(--Main-Colors-Dark-Grey-0, #111213);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 137.5% */`
    ;

export const AssistanceChatMessageTitle = styled(AssistanceChatMessageText)`
    font-weight: 700;
`;

export const AssistanceChatLinkButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`;

export const AssistanceChatLinkButton = styled.div`
    display: flex;
    height: 44px;
    padding: 8px 16px 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 6px;

    border-radius: 40px;
    border: 1px solid var(--Gray-3, #DDE3EB);

    /* Card */
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08), 0px 8px 16px 0px rgba(0, 0, 0, 0.04);

    :hover {
        border: 1px solid var(--Main-Colors-Grey-2, #ACB1BB);
        cursor: pointer;
    }
    `;

export const AssistanceChatIconWrapper = styled.div`
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
`;

export const AssistanceChatMessageContent = styled(AssistanceChatMessageText)`
    font-weight: 450;

    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 0 0;

    .title_div {
        font-weight: 700;
    }

    ${views.tablet}{
     color: var(--White, #FFF);
     h1, h2, h3, h4, h5, h6 {
        color: var(--White, #FFF);
     }
    }

    ${({ approach }) => approach === 'Shimri' && `
        color: #673AB7;
    `}

    ${({ approach }) => approach === 'Eliav' && `
        color: #0000FF;
    `}

    table {
        width: 100%;
        border-collapse: collapse;

        th {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
            background-color: #f2f2f2; /* Light gray background */
        }

        td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
        }
    }
`;

export const AssistanceChatFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    position: relative;
    ${views.tablet}{
        width: 100%;
    }
`;

export const AssistanceFooterContainer = styled.div`
    display: flex;
    padding: 32px 24px 20px 24px;
    flex-direction: column;
    gap: 16px;
     ${views.tablet}{
        width: 100%;
        padding: 8px 12px 16px 12px;
    }
`;

export const AssistanceClarificationMessage = styled.div`
    display: flex;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
`;

export const AssistanceClarificationMessageText = styled.div`
    color: var(--Main-Colors-Dark-Grey-3, #9097A3);
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;

export const AssistanceChatInputWrapper = styled.div`
    display: flex;
    width: 640px;
    padding: 16px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    position: relative;

    border-radius: 26px;

    border: 1px solid var(--Main-Colors-Grey-2, #ACB1BB);
    :focus-within{
        border-color: var(--Main-Colors-Blue-1, #9097A3);
    }
    ${views.tablet}{
        width: 100%;
    }

    ${({ disabled }) => disabled && `
        border: 1px solid var(--Main-Colors-Dark-Grey-3, #9097A3);
        opacity: 0.4;
    `
    }
`;

export const AssistanceChatMobileScrollButton = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    border: 1px solid var(--Main-Colors-Dark-Grey-2, #585D66);
    background: ${colors.darkGray1};
    position: absolute;
    left: calc(50% - 16px);
    bottom: 80px;
    box-shadow: 0px -1px 6.6px 0px rgba(255, 255, 255, 0.25)
`;

export const AssistanceChatTextArea = styled.textarea`
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    color: var(--Main-Colors-Dark-Grey-0, #111213);
    background: var(--Main-Colors-White, #FFF);

    min-height: 20px;
    max-height: 100px;
    padding: 0;
    overflow-y: scroll;

    border: none;
    outline: none;
    resize: none;

    &::placeholder {
        color: var(--Gray-2, #ACB1BB);
    }

    width: 100%;

    ${views.tablet}{
         background: var(--Main-Colors-Dark-Grey-0, #111213);
          color: var(--Gray-2, #ACB1BB);
          &::placeholder {
        color:var(--Dark-Gray-2, #585D66);
    }
`;

export const AssistanceChatButton = styled.div`
    display: flex;
    width: 50px;
    height: 40px;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;

    position: absolute;
    right: 6px;
    bottom: 7px;

    border-radius: 28px;
    cursor: pointer;
`;


export const AssistanceChatSendButton = styled(AssistanceChatButton)`
    background: var(--Main-Colors-Dark-Grey-1, #1A1B1D);

    :hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), var(--Main-Colors-Dark-Grey-1, #1A1B1D);    
    }
`;

export const AssistanceChatThinkingButton = styled(AssistanceChatButton)`
    background: var(--Main-Colors-Dark-Grey-2, #585D66);

    :hover {
        background: var(--Main-Colors-Dark-Grey-1, #1A1B1D);
    }
`;

export const AssistanceClearHistoryButton = styled.div`
    display: inline-flex;
    height: 38px;
    padding: 0 16px 0 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;

    color: var(--Main-Colors-Dark-Grey-2, #585D66);
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    cursor: pointer;

    position: relative;
    bottom: 8px;
`;

export const AssistanceTooltipCot = styled.div`

    .ant-tooltip-inner {
        display: flex;
        padding: 8px 24px;
        align-items: flex-start;
        gap: 10px;
    }
`;


export const AssistanceInfoItem = styled.div`
    display: flex;
    padding: 16px 0;
    margin: 0 20px 0 24px;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    border-bottom: 1px solid var(--Main-Colors-Dark-Grey-2, #585D66);
`;

export const AssistanceInfoItemDescription = styled.div`
    display: flex;
    padding: 16px 0;
    margin: 0 24px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    flex: 1 0 0;

    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 22px; /* 157.143% */

    border-bottom: 1px solid var(--Main-Colors-Dark-Grey-2, #585D66);
`;

export const AssistanceInfoItemIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AssistanceInfoItemText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
`;

export const AssistanceInfoItemTextTitle = styled.div`
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

export const AssistanceInfoItemTextContent = styled.div`
    color: var(--Gray-2, #ACB1BB);
    font-size: 13px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;

export const AssistanceInfoReadMoreWrapper = styled.a`
    display: flex;
    padding: 12px 24px;
    margin:6px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    color: var(--Gray-2, #ACB1BB);
    font-size: 13px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;

    :hover {
        color:  var(--Gray-3, #DDE3EB);
        background:#2F3236;
        svg{
            path{
                stroke: var(--Gray-3, #DDE3EB);
            }
        }
    }
`;

export const AssistanceItemInfoHoverWrapper = styled.div`
    display: none;
    position: absolute;
    height: 24px;
    top: 38px;
    left: 0;
    right: 0px;
`;

export const AssistanceItemInfoWrapper = styled.div`
    display: flex;
    width: ${({ width }) => width || '344px'};
    padding-bottom: 4px;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 8px;
    border: 1px solid var(--Main-Colors-Dark-Grey-2, #585D66);
    background: var(--Dark-Gray-1, #1A1B1D);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.05)) drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.05));

    position: absolute;
    right: 0;
    top: 8px;
`;

export const AssistanceInfoWrapper = styled.div`
  position: relative;

  &:hover .assistance_info_items_hover {
    display: flex;
  }
`;

export const AssistanceDropdownList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    padding: 8px;
    gap: 8px;

    border-radius: 20px;
    border: 1px solid var(--Gray-3, #DDE3EB);
    background: var(--White, #FFF);

    /* Card */
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08), 0px 8px 16px 0px rgba(0, 0, 0, 0.04);

    position: absolute;
    bottom: 70px;
    left: 0;

    ${views.tablet}{
        background:#25272C;
        border: 1px solid #42464e;
        padding:4px 0;
        gap:0px;
        border-radius:26px;
    }
`;

export const AssistanceDropdownItem = styled.div`
    display: flex;
    height: 48px;
    padding: 0px 8px;
    align-items: center;
    align-self: stretch;

    &:not(:last-child) {
        border-bottom: 1px solid var(--Main-Colors-Light-Grey-2, #F1F3F5);
    }

    :hover {
        cursor: pointer;
        border-radius: 11px;
        border-bottom: 1px solid var(--Main-Colors-Light-Grey-2, #F1F3F5);
        background: var(--Main-Colors-Light-Grey-2, #F1F3F5);
    }

    ${views.tablet}{
        color: var(--White, #FFF);
        padding:6px 20px;
        min-height: 60px;
        &:not(:last-child) {
            border-bottom: 1px solid #42464e;
        }

        :hover {
            background: rgba(255, 255, 255, 0.10);
            border-radius: 0px;
            :first-of-type {
                border-radius: 26px 26px 0px 0px;
            }
            :last-of-type {
                border-radius: 0px 0px 26px 26px;
                border-bottom: none;
            }
        }
    }
`;

export const AssistanceDropdownItemText = styled.div`
    color: var(--Dark-Gray-1, #1A1B1D);
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    ${views.tablet}{
        color: var(--White, #FFF);
    }
`;

export const AssistanceDropdownItemWord = styled.span`
    color: var(--Main-Colors-Dark-Grey-3, #9097A3);
    ${views.tablet}{
        color: var(--White, #FFF);
    }
`;

export const AssistanceDropdownItemHighlightedWord = styled.span`
    color: var(--Main-Colors-Dark-Grey-3, #1A1B1D);
    font-weight: 700;
    ${views.tablet}{
        color: var(--White, #FFF);
    }
`;

// Feedback

export const FeedbackContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FeedbackButton = styled.button`
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: ${props => props.selected ? 1 : 0.6};
  &:hover {
    opacity: 0.8;
  }
`;

export const ModalBgWrapper = styled.div`
    background: url(${bgImg}) lightgray 50% / cover no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-position: center;

    border-radius: 12px;
    
    border: 1px solid var(--Dark-Gray-2, #585D66);
    /* Popup Shadow */
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.30), 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
    height: 100%;
`;

export const WelcomeModal = styled.div`
    display: flex;
    flex-direction: column;

    border-radius: 12px;
    width: 100%;
    height: 100%;
`;

export const WelcomeModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 6px 10px 6px 22px;
    width: 100%;
`;

export const WelcomeModalCloseButtonWrapper = styled.div`
    display: flex;
    width: 44px;
    height: 44px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`;

export const WelcomeModalCloseButton = styled.div`
    display: flex;
    width: 28px;
    height: 28px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 50px;
    background: #25272C;

    cursor: pointer;
    :hover {
        filter: brightness(0.9);
    }
`;

export const WelcomeAssistanceButtonWrapper = styled.div`
    padding: 9px 0 0 0;
`;

export const WelcomeModalBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const WelcomeModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 8px 64px 0 64px;
    height: 100%;
`;

export const WelcomeModalTitle = styled.div`
    color: var(--White, #FFF);
    font-size: 38px;
    font-style: normal;
    font-weight: 450;
    line-height: 32px; /* 84.211% */
    letter-spacing: -0.5px;

    ::before {
        content: 'Welcome to Vyzer AI Insights (Beta)';
    }
`;

export const WelcomeModalSubtitle = styled.div`
    color: var(--Main-Colors-White, #FFF);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 130% */
    width: 394px;

    ::before {
        content: 'Your personal finance companion — powered by AI and continuously learning.'
    }
`;

export const WelcomeMainText = styled.div`
    paddingTop: 4px;

    color: var(--Main-Colors-Grey-2, #ACB1BB);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 26px; /* 162.5% */

    width: 394px;
`;

export const WelcomeMainTextBoldParts = styled.span`
    color: var(--Main-Colors-White, #FFF);
    font-weight: 500;
`;

export const WelcomeModalFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 0 68px 24px 68px;
`;

export const WelcomeModalButtonWrapper = styled.div`

`;

export const WelcomeModalButton = styled.div`
    display: inline-flex;
    height: 48px;
    padding: 0px 24px 0px 28px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    flex-shrink: 0;

    border-radius: 25px;
    background: var(--Main-Colors-White, #FFF);
    box-shadow: 0px 10px 13.3px 0px rgba(255, 194, 102, 0.25);
    

    cursor: pointer;
    :hover {
        filter: brightness(0.9);
    }
`;

export const WelcomeModalButtonText = styled.div`
    color: var(--Dark-Gray-1, #1A1B1D);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    ::before {
        content: 'Ask your first question';
    }
`;

export const WelcomeFooterText = styled(WelcomeMainText)`
    width: 100%;
`;

export const WelcomeFooterTextBoldParts = styled(WelcomeMainTextBoldParts)`
    width: 100%;
`;

export const WelcomeButtonMobile = styled.div`
    display: flex;
    align-items: center;
`;