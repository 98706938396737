import styled from "@emotion/styled/macro";
import { mobileVars } from "../../../common/styles/theme.styles";
import { flexCenter } from "../../../common/styles/combos.styles";

export const AppWrapper = styled.div`
  height: ${props=>props.isWithTopBanner ? 'calc(100% - 48px)' : '100%'};
  display: flex;
  flex-direction: column;
  // padding-top: ${props => props.isHeaderFixed ? '52' : '0'}px;
  padding-top: ${props => props.isItemPageView || props.isReportPage || !props.isAuthenticated || props.isOnBoarding || props.isWelcome || props.emptySideMenuLayout ? '0' : props.isHeaderFixed ? '64' : '0'}px;
  background-color: ${mobileVars.blackBackgroundColor};
  // padding-bottom: ${props => props.isHeaderFixed ? '0' : '64'}px;
`


export const RightSidePushMenuWrapper = styled.div`
  grid-area: pushMenu;
  overflow-x: hidden;
`;

export const Banner = styled.div`
  grid-area: banner;
`

export const LogoAreaWrapper = styled.div`
  grid-area: logo;
  background: #1b1c1e;
  display: flex;
  align-items: center;
  justify-content: space-between;;
  line-height: 1em;
  padding-left:29px;
`

export const LogoAndBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap:8px;
`;

export const MinimizeButtonWrapper = styled.div`
  height: 20px;
  width: 11px;
  transform:rotate(${props=>props.isOpen ? 90 : 270}deg);
  margin-right: 16px; 
  transition: transform 0.3s;
  cursor: pointer;
`;

export const PageWrapper = styled.div`
  position:relative;
  display: flex;
  justify-content: center;
  padding: 45px 45px 0;
  background-color: #f2f3f5;
  ${props=>props.withHeader ? `` : `
    grid-area:content;
  `}
  overflow:auto;
  align-items: flex-start;
  min-height:100%;
  & > div {
    width: 100%;
    max-width: 880px;
    margin-bottom:45px;
  }

  ${props => props.isPopup ? `
    border-radius: 8px;
    padding: 16px;
    background-color: #fff;
  ` : ``} 
`

export const MobileBackButtonWrapper = styled.div`
  ${flexCenter};
  width: 40px;
  ${props=>props.fontColor ? `
    svg {path{stroke:${props.fontColor}}}
  ` : ``}
`;

export const MobileHeader = styled.div`
    position: ${props=>props.isHeaderFixed ? `fixed` : `relative`};
    top: 0;
    background-color: #111213;
    z-index: 2;
    width: 100%;
    min-height: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 24px 0 0;
`;

export const MobileHeaderTitle = styled.div`
    font-weight: 450;
    font-size: 18px;
    color: #fff;
`;



