import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ArrowDownIcon, CloseXIcon, PlusIcon } from '../../../../assets/icons/common/common-icons';
import debounce from 'lodash/debounce';
import { AddIconWrapper } from '../../../add-item/components/steps/addItemInstitutionStep.styles';
import { CommunityInputWrapper, InputContainerWrapper, InputContainer, TextInput, DropdownButton, DropdownList, DropdownContent, EmptyStateContainer, AddManuallyButton, CommunityItem, CommunityLogo, CommunitySelectedLogo, CommunityName, CheckMark, SelectedItem, RemoveButton } from './CommunityInput.style';

export function CommunityInput({ communities, selectedCommunities, handleSelectCommunity }) {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filteredCommunities, setFilteredCommunities] = useState([]);
  const inputRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    setFilteredCommunities(communities);
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [communities]);

  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchValue) => {
        const response = communities?.slice().filter(community => community.name.toLowerCase().trim().includes(searchValue.toLowerCase().trim()));
        setFilteredCommunities(response);
      }, 300),
    [communities]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleInputClick = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleDropdownToggle = (e) => {
    e.stopPropagation();
    setIsOpen(prev => !prev);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (!value.trim()) {
      setFilteredCommunities(communities);
      return;
    }
    debouncedSearch(value.trim());
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      addNewCommunity(inputValue.trim());
    }
  };

  const addNewCommunity = (communityName) => {
    const newCustomItem = {
      id: `custom-${Date.now()}`,
      name: communityName,
      logo: 'https://institutions-logo.s3.us-east-2.amazonaws.com/communities/custom+communities+icon.svg',
      isCustom: true
    };

    handleCommunitySelect(newCustomItem);
  };

  const handleCommunitySelect = (community) => {
    handleSelectCommunity(community);
    clearSearch();
  };

  const clearSearch = () => {
    setInputValue('');
    setFilteredCommunities(communities);
  }

  return (
    <CommunityInputWrapper ref={wrapperRef} >
      <InputContainerWrapper isEmpty={selectedCommunities.length === 0}>
        <InputContainer onClick={() => inputRef.current?.focus()} >
          {selectedCommunities.map(item => (
            <SelectedItem key={item.id} isCustom={item.isCustom}>
              {item.logo && (
                <CommunitySelectedLogo src={item.logo} />
              )}
              <span>{item.name}</span>
              <RemoveButton
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleCommunitySelect(item);
                }}
              >
                <CloseXIcon />
              </RemoveButton>
            </SelectedItem>
          ))}
          <TextInput
            ref={inputRef}
            value={inputValue}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            onClick={handleInputClick}
            placeholder={selectedCommunities.length ? 'Add...' : 'Select or type community name'}
          />
        </InputContainer>
        <DropdownButton onClick={handleDropdownToggle} isOpen={isOpen} isEmpty={selectedCommunities.length === 0}>
          <ArrowDownIcon />
        </DropdownButton>
      </InputContainerWrapper>

      {
        isOpen && (
          <DropdownList>
            <DropdownContent>
              {
                filteredCommunities.length === 0 ?
                  (
                    <EmptyStateContainer>
                      <AddManuallyButton
                        onClick={(e) => {
                          e.stopPropagation();
                          if (inputValue.trim()) {
                            addNewCommunity(inputValue.trim());
                          }
                        }}
                      >
                        <AddIconWrapper><PlusIcon /></AddIconWrapper>
                        <span>Add "{inputValue}" community</span>
                      </AddManuallyButton>
                    </EmptyStateContainer>
                  ) : (
                    filteredCommunities
                      .filter(community => !community.isCustom)
                      .map(community => (
                        <CommunityItem
                          key={community.id}
                          onClick={() => handleCommunitySelect(community)}
                          isSelected={selectedCommunities.some(item => item.id === community.id)}
                        >
                          {community.logo && <CommunityLogo src={community.logo} />}
                          <CommunityName isSelected={selectedCommunities.some(item => item.id === community.id)}>{community.name}</CommunityName>
                          <CheckMark isSelected={selectedCommunities.some(item => item.id === community.id)} />
                        </CommunityItem>
                      ))
                  )}
            </DropdownContent>
          </DropdownList>
        )
      }
    </CommunityInputWrapper >
  );
} 