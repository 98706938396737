import { observer } from 'mobx-react'
import { TrendsGraphOverviewItem, TrendsGraphOverviewItems, TrendsGraphTitle, TrendsGraphTooltip } from "./TrendsHero.style";
import { useStore } from '../../../../../../app/data/root.store';
import { displayNumberByTrendsType, getFormattedData } from "./TrendsUtils";
import { useEffect, useState } from 'react';

export const TrendsTooltip = observer(({ data, labels, whiteTheme, trendType, showAbsoluteValues }) => {
    const { wealthStore, itemStore, metadataStore } = useStore();
    const [tooltipData, setTooltipData] = useState(null);
    const [isHovering, setIsHovering] = useState(false);

    useEffect(() => {
        if (!isHovering) {
            const wealthtooltipData = wealthStore.trendsTooltipData;
            setTooltipData(wealthtooltipData);
        }

    }, [wealthStore.trendsTooltipData, isHovering]);

    if (!tooltipData) {
        return null;
    }
    const { index, left, top } = tooltipData;

    const handleMouseEnter = () => setIsHovering(true);
    const handleMouseLeave = () => setIsHovering(false);
    if (data.length === 0) {
        return null;
    }
    const formattedData = getFormattedData(data, metadataStore, wealthStore.allocateBy, trendType, wealthStore.assetsAsObj , index );
    const classesWithBreakdown = ['classes','categories','customClasses','customSubClasses','accounts','entities','currencies','risk','liquidity','institution','companySector'];

    const currData = classesWithBreakdown.includes(wealthStore.allocateBy) ? formattedData : formattedData.length === 1 ? formattedData[0] : formattedData?.[index];
    if (!currData || !labels[index]) {
        return null;
    }
    
    const displayCurrency = wealthStore.viewOf === 'item' ? itemStore.item.currency : 'USD';

    const sortedBreakdown = Object.values(currData.breakdown).sort((a, b) => b.value - a.value).filter(item => item.value !== 0);

    return (
        <TrendsGraphTooltip
            left={left}
            top={top}
            whiteTheme={whiteTheme}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <TrendsGraphTitle noPadding={wealthStore.viewOf === 'item'}>
                <div>{labels[index]}</div>
                <div>{displayNumberByTrendsType(showAbsoluteValues ? Math.abs(currData.value) : currData.value, trendType, displayCurrency)}</div>
            </TrendsGraphTitle>
            {wealthStore.viewOf !== 'item' &&
            <TrendsGraphOverviewItems>
                {sortedBreakdown.map((item, index) => (
                    <TrendsGraphOverviewItem borderLeftColor={item.color} key={index}>
                        <div>{item.name}</div>
                        <div>{displayNumberByTrendsType(showAbsoluteValues ? Math.abs(item.value) : item.value, trendType, displayCurrency)}</div>
                    </TrendsGraphOverviewItem>
                ))}
            </TrendsGraphOverviewItems>}
        </TrendsGraphTooltip>
    );
});