
import React from 'react'
import { DeepAnalysisIconWrapper, WhatsNextCompleteIconWrapper, WhatsNextPromptHoverEffect, WhatsNextPropt, WhatsNextProptSpacer, WhatsNextProptSpacerLine, WhatsNextProptTitle, WhatsNextProptWrapper, WhatsNextRowContent, WhatsNextRowTitle, WhatsNextStepWrapper, WhatsNextTitle } from './DeepAnalysisReviewResultModal.styles'
import { StepRowDetailsWrapper } from '../../../user-activation-flow/components/UserActivationFlowPage.styles';


export const DeepAnalysisWhatsNext = () => {
    return (
        <WhatsNextProptWrapper>
            <WhatsNextTitle>
                <div style={{ width: '24px', height: '24px' }}><MagicBoxIcon /></div>
                What next?
            </WhatsNextTitle>
            <WhatsNextPromptHoverEffect>
                <WhatsNextPropt>

                    <WhatsNextProptTitle>3 steps to set up your portfolio:</WhatsNextProptTitle>
                    <WhatsNextProptSpacer>
                        <WhatsNextProptSpacerLine />
                    </WhatsNextProptSpacer>
                    <WhatsNextStepWrapper>
                        <WhatsNextCompleteIconWrapper>
                            <CompleteStepIcon />
                        </WhatsNextCompleteIconWrapper>
                        <StepRowDetailsWrapper>
                            <WhatsNextRowTitle>1. Import Files</WhatsNextRowTitle>
                            <WhatsNextRowContent>
                                Import a files that contains your investments info, like Spreadsheet Tracker / Personal Balance Sheet
                            </WhatsNextRowContent>
                        </StepRowDetailsWrapper>
                    </WhatsNextStepWrapper>


                    <WhatsNextProptSpacer>
                        <WhatsNextProptSpacerLine />
                    </WhatsNextProptSpacer>
                    <WhatsNextStepWrapper>
                        <WhatsNextCompleteIconWrapper>
                            <CompleteStepIcon />
                        </WhatsNextCompleteIconWrapper>
                        <StepRowDetailsWrapper>
                            <WhatsNextRowTitle>2.  Review AI initial results </WhatsNextRowTitle>
                            <WhatsNextRowContent>
                                Choose categories to create assets and liabilities in your portfolio
                            </WhatsNextRowContent>
                        </StepRowDetailsWrapper>
                    </WhatsNextStepWrapper>


                    <WhatsNextProptSpacer>
                        <WhatsNextProptSpacerLine />
                    </WhatsNextProptSpacer>
                    <WhatsNextStepWrapper>
                        <DeepAnalysisIconWrapper>
                            <DeepAnalysisIcon />
                        </DeepAnalysisIconWrapper>
                        <StepRowDetailsWrapper>
                            <WhatsNextRowTitle>3. Deep Analysis</WhatsNextRowTitle>
                            <WhatsNextRowContent>
                                To extract all the financial data from your files, it will go through a deeper analysis process. This can take up to 24h. Meanwhile you can explore your portfolio.
                            </WhatsNextRowContent>
                        </StepRowDetailsWrapper>
                    </WhatsNextStepWrapper>


                </WhatsNextPropt>
            </WhatsNextPromptHoverEffect>
        </WhatsNextProptWrapper>
    )
};

const CompleteStepIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
            <path d="M8.67369 0.735917L4.00402 5.40825L1.66668 3.07161" stroke="#111213" strokeWidth="2" />
        </svg>
    );
}

const DeepAnalysisIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.7225 1.05483L10.4207 0H7.57934L7.2775 1.05485C6.95335 2.18765 5.66012 2.72497 4.62867 2.15539L3.6406 1.60977L1.63148 3.59216L2.1548 4.5148C2.74298 5.55178 2.20056 6.86546 1.05212 7.18539L0 7.47848V10.282L1.05212 10.5751C2.20056 10.895 2.74297 12.2087 2.15479 13.2457L1.63146 14.1683L3.64058 16.1507L4.62867 15.6051C5.66013 15.0355 6.95335 15.5728 7.27749 16.7056L7.57934 17.7605L10.4207 18L10.7516 16.7587C11.0587 15.607 12.3659 15.0499 13.4093 15.6261L14.3594 16.1507L16.3685 14.1683L15.8452 13.2457C15.257 12.2087 15.7994 10.895 16.9479 10.5751L18 10.282V7.47848L16.9479 7.18538C15.7994 6.86545 15.257 5.55179 15.8452 4.51481L16.3685 3.59214L14.3594 1.60975L13.3713 2.15537C12.3399 2.72495 11.0466 2.18764 10.7225 1.05483ZM8.99867 4.43301C6.47657 4.43301 4.43201 6.47758 4.43201 8.99968C4.43201 11.5218 6.47657 13.5663 8.99867 13.5663C11.5208 13.5663 13.5653 11.5218 13.5653 8.99968C13.5653 6.47758 11.5208 4.43301 8.99867 4.43301ZM6.23201 8.99968C6.23201 7.47169 7.47069 6.23301 8.99867 6.23301C10.5267 6.23301 11.7653 7.47169 11.7653 8.99968C11.7653 10.5277 10.5267 11.7663 8.99867 11.7663C7.47069 11.7663 6.23201 10.5277 6.23201 8.99968Z" fill="white" />
        </svg>
    );
}

const MagicBoxIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5867 1.7207C13.4594 3.63514 15.336 5.05265 17.1427 4.40676L17.8196 4.16475L19.3275 6.77665C18.6452 7.33357 18.2639 8.1048 18.1836 8.90125L15.4506 9.77582C15.4788 9.59528 15.4935 9.41023 15.4935 9.22175C15.4935 7.25839 13.902 5.66677 11.9387 5.66677C9.97548 5.66677 8.38396 7.25839 8.38396 9.22175C8.38396 9.39592 8.39649 9.56716 8.42068 9.73462L5.97883 8.95323C5.91019 8.13843 5.52727 7.34567 4.83008 6.77665L6.33796 4.16475L7.01489 4.40676C8.82156 5.05265 10.6982 3.63514 10.5709 1.7207H13.5867Z" fill="#585D66" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11 12.56L3 10V19.12L11 21.68V12.56ZM13 12.56V21.68L21 19.12V10L13 12.56Z" fill="#585D66" />
        </svg>
    );
}