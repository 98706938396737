import { Dropdown } from 'antd';
import { Observer } from 'mobx-react';
import React, { useState } from 'react';
import moment from 'moment';
import { ArrowDownIcon, CheckmarkIcon, GoToIcon, TooltipIcon } from '../../../../../../../assets/icons/common/common-icons';
import { MenuItemWrapperWealthPie, MenuWrapperWealthPie, MenuWrapperWealthPieDropdown, MenuWrapperWealthPieDropdownItem, MenuWrapperWealthPieDropdownItemSubTitle, MenuWrapperWealthPieDropdownItemTextWrapper, MenuWrapperWealthPieDropdownItemTitle } from '../../../../../../../common/components/DropDownMenu/DropDownMenu.styles';
import { ModifiedTooltip } from '../../../../../../../common/components/ModifiedTooltip/ModifiedTooltip';
import { CountAnimation } from '../../../../../../../common/components/count-animation/CountAnimation';
import { colors } from '../../../../../../../common/styles/theme.styles';
import { displayMoneyValue } from '../../../../../../../common/utils/number-display.utils';
import { capitalize, getUrlDisplayName } from '../../../../../../../common/utils/string.utils';
import { useStore } from '../../../../../../app/data/root.store';
import { ClassViewStatInfoBoxWrapper, MobileCarouselBoxWrapper, MobileStatBoxWrapper, MobileStatsWrapper, StatsColumn, StatsRow, ToolTipWrapper } from '../../../../asset/components/AssetOverviewHeroContent.styles';
import { MetricWrapper, StatBox, StatContentWrapper, StatDescriptionBig, StatTimeFrameSelectorWrapper, StatValue, ValueChangesButton } from '../../../../asset/components/Overview/OverviewTab.styles';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { EventDateArrowLink, EventDateContainer, PieValueContainer } from '../../../../../components/charts/WealthPieChart/WealthPieChart.styles';
import { MagicBoxAnimatedIconGrey } from '../../../../../../magic-box/components/MagicBoxAnimatedIconGrey';
import { CtaArrowSvg } from '../../../../../../../common/components/CommonBadge/CommonBadge';
import { navigateToUrl } from '../../../../../../../common/utils/navigation.utils';
import { useHistory } from "react-router-dom";
import { ROUTES } from '../../../../../../../common/constants/routes.const';


export function WealthOverviewHeroDataBox(props) {
    const { uiStore } = useStore();
    return (<Observer>{() => (uiStore.isDesktopView ? <WealthOverviewHeroDataBoxDesktop /> : <WealthOverviewHeroDataBoxMobile />)}</Observer>);
}


function WealthOverviewHeroDataBoxDesktop(props) {
    const { wealthStore, uiStore } = useStore();
    const currentYear = '' + moment().year()
    const currentYearIndex = wealthStore.overviewHeroInflow.findIndex(item => '' + item.label === currentYear)

    const [selectedTimeFrameKey, setSelectedTimeFrameKey] = useState(currentYearIndex !== -1 ? currentYear : 'total');
    const [cashView, setCashView] = useState(0);
    const [selectedCapitalCommitmentView, setSelectedCapitalCommitmentView] = useState(0);
    // const wealthPageContentElement = document.querySelector('#wealthPageContent');
    let history = useHistory();

    const goToCashPositionTrendsView = () => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('hvm', 'trends');
        navigateToUrl(history, `overview?${searchParams.toString()}`, false);
        wealthStore.setTrendsView('total_net_worth')
    }

    const goToCashflowView = () => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('hvm', 'cashflow');
        navigateToUrl(history, `overview?${searchParams.toString()}`, false);
    }

    return (<Observer>{() => (
        <>
            <StatsRow>
                <StatsColumn>
                    <ClassViewStatInfoBoxWrapper>
                        <StatBox isWealthView={true}>
                            <div>
                                <StatDescriptionBig isWealthView>Assets ({wealthStore.totalAssetsCount})</StatDescriptionBig>
                                <StatTimeFrameSelectorWrapper></StatTimeFrameSelectorWrapper>
                            </div>
                            <StatContentWrapper>
                                <StatValue>
                                    {
                                        uiStore.isIncognitoMode ?
                                            displayMoneyValue(wealthStore.assetsTotalUsdValue, 'USD', true) :
                                            <PieValueContainer>
                                                <CountAnimation currency={'USD'} value={wealthStore.assetsTotalUsdValue} />
                                                {wealthStore.hasLLMInProcessItem && <MagicBoxAnimatedIconGrey width='16px' display='flex' fill='rgb(241,243,245)' />}
                                            </PieValueContainer>
                                    }
                                </StatValue>
                            </StatContentWrapper>

                        </StatBox>
                    </ClassViewStatInfoBoxWrapper>
                </StatsColumn>

                <StatsColumn>
                    <ClassViewStatInfoBoxWrapper>
                        <StatBox isWealthView={true}>
                            <div>
                                <StatDescriptionBig isWealthView>Liabilities ({wealthStore.totalLiabilitiesCount})</StatDescriptionBig>
                                <StatTimeFrameSelectorWrapper></StatTimeFrameSelectorWrapper>
                            </div>
                            <StatContentWrapper>
                                <StatValue>
                                    {
                                        uiStore.isIncognitoMode ? displayMoneyValue(wealthStore.liabilitiesTotalUsdValue, 'USD', true) :
                                            <PieValueContainer>
                                                <CountAnimation currency={'USD'} value={wealthStore.liabilitiesTotalUsdValue} />
                                                {wealthStore.hasLLMInProcessItem && <MagicBoxAnimatedIconGrey width='16px' display='flex' fill='rgb(241,243,245)' />}
                                            </PieValueContainer>
                                    }
                                </StatValue>
                            </StatContentWrapper>

                        </StatBox>
                    </ClassViewStatInfoBoxWrapper>
                </StatsColumn>
            </StatsRow>
            <StatsRow>
                <StatsColumn>
                    <ClassViewStatInfoBoxWrapper isWealthView>
                        <StatBox isWealthView>
                            <>
                                <MetricWrapper>
                                    {
                                        (wealthStore.overviewHeroCashMetrics.length > 1) ?
                                            <Dropdown
                                                overlay={
                                                    CashViewDropDown({
                                                        itemsList: wealthStore.overviewHeroCashMetrics,
                                                        selectedIndex: cashView,
                                                        setIndex: (index) => setCashView(index)
                                                    })
                                                }
                                                getPopupContainer={() => document.getElementById('wealthPageContent')}
                                                trigger={['click']}
                                                placement="bottomCenter"
                                            >
                                                <StatDescriptionBig width={'155px'} isWealthView>
                                                    {capitalize(wealthStore.overviewHeroCashMetrics[cashView].label)}
                                                    <ArrowDownIcon />
                                                </StatDescriptionBig>
                                            </Dropdown> :
                                            <StatDescriptionBig isWealthView>Current cash position</StatDescriptionBig>
                                    }
                                    <ToolTipWrapper>
                                        <ModifiedTooltip title={`Go to trends`}>
                                            <GoToIcon style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => { goToCashPositionTrendsView() }} />
                                        </ModifiedTooltip>
                                    </ToolTipWrapper>
                                </MetricWrapper>
                                {
                                    (1 === cashView) &&
                                    <StatTimeFrameSelectorWrapper>
                                        <Dropdown
                                            overlay={
                                                TimeFrameDropDown({
                                                    itemsList: wealthStore.overviewHeroCashMetrics[1].subItems.map(item => item.label),
                                                    selectedKey: wealthStore.overviewHeroCashMetrics[1].subItems[selectedCapitalCommitmentView].label,
                                                    selectTimeFrame: (_key, index) => setSelectedCapitalCommitmentView(index)
                                                })
                                            }
                                            getPopupContainer={() => document.getElementById('wealthPageContent')}
                                            trigger={['click']} placement="bottomCenter" >
                                            <ValueChangesButton borderLess color={colors.gray2} >
                                                {capitalize(wealthStore.overviewHeroCashMetrics[1].subItems[selectedCapitalCommitmentView].label)}
                                                <ArrowDownIcon />
                                            </ValueChangesButton>
                                        </Dropdown>
                                    </StatTimeFrameSelectorWrapper>
                                }
                            </>
                            <StatContentWrapper style={{ height: 'auto' }}>
                                <StatValue>{CashViewValue(wealthStore, cashView, selectedCapitalCommitmentView, uiStore.isIncognitoMode)}</StatValue>
                            </StatContentWrapper>
                        </StatBox>
                    </ClassViewStatInfoBoxWrapper>
                </StatsColumn>

                <StatsColumn>
                    <ClassViewStatInfoBoxWrapper>
                        <StatBox isWealthView={true}>
                            <MetricWrapper>
                                <StatDescriptionBig isWealthView>Inflow <ModifiedTooltip overlayInnerStyle={{ whiteSpace: 'pre-line', width: '276px', lineHeight: '16px' }} title={`The sum of all inflow events recorded in Vyzer.
    Transactions from 'Cash accounts' and 'Credit cards' that aren't linked to any event are excluded from this calculation.`}><TooltipIcon /></ModifiedTooltip></StatDescriptionBig>
                                <ToolTipWrapper>
                                    <ModifiedTooltip title={`Go to Inflow/Outflow`}>
                                        <GoToIcon style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => { goToCashflowView() }} />
                                    </ModifiedTooltip>
                                </ToolTipWrapper>

                            </MetricWrapper>
                            <StatTimeFrameSelectorWrapper>
                                <Dropdown
                                    overlay={
                                        TimeFrameDropDown({
                                            itemsList: wealthStore.overviewHeroInflow.map(item => '' + item.label).reverse(),
                                            selectedKey: selectedTimeFrameKey,
                                            selectTimeFrame: (key) => { setSelectedTimeFrameKey(key) }
                                        })
                                    }
                                    getPopupContainer={() => document.getElementById('wealthPageContent')}
                                    trigger={['click']} placement="bottomCenter" >
                                    <ValueChangesButton borderLess color={colors.gray2} >{capitalize(selectedTimeFrameKey)}<ArrowDownIcon /></ValueChangesButton>
                                    {/* <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    Hover me <DownOutlined />
                                </a> */}
                                </Dropdown>
                            </StatTimeFrameSelectorWrapper>
                            <StatContentWrapper>
                                {wealthStore.totalDistributions.length ? <StatValue>
                                    {
                                        uiStore.isIncognitoMode ? displayMoneyValue(wealthStore.overviewHeroInflow.find(i => '' + i.label === selectedTimeFrameKey).inflow, 'USD', true) :
                                            <PieValueContainer>
                                                <CountAnimation currency={'USD'} value={wealthStore.overviewHeroInflow.find(i => '' + i.label === selectedTimeFrameKey).inflow} />
                                                {wealthStore.hasLLMInProcessItem && <MagicBoxAnimatedIconGrey width='16px' display='flex' fill='rgb(241,243,245)' />}
                                            </PieValueContainer>
                                    }
                                </StatValue> : null}
                            </StatContentWrapper>

                        </StatBox>
                    </ClassViewStatInfoBoxWrapper>
                </StatsColumn>


            </StatsRow>
        </>
    )}</Observer>)
}

function WealthOverviewHeroDataBoxMobile(props) {
    const { wealthStore, uiStore } = useStore();
    const [cashView, setCashView] = useState(0);
    const [selectedCapitalCommitmentView, setSelectedCapitalCommitmentView] = useState(0);

    const currentYear = '' + moment().year()
    const currentYearIndex = wealthStore.totalDistributions.findIndex(item => item.label === currentYear)

    const [selectedTimeFrameKey, setSelectedTimeFrameKey] = useState(currentYearIndex !== -1 ? currentYear : 'total');
    // eslint-disable-next-line 
    const [selectedTimeFrameIndex, setSelectedTimeFrameIndex] = useState(currentYearIndex);
    // const wealthPageContentElement = document.querySelector('#wealthPageContent');

    return (<Observer>{() => (
        <MobileStatsWrapper>
            {/* <Carousel 
                responsive={{mobile:{breakpoint: {
                    max: 1024,
                    min: 0
                    },
                    items: 2,
                    partialVisibilityGutter: 10}}
                }
                arrows={false}
                // partialVisible
                 centerMode
                infinite
                > */}
            <Slider dots={false} infinite={true} centerMode={true} arrows={false}>
                <MobileCarouselBoxWrapper>
                    <MobileStatBoxWrapper padding={16}>
                        <div>
                            <StatDescriptionBig isWealthView>Assets ({wealthStore.totalAssetsCount})</StatDescriptionBig>
                            <StatTimeFrameSelectorWrapper></StatTimeFrameSelectorWrapper>
                        </div>
                        <StatContentWrapper>
                            <StatValue>
                                {
                                    uiStore.isIncognitoMode ?
                                        displayMoneyValue(wealthStore.assetsTotalUsdValue, 'USD', true) :
                                        <PieValueContainer>
                                            <CountAnimation currency={'USD'} value={wealthStore.assetsTotalUsdValue} />
                                            {wealthStore.hasLLMInProcessItem && <MagicBoxAnimatedIconGrey width='16px' display='flex' fill='rgb(241,243,245)' />}
                                        </PieValueContainer>
                                }
                            </StatValue>
                        </StatContentWrapper>

                    </MobileStatBoxWrapper>
                    {/* <MobileStatBoxWrapper>Div 1</MobileStatBoxWrapper> */}
                </MobileCarouselBoxWrapper>
                <MobileCarouselBoxWrapper>
                    <MobileStatBoxWrapper padding={16}>
                        <div>
                            <StatDescriptionBig isWealthView>Liabilities ({wealthStore.totalLiabilitiesCount})</StatDescriptionBig>
                            <StatTimeFrameSelectorWrapper></StatTimeFrameSelectorWrapper>
                        </div>
                        <StatContentWrapper>
                            <StatValue>
                                {
                                    uiStore.isIncognitoMode ? displayMoneyValue(wealthStore.liabilitiesTotalUsdValue, 'USD', true) :
                                        <PieValueContainer>
                                            <CountAnimation currency={'USD'} value={wealthStore.liabilitiesTotalUsdValue} />
                                            {wealthStore.hasLLMInProcessItem && <MagicBoxAnimatedIconGrey width='16px' display='flex' fill='rgb(241,243,245)' />}
                                        </PieValueContainer>
                                }
                            </StatValue>
                        </StatContentWrapper>

                    </MobileStatBoxWrapper>

                </MobileCarouselBoxWrapper>


                <MobileCarouselBoxWrapper>
                    <MobileStatBoxWrapper padding={16}>
                        <div>
                            {
                                (wealthStore.overviewHeroCashMetrics.length > 1) ?
                                    <Dropdown
                                        overlay={
                                            CashViewDropDown({
                                                itemsList: wealthStore.overviewHeroCashMetrics,
                                                selectedIndex: cashView,
                                                setIndex: (index) => setCashView(index)
                                            })
                                        }
                                        getPopupContainer={() => document.getElementById('wealthPageContent')}
                                        trigger={['click']}
                                        placement="bottomCenter"
                                    >
                                        <StatDescriptionBig width={'155px'} isWealthView>
                                            {capitalize(wealthStore.overviewHeroCashMetrics[cashView].label)}
                                            <ArrowDownIcon />
                                        </StatDescriptionBig>
                                    </Dropdown> :
                                    <StatDescriptionBig isWealthView>Current cash position</StatDescriptionBig>
                            }
                            <StatTimeFrameSelectorWrapper>
                                {
                                    (1 === cashView) &&
                                    <Dropdown
                                        overlay={
                                            TimeFrameDropDown({
                                                itemsList: wealthStore.overviewHeroCashMetrics[1].subItems.map(item => item.label),
                                                selectedKey: wealthStore.overviewHeroCashMetrics[1].subItems[selectedCapitalCommitmentView].label,
                                                selectTimeFrame: (_key, index) => setSelectedCapitalCommitmentView(index)
                                            })
                                        }
                                        getPopupContainer={() => document.getElementById('wealthPageContent')}
                                        trigger={['click']} placement="bottomCenter" >
                                        <ValueChangesButton borderLess color={colors.gray2} >
                                            {capitalize(wealthStore.overviewHeroCashMetrics[1].subItems[selectedCapitalCommitmentView].label)}
                                            <ArrowDownIcon />
                                        </ValueChangesButton>
                                    </Dropdown>
                                }
                            </StatTimeFrameSelectorWrapper>
                        </div>
                        <StatContentWrapper style={{ height: 'auto' }}>
                            <StatValue>{CashViewValue(wealthStore, cashView, selectedCapitalCommitmentView, uiStore.isIncognitoMode)}</StatValue>
                        </StatContentWrapper>
                    </MobileStatBoxWrapper>
                </MobileCarouselBoxWrapper>

                <MobileCarouselBoxWrapper>
                    <MobileStatBoxWrapper padding={16}>
                        <div>
                            <StatDescriptionBig isWealthView>Inflow <ModifiedTooltip overlayInnerStyle={{ whiteSpace: 'pre-line', width: '276px', lineHeight: '16px' }} title={`The sum of all inflow events recorded in Vyzer.
    Note: Transactions from 'Cash accounts' and 'Credit cards' that aren't linked to any event are excluded from this calculation.`}><TooltipIcon /></ModifiedTooltip></StatDescriptionBig>
                            <StatTimeFrameSelectorWrapper>
                                <Dropdown
                                    overlay={
                                        TimeFrameDropDown({
                                            itemsList: wealthStore.totalDistributions.map(item => item.label).reverse(),
                                            selectedKey: selectedTimeFrameKey,
                                            selectTimeFrame: (key, index) => { setSelectedTimeFrameKey(key); setSelectedTimeFrameIndex(index) }
                                        })
                                    }
                                    getPopupContainer={() => document.getElementById('wealthPageContent')}
                                    trigger={['click']} placement="bottomCenter" >
                                    <ValueChangesButton borderLess color={colors.gray2} >{capitalize(selectedTimeFrameKey)}<ArrowDownIcon /></ValueChangesButton>
                                    {/* <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    Hover me <DownOutlined />
                                </a> */}
                                </Dropdown>

                            </StatTimeFrameSelectorWrapper>
                        </div>
                        <StatContentWrapper>
                            {wealthStore.totalDistributions.length ? <StatValue>
                                {
                                    uiStore.isIncognitoMode ? displayMoneyValue(wealthStore.totalDistributions.find(i => i.label === selectedTimeFrameKey).actual, 'USD', true) :
                                        <PieValueContainer>
                                            <CountAnimation currency={'USD'} value={wealthStore.totalDistributions.find(i => i.label === selectedTimeFrameKey).actual} />
                                            {wealthStore.hasLLMInProcessItem && <MagicBoxAnimatedIconGrey width='16px' display='flex' fill='rgb(241,243,245)' />}
                                        </PieValueContainer>
                                }
                            </StatValue> : null}
                        </StatContentWrapper>

                    </MobileStatBoxWrapper>
                </MobileCarouselBoxWrapper>

            </Slider>
            {/* </Carousel> */}
        </MobileStatsWrapper>
    )}</Observer>)
}


function TimeFrameDropDown(props) {
    const { itemsList, selectTimeFrame, selectedKey } = props;
    return (
        <MenuWrapperWealthPie>
            {itemsList && itemsList.map((item, index) => (
                <MenuItemWrapperWealthPie key={index} onClick={() => { selectTimeFrame && selectTimeFrame(item, index) }}>{capitalize(item)} {selectedKey === item && <CheckmarkIcon />}</MenuItemWrapperWealthPie>
            ))}
        </MenuWrapperWealthPie>
    )
}

function CashViewDropDown(props) {
    const { itemsList, setIndex, selectedIndex } = props;
    return (
        <MenuWrapperWealthPieDropdown>
            {
                itemsList && itemsList.map((item, index) => {
                    const checked = (selectedIndex === index);
                    return (
                        <MenuWrapperWealthPieDropdownItem onClick={() => setIndex(index)} key={index}>
                            <MenuWrapperWealthPieDropdownItemTextWrapper checked={checked}>
                                <MenuWrapperWealthPieDropdownItemTitle>{capitalize(item.label)}</MenuWrapperWealthPieDropdownItemTitle>
                                {
                                    (item.description) &&
                                    <MenuWrapperWealthPieDropdownItemSubTitle>{item.description}</MenuWrapperWealthPieDropdownItemSubTitle>
                                }
                            </MenuWrapperWealthPieDropdownItemTextWrapper>
                            {(checked) && <CheckmarkIcon />}
                        </MenuWrapperWealthPieDropdownItem>
                    );
                })
            }
        </MenuWrapperWealthPieDropdown>
    )
}

function CashViewValue(wealthStore, cashView, selectedCapitalCommitmentView, isIncognitoMode) {
    const { filtersStore } = useStore();
    const history = useHistory();
    const value = (0 === cashView) ? wealthStore.currentCashPosition : wealthStore.overviewHeroCashMetrics[1].subItems[selectedCapitalCommitmentView].amount;

    const onGoToAsset = () => {
        const selectedOption = wealthStore.overviewHeroCashMetrics[1].subItems[selectedCapitalCommitmentView];
        navigateToUrl(history, `/wealth/assets/${getUrlDisplayName(selectedOption.classTitle)}/${selectedOption.itemId}`);
    }

    const showUncalledCapitalFilter = () => {
        filtersStore.setListOptionSelectedFromAllocation('capitalCommitments', 'uncalled_capital');
        const urlParams = filtersStore.setAppliedFiltersFromSelection();
        navigateToUrl(history, ROUTES.wealthOverview.url + `?filters=${urlParams}`, false, true);
    }

    return (<>
        {
            isIncognitoMode ?
                displayMoneyValue(value, 'USD', true) :
                <div>
                    <PieValueContainer>
                        <CountAnimation currency={'USD'} value={value} />
                        {wealthStore.hasLLMInProcessItem && <MagicBoxAnimatedIconGrey width='16px' display='flex' fill='rgb(241,243,245)' />}
                    </PieValueContainer>
                    {
                        (1 === cashView) &&
                        <ModifiedTooltip title={1 === selectedCapitalCommitmentView ? 'Go to next call' : 'Go to all uncalled capital'} >
                            <EventDateContainer onClick={(1 === selectedCapitalCommitmentView) ? onGoToAsset : showUncalledCapitalFilter}>
                                {
                                    value &&
                                    ((1 === selectedCapitalCommitmentView) ?
                                        <div style={{ fontSize: '12px' }}>{moment(wealthStore.overviewHeroCashMetrics[1].subItems[selectedCapitalCommitmentView].date).format('MMM YY')}</div> :
                                        <div style={{ fontSize: '12px' }}>All uncalled capital</div>)
                                }
                                <EventDateArrowLink >
                                    <CtaArrowSvg strokeColor={"#ACB1BB"} />
                                </EventDateArrowLink>
                            </EventDateContainer>
                        </ModifiedTooltip>
                    }
                </div>
        }
    </>);
}