import { Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from '../../../common/constants/routes.const';
import { ls } from '../../../common/utils/localstorage.util';
import { removeUrlParams } from '../../../common/utils/navigation.utils';
import { useStore } from '../../app/data/root.store';
import { DesktopLayout } from './DesktopLayout';
import { MobileLayout } from './MobileLayout';
import { datadogRum } from '@datadog/browser-rum';

export const Layout = () => {
  const { userStore, uiStore, metadataStore, filtersStore, dataLayersStore, wealthStore, assistanceStore } = useStore();
  const history = useHistory();
  const urlParams = new URLSearchParams(useLocation().search);
  const isAfterPayment = urlParams.get('subscribed_success') !== null;
  const isBackToPricingModal = urlParams.get('pricing_modal') !== null;
  const messagingOrigin = process.env.REACT_APP_MOBILE_APP_MESSAGING_ORIGIN;

  useEffect(() => {
    uiStore.setAxiosInterceptors();
    const fetchUserData = async () => {
      const userData = await userStore.fetchData(metadataStore, uiStore, wealthStore, filtersStore, dataLayersStore, assistanceStore);
      if (userData && ls.get('isAfterLogin')) {
        ls.remove('isAfterLogin');
        filtersStore.handleClearFilterSelections(true);
        if (userData.sharedAccess.length > 0 && userData.profile.tier !== 'NONE' && userData.profile.onBoardingStep !== 'questionnaire') {
          window.location.href = ROUTES.portfolios.url
        }
      }
    }

    fetchUserData();

    return () => { }
  }, [userStore, uiStore, wealthStore, metadataStore, filtersStore, dataLayersStore, assistanceStore]);



  useEffect(() => {
    if (userStore.isAuthenticated) {
      datadogRum.setUser({
        id: userStore.data.id
      });
    }
  }, [userStore.isAuthenticated, userStore.data.id])

  useEffect(() => {
    const handleResize = () => {
      uiStore.setWindowSize();
    }
    window.addEventListener("resize", handleResize);

    const handleMessage = (event) => {
      // Ensure the message is from the expected origin
      if (event.origin !== messagingOrigin) return;
      if (event?.data?.flag === 'fromAppWebView') {
        uiStore.setIsMobileAppView(true);
      }

      // const data = JSON.parse(event.data);
      // alert(data);
      // if (data.flag === 'fromAppWebView') {
      //   alert('Message received from WebView:' + JSON.stringify(data));
      //   console.log('Message received from WebView:', data);
      //   // Handle the flag or other data as needed
      // }
    };

    window.addEventListener('message', handleMessage);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener('message', handleMessage);
    }
  }, [uiStore, messagingOrigin]);

  useEffect(() => {
    if (
      //isMobileAfterPaymentModal || 
      isAfterPayment || isBackToPricingModal) {
      removeUrlParams(history);
    }
    // return () => {}
  }, [
    //isMobileAfterPaymentModal, 
    isAfterPayment, isBackToPricingModal, history])

  return (<Observer>{() => (uiStore.isDesktopView ? <DesktopLayout /> : (userStore.isLoaded ? <MobileLayout /> : null))}</Observer>);
}
