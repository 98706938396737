import styled from "@emotion/styled";

export const CommunityInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const InputContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 8px;
  
  width: 100%;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  ${props=>props.isEmpty ? `` : `
    min-height: 48px;  
  `}
  padding: ${props=>props.isEmpty ? '0px 8px' : '8px'};
  background: white;
  &:focus-within {
    border-color: #000;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  cursor: text;
  align-items: center;
  width: 100%;
`;

export const TextInput = styled.input`
  border: none;
  outline: none;
  flex: 1;
  min-width: 100px;
  height: 30px;
  font-size: 16px;
  
  &::placeholder {
    color: #757575;
  }
`;

export const DropdownButton = styled.button`
  background: none;
  border: none;
  padding: 8px;
  padding-right: 2px;
  padding-top:9px;
  cursor: pointer;
  display: flex;
  align-items: center;
  // transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
  // transition: transform 0.2s ease;
  // padding-bottom:${props=>props.isOpen ? '10px' : '8px'};
  // padding-left:${props=>props.isOpen ? '2px' : '8px'};
`;

export const DropdownList = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  min-height: 48px;
  max-height: 201px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

export const DropdownContent = styled.div`
  padding: 8px 12px;
  width: 100%;
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const AddManuallyButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.2s;
  background: white;
  border: none;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  text-align: left;

  &:hover {
    background: #F7F8F9;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const CommunityItem = styled.div`
  height: 32px;
  padding: 4px 16px 4px 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: background 0.2s;
  background: ${props => props.isSelected ? 'var(--Main-Colors-Dark-Grey-2, #585D66)' : props.isCustom ? '#F5F5F5' : 'white'};
  border-radius: ${props => props.isSelected ? '29px' : '20px'};
  border: 1px solid var(--Main-Colors-Grey-3, #DDE3EB);
  margin: 4px 0;
  width: fit-content;
  max-width: 100%;
  &:hover {
    background: ${props => props.isSelected ? 'var(--Main-Colors-Dark-Grey-2, #585D66)' : 'white'};
    border: ${props => props.isSelected ? '1px solid var(--Main-Colors-Grey-3, #DDE3EB)' : '1px solid var(--Main-Colors-Dark-Grey-1, #1A1B1D)'};
  }
`;

export const CommunityLogo = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  background-color: #F5F5F5;
`;

export const CommunitySelectedLogo = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  background-color: #F5F5F5;
`;

export const CommunityName = styled.span`
  flex: 1;
  font-size: 16px;
  color: ${props => props.isSelected ? '#fff' : '#000'};
`;

export const CheckMark = styled.div`
  display: ${props => props.isSelected ? 'block' : 'none'};
  width: 20px;
  height: 20px;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 6px;
    border: 2px solid white;
    border-top: none;
    border-right: none;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

export const SelectedItem = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  color: var(--Dark-Gray-1, #1A1B1D);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  background: ${props => props.isCustom ? '#fff' : 'white'};
  border-radius: 20px;
  border: 1px solid var(--Main-Colors-Grey-3, #DDE3EB);
`;

export const RemoveButton = styled.button`
  border: none;
  padding: 2px 4px 2px 4px;
  cursor: pointer;
  color: #757575;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  min-width: 20px;
  &:hover {
    color: #000;
  }
  svg {
    path{
      stroke: #757575;
    }
  }
`;