import { useEffect, useState } from "react";
import { CloseXIcon } from "../../../../../assets/icons/common/common-icons"
import { CloseButtonWrapper, NextStepButton, NextStepButtonWrapper, Step2DataBoxIconWrapper, Step2DataBoxTextDescription, Step2DataBoxTextTitle, Step2DataBoxTextWrapper, Step2DataBoxWrapper, StepBigTitle, StepContentWrapper, StepIndicatorLineBg, StepIndicatorLineFiller, StepsIndicationsWrapper, StepSmallTitle, StepSubTitle, WelcomeModalBackdrop, WelcomeModalBg, WelcomeModalContent } from "./AssistanceMobileWelcomeModal.styles"
import { AssistanceHeroLogoText, AssistanceHeroLogoWrapper, WelcomeButtonMobile } from "../../Assistance.styles";
import { VyzerAIAssistanceIcon, WelcomeRightArrow } from "../../../assets/icons";
import { observer } from "mobx-react";
import { useStore } from "../../../../app/data/root.store";




export const AssistanceMobileWelcomeModal = observer(({ onClose }) => {
  const { userStore } = useStore();

  const [currentStep, setCurrentStep] = useState(-1);

  useEffect(() => {
    setTimeout(() => {
      setCurrentStep(0);
    }, 100);
  }, []);

  const handleNextStep = () => {
    if (currentStep !== 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onClose && onClose();
    }
  }

  return (
    <>
      <WelcomeModalBackdrop>
        <WelcomeModalContent>
          <WelcomeModalBg opacity={currentStep === 1 ? 0.2 : 0.8} />
          <CloseButtonWrapper onClick={() => onClose && onClose()}>
            <CloseXIcon />
          </CloseButtonWrapper>
          <StepsIndicationsWrapper>
            <StepIndicatorLineBg>
              <StepIndicatorLineFiller isActive={currentStep >= 0} />
            </StepIndicatorLineBg>
            <StepIndicatorLineBg>
              <StepIndicatorLineFiller isActive={currentStep === 1} />
            </StepIndicatorLineBg>
          </StepsIndicationsWrapper>
          <StepContentWrapper>
            {currentStep <= 0 && (
              <>
                <AssistanceHeroLogoWrapper>
                  <VyzerAIAssistanceIcon />
                  <AssistanceHeroLogoText>{'AI-BETA'}</AssistanceHeroLogoText>
                </AssistanceHeroLogoWrapper>
                <StepBigTitle>Hello, {userStore.granteeFirstNameCapitalize}</StepBigTitle>
                <StepSubTitle>
                  I'm Vyzer AI.<br />I'm here to answer financial questions<br />about your portfolio.
                  <br /><br />
                  This feature is in <b>Beta</b> —<br />your feedback helps improve it for you.
                </StepSubTitle>
              </>
            )}
            {currentStep === 1 && (
              <>
                <StepSmallTitle>I can assist with:</StepSmallTitle>
                <Step2DataBoxWrapper>
                  <Step2DataBoxIconWrapper>
                    <InsightIcon />
                  </Step2DataBoxIconWrapper>
                  <Step2DataBoxTextWrapper>
                    <Step2DataBoxTextTitle>Uncover insights</Step2DataBoxTextTitle>
                    <Step2DataBoxTextDescription>Explore trends and performance across your portfolio</Step2DataBoxTextDescription>
                  </Step2DataBoxTextWrapper>
                </Step2DataBoxWrapper>
                <Step2DataBoxWrapper>
                  <Step2DataBoxIconWrapper>
                    <QuestionIcon />
                  </Step2DataBoxIconWrapper>
                  <Step2DataBoxTextWrapper>
                    <Step2DataBoxTextTitle>Ask questions</Step2DataBoxTextTitle>
                    <Step2DataBoxTextDescription>Dive into your portfolio, get financial summaries quickly and easily.</Step2DataBoxTextDescription>
                  </Step2DataBoxTextWrapper>
                </Step2DataBoxWrapper>
                <Step2DataBoxWrapper>
                  <Step2DataBoxIconWrapper>
                    <SaveTimeIcon />
                  </Step2DataBoxIconWrapper>
                  <Step2DataBoxTextWrapper>
                    <Step2DataBoxTextTitle>Save time</Step2DataBoxTextTitle>
                    <Step2DataBoxTextDescription>Get accurate, real-time answers without the need to dig it through</Step2DataBoxTextDescription>
                  </Step2DataBoxTextWrapper>
                </Step2DataBoxWrapper>
              </>
            )}
          </StepContentWrapper>
          <NextStepButtonWrapper onClick={() => handleNextStep()}>
            <NextStepButton>
              {(currentStep === 1) ?
                <WelcomeButtonMobile>
                  {"Ask your first question"}
                  <WelcomeRightArrow />
                </WelcomeButtonMobile> :
                "Next"
              }</NextStepButton>
          </NextStepButtonWrapper>

        </WelcomeModalContent>
      </WelcomeModalBackdrop>
    </>

  )
});

const InsightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <path d="M5 10C5 7.79086 6.79086 6 9 6H23C25.2091 6 27 7.79086 27 10V22.8421C27 25.0512 25.2091 26.8421 23 26.8421H9C6.79086 26.8421 5 25.0512 5 22.8421V10Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.60001 22.3333L9.60001 16.5L11.6 16.5L11.6 22.3333L9.60001 22.3333Z" fill="#1A1B1D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15 22.333V11.833H17V22.333H15Z" fill="#1A1B1D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.4 22.3337L20.4 14.167H22.4L22.4 22.3337H20.4Z" fill="#1A1B1D" />
  </svg>
)

const QuestionIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M18 5.5H19C23.9706 5.5 28 9.52944 28 14.5V21.5H24.8937C24.9638 21.0101 25 20.5093 25 20C25 14.201 20.299 9.5 14.5 9.5H13.5C12.609 9.5 11.7416 9.60134 10.9088 9.79311C12.2456 7.24119 14.9194 5.5 18 5.5ZM8.4003 10.6897C9.61458 6.53496 13.4529 3.5 18 3.5H19C25.0751 3.5 30 8.42487 30 14.5V21.5V23.5H28H24.4025C22.9611 27.5782 19.0718 30.5 14.5 30.5H2V21C2 16.4802 4.6075 12.5693 8.4003 10.6897Z" fill="white" />
    <line x1="9" y1="18.5" x2="14" y2="18.5" stroke="#1A1B1D" strokeWidth="2" />
    <line x1="9" y1="23" x2="17.75" y2="23" stroke="#1A1B1D" strokeWidth="2" />
  </svg>
)
const SaveTimeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M23 2.5V0.5H24V2.5H26V3.5H24V5.5H23V3.5H21V2.5H23ZM14 5.5H11.75V3.75H18.25V5.5H16V7.04484C21.6065 7.55007 26 12.262 26 18C26 24.0751 21.0751 29 15 29C8.92487 29 4 24.0751 4 18C4 12.262 8.3935 7.55007 14 7.04484V5.5ZM29 9.5V7.5H30V9.5H32V10.5H30V12.5H29V10.5H27V9.5H29Z" fill="white" />
    <path d="M14.7815 12.5L14.7815 20.0008L20.5 20.0009" stroke="#1A1B1D" strokeWidth="2" strokeLinejoin="round" />
  </svg>
)
