import styled from "@emotion/styled";
import { useState } from 'react';
import { colors } from "../../../common/styles/theme.styles";
import { flexCenter } from "../../../common/styles/combos.styles";

export const FeedbackDialogWrapper = styled.div`
    top: 100%;
    right: 0;
    z-index: 1000;
    margin-top: 8px;
`;


const DialogOverlay = styled.div`
  position: relative;
  background: white;
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  background-color: ${colors.lightGray1};
`;

const DialogTitle = styled.h3`
  font-size: 14px;
  color: ${colors.darkGray2};
  margin: 0 0 20px 0;
  font-style: normal;
  font-weight: 450;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const OptionButton = styled.button`
  padding: 8px 16px;
  background-color: #FFF;
  border: 1px solid ${props => props.selected ? colors.darkGray1 : colors.gray3};
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  color: #1A1B1D;
  transition: all 0.2s ease;
  flex: 0 0 calc(33% - 8px);

  &:hover {
    border: 1px solid ${colors.darkGray1};
    color: ${colors.darkGray1};
  }
`;

const OptionSubmitButton = styled.button`
${flexCenter}
  height: 30px;
  padding: 6px 10px;
  background-color: #FFF;
  border: 1px solid ${props => props.selected ? colors.darkGray1 : colors.gray3};
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  color: #1A1B1D;
  transition: all 0.2s ease;
  border-radius: 28px;
  background: var(--Main-Colors-Dark-Grey-1, #1A1B1D);
  &:hover {
    border: 1px solid ${colors.darkGray1};
    color: ${colors.darkGray1};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: #666;
  font-size: 18px;

  &:hover {
    color: #333;
  }
`;

const options = [
  'Too Long',
  'Too Generic',
  'Inaccurate',
  'Missing Information',
  'Too Slow',
  'Other...'
];

export const FeedbackDialog = ({ onClose, onSubmit }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showTextArea, setShowTextArea] = useState(false);
  const [otherFeedback, setOtherFeedback] = useState('');

  const handleOptionClick = (option) => {
    if (option === 'Other...') {
      setShowTextArea(true);
      setSelectedOption(option);
    } else {
      setSelectedOption(option);
      onSubmit(option);
    }
  };

  const handleTextAreaSubmit = () => {
    onSubmit(otherFeedback);
  };

  return (
    <DialogOverlay>
      <CloseButton onClick={onClose}>×</CloseButton>
      <DialogTitle>What didn't you like about this response? (optional)</DialogTitle>
      {!showTextArea ? (
        <OptionsContainer>
          {options.map((option) => (
            <OptionButton
              key={option}
              selected={selectedOption === option}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </OptionButton>
          ))}
        </OptionsContainer>
      ) : (
        <div style={{ position: 'relative' }}>
          <textarea
            value={otherFeedback}
            onChange={(e) => setOtherFeedback(e.target.value)}
            maxLength={200}
            placeholder="Please provide your feedback..."
            style={{
              width: '100%',
              minHeight: '100px',
              padding: '8px',
              borderRadius: '8px',
              border: `1px solid ${colors.gray3}`,
              marginBottom: '16px',
              resize: 'vertical'
            }}
          />
          <div style={{
            position: 'absolute',
            bottom: '30px',
            right: '10px',
            color: otherFeedback.length === 200 ? '#ff4d4f' : '#9097A3',
            fontSize: '12px'
          }}>
            <div style={{ display: 'flex' }}>
              <OptionSubmitButton
                onClick={handleTextAreaSubmit}
                disabled={!otherFeedback.trim()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 6V18M12 6L6 11M12 6L18 11" stroke="white" strokeWidth="1.5" />
                </svg>
              </OptionSubmitButton>
            </div>
          </div>
        </div>
      )}
    </DialogOverlay>
  );
};