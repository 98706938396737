import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../../common/styles/combos.styles";
import { colors, shadows } from "../../../../../common/styles/theme.styles";

export const FilterOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 16px 24px;
`;

export const ListParameterOption = styled.div`
  ${flexCenter}
  box-shadow: ${shadows.toggleShadow};
  border-radius: 16px;
  padding: 4px 8px;
  width: fit-content;
  min-width: 32px;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  ${props=>props.isSelected ? `background:${colors.darkGray3}; color:#fff;` : ``};
  user-select: none;
  border: 1px solid transparent;
  :hover{
    border: 1px solid #585D66;
  }
`;