import { Select } from 'antd';
import { Observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ArrowDownIcon, ExternalIcon, MobileBackButton, ModalCloseIcon } from '../../../assets/icons/common/common-icons';
import { ModifiedSelectClearIcon } from '../../../common/components/ModifiedSelectClearIcon/ModifiedSelectClearIcon';
import { ModifiedTooltip } from '../../../common/components/ModifiedTooltip/ModifiedTooltip';
import { countryListAlpha3, countryListPhone } from '../../../common/constants/countries.const';
import { ROUTES } from '../../../common/constants/routes.const';
import { getTranslations } from '../../../common/translations/translations';
import { isNullOrUndefined } from '../../../common/utils/object.utils';
import { useAnalytics } from '../../app/data/AnalyticsProvider';
import { useStore } from '../../app/data/root.store';
import { AppHeader } from '../../layout/components/Header/Header';
import { HeaderWrapper } from '../../layout/components/Header/Header.styles';
import { PageWrapper, RightSidePushMenuWrapper } from '../../layout/components/Layout.styles';
import { MobileBackButtonWrapper } from '../../layout/components/MobileLayout.styles';
import { CardContentBoxWrapper } from '../../wealth/components/wealth.commons.styles';
import { Conformation } from '../../wealth/pages/asset/components/conformation/Conformation';
import { getProfileFinancialSettings } from '../data/profile.mixpanel-events';
import { ChangePasswordModal } from './ChangePasswordModal/ChangePasswordModal';
import { CommunityInput } from './CommunityInput/CommunityInput';
import { ExportAllDataModal } from './ExportAllDataModal/ExportAllDataModal';
import { PhoneInput } from './PhoneInput/PhoneInput';
import { ActionsButton, ActionsStickyWrapper, ActionsWrapper, ButtonContentWrapper, DataBoxFormContentWrapper, DataBoxFormTitle, DataBoxFormWrapper, DataBoxHeader, DataBoxHeaderWrapper, DataBoxInfoWrapper, DataBoxInputElement, DataBoxInputLabel, DataBoxInputWrapper, DataBoxPhoneInputWrapper, DataBoxSubTitle, DataBoxTitle, FloatingLabeInInput, HeaderTitle, InfoBoxDescription, InfoBoxTitle, InfoBoxWrapper, InputWithLabelOnTopWrapper, ProfileDesktopBackLink, ProfileMobileHeader, RadioButtonsWrapper, StyledDatePicker, StyledInput, StyledRadioOption, StyledSelect } from './Profile.styles';
import { fetchVerifiedCommunities } from '../data/verified-communities.service';

const familyStatuses = ['Single', 'Married', 'Divorced', 'Widowed', 'Complicated'];
const kidsOption = [{ val: 1, text: '0-1' }, { val: 2, text: '2-3' }, { val: 3, text: '4+' }];
const investorTypes = {
  none_accredited: {
    title: 'Non-Accredited Investor',
    description: 'Investor below accredited status thresholds.',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.23882 20.8174C6.23889 20.8174 6.23896 20.8174 6.23902 20.8174C6.92888 21.2067 7.67849 21.5162 8.47897 21.7307C13.6002 23.103 18.8643 20.0638 20.2365 14.9425C21.6088 9.82122 18.5696 4.55718 13.4483 3.18494C12.6479 2.97046 11.8439 2.86375 11.0519 2.85591C11.0518 2.85586 11.0517 2.8558 11.0516 2.85575L11.0516 2.85591C11.0517 2.85591 11.0518 2.85591 11.0519 2.85591C14.7756 4.95695 16.7579 9.37936 15.6002 13.7002C14.4424 18.021 10.5144 20.8597 6.23902 20.8174C6.23897 20.8174 6.23891 20.8173 6.23885 20.8173L6.23882 20.8174Z" fill="#1A1B1D" />
    </svg>
  },
  accredited: {
    title: 'Accredited Investor',
    description: '$1M+ net worth or $200K+ annual income.',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.27557 19.7748C5.27808 19.7743 5.28058 19.7739 5.28309 19.7734C6.34962 20.7013 7.63847 21.4071 9.09597 21.7976C14.2172 23.1699 19.4813 20.1307 20.8535 15.0094C22.2258 9.88813 19.1866 4.6241 14.0653 3.25186C12.6079 2.86134 11.1389 2.8281 9.75133 3.09843C9.74935 3.09671 9.74737 3.09499 9.74539 3.09326L9.7436 3.09994C9.74617 3.09944 9.74875 3.09894 9.75133 3.09843C12.4323 5.43111 13.7084 9.16795 12.7267 12.8317C11.7449 16.4956 8.77131 19.0938 5.28309 19.7734C5.28116 19.7717 5.27924 19.77 5.27731 19.7683L5.27557 19.7748Z" fill="#1A1B1D" />
    </svg>
  },
  qualified_client: {
    title: 'Qualified Client',
    description: '$2.2M+ net worth or $1.1M+ under management.',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_668_26357)">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.3912 18.5173C4.3937 18.5162 4.3962 18.5151 4.39869 18.514C5.64538 20.2155 7.45859 21.5196 9.65464 22.108C14.7759 23.4803 20.0399 20.4411 21.4122 15.3198C22.7844 10.1985 19.7452 4.93449 14.624 3.56225C12.428 2.97383 10.2057 3.19655 8.27528 4.04674C8.27364 4.04451 8.27201 4.04228 8.27037 4.04005L8.26768 4.05009C8.27021 4.04898 8.27274 4.04786 8.27528 4.04674C9.93591 6.31326 10.5914 9.28505 9.80755 12.2103C9.02373 15.1355 6.97015 17.3815 4.39869 18.514C4.39708 18.5118 4.39547 18.5096 4.39386 18.5074L4.3912 18.5173Z" fill="#1A1B1D" />
      </g>
      <defs>
        <clipPath id="clip0_668_26357">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  },
  qualified_purchaser: {
    title: 'Qualified Purchaser',
    description: '$5M+ in investments owned directly / jointly.',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
      <rect x="2" y="2.5" width="20" height="20" rx="10" fill="#1A1B1D" />
    </svg>
  }
};

export function ProfilePage({ isPopup, onClosePopup }) {
  const { Option } = Select;
  const [haveChanges, setHaveChanges] = useState(true);
  const [haveInvestorProfileChanges, setHaveInvestorProfileChanges] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const { mixpanelTrack } = useAnalytics()
  const [showExportDataModal, setShowExportDataModal] = useState(false);
  const { userStore, uiStore } = useStore();
  const { isDemoMode: is_demo } = userStore;
  let history = useHistory();
  const [userFirstName, setUserFirstName] = useState(userStore.granteeFirstNameCapitalize);
  const [userLastName, setUserLastName] = useState(userStore.granteeLastName);
  const [userPhoneNumber, setUserPhoneNumber] = useState({ value: userStore.phoneNumber, alpha3: userStore.phoneAlpha3 });
  const [isPhoneNumberWithError, setIsPhoneNumberWithError] = useState(false);
  const [editedChanges, setEditedChanges] = useState({});
  const isRoleBlocked = userStore.isActionBlockedByRole('updateFinancialSettings');
  const { INVESTOR_TYPE_TOOLTIP } = getTranslations().profile;

  const [verifiedCommunities, setVerifiedCommunities] = useState([]);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const fetchCommunities = useCallback(async () => {
    const verifiedCommunities = await fetchVerifiedCommunities();
    setVerifiedCommunities(verifiedCommunities);
    setSelectedCommunities(verifiedCommunities.filter(community => community.isSelected));
  }, []);

  useEffect(() => {
    userStore.fetchProfile();
    uiStore.setIsRightMenuOpen(false);
    uiStore.setIsUserMenuActive(true);
    fetchCommunities();
    return () => {
      uiStore.setIsUserMenuActive(false);
    }
  }, [userStore, uiStore, fetchCommunities]);

  useEffect(() => {
    setHaveChanges(
      userStore.granteeFirstNameCapitalize !== userFirstName ||
      userStore.granteeLastName !== userLastName ||
      userStore.granteePhoneNumber !== userPhoneNumber.value ||
      userStore.granteePhoneAlpha3 !== userPhoneNumber.alpha3
    );
  }, [userStore, userFirstName, userLastName, userPhoneNumber]);

  const handlePhoneChange = (phoneNumber) => {
    setIsPhoneNumberWithError(false);
    setUserPhoneNumber(phoneNumber);
  }

  const handleSavePersonalInfo = async () => {
    if ((isPopup && !haveChanges) || isUpdating) {
      return;
    }

    setIsUpdating(true);
    try {
      userStore.changeGranteeFullName(userFirstName + ' ' + userLastName);
      const { value: phoneNumber, alpha3 } = userPhoneNumber;
      const phoneNumberWithCountryCode = `${alpha3 ? countryListPhone[alpha3].code + '-' : ''}${phoneNumber}`;
      const isValid = await userStore.updatePhoneNumber(phoneNumberWithCountryCode, alpha3);
      setIsPhoneNumberWithError(!isValid);
      mixpanelTrack('Account Updated', {
        is_demo
      })
      setHaveChanges(false);
    } catch (error) {

    } finally {
      setIsUpdating(false);
    }
  }

  const handleCancelPersonalInfo = () => {
    if (isPopup && !haveChanges) {
      return;
    }
    setIsChangingPassword(false);
    setUserFirstName(userStore.granteeFirstNameCapitalize);
    setUserLastName(userStore.granteeLastName);
    setHaveChanges(false);
  }

  const handleBackClick = () => {
    if (userStore.isNoPortfolioSelected) {
      window.location.href = ROUTES.portfolios.url;
    } else {
      uiStore.setIsAppMobileMenuOpen(true)
    }
  }

  const handleDesktopBackClick = () => {
    if (userStore.isRelevantVariant === 3) {
      history.push(ROUTES.welcome.url);
    } else if (userStore.isNoPortfolioSelected) {
      history.push(ROUTES.portfolios.url);
    } else {
      if (uiStore.previousRoute === '') {
        history.push(ROUTES.wealth.url);
      } else {
        history.goBack();
      }
    }
  }

  const handleSelectCommunity = (item) => {
    setSelectedCommunities(prev => {
      let updatedItems = prev.slice();
      const currItemIndex = prev.findIndex(c => c.id === item.id);
      if (currItemIndex > -1) {
        updatedItems.splice(currItemIndex, 1);
      } else {
        updatedItems.push(item);
      }

      const updatedCommunities = updatedItems.reduce((acc, c) => {
        if (c.isCustom) {
          acc.customCommunities.push(c.name);
        } else {
          acc.verifiedCommunityIds.push(c.id);
        }
        acc.names.push(c.name);

        return acc;
      }, {
        verifiedCommunityIds: [],
        customCommunities: [],
        names: []
      });

      handleFieldChange('communities', updatedCommunities);

      return updatedItems;
    });
  }

  const handleFieldChange = (fieldName, value) => {
    if (isRoleBlocked) {
      return;
    };

    let updatedChanges = {};

    if (userStore.profileData[fieldName] !== value && (!isNullOrUndefined(userStore.profileData[fieldName]) || !isNullOrUndefined(value))) {
      updatedChanges = Object.assign({}, editedChanges, {
        [fieldName]: value
      })
    } else {
      updatedChanges = Object.assign({}, editedChanges);
      delete updatedChanges[fieldName];
    }

    const { communities, ...updatedProfileChanges } = updatedChanges;
    const updatedProfileData = Object.assign({}, userStore.profileData, updatedProfileChanges);
    const prevCommunities = verifiedCommunities?.filter(c => c.isSelected).map(c => c.name).sort();

    const isChanged = (JSON.stringify(userStore.profileData).length !== JSON.stringify(updatedProfileData).length) || (JSON.stringify(prevCommunities) !== JSON.stringify(communities.names.sort()));
    setEditedChanges(updatedChanges);
    setHaveInvestorProfileChanges((prev) => {
      userStore.updateProfile({
        [fieldName]: value
      });
      return isChanged;
    });
  }

  const handleCancelChanges = () => {
    userStore.cancelProfileUpdates([
      'country',
      'dateOfBirth',
      'familyStatus',
      'kids',
      'zipCode',
      'communities',
      'investorType'
    ]);
    setSelectedCommunities(verifiedCommunities.filter(community => community.isSelected));
    setEditedChanges({});
    setHaveInvestorProfileChanges(false);
  }

  const handleSaveChanges = async () => {
    setIsUpdating(true);
    try {
      const response = await userStore.saveProfileUpdates([
        'country',
        'dateOfBirth',
        'familyStatus',
        'kids',
        'zipCode',
        'communities',
        'investorType'
      ]);
      mixpanelTrack('Account Updated', getProfileFinancialSettings(response, is_demo, kidsOption));
      setEditedChanges({});
      setHaveInvestorProfileChanges(false);

    } catch (error) {

    } finally {
      setIsUpdating(false);
    }
  }

  return <Observer>{() => (<>
    {!isPopup && <>
      {uiStore.isDesktopView ? (
        (userStore.isViewingOwnPortfolio && !userStore.isNoPortfolioSelected) ? <AppHeader /> :
          <HeaderWrapper displayStyle={uiStore.headerStyle}><ProfileDesktopBackLink onClick={handleDesktopBackClick}><ProfileHeaderBackButton />Back to {userStore.isRelevantVariant === 3 ? 'select plan' : userStore.isNoPortfolioSelected ? 'Shared portfolios' : userStore.fullName}</ProfileDesktopBackLink></HeaderWrapper>
      ) :
        <ProfileMobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}>
          <MobileBackButtonWrapper onClick={handleBackClick}><MobileBackButton /></MobileBackButtonWrapper>
          <HeaderTitle>My profile</HeaderTitle></ProfileMobileHeader>}
    </>}
    <RightSidePushMenuWrapper />
    <PageWrapper withHeader={!isPopup} isPopup={isPopup} style={isPopup ? { padding: 0 } : {}}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', ...(isPopup ? { marginBottom: 0 } : {}) }}>
        <CardContentBoxWrapper borderLess={isPopup}  >
          {isPopup && <div style={{ position: 'absolute', width: '20px', height: '20px', right: 20, top: 20, cursor: 'pointer' }} onClick={() => { onClosePopup && onClosePopup() }}><ModalCloseIcon /></div>}
          <DataBoxHeaderWrapper style={isPopup ? { padding: 0 } : {}}>
            <DataBoxHeader borderBottom style={isPopup ? { padding: '24px' } : {}}>
              {uiStore.isDesktopView && <DataBoxTitle>Profile</DataBoxTitle>}
              <DataBoxSubTitle>Manage your account preferences</DataBoxSubTitle>
            </DataBoxHeader>
          </DataBoxHeaderWrapper>
          <DataBoxFormContentWrapper>
            <DataBoxFormWrapper>

              <DataBoxInputWrapper>
                <InputWithLabelOnTopWrapper>
                  <FloatingLabeInInput>First name</FloatingLabeInInput>
                  <StyledInput autoComplete="chrome-off" type='text' name='firstName' value={userFirstName} onChange={(e) => { setUserFirstName(e.target.value) }} />
                </InputWithLabelOnTopWrapper>
              </DataBoxInputWrapper>
              <DataBoxInputWrapper>
                <InputWithLabelOnTopWrapper>
                  <FloatingLabeInInput>Last name</FloatingLabeInInput>
                  <StyledInput autoComplete="chrome-off" type='text' name='lastName' value={userLastName} onChange={(e) => { setUserLastName(e.target.value) }} />
                </InputWithLabelOnTopWrapper>
              </DataBoxInputWrapper>

              <DataBoxInputWrapper>
                <DataBoxInputLabel>
                  Mobile number
                </DataBoxInputLabel>
                <DataBoxPhoneInputWrapper>
                  <PhoneInput
                    userPhoneNumber={userPhoneNumber}
                    handlePhoneChange={handlePhoneChange}
                    customWrapperStyle={{
                      height: '60px', width: '100%', borderRadius: '8px',
                      ...(isPhoneNumberWithError ? {
                        border: '1px solid #ff0000'
                      } : {})
                    }}
                    noFocus
                  />
                </DataBoxPhoneInputWrapper>
              </DataBoxInputWrapper>

              <DataBoxInputWrapper>
                <InputWithLabelOnTopWrapper>
                  <FloatingLabeInInput>
                    Email
                  </FloatingLabeInInput>
                  <StyledInput type='text' name='email' value={userStore.granteeEmail} disabled email={'true'} />
                </InputWithLabelOnTopWrapper>
              </DataBoxInputWrapper>
              <DataBoxInputWrapper>
                <DataBoxInputElement>
                  <ActionsButton onClick={() => setIsChangingPassword(true)} >{userStore.data.profile?.isPasswordEmpty ? 'Set' : 'Change'} password</ActionsButton>
                  {isChangingPassword && <ChangePasswordModal onClose={() => { setIsChangingPassword(false); userStore.updateData() }} />}
                </DataBoxInputElement>
              </DataBoxInputWrapper>
              {userStore.isGranteeHavePortfolio && !userStore.isQuestionnaire &&
                <DataBoxInputWrapper>
                  <DataBoxInputElement>
                    <ActionsButton onClick={() => { setShowExportDataModal(true) }}><ButtonContentWrapper><ExternalIcon /> Export all data</ButtonContentWrapper></ActionsButton>
                    {showExportDataModal && <ExportAllDataModal onClose={() => { setShowExportDataModal(false) }} isMobileAppView={uiStore.isMobileAppView} />}
                  </DataBoxInputElement>
                </DataBoxInputWrapper>}
            </DataBoxFormWrapper>
          </DataBoxFormContentWrapper>
          {(haveChanges || isPopup) &&
            <ActionsWrapper>
              <ActionsStickyWrapper style={isPopup ? { justifyContent: 'flex-end', gap: '16px' } : {}}>
                <ActionsButton borderless onClick={handleCancelPersonalInfo} disabled={isPopup && !haveChanges}>Cancel changes</ActionsButton>
                <ActionsButton black onClick={handleSavePersonalInfo} disabled={((isPopup && !haveChanges) || isUpdating)}>Save</ActionsButton>
              </ActionsStickyWrapper>
            </ActionsWrapper>
          }
        </CardContentBoxWrapper>

        {!userStore.isQuestionnaire && <CardContentBoxWrapper>
          <DataBoxFormContentWrapper>
            <DataBoxFormWrapper>
              <DataBoxFormTitle>
                Investor profile
              </DataBoxFormTitle>

              <DataBoxInputWrapper>
                <DataBoxInputLabel style={{ color: '#585D66', fontSize: '13px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal', display: 'flex', alignItems: 'center', gap: '4px' }}>
                  Type of investor
                  <ModifiedTooltip title={INVESTOR_TYPE_TOOLTIP} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd" d="M11.5 6.00087C11.5 9.03835 9.03661 11.5 5.99913 11.5C2.96165 11.5 0.5 9.03835 0.5 6.00087C0.5 2.96165 2.96165 0.5 5.99913 0.5C9.03661 0.5 11.5 2.96165 11.5 6.00087Z" fill="#9097A3" />
                      <path d="M5.5415 5.28076H6.45802V8.94728H5.5415V5.28076Z" fill="white" stroke="white" strokeWidth="0.251429" />
                      <path d="M5.54175 3.5658C5.54175 3.31271 5.74692 3.10754 6.00001 3.10754C6.2531 3.10754 6.45827 3.31271 6.45827 3.5658C6.45827 3.81889 6.2531 4.02406 6.00001 4.02406C5.74692 4.02406 5.54175 3.81889 5.54175 3.5658Z" fill="white" stroke="white" strokeWidth="0.628571" />
                    </svg>
                  </ModifiedTooltip>
                </DataBoxInputLabel>
                <DataBoxInputElement>
                  <StyledSelect

                    allowClear
                    optionFilterProp='label'
                    optionLabelProp='label'
                    labelInValue={false}
                    name='investorType'
                    placeholder="Select type"
                    value={userStore.profileData?.investorType}
                    onChange={(val) => {
                      if (val === undefined) {
                        handleFieldChange('investorType', null);
                      } else {
                        handleFieldChange('investorType', val);
                      }
                    }}
                    suffixIcon={<ArrowDownIcon />}
                    clearIcon={<ModifiedSelectClearIcon title={'Clear'} />}
                  >
                    {Object.entries(investorTypes).map(([key, value]) => (
                      <Option value={key} label={<div style={{ fontWeight: '450' }}>{value.title}</div>} >
                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px', padding: '8px 0' }}>
                          {value.icon}
                          <div>
                            <div style={{ color: '#1A1B1D', fontSize: '14px', fontWeight: '500', lineHeight: 'normal' }}>{value.title}</div>
                            <div style={{ color: 'var(--Main-Colors-Dark-Grey-3, #9097A3)', fontSize: '12px', fontWeight: '450', lineHeight: 'normal' }}>{value.description}</div>
                          </div>
                          {userStore.profileData?.investorType === key && (
                            <div style={{ marginLeft: 'auto' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
                                <path d="M11.5106 1.104L4.5061 8.1125L1.00008 4.60754" stroke="black" strokeWidth="2" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </Option>
                    ))}
                  </StyledSelect>
                </DataBoxInputElement>
              </DataBoxInputWrapper>

              <DataBoxInputWrapper>
                <DataBoxInputLabel>
                  Communities
                </DataBoxInputLabel>
                <DataBoxInputElement>
                  <CommunityInput
                    communities={verifiedCommunities}
                    selectedCommunities={selectedCommunities}
                    handleSelectCommunity={handleSelectCommunity}
                  />
                </DataBoxInputElement>
              </DataBoxInputWrapper>

              <DataBoxInputWrapper>
                <DataBoxInputLabel>
                  Country
                </DataBoxInputLabel>
                <DataBoxInputElement>
                  <StyledSelect name='country' autoComplete="chrome-off"

                    value={userStore.profileData.country} showSearch allowClear optionFilterProp='label'
                    onChange={(val) => { handleFieldChange('country', val || '') }}
                    suffixIcon={<ArrowDownIcon />} >
                    {Object.entries(countryListAlpha3).map(([value, label]) => (
                      <Option key={value} value={value} label={label}>{label}</Option>
                    ))}
                  </StyledSelect>
                </DataBoxInputElement>
              </DataBoxInputWrapper>

              <DataBoxInputWrapper>
                <DataBoxInputLabel>
                  Date of birth
                </DataBoxInputLabel>
                <DataBoxInputElement>
                  <StyledDatePicker

                    value={userStore.profileData?.dateOfBirth ? moment(userStore.profileData?.dateOfBirth) : ''}
                    name='dateOfBirth'
                    onChange={(val) => { handleFieldChange('dateOfBirth', (val ? val.format() : null)) }}
                  />
                </DataBoxInputElement>
              </DataBoxInputWrapper>

              <DataBoxInputWrapper>
                <DataBoxInputLabel>
                  Family status
                </DataBoxInputLabel>
                <DataBoxInputElement>
                  <RadioButtonsWrapper>
                    {familyStatuses.map((fs) => (
                      <StyledRadioOption key={fs} isSelected={userStore.profileData.familyStatus === fs}

                        onClick={() => { handleFieldChange('familyStatus', userStore.profileData.familyStatus === fs ? null : fs) }}>
                        {fs}
                      </StyledRadioOption>
                    ))}
                  </RadioButtonsWrapper>
                </DataBoxInputElement>
              </DataBoxInputWrapper>

              <DataBoxInputWrapper>
                <DataBoxInputLabel>Kids</DataBoxInputLabel>
                <DataBoxInputElement>
                  <RadioButtonsWrapper>
                    {kidsOption.map((fs) => (
                      <StyledRadioOption key={fs.val} isSelected={userStore.profileData.kids === fs.val}

                        onClick={() => { handleFieldChange('kids', userStore.profileData.kids === fs.val ? null : fs.val) }}>
                        {fs.text}
                      </StyledRadioOption>
                    ))}
                  </RadioButtonsWrapper>
                </DataBoxInputElement>
              </DataBoxInputWrapper>

              <DataBoxInputWrapper>
                <DataBoxInputLabel>
                  Zip code
                </DataBoxInputLabel>
                <DataBoxInputElement>
                  <StyledInput
                    autoComplete="chrome-off"
                    type='text'
                    name='zip-code'
                    value={userStore.profileData.zipCode}
                    onChange={(e) => { handleFieldChange('zipCode', e.target.value) }} />
                </DataBoxInputElement>
              </DataBoxInputWrapper>
            </DataBoxFormWrapper>
            <DataBoxInfoWrapper>
              <InfoBoxWrapper>
                <InfoBoxTitle>
                  Why do we ask this?
                </InfoBoxTitle>
                <InfoBoxDescription>
                  This helps us customize Vyzer to your specific needs.
                </InfoBoxDescription>
              </InfoBoxWrapper>
            </DataBoxInfoWrapper>

          </DataBoxFormContentWrapper>
          {haveInvestorProfileChanges &&
            <ActionsWrapper>
              <ActionsStickyWrapper>
                <ActionsButton borderless onClick={handleCancelChanges}>Cancel changes</ActionsButton>
                <ActionsButton black onClick={handleSaveChanges} disabled={isUpdating}>Save</ActionsButton>
              </ActionsStickyWrapper>
            </ActionsWrapper>
          }
        </CardContentBoxWrapper>}
      </div>
    </PageWrapper>

    <Conformation
      when={haveChanges}
      onSave={handleSavePersonalInfo}
    />
  </>)}
  </Observer>
}

const ProfileHeaderBackButton = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6802 16.0132L10.3468 11.9995L13.6802 7.98584" stroke="black" strokeWidth="1.5" />
  </svg>
)