import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors, shadows, views } from "../../../../common/styles/theme.styles";

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerNumberWrapper = styled.div`
  ${flexCenter};
  margin-left: auto;
`;

export const NumberSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #fff;
  position: relative;
  margin-left: auto;
  width: 16px;
  height: 16px;

  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid transparent;
    border-top-color: #fff;
    border-right-color: #fff;
    animation: ${spin} 1.5s linear infinite;
  }

`;

export const SidebarWrapper = styled.div`
  
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${colors.darkHeroBackground};
  ${views.desktop} {
    // gap: 20px;
    grid-area: sidebar;
  }
  ${views.tablet} {
    padding-bottom:80px;
  }
  
  color: #fff;
  ul {
    display: flex;
    flex-direction: column;
    /* flex-grow: 1; */
    gap: 8px;
    list-style: none;
    margin:  ${props => props.isSmall ? '16px 6px' : '16px'};
    padding: 0;
    li {
      ${props => props.isSmall ? 'display: flex;' : ''}
      a, .menuButton {
        cursor: pointer;
        display: flex;
        /* padding: 6px 10px; */
        height: 40px;
        padding-inline: 10px;
        align-items: center;
        justify-content: start;
        gap: 10px;
        border-radius: 6px;
        color: #fff;
        &:hover {
          background: #2F3236;
        }
        white-space: nowrap;
        overflow: hidden;
        svg {min-width: 24px;}
        &.active {
          background: ${colors.darkGray2};
        }
        ${props => props.isSmall ? `
          flex-direction: column;
          justify-content: center;
          gap: 0px;
          height: 60px;
          width: 68px;
          font-size: 12px;
          line-height: 13px;
          font-weight: 450;
          white-space: wrap;
          text-align: center;
          &.small-text{
            font-size: 12px;
          }
        ` : ''}
      }

      &.ai-assistance a {
          display: flex;
          height: 40px;
          padding: 0px 10px;
          align-items: center;
          gap: 6px;
          flex-shrink: 0;

          border-radius: 8px;
          border: 1px solid #546481;
          background: #000;
          box-shadow: 1px -1px 4px 0px rgba(255, 255, 255, 0.25);
          
          div.ai-assistance-icon {
            display: flex;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
          }

            &:not(&.active){
              .assistance_arrow {
                display: none;
              }

              &:hover {
                border-radius: 8px;
                border: 1px solid #546481;
                background: #000;
                box-shadow: -5px -2px 10.1px 0px rgba(255, 194, 102, 0.38), 7px 4px 14.5px 0px rgba(255, 255, 255, 0.36), 1px -1px 4px 0px rgba(255, 255, 255, 0.25);
              }
            }

            &.active {
              border-radius: 8px;
              border: 1px solid #C6D3EB;
              background: #000;
              box-shadow: 16px -20px 44.6px 0px rgba(255, 255, 255, 0.25), -5px -2px 10.1px 0px rgba(255, 194, 102, 0.38), 7px 4px 14.5px 0px rgba(255, 255, 255, 0.36), 1px -1px 4px 0px rgba(255, 255, 255, 0.25);          
              }
          }
        }
  }
`

export const SideBarButtonText = styled.div`

`;

export const DemoStartPortfolioButton = styled.div`
  ${flexCenter}
  justify-content: space-between;
  background: #2565E7;
  border-radius: 26px;
  color:#fff;
  margin:0 16px;
  height: 40px;
  padding:0 16px;
  font-weight: 500;
  font-size: 14px;
  svg path{
    stroke:#fff;
  }
  cursor: pointer;
`;

export const SidebarNav = styled.nav`

`
export const UserProfileWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 0 10px;
  border-radius: 6px;
  min-height: 44px;
  cursor: pointer;
  ${props => props.isSideMenuMinimized ? `margin-left:8px;` : ``}
  &:hover {
    background: #2F3236;
  }
  &.active {
    background: ${colors.darkGray2};
  }
  .profile-image {
    background: #111213;
    /* aspect-ratio: 1/1; */
    min-width: 34px;
    width: 34px;
    height: 34px;
    border-radius: 12px;
    border: 1px solid ${colors.darkGray2};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
  }
  .user-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    & > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .user-name-text{
      text-transform: capitalize;
      font-weight: 450;
      line-height:16px;
    }
    .user-email-text{
      font-size:12px;
    }
  }
  ${props => props.isSelected ? `background: ${colors.darkGray2};` : ``};
`

export const SettingsButtonWrapper = styled.button`
  background: inherit;
  border: 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
  height: 40px;
  border-radius: 4px;
  padding-inline: 10px;
  &:hover {
    background: #2F3236;
  }
`

export const ArrowIconWrapper = styled.div`
  margin-left: auto;
`;

export const SideMenuNotification = styled.div`
          background-color: #FF7B7B;
          width:20px; 
          min-width:20px;
          height:20px; 
          border-radius:50%;
          ${flexCenter};
          color:${colors.darkGray1};
`;

export const MagicBoxTooltipTitle = styled.div`
  position: absolute;
  font-size: 14px;
  background: #F13DA9;
  border-radius: 6px;
  padding: 4px 8px;
`;

export const MagicBoxTooltipWrapper = styled.div`
  position: relative;
  display: flex;
  /* :hover {
    ${MagicBoxTooltipTitle} {
      display: flex;
    }
  } */
`;

export const MagicBoxInProcessTooltip = styled.div`
  position: absolute;
  display: ${props => props.isDisplayed ? 'flex' : 'none'};
  top: -25px;
  left: 9px;
  color: #fff;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  padding: 4px 8px;
  background-color: #F13DA9;
  border-radius: 6px;
  box-shadow: ${shadows.menuPopup};
  :after {
    content: "";
    position:absolute;
    /* margin-top:-6px;
    margin-left:-5px; */
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #F13DA9;
    /* transform:rotate(-45deg); */
    bottom: -4px;
    left: 10px;
  }
`;

export const SideBarUpgradeButton = styled.div`
  display: flex;
  align-items: center;
  height:40px;
  font-size:14px;
  font-weight:450;
  outline:none;
  border:none;
  border-radius:6px;
  cursor:pointer;
  gap: ${props => props.isSideMenu ? '8px' : '4px'};
  padding: 0 ${props => props.isSideMenu ? '10px' : '8px'};;
  ${props => props.isProfileMenu ? `
    background-color:${props.isBlueBtn ? colors.blueAzul : colors.upgradeOrange}; 
    height: 34px;
  ` : ``}
  
  color:#fff;
  :hover{
      background-color: ${props => props.isBlueBtn ? '#1A56CF' : colors.upgradeOrangeHover};
  }
`;

export const SeparatorLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: #3F444F;
`;

export const UserWithSharedPortfolioMenuButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  // padding: 0 10px;
  border-radius: 6px;
  min-height: 44px;
  a { 
    &:hover{
      background: none!important;
    }
  }}
  ${props => props.isSideMenuMinimized ? `margin-left:8px;` : ``}
  // &.active {
  //   background: ${colors.darkGray2};
  // }
  
  
   ${props => props.isSelected ? `background: ${colors.darkGray2};` : ``};
`;

export const UserMenuButtonWrapper = styled.div`
    width: 34px;
    min-width: 34px;
    height: 34px;
    border-radius: 24px;
    border:1px solid #3D424D;
    ${flexCenter}
    cursor: pointer;
    &:hover {
      background: #2F3236;
    }
    &.active {
      background: ${colors.darkGray2};
    }
    ${props => props.isSelected ? `background: ${colors.darkGray2};` : ``};
`;