import styled from '@emotion/styled/macro';
import { flexCenter } from '../../styles/combos.styles';
import { colors, views } from '../../styles/theme.styles';
import { keyframes } from '@emotion/react';

export const Wrapper = styled.div`
    display:flex;
    // padding:24px 240px 16px 240px;
    flex-direction:column;
    align-items:center;
`

export const SectionBgWrapper = styled.div`
    width:100%;
    // background-color:${props=> !props.isStepsDisplay && props.index%2 === 1 ? '#fbfcfc' : '#fff'};
`;

export const SectionWrapper = styled.div`
    display:flex;
    // padding:24px 240px 16px 240px;
    // padding:24px 16px;
    padding:${props=>props.padding ? props.padding : '24px'};
    flex-direction:column;
    width:100%;
    margin: ${props=>props.margin ? props.margin : '0 auto'};
    ${props=>props.fullWidth ? `` : `max-width:512px;`}
    // background-color:${props=> props.index%2 === 1 ? '#fbfcfc' : '#fff'};
    /* ${props=>props.horizntalSections ? `
        flex-direction:row;
        &:not(:last-child){
            border-bottom: 1px solid #E5E5E5;
        }
    ` : `
        flex-direction:column;
    `} */

    ${views.tablet}{
        padding:${props=>props.padding ? props.padding : '24px 8px'};
    }
`;

const flash = keyframes`
    0%,
    100% {
      background-color: transparent;
    }
  
    50% {
      background-color: #ffff99;
      /* Light yellow for visibility, change as needed */
    }
  }
`;

export const SectionTitle = styled.div`
    display: flex;
    align-items: center;
    color: ${colors.darkGray1};
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    text-transform:uppercase;
    margin-bottom:24px;
    min-width:200px;
    letter-spacing: 1px;
    padding-right:16px; 
`;

export const SectionTitleText = styled.div`
    &.flashing {
            animation: ${flash} 1s ease-in-out infinite;
    }
`;

export const SectionRowsWrapper = styled.div`
    display:flex;
    flex-direction: column;
    flex: 1;
`;

export const RowTitle = styled.div`

`;

export const CustomComponentRowWrapper = styled.div`

`;

export const RowSeparator = styled.div`
    width:100%;
    height:1px;
    background-color:#DDE3EB;
    margin-bottom:24px;
`;

export const RowWrapper = styled.div`
    display:flex;
    flex-direction:row;
    margin:0 -8px;
    ${props=>props.customStyle ? props.customStyle : ``}
    ${props=>props.fieldsAsRowsForMobile ? `
        ${views.tablet}{
            flex-direction:column;    
        }` : 
        ``
    }
`;

export const FieldWrapper = styled.div`
    flex: ${props=>props.size || 1};
    margin:0 8px;
    max-width:calc(100% - 16px);
    display:flex;
    flex-direction:column;
    justify-content: flex-end;
    ${props=>props.customStyle ? props.customStyle : ``}
    ${views.tablet}{
        ${props=>props.mobileCustomStyle ? props.mobileCustomStyle : ``}
    }
    ${views.desktop}{
        ${props=>props.desktopCustomStyle ? props.desktopCustomStyle : ``}
    }
`;

export const FieldLabelAndErrorWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FieldLabel = styled.div`
    margin-bottom: ${props => props.noMargin? '0': '4px'};
    display: flex;
    align-items:center;
    position:relative;
    &.flashing {
        animation: ${flash} 1s ease-in-out infinite;
    }
`;

export const FieldLabelText = styled.div`
    color:${colors.darkGray1};
    font-size:16px;
    line-height: 16px;
`;

export const FieldLabelSubText = styled.div`
    font-weight: 450;
    font-size: 12px;
    color: #585D66;
`;

export const FieldLabelToolTipTriggerWrapper = styled.div`
    display:flex;
    margin:0 8px;
    svg{
        path{
            fill: ${(props) => (props.isNegative ? 'red' : '')};
        }
    }
`;

export const FieldDescription = styled.div`
    color:#9D9D9D;
    font-size:14px;
    line-height: 18px;
    margin-bottom: 8px;
`;

export const InputAndHistoryWrapper = styled.div`
    width:100%;
    display:flex;
`;

export const HistoryIconWrapper = styled.div`
    padding-left: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;


export const InputWrapper = styled.div`
    width:100%;
    position: relative;
    .ant-select{
        width: 100%;
        font-size:18px;
    }

    .ant-select-arrow path{
        stroke:#000;
    }
    .ant-select-disabled .ant-select-arrow path{
        stroke: rgba(0, 0, 0, 0.25);
    }

    .ant-select .ant-select-selector{
        border-color:${props=>props.withError ? '#FF7676' : '#d9d9d9'};
    }
    
    .ant-select-single .ant-select-selector,.ant-select-single .ant-select-selector .ant-select-selection-item{
        height:38px;
        line-height:38px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
        line-height:38px;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
        border-color:#7d7d7d;
    }
    .ant-select-focused .ant-select-selector{
        border-color:#7d7d7d!important;
        box-shadow: none!important;
    }
    .ant-picker:not(.innerTable){
        width: 100%;
        font-size:18px;
        height:38px;
        border-color:${props=>props.withError ? '#FF7676' : '#d9d9d9'};
        
        &:hover:not(.ant-picker-disabled,[disabled]){
            border-color:#7d7d7d;
        }
    }
    .ant-dropdown-trigger:not(.innerTable){
        &:hover{
            border-color:#7d7d7d;
        }
    }
    .ant-picker-input > input{
        font-size:18px;
        line-height:18px;
    }
    .ant-picker-focused{
        border-color:#7d7d7d!important;
        box-shadow: none!important;
    }
    .ant-input:not(.innerTable){
        color:${props=>props.textColor && '#FF7676' };
        font-size:18px;
        // border-color:'green';
        border-color:${props=>props.withError ? '#FF7676' : '#d9d9d9'};
        &:not([disabled]):hover{
            border-color:#7d7d7d;
        }
        &:focus{
            border-color:#7d7d7d;
            box-shadow: none;
        }
        &.holdings-left{
            border-top-right-radius:0px!important;
            border-bottom-right-radius:0px!important; 
            // border-right:0px;
        }
        &.holdings-right{
            border-top-left-radius:0px!important;
            border-bottom-left-radius:0px!important; 
        }
    }
    .ant-input:not(.innerTable):not(textarea){
        height:38px;
    }
    .ant-input-affix-wrapper{
        border-radius:4px;
        &:hover{
            border-color:#7d7d7d;
        }
        &:focus{
            border-color:#7d7d7d;
        }
        .ant-input{
            box-shadow:none;
        }
    }
    .ant-input-affix-wrapper-focused{
        border-color:#7d7d7d;
    }
    
    .ant-input-focused{
        border-color:#7d7d7d;
        box-shadow: none;
    }

    .ant-checkbox-wrapper{
        .ant-checkbox-input, .ant-checkbox-inner {
            width: 16px;
            height: 16px;
            :focus{
                border-color:${colors.darkGray1};
            }
        }
        .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color:${colors.darkGray1};
        }
        .ant-checkbox-checked{
            .ant-checkbox-inner {
                background-color:${colors.darkGray1};
                border-color:${colors.darkGray1};
                ::after{
                    transform: rotate(45deg) scale(1) translate(-56%, -58%);
                }
            }
            ::after {
                border-color:${colors.darkGray1};
            }
        }
        .ant-checkbox-disabled .ant-checkbox-inner {
            background-color: #d9d9d9;
        }
        :hover{
            .ant-checkbox-inner {
                border-color:${colors.darkGray1};
            }
        }
        &.ant-checkbox-wrapper-checked:hover{
            .ant-checkbox-inner {
                border-color:${colors.darkGray1};
            }
        }
    }
    

    .ant-switch-checked{
        background-color: #65A87F; //${colors.darkGray1};
    }

`;

export const BottomErrorWrapper = styled.div`
`;

export const BottomInputErrorMessage = styled.div`
    color:#FF7676;
    margin-top: 5px;
    padding-right: 10px;
    white-space: pre-line;
`;

export const CheckboxWrapper = styled.div`
    display:flex; 
    align-items:center;
`;

export const ToggleWrapper = styled.div`
    display:flex; 
    align-items:center;
`;

export const ToggleLabel = styled.div`
    margin:0 8px;
    font-size: 16px;
    color:${colors.darkGray1};
`;

export const InputSwitcherWrapper = styled.div`
    position: relative;
    margin-bottom: 28px;
`;

export const EditWithOutlineIconWrapper = styled.div`
    ${flexCenter};
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    z-index: 1;
`;

export const InputErrorMessage = styled.div`
    color:#FF7676;
    font-size:12px;
    height: 20px;
    line-height: 18px;
`;

export const InputRequired = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${colors.darkGray2};
`;

export const ClickToHideComponentWrapper = styled.div`
    ${flexCenter};
`;

export const PercentageInputWrapper = styled(InputWrapper)`
  display: flex;
`;