import { Observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { LimitedSharedAccess } from '../../../common/components/LimitedSharedAccess/LimitedSharedAccess';
import { ROUTES } from '../../../common/constants/routes.const';
import { useAnalytics } from '../../app/data/AnalyticsProvider';
import { useStore } from '../../app/data/root.store';
import { FiltersPanel } from '../../filters/components/FiltersPanel';
import { ComparePlansModal } from '../../onboarding/components/ComparePlansModal/ComparePlansModal';
import { DemoAccountStep } from '../../onboarding/components/DemoAccountStep/DemoAccountStep';
import { MobilePricingModal } from '../../onboarding/components/PricingModal/MobilePricingModal';
import { PricingModal } from '../../onboarding/components/PricingModal/PricingModal';
import { ReadOnlyMode } from '../../onboarding/components/ReadOnlyMode/ReadOnlyMode';
import { StarterPlanModal } from '../../onboarding/components/StarterPlanModal/StarterPlanModal';
import { MobileWelcomeModal } from '../../onboarding/components/WelcomeModal/MobileWelcomeModal';
import { PageRouter } from '../../router/components/PageRouter';
import { PaymentErrorBanner } from './Banner/PaymentErrorBanner';
import { DesktopLogo } from './DesktopLogo/DesktopLogo';
import { AppWrapper, Banner } from './Layout.styles';
import { NotificationsWrapper } from './Notifications/NotificationsWrapper';
import { DesktopSharedPortfolioBanner } from './SharedPortfolioBanner/DesktopSharedPortfolioBanner';
import { Sidebar } from './Sidebar/Sidebar';
import { SessionExpirationModal } from '../../user/components/SessionExpirationModal/SessionExpirationModal';

export const DesktopLayout = () => {
  const location = useLocation();
  const { userStore, uiStore, metadataStore, billingStore, magicBoxAiStore, assistanceStore } = useStore();
  const { mixpanel, mixpanelTrack } = useAnalytics();
  const urlParams = new URLSearchParams(useLocation().search);
  const isAfterPayment = urlParams.get('subscribed_success') !== null;
  const shouldShowWelcomePopup = urlParams.get('sw') !== null;
  const [isMobileAfterPaymentModal, setIsMobileAfterPaymentModal] = useState(isAfterPayment && shouldShowWelcomePopup);
  const isBackToPricingModal = urlParams.get('pricing_modal') !== null;
  const [backToPricingModal, setbackToPricingModal] = useState(isBackToPricingModal)

  useEffect(() => {
    if (uiStore.currentRoute !== location.pathname) {
      if (location.pathname !== '/' && userStore.isAuthenticated) {
        mixpanel.config && mixpanelTrack('Page Viewed', {
          path: window.location.pathname,
          referrer: document.referrer,
          title: document.title,
          url: window.location.href,
          is_product: true
        });
      }
      uiStore.routeChanged(location.pathname)
    }
  }, [uiStore, mixpanel, mixpanelTrack, location, userStore.isAuthenticated]);

  useEffect(() => {
    const fetch = async () => {
      await magicBoxAiStore.fetchData();
    };

    fetch();

  }, [magicBoxAiStore]);

  return (<Observer>{() => (
    <>
      <AppWrapper appLayout={userStore.isAuthenticated && (uiStore.isRouteWithEmptySideBarLayout || userStore.data?.profile?.isOnBoarding !== true)}
        emptySideMenuLayout={userStore.isEmptySharedSideMenu}
        withPushMenu={uiStore.isRightMenuOpen && userStore.isViewingOwnPortfolio} isSmallMenu={uiStore.isSideMenuMinimized && !userStore.isWelcome} >

        {userStore.isAuthenticated && (uiStore.isRouteWithEmptySideBarLayout || !userStore.isOnBoarding) && metadataStore.isLoaded && <>

          <Banner className='banner'>
            {!userStore.isEmptySharedSideMenu && <>
              {!userStore.isPaymentFailedMode && userStore.isReadOnlyMode && <ReadOnlyMode />}
              {userStore.isPaymentFailedMode && <PaymentErrorBanner />}
              {userStore.isDemoMode && <DemoAccountStep />}
            </>}
          </Banner>

          {userStore.isHavingSharedPortfolios && !userStore.isEmptySharedSideMenu ? <DesktopSharedPortfolioBanner /> : <DesktopLogo keepUrl={userStore.isViewingOwnPortfolio && !userStore.isGranteeHavePortfolio ? ROUTES.portfolios.url : false} />}
          <Sidebar className="sidebar" isWelcome={userStore.isWelcome} />
          <FiltersPanel />
        </>}

        {userStore.isLoaded &&
          <PageRouter
            isLoggedIn={userStore.isAuthenticated}
            isOnBoarding={userStore.data?.profile?.isOnBoarding}
            isGranteeHavePortfolio={userStore.isGranteeHavePortfolio}
            isViewingOwnPortfolio={userStore.isViewingOwnPortfolio}
            isViewRolesPageBlocked={userStore.isActionBlockedByRole('viewRolesPage')}
            isWelcome={userStore.isWelcome}
            isDemoMode={userStore.isDemoMode || userStore.isDemoTier}
            isCommunityLimited={!userStore.userLimitations.community || userStore.isReadOnlyMode}
            isDemoOrReadOnlyMode={userStore.isDemoOrReadOnlyMode}
            isMobileGetStarted={uiStore.showMobileGetStarted}
            isMobileView={uiStore.isMobileView}
            isMobileViewMessageSeen={uiStore.isMobileViewMessageSeen}
            isPricingTableOpen={billingStore.isShowPricingTablePopup}
            isDesktopView={true}
            isPricingModalPage={userStore.isRelevantVariant === 3}
            metaDataLoaded={metadataStore.isLoaded}
            showManualMagicBox={((userStore.isDemoTier) || (!metadataStore.showMagicBoxAI) || (magicBoxAiStore.switchToManualReview))}
            showAssistance={assistanceStore.showAssistance}
          />
        }


        <NotificationsWrapper isAuthenticated={userStore.isAuthenticated} />
        {billingStore.isShowPricingTablePopup || backToPricingModal ? uiStore.isMobileScreenSize && userStore.isDemoTier ? <MobilePricingModal onClose={() => { billingStore.setShowPricingTableModal(false); setbackToPricingModal(false) }} /> : <PricingModal onClose={() => { billingStore.setShowPricingTableModal(false) }} onBackDropClick={() => { billingStore.setShowPricingTableModal(false) }} /> : null}
        {billingStore.showComparePlansModal && <ComparePlansModal onClose={() => { billingStore.setShowComparePlansModal(false) }} />}
        {billingStore.showStarterModal && <StarterPlanModal onClose={() => { billingStore.setShowStarterModal(false) }} />}
        {isMobileAfterPaymentModal && uiStore.isMobileScreenSize && <MobileWelcomeModal onClose={() => { setIsMobileAfterPaymentModal(false) }} />}
        {userStore.showLimitedSharedAccess && uiStore.isSideMenuMinimized && <LimitedSharedAccess variant='modal' onClose={() => { userStore.setShowLimitedSharedAccess(false) }} portfolioDetails={userStore.currentPortfolioDetails} />}
        {uiStore.showLogoutModal && <SessionExpirationModal onBackDropClick={() => { }} />}
      </AppWrapper>
    </>
  )}
  </Observer>);
}
