import NumberFormat from 'react-number-format';
import { CURRENCY_SYMBOL } from '../../../../common/constants/currency-symbol.const';
import { Input } from 'antd';
import { DeepAnalysisResultCurrencyDropdown } from './DeepAnalysisResultCurrencyDropdown';

export const DeepAnalysisReviewValueCell = (props) => {
    const { handleFieldChange, currentItem, isEdited, isNew, isHoverOn, showError } = props;

    const onChangeCurrnecy = (value) => handleFieldChange('currency', value);
    const addonAfter = (isHoverOn || isEdited) ? <DeepAnalysisResultCurrencyDropdown value={currentItem.currency || 'USD'} onChange={onChangeCurrnecy} /> : '';
    const value = isNaN(currentItem.amount) ? '' : currentItem.amount;

    return (
        <>
            <NumberFormat
                thousandSeparator={true}
                customInput={Input}
                style={{
                    ...((isEdited || isHoverOn) ? { paddingLeft: '0px' } : { paddingLeft: '13px' }),
                    ...((isEdited || isHoverOn || isNew) ? {} : { borderColor: 'transparent', backgroundColor: 'transparent' }),

                    height: '48px', borderRadius: '8px',
                    ...(showError && isNaN(currentItem.amount) && !isNew && { borderColor: 'red' })
                }}
                value={value}
                onValueChange={(values) => handleFieldChange('amount', values.floatValue)}
                placeholder='Value'
                prefix={CURRENCY_SYMBOL[currentItem.currency || 'USD']}
                addonAfter={addonAfter}
            />
        </>
    );
}