import { useState } from "react";
import { colors } from "../../../../../../common/styles/theme.styles"
import { AssetClassIcon } from "../../../../../assets/components/AssetClassIcon"
import { FilterParameterIcon } from "../../../../../filters/components/FilterParameterIcon";
import { CommunitySubtitle, HeroClassIconWrapper, HeroTitleTextWrapper, StockIconPlaceholder, StockIconWrapper } from "../../../../components/WealthLayout.styles"
import { AllocateByButtonAndMenu } from "../../../../../allocations/components/AllocateByButtonAndMenu";
import { observer } from "mobx-react";
import { useStore } from "../../../../../app/data/root.store";
import { CommonCustomClassIcon } from "../../../../../../common/components/CommonCustomClassIcon/CommonCustomClassIcon";
import { CommonTextOverflow } from "../../../../../../common/components/CommonTextOverflow/CommonTextOverflow";

export const WealthHeroTitle = observer(({ iconName, tag, title, bgColor, isCommunity ,withoutIcon}) => {
  const { uiStore } = useStore();

  const {id, tagColor, logoBase64, logoURL, connectedInstitutionName, type, title: tagTitle, titlePrefix, icon: tagIcon  } = tag?.[0] || {};
  
  const count = tag?.length;
  const isSingle = count === 1;
  const isInstitution = type === 'institutions';
  const isClass = type === 'classes';
  const isStock = type === 'stocks';
  // const isLeveragedAssets = ['leveragedAssets','notLeveragedAssets'].includes(type);
  
  
  const HeroTitle = () => title && !count ? title : isSingle ? titlePrefix + tagTitle : `${count} ${count > 1 ? 'Filters' : 'Filter'}`

  const HeroIcon = () => {
    if (isSingle && type === 'customClasses' ) return <CommonCustomClassIcon customClassId={id} borderRadius={9} isEmptySubClass={tagIcon==='hero-custom-sub-classes'}/>
    if (iconName && !count) return  <AssetClassIcon name={iconName} />;
    if (count > 1) return <AssetClassIcon name='filtered' />;
    if (isSingle && isClass) return <AssetClassIcon name={tagIcon} />;
    if (isSingle && isInstitution) return <InstitutionIcon />;
    if (isSingle && (type === 'endDate' || type === 'startDate')) return <FilterParameterIcon name='hero-startEndDate' />;
    if (isSingle && isStock) return <StockIcon ticker={tag?.[0]} />
    if (isSingle) return <FilterParameterIcon name={'hero-' + type} />;
    return null;
  }

  const isWithoutFillColor = () => {
    return isSingle && type === 'customClasses';
  }

  const InstitutionIcon = () => {
    return (
          logoBase64 ? <img alt={connectedInstitutionName} src={'data:image/png;base64,' + logoBase64} /> :
          logoURL ? <img alt={connectedInstitutionName} src={logoURL} /> : 
            <FilterParameterIcon name='institutions-hero' />
    )
  }

  const backgroundColor = () => {
    if (isSingle && type === 'customClasses' ) return null
    if (bgColor) return bgColor;
    if (isSingle && tagColor) return tagColor;
    if (isSingle && isStock && tag?.[0]?.isSingleTicker) return '#B3E8CF';
    if (isSingle && ((isInstitution && (logoBase64 || logoURL)) || isStock)) return '#fff';
    return colors.darkGray2;
  }

  return (
    <>
      {!withoutIcon && <HeroClassIconWrapper color={backgroundColor()} round={isSingle && (isInstitution || isStock)} 
        strokeColor={isSingle && isInstitution && (logoBase64 || logoURL) ? '#fff' : null}
        withoutFillColor={isWithoutFillColor()}>
        <HeroIcon />
      </HeroClassIconWrapper> }
      <div style={{display:'flex', flexDirection:'column' , alignItems:'flex-start', maxWidth:'calc(100% - 64px)'}}>
      {isCommunity ? <>
          <HeroTitle />
          <CommunitySubtitle>Of Vyzer community</CommunitySubtitle>
        </>
       :  <>
   
        <HeroTitleTextWrapper>
          <CommonTextOverflow text={<HeroTitle />} placement={'topLeft'} isLongText={true}/>
        </HeroTitleTextWrapper>
        {uiStore.isDesktopView && <AllocateByButtonAndMenu />}
      </> }
       </div>
    </>
  )
})

const StockIcon = ({ticker}) => {
  const [isCorruptedImage, setIsCorruptedImage] = useState(false);

  return (
    <StockIconWrapper>{ticker.logoURL && !isCorruptedImage ? <img alt={ticker.symbol} src={ticker.logoURL} onError={() => setIsCorruptedImage(true)} /> : <StockIconPlaceholder>{(ticker.name && ticker.name !== '') ? ticker.name.charAt(0).toUpperCase() : (ticker.symbol && ticker.symbol !== '') ? ticker.symbol.charAt(0).toUpperCase() : (ticker.type && ticker.type !== '') ? ticker.type.charAt(0).toUpperCase() : ''}</StockIconPlaceholder>}</StockIconWrapper>
  )
}