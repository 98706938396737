
const exitEventPathByCategory = {
    // class id 8
    14: 'aggregatedMetricsData.attributes.exitEvent.date', // Hedge fund
    19: 'aggregatedMetricsData.attributes.exitEvent.date', // Real estate
    26: 'aggregatedMetricsData.attributes.exitEvent.date', // Private equity
    27: 'aggregatedMetricsData.attributes.exitEvent.date', // Venture capital
    // class id 4
    16: 'aggregatedMetricsData.attributes.exitEvent.date', // Rental property - syndication
    18: 'aggregatedMetricsData.attributes.exitEvent.date', // Development - syndication
    // class id 6
    31: 'aggregatedMetricsData.attributes.exitEvent.date', // Traditional business
}

const allocationAttr = {dataKey:'allocation', title: 'Allocation', subAttributes: [], type:'percentage'}
const assetsAllocationAttr = {dataKey:'assetsAllocation', title: 'Assets Allocation', subAttributes: [], type:'percentage'}
const liabilitiesValueAttr = {dataKey:'liabilitiesValue' , type: 'money' , title: 'Liabilities value' , subAttributes: [], }
const nameAttr = {dataKey:'title', title: 'Asset name', isLocked: true, subAttributes: [], type:'text'}
const valueAttr = {dataKey:'assetValue', title: 'Asset value',  isLockedForDesktop: true, isHiddenForMobile:true, subAttributes: []}
const balanceAttr = {dataKey:'assetValue', title: 'Balance', isLocked: true, subAttributes: []}
const startDateAttr = {dataKey:'startDate' , title: 'Start date', subAttributes : [] , type:'date' };
const liabilityAmountAttr = {dataKey:'liabilityAmount' , title: 'Liability amount', subAttributes : [] , type: 'liabilityAmount' , path: 'connectedLiabilities'};
const allocationLoansAmount = {dataKey:'loansAmount' , title: 'Loans amount', subAttributes : [] , type: 'money' };
export const lastUpdate_path = 'aggregatedMetricsData.attributes.lastUpdateDate';
const lastUpdateAttr = {dataKey:'lastUpdate' , title: 'Last update date', htmlTitle:'Last&nbsp;update<br/>date', subAttributes : [] , type: 'date' , path: lastUpdate_path};
const allocationLastUpdateAttr = {dataKey:'lastUpdate' , title: 'Last update date', htmlTitle:'Last&nbsp;update<br/>date', subAttributes : [] , type: 'date' };
const holdingEntityAttr = {dataKey:'beneficiary', title: 'Holding entity', subAttributes : [] , type: 'text'};
const institutionAttr = {dataKey:'name',  title: 'Institution / Sponsor', subAttributes : [] , type: 'text'};
const lenderAttr = {dataKey:'name',  title: 'Lender', subAttributes : [] , type: 'text'};
const expectedEndDateAttr = {dataKey:'expectedEndDate',  isItemProp: true , title: 'Expected end date', htmlTitle:'Expected<br/>end&nbsp;date', subAttributes : [] , type:'date', categoryPath: exitEventPathByCategory , fallbackPath : 'expectedEndDate' };
const lastSyncAttr = {dataKey:'lastSync' , title: 'Last sync', type:'date', subAttributes : []};
// const remainingTimeAttr = {dataKey:'remainingMonths' , title: 'Remaining time', subAttributes : []};
export const total_capital_path = 'aggregatedMetricsData.attributes.totalCommittedCapital';
const totalCapitalAttr = {dataKey:'totalCommittedCapital' , title: 'Total committed capital', htmlTitle:'Committed<br/>capital&nbsp;(Total)', subAttributes : [] , type:'money', path :total_capital_path };
const allocationTotalCapitalAttr = {dataKey:'totalCommittedCapital' , title: 'Total committed capital', htmlTitle:'Committed<br/>capital&nbsp;(Total)', subAttributes : [] , type:'money' };
const nextCapitalAttr = {dataKey:'nextCapitalCall' , title: 'Next capital call (date)', subAttributes : [], type:'date' , path : 'aggregatedMetricsData.attributes.nextCapitalCall'};
const nextCapitalValueAttr = {dataKey:'nextCapitalCallValue' , title: 'Next capital call ($)', subAttributes : [], type:'money' , path : 'aggregatedMetricsData.attributes.nextCapitalCallValue'};
const calledCapitalsAttr = {dataKey:'calledCapitals' , title: 'Called capital', subAttributes : [] , type: 'money' , path : 'aggregatedMetricsData.called_capital.total.actual'};
const calledCapitalsPercentageAttr = {dataKey:'calledCapitalsPercentage' , title: 'Called capital (%)', subAttributes : [] , type: 'percentage' , path : 'aggregatedMetricsData.attributes.calledCapitalsPercentage'};
const uncalledCapitalsAttr = {dataKey:'uncalledCapitals' , title: 'Uncalled capital', subAttributes : [] , type: 'money' , path : 'aggregatedMetricsData.attributes.uncalledCapitals'};

const capitalCallGroup = {
    dataKey: 'capitalCallGroup',
    title : 'Capital calls',
    subAttributes : [
        totalCapitalAttr,
        nextCapitalAttr,
        nextCapitalValueAttr,
        calledCapitalsAttr,
        calledCapitalsPercentageAttr,
        uncalledCapitalsAttr,
    ]
}

const assetTypeAttr = {dataKey:'assetType' , title: 'Asset type', subAttributes : [] , type:'text' , path : 'aggregatedMetricsData.attributes.propertyType'};
const addressAttr = {dataKey:'address' , title: 'Address', subAttributes : [] , type: 'text' , path: 'aggregatedMetricsData.attributes.address'};
const distributionReinvestedAttr = {dataKey:'distributionReinvested' , title: 'Distribution reinvested', subAttributes : [] , type: 'bool' , path : 'aggregatedMetricsData.attributes.distributionReinvested'};
const ppsAttr = {dataKey:'pps' , title: 'PPS', subAttributes : [
    {dataKey: 'pps_avg' , title: 'Average'  , parentDataKey: 'pps' , type: 'money' , path: 'aggregatedMetricsData.pps.avg.actual'  },
    {dataKey: 'pps_current' , title: 'Current' , parentDataKey: 'pps' , type: 'money' , path: 'aggregatedMetricsData.pps.current.actual'  }
]};
const numberOfShareAttr = {dataKey:'numberOfShare' , title: 'Number of Shares', subAttributes : [] , type: 'number' , path: 'aggregatedMetricsData.number_of_shares.total.actual'};
const sectorAttr = {dataKey:'sector' , title: 'Sector', subAttributes : [] , type: 'text' , path: 'aggregatedMetricsData.attributes.companySector'};

// const roiAttr = createMainAttributesWithSubs('roi','ROI',true,true,'percentage','aggregatedMetricsData.roi');
const assetsRoiAttr = {dataKey:'assetsRoi' , title: 'ROI', subAttributes : [], type: 'percentage' };
export const roi_total_path = 'aggregatedMetricsData.roi.total.actual';
const roiAttr = {dataKey:'roi' , title: 'ROI', subAttributes : [
    {dataKey: 'roi_annualized' , title: 'AAR'  , parentDataKey: 'roi' , type: 'percentage' , path: 'aggregatedMetricsData.roi.aar.actual'  },
    {dataKey: 'roi_total' , title: 'Total' , parentDataKey: 'roi' , type: 'percentage' , path: roi_total_path  }
]};

// const irrAttr = createMainAttributesWithSubs('xirr','IRR',false,false,'percentage','aggregatedMetricsData.xirr');
// const assetsIrrAttr = {dataKey:'assetsIrr' , title: 'IRR', subAttributes : [], type: 'percentage' };
export const xirr_total_path = 'aggregatedMetricsData.xirr.total.actual';
const irrAttr = {dataKey:'xirr' , title: 'IRR', subAttributes : [
    {dataKey: 'xirr_total' , title: 'Total' , parentDataKey: 'xirr' , type: 'percentage' , path: xirr_total_path  }
]};

const dpiAttr = {dataKey:'dpi' , title: 'DPI', subAttributes : [
    {dataKey: 'dpi_total' , title: 'Total' , parentDataKey: 'dpi' , type: 'multiplier' , path: 'aggregatedMetricsData.dpi.total.actual'  }
]};
// const moicAttr = createMainAttributesWithSubs('moic','MOIC',false,false,'multiplier','aggregatedMetricsData.moic');
const moicAttr = {dataKey:'moic' , title: 'MOIC', subAttributes : [
    {dataKey: 'moic_total' , title: 'Total' , parentDataKey: 'moic' , type: 'multiplier' , path: 'aggregatedMetricsData.moic.total.actual'  }
]};

// const pAttr = { dataKey: 'container', title: 'Investaccount', type:'perc', subAttributes : [] }
const loanTypeAttr = {dataKey:'loanType' , title: 'Loan type' , subAttributes : [] , type : 'text' , path : 'aggregatedMetricsData.attributes.loanType' }
const interestRateAttr = {dataKey:'interest' , title: 'Interest rate', subAttributes : [] , type : 'percentageMax1' , path : 'aggregatedMetricsData.attributes.interest'};
const monthlyPaymentAttr = {dataKey:'monthlyPayment' , title: 'Monthly payment ', subAttributes : [] , type : 'money' , path : 'aggregatedMetricsData.attributes.fixedMonthlyPayment'};
const expectedPayoffDateAttr = {dataKey:'expectedPayoffDate' , title: 'Expected payoff date',  htmlTitle:'Expected<br/>payoff&nbsp;date' , subAttributes : [] , type: 'date' , path: 'aggregatedMetricsData.expected_payoff_date.total.actual' , categoryPath : { 41 : 'aggregatedMetricsData.expected_payoff_date_non_amortize.total.actual', 42 : 'aggregatedMetricsData.expected_payoff_date_non_amortize.total.actual'}};
const remainingMonthsAttr = {dataKey:'remainingMonths' , title: 'Remaining time' , subAttributes : [] , type: 'remainingTimeFromDate' , path: 'aggregatedMetricsData.expected_payoff_date.total.actual' , categoryPath : { 41 : 'aggregatedMetricsData.expected_payoff_date_non_amortize.total.actual' , 42 : 'aggregatedMetricsData.expected_payoff_date_non_amortize.total.actual'}};

const paymentPeriodAttr = {dataKey:'paymentPeriod' , title: 'Payment period' , subAttributes : [] , type : 'text' , path : 'aggregatedMetricsData.attributes.expectedDistributionPeriod' , labels : {Custom : 'Periodic'} }
const periodicalPaymentAttr = {dataKey:'periodicalPayment' , title: 'Periodical payment' , subAttributes : [] , type : 'money' , path : 'aggregatedMetricsData.periodical_payment.total.actual' }

const forYears = [0,1,2,3,4];
const createMainAttributesWithSubs = (dataKey, title , withAnnualized, isAnnualizedAar, type , relatedPath) => {
    const currentYear = new Date().getFullYear(); 
    return {
        dataKey, title, subAttributes: [
            ...(withAnnualized ? [{dataKey: dataKey + '_annualized' , title: 'Annualized' , parentDataKey: dataKey , type, path: relatedPath + '.' + (isAnnualizedAar ? 'aar' : 'annualized') + '.actual'}] : [] ),
            {dataKey: dataKey + '_total' , title: 'Total' , parentDataKey: dataKey , type , path: relatedPath + '.total.actual' },
            ...forYears.map((ind)=>{
                return {dataKey: dataKey + '_' + (currentYear-ind) , title: (currentYear-ind) , parentDataKey: dataKey , type , path: relatedPath + '.' + (currentYear-ind) + '.actual'}
            })
        ]
    }
}

const investmentAccountAttr = { dataKey: 'container', title: 'Investment account', type:'text', subAttributes : [] }

// stocks attrs
const stockGainAttr = { dataKey: 'gain', title: 'Gain ($)', type:'money' , subAttributes : [] };
const stockPerformanceAttr = { dataKey: 'performance', title: 'Performance', type:'percentage', subAttributes : [] };
const stockNumberOfShareAttr = {dataKey:'quantity' , title: 'Number of Shares', subAttributes : [] , type: 'number' };
const stockPpsAttr = { dataKey: 'currentPrice', title: 'PPS (Close price)', type:'money', withoutHoldings:true, subAttributes : []};
const stockCostPerShareAttr = { dataKey: 'costPerShare' , title: 'Cost per share', type: 'money',  withoutHoldings:true, subAttributes: []};
const stockTotalCostAttr = { dataKey: 'costBasis' , title: 'Total cost', type: 'money' , subAttributes: []};

// const pAttr = { dataKey: 'container', title: 'Investaccount', type:'perc', subAttributes : [] }

// life insurance attrs
const leveragedAssetsAttr = { dataKey: 'leveraged_assets', title: 'Leveraged assets', type:'money', subAttributes: [], path: 'aggregatedMetricsData.leveraged_assets.total.actual' }
const outstandingLoansAttr = { dataKey: 'outstanding_loans', title: 'Outstanding loans', type:'money', subAttributes: [], path: 'aggregatedMetricsData.outstanding_loans.total.actual' }
const availableLoanAmountAttr = { dataKey: 'available_loan_amount', title: 'Available loan amount', type:'money', subAttributes: [], path: 'aggregatedMetricsData.available_loan_amount.total.actual' }
const remainingPremiumAttr = () => ({ dataKey: 'remaining_premium', title: 'Remaining premium '+ (new Date().getFullYear()),  htmlTitle:'Remaining<br/>premium&nbsp;' + (new Date().getFullYear()) , type:'money', subAttributes: [], path: 'aggregatedMetricsData.remaining_premium.total.actual' })
const kOneTrackerAttr = {dataKey:'kOneDocuments' , title: 'K-1 tracker', isSingleSelect: true, subAttributes : [
    ...forYears.map((ind)=>{
        const currentYear = new Date().getFullYear(); 

        return {dataKey: `kOneDocuments_${currentYear-ind}` , title: `${currentYear-ind}`  , parentDataKey: 'kOneDocuments' , type: 'text' , path: `kOneDocuments.${currentYear-ind}`}
    })
]};

const commonDLForAllItemsAtTheEnd = [
    {dataKey:'category', title: 'Category', subAttributes: [], type:'category' , path: 'category.title'},
    {dataKey:'riskLevel', title: 'Risk', subAttributes: [], type:'riskLevel'},
    {dataKey:'liquidity', title: 'Liquidity', subAttributes: [], type:'number'},
    {dataKey:'customClass', title: 'Custom class', subAttributes: [], type:'customClass'},
    {dataKey:'customSubClass', title: 'Custom sub class', subAttributes: [], type:'customSubClass'}
]

export const MAIN_RESULTS_ITEMS_ATTRIBUTES = (customLayers = []) => ([
    nameAttr,
    startDateAttr,
    roiAttr,
    irrAttr,
    createMainAttributesWithSubs('contributions','Contributions',false,false,'money','aggregatedMetricsData.contributions'),
    createMainAttributesWithSubs('distributions','Distributions',false,false,'money','aggregatedMetricsData.distributions'),
    ...customLayers,
    liabilityAmountAttr,
    lastUpdateAttr,
    holdingEntityAttr,
    institutionAttr,
    expectedEndDateAttr,
    kOneTrackerAttr,
    {dataKey:'class', title: 'Class', subAttributes: [], type:'class' , path: 'categoryClass.title'},
    ...commonDLForAllItemsAtTheEnd,
    valueAttr,
])

export const MAIN_RESULTS_CLASSES_CATEGORIES_ATTRIBUTES = (customLayers = []) => ([
    nameAttr,
    assetsRoiAttr,
    // assetsIrrAttr,
    createMainAttributesWithSubs('contributions','Contributions',false,false,'money','contributions'),
    createMainAttributesWithSubs('distributions','Distributions',false,false,'money','distributions'),
    ...customLayers,
    allocationTotalCapitalAttr,
    allocationLoansAmount,
    allocationLastUpdateAttr,
    allocationAttr,
    valueAttr,
])

export const MAIN_RESULTS_ALLOCATIONS_ATTRIBUTES = (customLayers = []) => ([
    nameAttr,
    assetsRoiAttr,
    // assetsIrrAttr,
    createMainAttributesWithSubs('contributions','Contributions',false,false,'money','contributions'),
    createMainAttributesWithSubs('distributions','Distributions',false,false,'money','distributions'),
    ...customLayers,
    allocationLoansAmount,
    allocationTotalCapitalAttr,
    allocationLastUpdateAttr,
    assetsAllocationAttr,
    liabilitiesValueAttr,
    valueAttr,
])

export const MIXED_RESULTS_DATA_LAYERS = (customLayers = []) => ([
    nameAttr,
    startDateAttr,
    roiAttr,
    irrAttr,
    createMainAttributesWithSubs('contributions','Contributions',false,false,'money','aggregatedMetricsData.contributions'),
    createMainAttributesWithSubs('distributions','Distributions',false,false,'money','aggregatedMetricsData.distributions'),
    ...customLayers,
    liabilityAmountAttr,
    lastUpdateAttr,
    holdingEntityAttr,
    institutionAttr,
    expectedEndDateAttr,
    kOneTrackerAttr,
    valueAttr,
])

export const STOCK_DATA_LAYERS_FOR_MIXED_RESULTS = [
    {...stockGainAttr, path: 'aggregatedMetricsData.gain.total.actual' } ,
    {...stockPerformanceAttr, path: 'aggregatedMetricsData.roi.total.actual'},
    {...stockNumberOfShareAttr, path: 'aggregatedMetricsData.number_of_shares.total.actual'},
    {...stockPpsAttr, path: 'aggregatedMetricsData.pps.current.actual'},
    {...stockCostPerShareAttr, path: 'aggregatedMetricsData.cost_per_share.total.actual'},
    // {...stockTotalCostAttr, path: 'aggregatedMetricsData.number_of_shares.total.actual'},
];

export const CAPITAL_CALLS_LAYERS_FOR_MIXED_RESULTS = [
    // totalCapitalAttr,
    // nextCapitalAttr,
    // nextCapitalValueAttr,
    // calledCapitalsAttr,
    // calledCapitalsPercentageAttr,
    // uncalledCapitalsAttr,
    capitalCallGroup,
]

export const SINGLE_CLASS_RESULTS_DATA_LAYERS = {
    1: () => ([
        nameAttr,
        {...holdingEntityAttr, title: 'Account owner'},
        lastSyncAttr,
        institutionAttr,
        ...commonDLForAllItemsAtTheEnd,
        valueAttr,
    ]),
    2: () => ([
        nameAttr,
        startDateAttr,
        createMainAttributesWithSubs('contributions','Contributions',false,false,'money','aggregatedMetricsData.contributions'),
        createMainAttributesWithSubs('distributions','Distributions',false,false,'money','aggregatedMetricsData.distributions'),
        {...holdingEntityAttr, title: 'Account owner'},
        institutionAttr,
        roiAttr,
        leveragedAssetsAttr,
        outstandingLoansAttr,
        availableLoanAmountAttr,
        remainingPremiumAttr(),
        liabilityAmountAttr,
        lastUpdateAttr,
        kOneTrackerAttr,
        ...commonDLForAllItemsAtTheEnd,
        valueAttr,
    ]),
    '2_holdings': () => ([
        nameAttr,
        startDateAttr,
        {...holdingEntityAttr, title: 'Account owner'},
        institutionAttr,
        investmentAccountAttr,
        stockGainAttr,
        stockPerformanceAttr,
        stockNumberOfShareAttr,
        stockPpsAttr,
        stockCostPerShareAttr,
        stockTotalCostAttr,
        lastUpdateAttr,
        kOneTrackerAttr,
        ...commonDLForAllItemsAtTheEnd,
        valueAttr,
    ]),
    3: (customLayers = []) => ([
        nameAttr,
        startDateAttr,
        roiAttr,
        createMainAttributesWithSubs('contributions','Contributions',false,false,'money','aggregatedMetricsData.contributions'),
        createMainAttributesWithSubs('distributions','Distributions',false,false,'money','aggregatedMetricsData.distributions'),
        ...customLayers,
        holdingEntityAttr,
        institutionAttr,
        liabilityAmountAttr,
        lastUpdateAttr,
        kOneTrackerAttr,
        ...commonDLForAllItemsAtTheEnd,
        valueAttr,
    ]),
    '3_holdings': () => ([
        nameAttr,
        startDateAttr,
        holdingEntityAttr,
        institutionAttr,
        investmentAccountAttr,
        stockGainAttr,
        stockPerformanceAttr,
        stockNumberOfShareAttr,
        stockPpsAttr,
        stockCostPerShareAttr,
        stockTotalCostAttr,
        lastUpdateAttr,
        kOneTrackerAttr,
        ...commonDLForAllItemsAtTheEnd,
        valueAttr,
    ]),
    4: (customLayers = []) => ([
        nameAttr,
        startDateAttr,
        createMainAttributesWithSubs('coc_capital','CoC',true,false,'percentage','aggregatedMetricsData.coc_capital'),
        roiAttr,
        irrAttr,
        moicAttr,
        createMainAttributesWithSubs('contributions','Contributions',false,false,'money','aggregatedMetricsData.contributions'),
        createMainAttributesWithSubs('distributions','Distributions',false,false,'money','aggregatedMetricsData.distributions'),
        // remainingTimeAttr,
        holdingEntityAttr,
        institutionAttr,
        ...customLayers,
        liabilityAmountAttr,
        expectedEndDateAttr,
        lastUpdateAttr,
        assetTypeAttr,
        addressAttr,
        kOneTrackerAttr,
        ...commonDLForAllItemsAtTheEnd,
        valueAttr,
    ]),
    5: () => ([
        nameAttr,
        holdingEntityAttr,
        {...institutionAttr, title: 'Agent / Platform'},
        {...startDateAttr, title:'Initial loan date'},
        {...interestRateAttr, title: 'Annual interest rate'},
        expectedPayoffDateAttr,
        remainingMonthsAttr,
        paymentPeriodAttr,
        periodicalPaymentAttr,
        createMainAttributesWithSubs('distributions','Total inflow',false,false,'money','aggregatedMetricsData.distributions'),
        createMainAttributesWithSubs('contributions','Total outflow',false,false,'money','aggregatedMetricsData.contributions'),
        irrAttr,
        lastUpdateAttr,
        kOneTrackerAttr,
        ...commonDLForAllItemsAtTheEnd,
        valueAttr,
    ]),
    6: () => ([
        nameAttr,
        startDateAttr,
        roiAttr,
        // createMainAttributesWithSubs('multiplier','Multiplier',true),
        createMainAttributesWithSubs('contributions','Contributions',false,false,'money','aggregatedMetricsData.contributions'),
        createMainAttributesWithSubs('distributions','Distributions',false,false,'money','aggregatedMetricsData.distributions'),
        holdingEntityAttr,
        institutionAttr,
        ppsAttr,
        numberOfShareAttr,
        liabilityAmountAttr,
        expectedEndDateAttr,
        lastUpdateAttr,
        sectorAttr,
        kOneTrackerAttr,
        ...commonDLForAllItemsAtTheEnd,
        valueAttr,
    ]),
    7: () => ([
        nameAttr,
        startDateAttr,
        roiAttr,
        createMainAttributesWithSubs('contributions','Contributions',false,false,'money','aggregatedMetricsData.contributions'),
        createMainAttributesWithSubs('distributions','Distributions',false,false,'money','aggregatedMetricsData.distributions'),
        liabilityAmountAttr,
        lastUpdateAttr,
        kOneTrackerAttr,
        ...commonDLForAllItemsAtTheEnd,
        valueAttr,
    ]),
    8: () => ([
        nameAttr,
        startDateAttr,
        roiAttr,
        dpiAttr,
        irrAttr,
        moicAttr,
        createMainAttributesWithSubs('contributions','Contributions',false,false,'money','aggregatedMetricsData.contributions'),
        createMainAttributesWithSubs('distributions','Distributions',false,false,'money','aggregatedMetricsData.distributions'),
        // remainingTimeAttr,
        holdingEntityAttr,
        institutionAttr,
        capitalCallGroup,
        liabilityAmountAttr,
        expectedEndDateAttr,
        lastUpdateAttr,
        distributionReinvestedAttr,
        kOneTrackerAttr,
        ...commonDLForAllItemsAtTheEnd,
        valueAttr,
    ]),
    9: () => ([
        nameAttr,
        startDateAttr,
        roiAttr,
        createMainAttributesWithSubs('contributions','Contributions',false,false,'money','aggregatedMetricsData.contributions'),
        createMainAttributesWithSubs('distributions','Distributions',false,false,'money','aggregatedMetricsData.distributions'),
        liabilityAmountAttr,
        lastUpdateAttr,
        kOneTrackerAttr,
        ...commonDLForAllItemsAtTheEnd,
        valueAttr,
    ]),
    '9_crypto-holdings': () => ([
        nameAttr,
        startDateAttr,
        holdingEntityAttr,
        institutionAttr,
        investmentAccountAttr,
        {...stockGainAttr, path: 'aggregatedMetricsData.gain.total.actual' } ,
        roiAttr,
        {...stockNumberOfShareAttr, title: 'Number of coins',  path: 'aggregatedMetricsData.quantities.total.actual'},
        {...stockPpsAttr, title: 'Current price (Close price)',  htmlTitle:'Current&nbsp;price<br/>(Close&nbsp;price)', path: 'aggregatedMetricsData.pps.current.actual'},
        {...stockCostPerShareAttr, title: 'Cost per coin', path: 'aggregatedMetricsData.cost_per_coin.total.actual'},
        lastUpdateAttr,
        kOneTrackerAttr,
        ...commonDLForAllItemsAtTheEnd,
        valueAttr,
    ]),
    10: () => ([
        nameAttr,
        startDateAttr,
        holdingEntityAttr,
        lenderAttr,
        loanTypeAttr,
        interestRateAttr,
        monthlyPaymentAttr,
        remainingMonthsAttr,
        expectedPayoffDateAttr,
        createMainAttributesWithSubs('amount_borrowed','Amount borrowed',false,false,'money','aggregatedMetricsData.amount_borrowed'),
        createMainAttributesWithSubs('contributions','Amount paid back',false,false,'money','aggregatedMetricsData.contributions'),
        lastUpdateAttr,
        ...commonDLForAllItemsAtTheEnd,
        balanceAttr,
    ]),
    11: () => ([
        nameAttr,
        {...holdingEntityAttr, title: 'Account owner'},
        lastSyncAttr,
        institutionAttr,
        ...commonDLForAllItemsAtTheEnd,
        valueAttr,
    ]),
}


