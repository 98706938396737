import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApproveIcon, CloseXIcon } from '../../../../assets/icons/common/common-icons';
import { MessageWrapper, IconWrapper, TextWrapper, UndoWrapper } from './notifications.styles';

const SuccessMsg = ({ closeToast, toastProps, text }) => (
    <MessageWrapper>
        <IconWrapper>
            <ApproveIcon />
        </IconWrapper>
        <TextWrapper>
            {text}
        </TextWrapper>
    </MessageWrapper>
)

const SuccessMsgWithUndo = ({ closeToast, toastProps, text, onUndo }) => (
    <MessageWrapper>
        <UndoWrapper >
            <span onClick={() => { onUndo(); closeToast() }}>Undo</span>
        </UndoWrapper>
        {/* <IconWrapper>
            <ApproveIcon/>
        </IconWrapper> */}
        <TextWrapper>
            {text}
        </TextWrapper>
        <div onClick={closeToast}>
            X
        </div>
    </MessageWrapper>
)

const ErrorMsg = ({ closeToast, toastProps, text }) => (
    <MessageWrapper>
        <IconWrapper>
            <CloseXIcon />
        </IconWrapper>
        <TextWrapper>
            {text}
        </TextWrapper>
    </MessageWrapper>
)

const InfoMsg = ({ closeToast, toastProps, text }) => (
    <MessageWrapper>
        <TextWrapper>
            {text}
        </TextWrapper>
    </MessageWrapper>
)




export const successNotification = (text, options) => {
    toast(<SuccessMsg text={text} />, options);
}

export const successNotificationWithUndo = (text, onUndo, options) => {
    toast(<SuccessMsgWithUndo text={text} onUndo={onUndo} />, options);
}

export const errorNotification = (text, options) => {
    toast(<ErrorMsg text={text || 'Something went wrong'} />, options);
}

export const infoNotification = (text, options) => {
    toast(<InfoMsg text={text} />, options);
}