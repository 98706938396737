import { observer } from "mobx-react";
import { BulkEditFloatingMenu, BulkEditFloatingMenuButtonDark, BulkEditFloatingMenuButtonDarkText, BulkEditFloatingMenuButtonIcon, BulkEditFloatingMenuButtonLight, BulkEditFloatingMenuButtonLightText, BulkEditFloatingMenuOptions, BulkEditFloatingMenuText, FooterWrapper } from "./DeepAnalysisReviewResultModal.styles";
import { useStore } from "../../../app/data/root.store";
import { DeepAnalysisReviewBulkEditModal } from "./DeepAnalysisReviewBulkEditModal";
import { useState } from "react";
import { DeepAnalysisReviewBulkEditDeleteModal } from "./DeepAnalysisReviewBulkEditDeleteModal";
import { DeepAnalysisReviewBulkEditUndo } from "./DeepAnalysisReviewBulkEditUndo";

export const DeepAnalysisReviewBulkEdit = observer(({ isInBulkEditMode, relevantClasses }) => {
    const { magicBoxAiStore } = useStore();
    const { bulkEditItemsCount } = magicBoxAiStore;
    const [editField, setEditField] = useState(null);
    const [showBulkEditModal, setShowBulkEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onClickEdit = (field) => {
        setEditField(field);
        setShowBulkEditModal(true);
    };

    const onSave = (fieldsToChange) => {
        magicBoxAiStore.bulkEditItems(fieldsToChange, editField);
        setShowBulkEditModal(false);
    };

    const onDeleteItems = () => setShowDeleteModal(true);

    return (
        <>
            {
                <FooterWrapper show={isInBulkEditMode}>
                    <BulkEditFloatingMenu>
                        <BulkEditFloatingMenuText>Edit {bulkEditItemsCount} selected item{(bulkEditItemsCount > 1) && 's'}</BulkEditFloatingMenuText>
                        <BulkEditFloatingMenuOptions>
                            <BulkEditFloatingMenuButtonDark onClick={() => onClickEdit('value')}>
                                <BulkEditFloatingMenuButtonIcon>
                                    <EditIcon />
                                </BulkEditFloatingMenuButtonIcon>
                                <BulkEditFloatingMenuButtonDarkText>Value</BulkEditFloatingMenuButtonDarkText>
                            </BulkEditFloatingMenuButtonDark>
                            <BulkEditFloatingMenuButtonDark onClick={() => onClickEdit('currency')}>
                                <BulkEditFloatingMenuButtonIcon>
                                    <EditIcon />
                                </BulkEditFloatingMenuButtonIcon>
                                <BulkEditFloatingMenuButtonDarkText>Currency</BulkEditFloatingMenuButtonDarkText>
                            </BulkEditFloatingMenuButtonDark>
                            <BulkEditFloatingMenuButtonDark onClick={() => onClickEdit('category')}>
                                <BulkEditFloatingMenuButtonIcon>
                                    <EditIcon />
                                </BulkEditFloatingMenuButtonIcon>
                                <BulkEditFloatingMenuButtonDarkText>Category</BulkEditFloatingMenuButtonDarkText>
                            </BulkEditFloatingMenuButtonDark>
                            <BulkEditFloatingMenuButtonDark onClick={onDeleteItems}>
                                <BulkEditFloatingMenuButtonIcon>
                                    <DeleteIcon />
                                </BulkEditFloatingMenuButtonIcon>
                                <BulkEditFloatingMenuButtonDarkText>Delete</BulkEditFloatingMenuButtonDarkText>
                            </BulkEditFloatingMenuButtonDark>

                            <BulkEditFloatingMenuButtonLight onClick={() => magicBoxAiStore.exitBulkEditMode()}>
                                <BulkEditFloatingMenuButtonIcon>
                                    <CancelIcon />
                                </BulkEditFloatingMenuButtonIcon>
                                <BulkEditFloatingMenuButtonLightText>Cancel edit mode</BulkEditFloatingMenuButtonLightText>
                            </BulkEditFloatingMenuButtonLight>
                        </BulkEditFloatingMenuOptions>
                    </BulkEditFloatingMenu>
                </FooterWrapper>
            }
            {
                showBulkEditModal &&
                <DeepAnalysisReviewBulkEditModal
                    onClose={() => setShowBulkEditModal(false)}
                    relevantClasses={relevantClasses}
                    editField={editField}
                    onSave={(fieldsToChange) => onSave(fieldsToChange)}
                />
            }
            {
                showDeleteModal &&
                <DeepAnalysisReviewBulkEditDeleteModal
                    onClose={() => setShowDeleteModal(false)}
                    onDelete={() => {
                        magicBoxAiStore.deleteBulkEditItems();
                        setShowDeleteModal(false);
                    }}
                    count={bulkEditItemsCount}
                />
            }
            {
                magicBoxAiStore.backupData &&
                <DeepAnalysisReviewBulkEditUndo
                    text={magicBoxAiStore.backupData.text}
                    onUndo={() => magicBoxAiStore.undoBulkEdit()}
                    onClose={() => magicBoxAiStore.clearBackupData()}
                />
            }
        </>

    );
});


const EditIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1401 0.293677C13.9442 0.0990267 13.6273 0.0996624 13.4322 0.295097L6.24666 7.49334C6.18683 7.55327 6.14324 7.62739 6.11997 7.70873L5.40699 10.2016C5.3638 10.3526 5.50407 10.492 5.65524 10.4483L8.15079 9.72689C8.23222 9.70335 8.30633 9.65955 8.36616 9.59962L13.6412 4.31521L11.5306 2.20459L12.2377 1.49749L14.3477 3.60748L15.5517 2.40138C15.7468 2.20594 15.7462 1.88972 15.5503 1.69507L14.1401 0.293677Z" fill="white" />
            <path d="M14 8V13.9C14 13.9552 13.9552 14 13.9 14H2.1C2.04477 14 2 13.9552 2 13.9V2.1C2 2.04477 2.04477 2 2.1 2H8" stroke="white" stroke-width="1.5" />
        </svg>
    );
};

const DeleteIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path d="M15.25 7.5C15.25 7.5 14.7262 13.9022 14.4194 16.9881C14.369 17.4947 13.9419 17.8757 13.4328 17.876L4.65189 17.8811C4.14227 17.8814 3.7143 17.5 3.66174 16.9931C3.34059 13.8953 2.75 7.5 2.75 7.5" stroke="white" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round" />
            <path d="M16.5 4.55273H1.5" stroke="white" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round" />
            <path d="M13.5 4.55288C12.4225 4.55288 12.0944 3.67353 12.042 2.75719C12.004 2.09153 11.5116 1.5 10.8448 1.5H7.15516C6.48842 1.5 5.99604 2.09153 5.95799 2.75719C5.90562 3.67353 5.57753 4.55288 4.5 4.55288" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}

const CancelIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7881 11.9139L2.30278 3.42867L3.21191 2.51953L11.6972 11.0048L10.7881 11.9139Z" fill="#111213" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6972 3.42776L3.21187 11.913L2.30273 11.0039L10.788 2.51863L11.6972 3.42776Z" fill="#111213" />
        </svg>
    );
};
