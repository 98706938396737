import { Tabs } from 'antd';
import { Observer } from 'mobx-react';
import React from 'react';
import { useStore } from '../../../app/data/root.store';
import { HeaderTabsWrapper, HeaderTitleTab, SortHoverIconWrapper, TableHeaderSortIconWrapper } from '../../../wealth/components/wealth.commons.styles';
// import { ReactComponent as SortHoverIcon } from '../assets/sort-hover-icon.svg';
import { ReactComponent as SortHoverIcon } from '../../assets/sort-hover-icon.svg';
import { ReactComponent as SortIcon } from '../../assets/sort-icon.svg';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';


export function CommunityItemsListHeaderTabs(props) {
    const { communityStore } = useStore();
    
    const { TabPane } = Tabs;
    const { mixpanelTrack } = useAnalytics();

    const handleColumHeaderClick = (dl) => {
      if (communityStore.itemsSortBy === dl.dataKey) {
        if (communityStore.itemsSortDirection === 'asc') {
          communityStore.setSortBy('instName');
          // communityStore.setSortByColumn({ dataKey: 'assetName', type: 'assetName' });
        }
        communityStore.setSortDirection(communityStore.itemsSortDirection === 'desc' ? 'asc' : 'desc');
      } else {
        communityStore.setSortBy(dl.dataKey);
        // communityStore.setSortByColumn(dl);
        communityStore.setSortDirection(dl.dataKey === 'instName' ? 'asc' : 'desc');
      }
    }

    const handleTabClick = (viewMode) => {
      if (communityStore.selectedTab !== viewMode){
        const pageLocation = 'Community main page';
        mixpanelTrack('Tab Clicked', {
          destination : viewMode,
          location: pageLocation,
          tab_text: viewMode,
          type: 'tab',
          url: window.location.href
        })
      }
      communityStore.setSelectedTab(viewMode);      
    }

    const handleSortByInstNameHeaderTabClick = (viewMode) => {
      if (communityStore.isShowingInstitutions){
          handleColumHeaderClick({dataKey: 'instName'})
      }
      
    }
    
    return (<Observer>{() => (
            <HeaderTabsWrapper mobileTabs={true}>
                <Tabs activeKey={communityStore.isClassView ? '' : communityStore.selectedTab} onChange={handleTabClick}>
                  {communityStore.isOverviewView && <TabPane
                    tab={
                      <HeaderTitleTab isSelected={communityStore.selectedTab === 'classes'} 
                      // onClick={() => handleTabClick('classes')}
                      >Classes (11)</HeaderTitleTab>
                    }
                    key='classes'
                  />}

                  <TabPane tab={
                    <HeaderTitleTab isSelected={!communityStore.isClassView  && communityStore.selectedTab === 'institutions'} 
                    onClick={()=>handleSortByInstNameHeaderTabClick('institutions')}
                    >
                      Institutions ({communityStore.institutionsCount})
                      {communityStore.itemsSortBy === 'instName' && communityStore.isShowingInstitutions ? <TableHeaderSortIconWrapper isAsc={communityStore.itemsSortDirection === 'asc'}>
                      <SortIcon />
                    </TableHeaderSortIconWrapper> : null}

                     {communityStore.itemsSortBy !== 'instName'  && communityStore.isShowingInstitutions ? <SortHoverIconWrapper><SortHoverIcon /></SortHoverIconWrapper> : null}

                    </HeaderTitleTab> 
                  } key='institutions' />
                </Tabs>

                    
            </HeaderTabsWrapper>
 
    )}</Observer>)
}