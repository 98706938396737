import { Observer } from 'mobx-react';
import React
, { useState }  // , { useState }
  from 'react';
import { useTrackEvent, useTrackGTMEvent } from '../../../../hooks/useTrackEvent/useTrackEvent';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { useStore } from '../../../app/data/root.store';
import { MagicBoxAiActivationFlow } from '../../../magic-box/components/MagicBoxAiActivationFlow';
import DocumentTypesIcon from '../../assets/files-logos.png';
import { SecuredMagicBoxIcon } from '../../assets/userActivationIcons';
import { ChatRow } from '../ChatRow/ChatRow';
import { useAnimationsStepDelayedHook } from '../useAnimationsStepDelayedHook';
import { ActionButton, ActionButtonsWrapper, ActivationFlowStepWrapper, AnimatedActivationFlowWrapper, DocumentTypesHoverEffect, DocumentTypesImg, DocumentTypesPromptWrapper, HowItWorksHoverEffect, HowItWorksPromptWrapper, LineSeparator, SecuredPromptHoverEffect, SecuredPromptWrapper, StepContent, StepFooter, StepRowDetailsWrapper, StepRowTitle, StepRowWrapper } from '../UserActivationFlowPage.styles';
import { PrimaryButton, PrimaryButtonWithIcon } from '../../../../common/components/buttons/NewButtons.styles';
import { UploadIconSvg } from '../../../magic-box/components/MagicBoxAIPage';
import { LoadingOutlined } from '@ant-design/icons'

export const MagicBoxAiStep = () => {
  const { magicBoxAiStore, uiStore } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const animationStep = useAnimationsStepDelayedHook({ animationsStepDelayed: [700] });
  const { trackEvent } = useAnalytics()
  const mixpanelStepsData = { step_name: 'Magic box ai', step_number: 2 };

  const handleFlowStepChange = (delta) => {
    if (delta > 0) {
      uiStore.setActivationStepAsDone(1);
      trackEvent('Onboarding Step Completed', mixpanelStepsData);
    }
    uiStore.setActivationStepIndex(uiStore.activationStepIndex + delta);
  }

  const handleDoNotHaveBalanceSheet = () => {
    trackEvent('CTA Clicked', {
      cta_text: 'I don’t have a balance sheet',
      location: 'Magic box ai activation flow',
      destination: 'manual magic box',
      type: "Button",
      url: window.location.href
    });
    magicBoxAiStore.setSwitchToManualReview(true);
  };

  useTrackEvent('Onboarding Step Viewed', mixpanelStepsData);
  useTrackGTMEvent('Onboarding Step Viewed', mixpanelStepsData);

  const onDelete = () => magicBoxAiStore.deleteDocument(true);
  const onSubmit = async () => {
    setIsLoading(true);
    trackEvent('CTA Clicked', {
      cta_text: 'Review initial results',
      location: 'Magic box ai activation flow',
      destination: ['failed', 'timeout'].includes(magicBoxAiStore.file.llmStatus) ? 'Manual processing' : 'llm results',
      type: "Button",
      url: window.location.href
    })
    await magicBoxAiStore.approveLLM();
    if ('failed' === magicBoxAiStore.file.llmStatus || 'timeout' === magicBoxAiStore.file.llmStatus) {
      await magicBoxAiStore.confirmUpload();
    }
    setIsLoading(false);
  }

  const buttonText = ('done' === magicBoxAiStore.file?.llmStatus) ?  "Review initial results" : "Submit and continue";

  return (<Observer>{() => (
    <ActivationFlowStepWrapper>

      <StepContent withFixedActions flex='none'>
        <ChatRow duration={0}>
          <span>
            <span style={{ fontWeight: 600 }}>Seamless setup: </span>
            Import your investment tracker / personal balance sheet</span>
        </ChatRow>

        <AnimatedActivationFlowWrapper padding={uiStore.isDesktopView ? '16px 0 16px 62px' : ''}>
          <MagicBoxAiActivationFlow onFlowStepChange={(delta) => handleFlowStepChange(delta)} />
        </AnimatedActivationFlowWrapper>
      </StepContent>

      <StepFooter paddingLeft={62}>
        <ActionButtonsWrapper>
          {uiStore.isDesktopView && <ActionButton isBack onClick={() => { handleFlowStepChange(-1) }}>Back</ActionButton>}
          {
            !magicBoxAiStore.isUploading &&
            <>
              {
                magicBoxAiStore.file ?
                  <>
                    {
                      (('done' === magicBoxAiStore.file.llmStatus) || ('failed' === magicBoxAiStore.file.llmStatus) || ('timeout' === magicBoxAiStore.file.llmStatus) || ('in process' === magicBoxAiStore.file.llmStatus)) &&
                      <PrimaryButton borderRadius={39} onClick={onSubmit} disabled={((magicBoxAiStore.isTyping) || ('in process' === magicBoxAiStore.file.llmStatus))} style={{ minWidth: '175px' }}>{isLoading ? <LoadingOutlined /> : buttonText}</PrimaryButton>
                    }
                    {
                      ('rejected' === magicBoxAiStore.file.llmStatus) &&
                      <>
                        <div onClick={() => handleFlowStepChange(1)} style={{ display: 'grid', placeItems: 'center', cursor: 'pointer', padding: uiStore.isDesktopView ? '0' : '0 16px' }}>Skip</div>
                        <PrimaryButtonWithIcon borderRadius={39} onClick={onDelete}><UploadIconSvg />Upload another file</PrimaryButtonWithIcon>
                      </>
                    }
                  </> :
                  <>
                    <ActionButton onClick={handleDoNotHaveBalanceSheet}>I don’t have a balance sheet</ActionButton>
                  </>
              }
            </>
          }
        </ActionButtonsWrapper>
      </StepFooter>

      {(uiStore.isDesktopView && animationStep > 0) &&
        <DocumentTypesPromptWrapper>
          <DocumentTypesSvg />
          Document types
          <DocumentTypesHoverEffect>
            <DocumentTypesHoverEffectContent />
          </DocumentTypesHoverEffect>
        </DocumentTypesPromptWrapper>}

      {(uiStore.isDesktopView && animationStep > 0) &&
        <SecuredPromptWrapper rightInput={'150'}>
          <SecuredMagicBoxIcon />
          Secured
          <SecuredPromptHoverEffect>We use bank-grade security and advanced encryption technologies to protect data privacy and security. All submitted documents are anonymized and then analyzed either by our LLM or, when needed, by our in-house team of analysts. Your data is never used to train our LLM model.</SecuredPromptHoverEffect>        </SecuredPromptWrapper>}

      {(uiStore.isDesktopView && animationStep > 0) &&
        <HowItWorksPromptWrapper>
          <QuestionMarkSvg />
          How it works
          <HowItWorksHoverEffect>
            <div style={{ padding: '16px 28px' }}>3 steps to set up your portfolio:</div>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '0 20px 0 24px' }}>
              <StepRowWrapper>
                <DocSvg />
                <StepRowDetailsWrapper>
                  <StepRowTitle>1. Import Files</StepRowTitle>
                  Import a file detailing your investment information, like a spreadsheet tracker or personal balance sheet.
                </StepRowDetailsWrapper>
              </StepRowWrapper>

              <StepRowWrapper>
                <MagicBoxSvg />
                <StepRowDetailsWrapper>
                  <StepRowTitle>2. Magic Box AI</StepRowTitle>
                  The Magic AI will convert your files into assets and liabilities to set up your portfolio.
                </StepRowDetailsWrapper>
              </StepRowWrapper>

              <StepRowWrapper>
                <SettingsSvg />
                <StepRowDetailsWrapper>
                  <StepRowTitle>3. Deep Analysis</StepRowTitle>
                  Our deep analysis process extracts all the financial data from your files, which can take up to 48 hours to complete.
                </StepRowDetailsWrapper>
              </StepRowWrapper>
            </div>

          </HowItWorksHoverEffect>
        </HowItWorksPromptWrapper>}


    </ActivationFlowStepWrapper>
  )}</Observer>)
}

export const DocumentTypesHoverEffectContent = () => {
  return (
    <>
      <DocumentTypesImg bgImg={DocumentTypesIcon} />
      <div style={{ textAlign: 'center' }}>We want to help set up your portfolio as<br />effortlessly as possible.</div>
      <LineSeparator />
      <div>Files containing your assets' current valuation, liabilities, and total net worth are the best to upload, for example: personal balance sheet, investment tracker, spreadsheet tracker, PFS.</div>
    </>
  )
}

const DocumentTypesSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.4993 9.58398H8.66602V8.33398H14.4993V9.58398Z" fill="#1A1B1D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.166 12.084L8.66602 12.084L8.66602 10.834L11.166 10.834L11.166 12.084Z" fill="#1A1B1D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.7493 5H5.41602V16.75C5.41602 18.5449 6.87109 20 8.66602 20H17.5C18.9597 20 20.1946 19.0377 20.605 17.713C20.6987 17.4654 20.75 17.1969 20.75 16.9165V16.75V15.334V15.0832C20.75 14.669 20.4142 14.3332 20 14.3332H17.7493V5ZM16.25 15.4167H16.2493V6.5H6.91602V16.75C6.91602 17.7165 7.69952 18.5 8.66602 18.5H16.9016C16.4988 18.0934 16.25 17.534 16.25 16.9165V15.4167ZM19.25 16.75C19.25 16.9126 19.2278 17.07 19.1864 17.2193C19.07 17.4827 18.8065 17.6665 18.5 17.6665C18.0858 17.6665 17.75 17.3307 17.75 16.9165V15.8332H19.25V16.75Z" fill="#1A1B1D" />
    </svg>
  )
}

const QuestionMarkSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.75 12C5.75 8.54822 8.54822 5.75 12 5.75C15.4518 5.75 18.25 8.54822 18.25 12C18.25 15.4518 15.4518 18.25 12 18.25C8.54822 18.25 5.75 15.4518 5.75 12ZM12 4.25C7.71979 4.25 4.25 7.71979 4.25 12C4.25 16.2802 7.71979 19.75 12 19.75C16.2802 19.75 19.75 16.2802 19.75 12C19.75 7.71979 16.2802 4.25 12 4.25ZM11.2475 13.6478H12.4235V13.5824C12.4235 13.136 12.6304 12.7984 13.0115 12.5262L13.458 12.2104C14.1004 11.7531 14.536 11.1433 14.536 10.2831C14.536 9.18332 13.654 8.11621 11.9662 8.11621C10.3547 8.11621 9.47266 9.24866 9.47266 10.4247C9.47266 10.588 9.48355 10.7622 9.5271 10.9147L10.8991 10.9691C10.8664 10.8711 10.8555 10.7513 10.8555 10.6207C10.8555 10.0109 11.1931 9.39021 11.9662 9.39021C12.6958 9.39021 13.0551 9.86932 13.0551 10.3702C13.0551 10.6969 12.9135 10.9909 12.5869 11.2304L12.0642 11.6224C11.4435 12.0907 11.204 12.6242 11.204 13.2013C11.204 13.3647 11.2258 13.5062 11.2475 13.6478ZM11.8573 14.3555C11.3782 14.3555 10.9971 14.7475 10.9971 15.2267C10.9971 15.7058 11.3782 16.0869 11.8573 16.0869C12.3364 16.0869 12.7284 15.7058 12.7284 15.2267C12.7284 14.7475 12.3364 14.3555 11.8573 14.3555Z" fill="#1A1B1D" />
    </svg>
  )
}

const DocSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.0445 12.9534L13.2075 13.2099C13.1602 12.9467 12.9442 12.5349 12.3367 12.5349C11.8845 12.5349 11.5875 12.8252 11.5875 13.1424C11.5875 13.4057 11.7562 13.6149 12.1072 13.6824L12.7755 13.8107C13.6462 13.9794 14.112 14.5464 14.112 15.2214C14.112 15.9572 13.4977 16.6997 12.384 16.6997C11.115 16.6997 10.5547 15.8829 10.4805 15.2012L11.3445 14.9717C11.385 15.4442 11.7157 15.8694 12.3907 15.8694C12.8902 15.8694 13.167 15.6197 13.167 15.2822C13.167 15.0054 12.9577 14.7894 12.5865 14.7152L11.9182 14.5802C11.1555 14.4249 10.6627 13.9322 10.6627 13.2099C10.6627 12.3594 11.4255 11.7114 12.33 11.7114C13.491 11.7114 13.9365 12.4134 14.0445 12.9534Z" fill="white" />
      <path d="M10.0918 16.5983L7.02734 16.5983L7.02734 11.8125L7.95884 11.8125L7.95884 15.7073L10.0918 15.7073L10.0918 16.5983Z" fill="white" />
      <path d="M6.35852 11.8125L4.79252 14.2088L6.36527 16.5983L5.23802 16.5983L4.20527 14.9445L3.16577 16.5983L2.07227 16.5983L3.64502 14.202L2.07902 11.8125L3.19952 11.8125L4.22552 13.4663L5.27177 11.8125L6.35852 11.8125Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.6563 8.99988L19.6563 21.1764C19.6563 21.6383 19.2818 22.0127 18.8199 22.0127L5.96133 22.0127C5.49944 22.0127 5.125 21.6383 5.125 21.1764L5.125 18.75L13.75 18.75C14.9926 18.75 16 17.7426 16 16.5L16 12C16 10.7574 14.9926 9.75 13.75 9.75L5.125 9.75L5.125 3.08653C5.125 2.62464 5.49944 2.2502 5.96133 2.2502L13.75 2.2502L13.75 7.49988C13.75 8.32831 14.4216 8.99988 15.25 8.99988L19.6563 8.99988ZM15.25 2.90679L15.25 7.49988L19.5147 7.49988C19.4901 7.46308 19.4624 7.42808 19.4318 7.39529L15.25 2.90679Z" fill="white" />
    </svg>

  )
}

const MagicBoxSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 10.56L3 8V17.12L11 19.68V10.56ZM13 10.56V19.68L21 17.12V8L13 10.56Z" fill="white" />
      <path d="M3 6.5L12 4.5L21 6.5L12 9L3 6.5Z" fill="white" />
    </svg>
  )
}

const SettingsSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.7225 4.05483L13.4207 3H10.5793L10.2775 4.05485C9.95335 5.18765 8.66012 5.72497 7.62867 5.15539L6.6406 4.60977L4.63148 6.59216L5.1548 7.5148C5.74298 8.55178 5.20056 9.86546 4.05212 10.1854L3 10.4785V13.282L4.05212 13.5751C5.20056 13.895 5.74297 15.2087 5.15479 16.2457L4.63146 17.1683L6.64058 19.1507L7.62867 18.6051C8.66013 18.0355 9.95335 18.5728 10.2775 19.7056L10.5793 20.7605L13.4207 21L13.7516 19.7587C14.0587 18.607 15.3659 18.0499 16.4093 18.6261L17.3594 19.1507L19.3685 17.1683L18.8452 16.2457C18.257 15.2087 18.7994 13.895 19.9479 13.5751L21 13.282V10.4785L19.9479 10.1854C18.7994 9.86545 18.257 8.55179 18.8452 7.51481L19.3685 6.59214L17.3594 4.60975L16.3713 5.15537C15.3399 5.72495 14.0466 5.18764 13.7225 4.05483ZM11.9987 7.43301C9.47657 7.43301 7.43201 9.47758 7.43201 11.9997C7.43201 14.5218 9.47657 16.5663 11.9987 16.5663C14.5208 16.5663 16.5653 14.5218 16.5653 11.9997C16.5653 9.47758 14.5208 7.43301 11.9987 7.43301ZM9.23201 11.9997C9.23201 10.4717 10.4707 9.23301 11.9987 9.23301C13.5267 9.23301 14.7653 10.4717 14.7653 11.9997C14.7653 13.5277 13.5267 14.7663 11.9987 14.7663C10.4707 14.7663 9.23201 13.5277 9.23201 11.9997Z" fill="white" />
    </svg>
  )
}