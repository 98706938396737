import React, { useState } from 'react';
import { Observer } from 'mobx-react';
// import moment from 'moment';
import { Title, Wrapper } from './OnBoardingHero.styles';
import { ActionsWrapper, AddAssetButton, AddAssetButtonText, AddAssetButtonWrapper, AltTitle,  LeftPanelContentWrapper, LeftPanelWrapper,  RightPanelWrapper, TitlesWrapper } from '../QuickStart/QuickStart.styles';
import { useStore } from '../../../app/data/root.store';
import { OnBoardingTourModal } from './OnBoardingTourModal';
import { ActionLocked } from '../../../../common/components/ActionLocked/ActionLocked';
import { ActionLockedIconWithTooltip } from '../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
import { HeroHeader, HeroTitleWrapper } from '../../../wealth/components/WealthLayout.styles';
import { WealthHeroTitle } from '../../../wealth/pages/overview/components/hero/WealthHeroTitle';
import heroAnimData from './assets/hero-lottie.json';
import { Player } from "@lottiefiles/react-lottie-player";


export function OnBoardingHero() {

  const [showTourModal, setShowTourModal] = useState(false);

  const { createItemStore, userStore, uiStore } = useStore();

  const manualItemOnClickHandler = () => {
    createItemStore.setChooseMethodStep();
    createItemStore.setShowModal(true);
  }

  return (<Observer>{() => (  
    <Wrapper >
      <HeroHeader isFixed={uiStore.isMobileHeaderFixed}>
        <HeroTitleWrapper>
          <WealthHeroTitle title={`${userStore.isViewingOwnPortfolio ? 'My' : `${userStore.firstNameCapitalize}'s`} portfolio`} iconName='home' />
        </HeroTitleWrapper>
      </HeroHeader>
      <div style={{display:'flex',flexDirection:'row', padding : uiStore.isDesktopView ? '0px' : '12px 0 36px 0'}} >
        <LeftPanelWrapper>
          <LeftPanelContentWrapper>
            <TitlesWrapper>
              <Title>Track your investments.</Title>
              <AltTitle>Add your assets and liabilities so you can see how your portfolio is doing.</AltTitle>
            </TitlesWrapper>
            <ActionsWrapper>
              <ActionLocked action={'quickStartHeroAddItem'}>
                <AddAssetButtonWrapper onClick={manualItemOnClickHandler}><AddAssetButton><AddAssetButtonText>Add asset</AddAssetButtonText> {userStore.isReadOnlyMode && <ActionLockedIconWithTooltip />}</AddAssetButton></AddAssetButtonWrapper>
              </ActionLocked>
            </ActionsWrapper>

          </LeftPanelContentWrapper>
        </LeftPanelWrapper>
        <RightPanelWrapper>
          <Player
                    loop={true}
                    src={heroAnimData}
                    //play={showLetsGoAnim}
                    speed={1}
                    autoplay
                    style={  uiStore.isDesktopView ? { height: '300px' } : {} }
                />
        </RightPanelWrapper>
      </div>
      {showTourModal && <OnBoardingTourModal onBackDropClick={() => { setShowTourModal(false) }} />}
    </Wrapper>
  )}</Observer>)
}   