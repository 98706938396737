import { observer } from "mobx-react";
import { AssistanceChatIconWrapper, AssistanceChatLinkButton, AssistanceChatLinkButtonWrapper } from "./Assistance.styles";
import { CUSTOM_DATA } from "../data/Assistance.consts";
import { useStore } from "../../app/data/root.store";

export const AssistanceChatMessageButton = observer(({ q, type }) => {
    const { assistanceStore } = useStore();

    const onClickButton = () => {
        switch (type) {
            case 'PRODUCT_SUPPORT':
                window.Intercom && window.Intercom('showNewMessage', q);
                break;
            case 'Error':
                assistanceStore.sendMessage(q);
                break;
            default:
                break;
        }
    };

    return (
        <AssistanceChatLinkButtonWrapper>
            <AssistanceChatLinkButton onClick={onClickButton}>
                {CUSTOM_DATA[type].title}
                <AssistanceChatIconWrapper>{CUSTOM_DATA[type].icon}</AssistanceChatIconWrapper>
            </AssistanceChatLinkButton>
        </AssistanceChatLinkButtonWrapper>
    );
});