import React from 'react'
import moment from 'moment';
import {
  // ArrowRightIconWrapper,
  CancelSection, ItemIconWrapper,
  // NotificationRowActionSection,
  NotificationRowCloseWrapper, NotificationRowSection, NotificationRowWrapper, NotificationEventStatusWrapper,
  NotificationStatusIconWrapper, NotificationTransactionWrapper, NotificationAmountWrapper,
  NotificationTransactionIconWrapper, NotificationDateAndBankWrapper, NotificationDateWrapper, NotificationAcountwrapper,
  NotificationAssetAndEventWrapper, NotificationAssetWrapper,
  InvitationAcceptedRoleRow,
  RolesNameIconWrapper,
  InvitationAcceptedRowNameAndMail,
  InvitationAcceptedRowNameText,
  InvitationAcceptedRowEmail,
  NotificationMagicBoxAiWrapper
} from './Notifications.styles';

import { useStore } from '../../app/data/root.store';
import { AssetClassIcon } from '../../assets/components/AssetClassIcon';
import { displayMoneyFormatter } from '../../../common/utils/number-display.utils'
import {
  // ArrowDownIcon as ArrowRightIcon,
  CloseXIcon, CalendarIcon1, TransactionIcon, TransactionSmallIcon
} from '../../../assets/icons/common/common-icons';
import { Tooltip } from 'antd';
import { useSwipeable } from 'react-swipeable';
import { capitalize } from '../../../common/utils/string.utils';
import { emailToColor } from '../../../common/styles/theme.styles';

const InvitationAcceptedSvg = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9995 10.9995L0.999513 10.9995L0.999512 4.19951L10.9995 4.19951L10.9995 10.9995Z" stroke="#585D66" strokeWidth="1.40013" strokeLinecap="round" />
      <path d="M5.99929 7.50977L0.619382 4.01001L5.99958 0.999955L11.3694 4.00952L5.99929 7.50977Z" fill="#585D66" />
      <path d="M0.619382 4.01001L0.326431 3.48639L0.292204 4.51296L0.619382 4.01001ZM5.99929 7.50977L5.67211 8.01271L5.99961 8.22576L6.32692 8.01242L5.99929 7.50977ZM11.3694 4.00952L11.697 4.51218L11.6627 3.48612L11.3694 4.00952ZM5.99958 0.999955L6.29293 0.476554L5.70663 0.476333L5.99958 0.999955ZM0.292204 4.51296L5.67211 8.01271L6.32647 7.00682L0.94656 3.50707L0.292204 4.51296ZM6.32692 8.01242L11.697 4.51218L11.0418 3.50687L5.67166 7.00712L6.32692 8.01242ZM11.6627 3.48612L6.29293 0.476554L5.70624 1.52336L11.076 4.53292L11.6627 3.48612ZM5.70663 0.476333L0.326431 3.48639L0.912332 4.53363L6.29253 1.52358L5.70663 0.476333Z" fill="#585D66" />
    </svg>
  )
}

const MagicBoxAiIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.63802 7.42688L2.30469 5.72021V11.8002L7.63802 13.5069V7.42688ZM8.97135 7.42688V13.5069L14.3047 11.8002V5.72021L8.97135 7.42688Z" fill="#585D66" />
      <path d="M2.30469 4.72005L8.30469 3.38672L14.3047 4.72005L8.30469 6.38672L2.30469 4.72005Z" fill="#585D66" />
    </svg>
  )
}

const eventTypeHandlers = {
  'event passed': {
    isType: (type) => type === 'event passed',
    icon: <CalendarIcon1 />,
    label: 'Event is overdue',
    wrapperStyle: { fontSize: '15px', marginTop: '4px' }
  },
  'invitation accepted': {
    isType: (type) => type === 'invitation accepted',
    icon: <InvitationAcceptedSvg />,
    label: 'Invitation accepted',
    dateFormatter: (notification) => moment(notification.dynamicData.date).format("MMM Do, h:mmA"),
  },
  'magic box ai data readiness': {
    isType: (type) => type === 'magic box ai data readiness',
    icon: <MagicBoxAiIcon />,
    label: 'Magic Box AI',
    // dateFormatter: (notification) => moment(notification.dynamicData.date).format("MMM Do, h:mmA"),
  },
  'transaction to specific event': {
    isType: (type) => type === 'transaction to specific event',
    icon: <TransactionIcon />,
    label: 'Pending your approval',
    transactionDetails: (notification, metadataStore) => (
      <>
        <NotificationAmountWrapper>
          {notification.dynamicData.transactionValue ? (
            notification.dynamicData.transactionCurrency
              ? displayMoneyFormatter(
                Math.abs(notification.dynamicData.transactionValue),
                notification.dynamicData.transactionCurrency,
                2
              )
              : Math.abs(notification.dynamicData.transactionValue).toFixed(2)
          ) : null}
        </NotificationAmountWrapper>
        <NotificationDateAndBankWrapper>
          <NotificationTransactionIconWrapper>
            <TransactionSmallIcon />
          </NotificationTransactionIconWrapper>
          <NotificationDateWrapper>
            {moment(notification.dynamicData.transactionFullDate).format("DD MMM,YY")}
          </NotificationDateWrapper>{' '}
          |{' '}
          <NotificationAcountwrapper>
            {metadataStore.itemsMeta[notification.dynamicData.transactionAssociatedId]?.title}
          </NotificationAcountwrapper>
        </NotificationDateAndBankWrapper>
      </>
    ),
  },
  // Add more event types here as needed
};


export const NotificationRow = (props) => {
  const { notification, onRead, onSeen, onActionClick, onClose, isMobile } = props;
  const { metadataStore, userStore } = useStore();

  const handleActionOnClick = () => {
    onActionClick?.();
  };

  const handleCloseOnClick = (event) => {
    event.stopPropagation();
    onRead?.();
    onClose?.();
  };

  const swipeHandlers = useSwipeable(
    isMobile
      ? {
        onSwipedLeft: (eventData) => {
          handleCloseOnClick(eventData.event);
        },
      }
      : {}
  );

  const currentEventHandler = Object.values(eventTypeHandlers).find((handler) =>
    handler.isType(notification.notificationType)
  );

  return (
    <NotificationRowWrapper onClick={() => { handleActionOnClick(); onSeen(); }} {...swipeHandlers}>
      <NotificationRowSection>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {currentEventHandler && (
            <NotificationEventStatusWrapper>
              <NotificationStatusIconWrapper marginRight={notification.notificationType === 'magic box ai data readiness' ? 10 : 8}>
                {currentEventHandler.icon}
              </NotificationStatusIconWrapper>
              {currentEventHandler.label}
            </NotificationEventStatusWrapper>
          )}
          {currentEventHandler?.dateFormatter && (
            <NotificationDateWrapper>
              {currentEventHandler.dateFormatter(notification)}
            </NotificationDateWrapper>
          )}
        </div>

        {currentEventHandler?.transactionDetails && (
          <NotificationTransactionWrapper>
            {currentEventHandler.transactionDetails(notification, metadataStore)}
          </NotificationTransactionWrapper>
        )}

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {notification.itemCategoryId && (
            <ItemIconWrapper
              backgroundColor={
                metadataStore.classesObj[
                  metadataStore.categoriesObj[notification.itemCategoryId].classId
                ].color
              }
            >
              <AssetClassIcon
                name={
                  metadataStore.classesObj[
                    metadataStore.categoriesObj[notification.itemCategoryId].classId
                  ].icon
                }
              />
            </ItemIconWrapper>
          )}

          {notification.notificationType === 'invitation accepted' && (
            <InvitationAcceptedRoleRow>
              <RolesNameIconWrapper color={emailToColor(notification.dynamicData?.granteeEmail)}>
                {notification.dynamicData.fullName.charAt(0).toUpperCase()}
              </RolesNameIconWrapper>
              <InvitationAcceptedRowNameAndMail>
                <InvitationAcceptedRowNameText>
                  {capitalize(notification.dynamicData.fullName)}
                </InvitationAcceptedRowNameText>
                <InvitationAcceptedRowEmail>
                  {notification.dynamicData.role.charAt(0).toUpperCase() +
                    notification.dynamicData.role.slice(1).toLowerCase()}{' '}
                  · {notification.dynamicData.roleDescription}
                </InvitationAcceptedRowEmail>
              </InvitationAcceptedRowNameAndMail>
            </InvitationAcceptedRoleRow>
          )}

          <NotificationAssetAndEventWrapper>
            {notification.notificationType === 'transaction to specific event' && (
              <NotificationAssetWrapper>{notification.itemTitle}</NotificationAssetWrapper>
            )}
            {notification.notificationType === 'event passed' && (

              <NotificationAssetWrapper style={currentEventHandler.wrapperStyle}>
                {notification.itemTitle}
              </NotificationAssetWrapper>
            )}
            {notification.notificationType === 'magic box ai data readiness' && (
              <>
                <NotificationMagicBoxAiWrapper>
                  {notification.title}
                </NotificationMagicBoxAiWrapper>
                <NotificationMagicBoxAiWrapper weight="450" underline={true}>
                  {notification.subTitle}
                </NotificationMagicBoxAiWrapper>
              </>
            )}
          </NotificationAssetAndEventWrapper>
        </div>
      </NotificationRowSection>

      {userStore?.isActionBlockedByRole('hideNotification') ? null : (
        <CancelSection>
          <Tooltip title="Hide notification" placement="topRight" arrowPointAtCenter={true}>
            <NotificationRowCloseWrapper onClick={(e) => handleCloseOnClick(e)}>
              <CloseXIcon />
            </NotificationRowCloseWrapper>
          </Tooltip>
        </CancelSection>
      )}
    </NotificationRowWrapper>
  );
};