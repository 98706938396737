import { wealthGroupToColor } from "../../../../../../../common/styles/theme.styles";
import { displayMoneyValue, floatFixed, percentageDisplay } from "../../../../../../../common/utils/number-display.utils";
import { liquidityNamesColors } from "../../../../../../add-item/data/common-fields/liquidity-field.const";
import { riskLevelColors } from "../../../../../../add-item/data/common-fields/risk-level-field.const";
import { customClassColorsObj } from "../../../../../../user/components/CustomClassList/icons/custom-colors";

export const getFormattedData = (data, metadataStore, allocateBy, trendType, assetsObj, index) => {
    if (trendType === 'current_cash_position'){
        return breakdownByAccounts(data, metadataStore, trendType, assetsObj, index);
    }

    switch (allocateBy) {
        case 'classes':
            return breakDownByClasses(data, metadataStore, trendType, index);
        case 'categories':
            return breakDownByCategories(data, metadataStore, trendType, index);
        case 'customClasses':
            return breakDownByCustomClasses(data, metadataStore, trendType, index);
        case 'customSubClasses':
            return breakDownBySubCustomClasses(data, metadataStore, trendType, index);
        case 'entities':
            return breakDownByEntities(data, metadataStore, trendType, index);
        case 'risk':
            return breakDownByRisk(data, trendType, index);
        case 'liquidity':
            return breakdownByLiquidity(data, trendType, index);
        case 'institution':
            return breakdownByInstitution(data, metadataStore, trendType, index);
        case 'currencies':
            return breakdownByCurrencies(data, trendType, index);
        case 'accounts':
            return breakdownByAccounts(data, metadataStore, trendType, assetsObj, index);
        case 'companySector':
            return breakdownByCompanySector(data, metadataStore, trendType, index);
        default:
            return breakDownByCategories(data, metadataStore, trendType, index);
    }
}

const breakdownByCurrencies = (data, trendType = 'total_net_worth', index) => {
    const relevantData = data.length === 1 ? data[0] : data[index];
    const ret = {
        date: relevantData.date,
        value: relevantData.value,
    }
    const breakDownWithRelevantData = {};
    for (let key in relevantData.breakdown) {
        const name = key;
        const color = wealthGroupToColor(name);
        breakDownWithRelevantData[name] = {
            value: relevantData.breakdown[key].value,
            color,
            name
        }
    }
    ret.breakdown = breakDownWithRelevantData;
    return ret;
};

const breakdownByAccounts = (data, metadataStore, trendType = 'total_net_worth', assetsObj, index) => {
    const categoriesObj = metadataStore.categoriesObj;
    const classesObj = metadataStore.classesObj;

    const relevantData = data.length === 1 ? data[0] : data[index];
    const ret = {
        date: relevantData.date,
        value: relevantData.value,
    }
    const breakDownWithRelevantData = {};
    for (let key in relevantData.breakdown) {
        const keyDetails = key.split('___');
        const isContainer = keyDetails[0] === 'container##';
        if (isContainer) {
            breakDownWithRelevantData[key] = {
                value: relevantData.breakdown[key].value,
                color: '#FFFFFF',
                name : keyDetails[1]
            }
        } else {
            const itemDetails =  assetsObj[keyDetails[1]];
            if (!itemDetails) {
                continue;
            }
            const classId = categoriesObj[itemDetails.categoryId]?.classId;
            const relevantClass = classesObj[classId];
            const color = relevantClass?.color ?? '#FFFFFF';
            
            breakDownWithRelevantData[key] = {
                value: relevantData.breakdown[key].value,
                color,
                name : keyDetails[2]
            }
        }
    }
    ret.breakdown = breakDownWithRelevantData;
    return ret;

};

const breakDownByClasses = (data, metadataStore, trendType = 'total_net_worth', index) => {
    
    const relevantData = data.length === 1 ? data[0] : data[index];
    const ret = {
        date: relevantData.date,
        value: relevantData.value,
    }
    const breakDownWithRelevantData = {};
    for (let key in relevantData.breakdown) {
        const classId = key;
        const classObj = metadataStore.classesObj[classId];
        const color = classObj?.color ?? '#FFFFFF';
        const name = classObj?.title ?? 'Unspecified';
        breakDownWithRelevantData[classId] = {
            value: relevantData.breakdown[key].value,
            color,
            name
        }
    }
    ret.breakdown = breakDownWithRelevantData;
    return ret;
    
};

const breakDownByCategories = (data, metadataStore, trendType = 'total_net_worth', index) => {
    const categoriesObj = metadataStore.categoriesObj;
    const relevantData = data.length === 1 ? data[0] : data[index];
    const ret = {
        date: relevantData.date,
        value: relevantData.value,
    }
    const breakDownWithRelevantData = {};

    for (let key in relevantData.breakdown) {
        const categoryId = key;
        const classId = categoriesObj[categoryId]?.classId || null;
        const relevantClass = classId ? metadataStore.classesObj[classId] : null;
        const categoryObj = categoriesObj[categoryId];
        const color = relevantClass?.color ?? '#FFFFFF';
        const name = categoryObj?.title || '';
        breakDownWithRelevantData[categoryId] = {
            value: relevantData.breakdown[key].value,
            color,
            name
        }
    }
    ret.breakdown = breakDownWithRelevantData;
    return ret;

};

const breakDownByCustomClasses = (data, metadataStore, trendType = 'total_net_worth', index) => {
    const relevantData = data.length === 1 ? data[0] : data[index];
    const ret = {
        date: relevantData.date,
        value: relevantData.value,
    }
    const breakDownWithRelevantData = {};
    for (let key in relevantData.breakdown) {
        const customClassId = key;
        const customClass = metadataStore.customClassObj[customClassId];
        // console.log('customClass', metadataStore.customSubClassesObj, key, customClass);
        const name = customClass?.name ?? 'Unspecified';
        const color = customClassColorsObj[customClass?.colorCode]?.fallbackColor ?? '#FFFFFF'
        breakDownWithRelevantData[customClassId] = {
            value: relevantData.breakdown[key].value,
            color,
            name
        }
    }
    ret.breakdown = breakDownWithRelevantData;
    return ret;

};

const breakDownBySubCustomClasses = (data, metadataStore, trendType = 'total_net_worth', index) => {
    const relevantData = data.length === 1 ? data[0] : data[index];
    const ret = {
        date: relevantData.date,
        value: relevantData.value,
    }
    const breakDownWithRelevantData = {};
    
    for (let key in relevantData.breakdown) {
        const customSubClassId = key;
        const customSubClass = metadataStore.customSubClassesObj[customSubClassId];
        const customClass = metadataStore.customClasses.find(cls => cls.customSubClasses.find(subCls => subCls.id === customSubClassId));
        const name = customSubClass?.name ?? 'Unspecified';
        const color = customClassColorsObj[customClass?.colorCode]?.fallbackColor ?? '#FFFFFF'
        breakDownWithRelevantData[customSubClassId] = {
            value: relevantData.breakdown[key].value,
            color,
            name
        }
    }
    ret.breakdown = breakDownWithRelevantData;
    return ret;

};

const breakDownByEntities = (data, metadataStore, trendType = 'total_net_worth', index) => {
    const beneficiaries = metadataStore.beneficiaries;
    const relevantData = data.length === 1 ? data[0] : data[index];
    const ret = {
        date: relevantData.date,
        value: relevantData.value,
    }
    const breakDownWithRelevantData = {};
    
    for (let key in relevantData.breakdown) {
        
        const entity = beneficiaries.find(ben => ben.name.trim()?.toLowerCase()?.replace(/\s+/g, '_')?.replace(/[^a-z0-9_]/g, '') === key);
        const color = entity?.colorCode ?? '#FFFFFF';
        const name = entity?.name && entity?.name !== 'unspecified' ? entity?.name : 'Unspecified';

        breakDownWithRelevantData[key] = {
            value: relevantData.breakdown[key].value,
            color,
            name
        }
    }
    ret.breakdown = breakDownWithRelevantData;
    return ret;

};

const breakDownByRisk = (data, trendType = 'total_net_worth', index) => {
    const relevantData = data.length === 1 ? data[0] : data[index];
    const ret = {
        date: relevantData.date,
        value: relevantData.value,
    }
    const breakDownWithRelevantData = {};
    for (let key in relevantData.breakdown) {
        const name = key;
        const color =  riskLevelColors[key] ?? '#FFFFFF';
        breakDownWithRelevantData[name] = {
            value: relevantData.breakdown[key].value,
            color,
            name
        }
    }
    ret.breakdown = breakDownWithRelevantData;
    return ret;
    
};

const breakdownByLiquidity = (data, trendType = 'total_net_worth', index) => {

    const relevantData = data.length === 1 ? data[0] : data[index];
    const ret = {
        date: relevantData.date,
        value: relevantData.value,
    }
    const breakDownWithRelevantData = {};
    for (let key in relevantData.breakdown) {
        const liquidityNameColor = liquidityNamesColors[key];
        const color = liquidityNameColor?.color ?? '#FFFFFF';
        const name = (liquidityNameColor?.name ?? 'Unspecified');
        breakDownWithRelevantData[name] = {
            value: relevantData.breakdown[key].value,
            color,
            name
        }
    }
    ret.breakdown = breakDownWithRelevantData;
    return ret;

};

const breakdownByInstitution = (data, metadataStore, trendType = 'total_net_worth', index) => {
    const institutions = metadataStore.institutions;
    const institutionDictionary = {};
    institutions.forEach((institution, index) => {
        institutionDictionary[institution] = institutionColors[(index % institutionColors.length)];
    });
    institutionDictionary['Unspecified'] = '#FFFFFF';

    const relevantData = data.length === 1 ? data[0] : data[index];
    const ret = {
        date: relevantData.date,
        value: relevantData.value,
    }
    const breakDownWithRelevantData = {};
    for (let key in relevantData.breakdown) {
        
        const color = institutionDictionary[key];
        const name = key;
        breakDownWithRelevantData[name] = {
            value: relevantData.breakdown[key].value,
            color,
            name
        }
    }
    ret.breakdown = breakDownWithRelevantData;
    return ret;
};

const breakdownByCompanySector = (data, metadataStore, trendType = 'total_net_worth', index) => {
    // const companySectors = metadataStore.companySectors;
    const companySectorDictionary = {};

    companySectorDictionary['Unspecified'] = '#FFFFFF';

    const relevantData = data.length === 1 ? data[0] : data[index];
    const ret = {
        date: relevantData.date,
        value: relevantData.value,
    }
    const breakDownWithRelevantData = {};
    for (let key in relevantData.breakdown) {
        
        const name = key;
        const color = name === 'Unspecified' ? '#FFFFFF' : '#B3A1E7';
        breakDownWithRelevantData[name] = {
            value: relevantData.breakdown[key].value,
            color,
            name
        }
    }
    ret.breakdown = breakDownWithRelevantData;
    return ret;

};

const institutionColors = ['#B3A1E7', '#A0ADB8', '#AAC8D4', '#C3B4B6', '#C2918B', '#EAB6D0', '#EB9583', '#E9AE7A', '#F7D595', '#CECAAC', '#B4C1B2', '#78A6B4', '#65AE9F', '#BCAEA4', '#AC9C77', '#D5C972'];

export const tooltipFunc = (context, wealthStore) => {
    const { chart, tooltip } = context;

    if (!tooltip || tooltip.opacity === 0 || !tooltip.dataPoints || !tooltip.dataPoints[0]) {
        setTimeout(() => wealthStore.setTrendsTooltipData(null), 500);
        return;
    }

    const position = chart.canvas.getBoundingClientRect();
    //const tooltipWidth = tooltip.getBoundingClientRect();
    const dataPoint = tooltip.dataPoints[0];
    wealthStore.setTrendsTooltipData({
        left: (position.left + window.scrollX + tooltip.caretX) - (317 * (tooltip.caretX / chart.width)),
        top: (position.top + window.scrollY + tooltip.caretY),
        index: dataPoint.dataIndex
    })
};

export const displayNumberByTrendsType = (value, trendType, currency) => {
    switch (trendType) {
        case 'total_net_worth':
        case 'current_cash_position':
            return displayMoneyValue(value.toFixed(0), currency);
        case 'xirr':
            return percentageDisplay(value, 1);
        default:
            return '';
    }
};

export const getDiffByTrendsType = (firstValue, lastValue, trendType) => {

    switch (trendType) {
        case 'total_net_worth':
        case 'current_cash_position':
            return floatFixed(((lastValue - firstValue) / (firstValue || 1)) * 100);
        case 'xirr':
            return floatFixed((lastValue - firstValue) * 100);
        default:
            return '';
    }
}
