import { Drawer, Tabs } from 'antd';
import { Observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { ArrowDownIcon } from '../../../../../../assets/icons/common/common-icons';
import { allocateByTitles } from '../../../../../allocations/data/allocations.const';
import { useAnalytics } from '../../../../../app/data/AnalyticsProvider';
import { useStore } from '../../../../../app/data/root.store';
import { HeaderTabsWrapper, HeaderTitleTab, MobileTabsSelectorButton, SortHoverIconWrapper, TableHeaderSortIconWrapper } from '../../../../components/wealth.commons.styles';
import { ReactComponent as SortHoverIcon } from '../assets/sort-hover-icon.svg';
import { ReactComponent as SortIcon } from '../assets/sort-icon.svg';
import { AllocateByTitle, AllocatedByMenuItem, AllocatedByMenuItemList, AllocatedByMenuItemText, AllocationDrawerCloseIconWrapper, DrawerHeader, DrawerHeaderTitle, FlexSpacer, SetAsDefaultText } from './ItemsListHeaderTabs.styles';
import { 
AllocationClassesIcon,
AllocationCategoriesIcon,
AllocationEntitiesIcon,
AllocationCurrenciesIcon,
AllocationRiskLevelIcon,
AllocationLiquidityLevelIcon,
AllocationInstSponsorIcon,
AllocationAccountsIcon,
AllocationCustomSubClassIcon,
AllocationCustomClassIcon,
AllocationPropertyTypeIcon,
AllocationCompanySectorIcon,
PinIcon,
 } from '../../../../../allocations/components/AllocateByButtonAndMenu';
import { CustomClassEmptyStateModal } from '../../../../../user/components/CustomClassList/CustomClassEmptyStateModal/CustomClassEmptyStateModal';
import { classesToShowAccountsAllocation } from '../../../../../../common/constants/tickers.const';
import { ROUTES } from '../../../../../../common/constants/routes.const';
import { navigateToUrl } from '../../../../../../common/utils/navigation.utils';
import { colors } from '../../../../../../common/styles/theme.styles';
import { successNotification } from '../../../../../layout/components/Notifications/notifications';

const classesItemsTitle = {
  3: 'Accounts',
  9: 'Accounts'
}

const allocationMenuItems = [
  { text: 'Asset classes', key: 'classes', icon: <AllocationClassesIcon color={'#000'} /> },
  { text: 'Accounts', key: 'accounts',  icon: <AllocationAccountsIcon color={'#000'} />   },
  { text: 'Custom classes', key: 'customClasses', icon: <AllocationCustomClassIcon color={'#000'} /> },
  { text: 'Custom sub classes', key: 'customSubClasses', icon: <AllocationCustomSubClassIcon color={'#000'} /> },
  { text: 'Categories', key: 'categories'   , icon: <AllocationCategoriesIcon color={'#000'} />  },
  { text: 'Entities', key: 'entities'   , icon: <AllocationEntitiesIcon color={'#000'} />  },
  { text: 'Currencies', key: 'currencies'   , icon: <AllocationCurrenciesIcon color={'#000'} />  },
  { text: 'Risk level', key: 'risk' , icon: <AllocationRiskLevelIcon color={'#000'} />  },
  { text: 'Liquidity level', key: 'liquidity'   , icon: <AllocationLiquidityLevelIcon color={'#000'} />  },
  { text: 'Institution / Sponsor', key: 'institution'  , icon: <AllocationInstSponsorIcon color={'#000'} />   },
  { text: 'Property type', key: 'propertyType'  , icon: <AllocationPropertyTypeIcon color={'#000'} />   },
  { text: 'Company sector', key: 'companySector'  , icon: <AllocationCompanySectorIcon color={'#000'} />   },
];

export function ItemsListHeaderTabs(props) {
    const { wealthStore , filtersStore , dataLayersStore, tickersStore, uiStore, metadataStore, userStore } = useStore();
    const { TabPane } = Tabs;
    let history = useHistory();
    const { mixpanelTrack } = useAnalytics();
    const [showAllocationDrawer, setShowAllocationDrawer] = useState(false);
    const [showCustomClassEmptyStateModal, setShowCustomClassEmptyStateModal] = useState(false);

    const handleTabClick = (viewMode) => {
      wealthStore.setIsBulkEditMode(false,true);
      wealthStore.setListViewMode(viewMode);
      dataLayersStore.setSelectedListViewMode(viewMode, wealthStore.allocateBy);
      const pageLocation = wealthStore.isMainResults ? 'My Portfolio main page' : 'My Portfolio filters' + (filtersStore.singleClassFilter ? ' - single class '  + filtersStore.singleClassFilter.title: '')
      mixpanelTrack('Tab Clicked', {
        destination : viewMode,
        location: pageLocation,
        tab_text: viewMode,
        type: 'tab',
        url: window.location.href
      })
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('lvm', viewMode);
      navigateToUrl(history, `${ROUTES.wealthOverview.url}?${searchParams.toString()}`, false);
      
    }

    const handleSortByAssetNameHeaderTabClick = (viewMode) => {
      if (wealthStore.listViewMode === viewMode){
        const titleDl = {dataKey: 'title',type:'text'};
        if (dataLayersStore.sortBy === 'title') {
          dataLayersStore.setSortDirection( dataLayersStore.sortByColumn.direction === 'asc' ? 'desc' : 'asc' ); 
        } else {
          dataLayersStore.setSortBy('title');
          dataLayersStore.setSortByColumn({...titleDl, direction:'asc'});
        }
      }
    }

    const handleAllocationChange = (key, e) => {
      const isCustomClassEmptyState = metadataStore.customClasses?.length === 0 && ['customClasses','customSubClasses'].includes(key);
      if (isCustomClassEmptyState) {
        setShowCustomClassEmptyStateModal(true);
      } else {
        const searchParams = new URLSearchParams(window.location.search);
        
        if (key === 'items') {
          wealthStore.setListViewMode('items');
          dataLayersStore.setSelectedListViewMode('items', '');
        } else {
          // Default handling for other keys
          wealthStore.setAllocateBy(key);
          wealthStore.setListViewMode('allocations');
          dataLayersStore.setSelectedListViewMode('allocations', key);
          searchParams.set('allocation', key);
        }
        searchParams.set('lvm', key === 'items' ? 'items' : 'allocations');
        navigateToUrl(history, `${ROUTES.wealthOverview.url}?${searchParams.toString()}`, false);
        setShowAllocationDrawer(false);
      }
      mixpanelTrack('Allocation Selection', { 
        selected_attribute: e.target.textContent,
        url: window.location.href
      });
    }

    const handleDefaultAllocationChange = async (key, e) => {
      const updatedKey = userStore.isViewingOwnPortfolio ? 'me' : userStore.data?.profile?.id;
      const res = await userStore.updateGranteeSettings('mainPageAllocation', { [updatedKey]: key });
      if (res && !res.error) {
        wealthStore.setUserDefaultAllocation(key);
        successNotification('Default allocation set successfully');
        mixpanelTrack('Default Allocation Selection', { 
          selected_default_attribute: key,
          url: window.location.href
        });
      }
    }
    
    return (<Observer>{() => (
            <HeaderTabsWrapper>

                {uiStore.isDesktopView ?

                <Tabs activeKey={wealthStore.listViewMode} onChange={handleTabClick}>
                   <TabPane
                    tab={
                      <HeaderTitleTab isSelected={wealthStore.listViewMode === 'allocations'} 
                      onClick={()=>handleSortByAssetNameHeaderTabClick('allocations')}
                      >{allocateByTitles[wealthStore.allocateBy]} ({wealthStore.allocateBy === 'classes' ? 11 : wealthStore.allocateBy === 'accounts' ? wealthStore.accountsHeaderTabCount : wealthStore.wealthOverviewList.length})
                      
                      {dataLayersStore.sortBy === 'title' && wealthStore.listViewMode === 'allocations' && !['classes','categories'].includes(wealthStore.allocateBy) ? <TableHeaderSortIconWrapper isAsc={dataLayersStore.sortByColumn.direction === 'asc'}>
                      <SortIcon />
                    </TableHeaderSortIconWrapper> : null}
                    { dataLayersStore.sortBy !== 'title' && wealthStore.listViewMode === 'allocations' &&  <SortHoverIconWrapper><SortHoverIcon /></SortHoverIconWrapper> }
                      
                      </HeaderTitleTab>
                    }
                    key='allocations'
                  />

                    {wealthStore.isSingleClassFilterResults && filtersStore.isOnlyOneClassFilter && classesToShowAccountsAllocation.includes(wealthStore.filterResultSingleClassId) ? null :
                  <TabPane tab={
                    <HeaderTitleTab isSelected={wealthStore.listViewMode === 'items'} 
                    // onClick={() => handleTabClick('items')}
                    onClick={()=>handleSortByAssetNameHeaderTabClick('items')}
                    >
                      Items ({wealthStore.itemsHeaderTabCount})

                      {dataLayersStore.sortBy === 'title' && wealthStore.listViewMode === 'items' ? <TableHeaderSortIconWrapper isAsc={dataLayersStore.sortByColumn.direction === 'asc'}>
                      <SortIcon />
                    </TableHeaderSortIconWrapper> : null}
                    { dataLayersStore.sortBy !== 'title' && wealthStore.listViewMode === 'items' && <SortHoverIconWrapper><SortHoverIcon /></SortHoverIconWrapper> }

                    </HeaderTitleTab> 
                  } key='items' />
                }

                  {wealthStore.isSingleClassFilterResults && filtersStore.isOnlyOneClassFilter && wealthStore.filterResultSingleClassId === 9 && <TabPane tab={
                    <HeaderTitleTab isSelected={wealthStore.listViewMode === 'crypto-holdings'} 
                    onClick={()=>handleSortByAssetNameHeaderTabClick('crypto-holdings')}
                    >Holdings {wealthStore.cryptoHoldingsHeaderTabCount ? `(${wealthStore.cryptoHoldingsHeaderTabCount})` : null}
                    
                    {dataLayersStore.sortBy === 'title' && wealthStore.listViewMode === 'crypto-holdings' ? <TableHeaderSortIconWrapper isAsc={dataLayersStore.sortByColumn.direction === 'asc'}>
                      <SortIcon />
                    </TableHeaderSortIconWrapper> : null}
                    { dataLayersStore.sortBy !== 'title' && wealthStore.listViewMode === 'crypto-holdings' && <SortHoverIconWrapper><SortHoverIcon /></SortHoverIconWrapper> }
                    
                    </HeaderTitleTab>
                  } key='crypto-holdings' />}

                  {wealthStore.isSingleClassFilterResults && filtersStore.isOnlyOneClassFilter && [2,3].includes(wealthStore.filterResultSingleClassId) && <TabPane tab={
                    <HeaderTitleTab isSelected={wealthStore.listViewMode === 'holdings'} 
                      onClick={()=>handleSortByAssetNameHeaderTabClick('holdings')}
                    >Holdings {tickersStore.tickers.length && tickersStore.currentClassId === wealthStore.filterResultSingleClassId ? `(${wealthStore.holdingsHeaderTabCount})` : null}
                    
                    {dataLayersStore.sortBy === 'title' && wealthStore.listViewMode === 'holdings' ? <TableHeaderSortIconWrapper isAsc={dataLayersStore.sortByColumn.direction === 'asc'}>
                      <SortIcon />
                    </TableHeaderSortIconWrapper> : null}
                    { dataLayersStore.sortBy !== 'title' && wealthStore.listViewMode === 'holdings' && <SortHoverIconWrapper><SortHoverIcon /></SortHoverIconWrapper> }

                    </HeaderTitleTab>
                  } key='holdings' />}

                  {/* { uiStore.isDesktopView && wealthStore.archivedItemsListLength > 0 && <TabPane tab={
                    <HeaderTitleTab isSelected={wealthStore.listViewMode === 'archived'} 
                    // onClick={() => handleTabClick('archived')}
                    onClick={()=>handleSortByAssetNameHeaderTabClick('archived')}
                    >
                      Archived ({wealthStore.archivedItemsListLength})


                      {dataLayersStore.sortBy === 'title' && wealthStore.listViewMode === 'archived' ? <TableHeaderSortIconWrapper isAsc={dataLayersStore.sortByColumn.direction === 'asc'}>
                      <SortIcon />
                    </TableHeaderSortIconWrapper> : null}
                    { dataLayersStore.sortBy !== 'title' && wealthStore.listViewMode === 'archived' && <SortHoverIconWrapper><SortHoverIcon /></SortHoverIconWrapper> }


                    </HeaderTitleTab>} key='archived' 
                     />} */}
                </Tabs>

                  :

          <>
            

            <MobileTabsSelectorButton onClick={() => {setShowAllocationDrawer(true)}}>
                {wealthStore.listViewMode === 'allocations' && <>{allocateByTitles[wealthStore.allocateBy]} ({wealthStore.allocateBy === 'classes' ? 11 : wealthStore.wealthOverviewList.length})</>}
                {wealthStore.listViewMode === 'items' && <>{wealthStore.isSingleClassFilterResults && filtersStore.isOnlyOneClassFilter && classesItemsTitle.hasOwnProperty(wealthStore.filterResultSingleClassId) ? classesItemsTitle[wealthStore.filterResultSingleClassId] : 'Items'} ({wealthStore.itemsListLength})</>}
                {wealthStore.listViewMode === 'holdings' && <>Holdings {tickersStore.tickers.length ? `(${tickersStore.tickers.length})` : null}</>}
                 <ArrowDownIcon />
            </MobileTabsSelectorButton>

            <Drawer
              closable={false}
              visible={showAllocationDrawer}
              width={340}
              height={'auto'}
              className={'allocationDrawerContainer allocationDrawerContainer_mobile'}
              placement={'bottom'}
              onClose={() => {setShowAllocationDrawer(false)}}
             >
              <DrawerHeader>
                <DrawerHeaderTitle>Select view</DrawerHeaderTitle>
                <AllocationDrawerCloseIconWrapper onClick={() => {setShowAllocationDrawer(false)}}><AllocationDrawerCloseIcon /></AllocationDrawerCloseIconWrapper>
              </DrawerHeader>

              <AllocatedByMenuItem 
                onClick={(e) =>{handleAllocationChange('items', e)}}
                isSelected={wealthStore.listViewMode === 'items'} style={{margin:'0px 12px'}}>
                <AllocationItemsIcon />
                <AllocatedByMenuItemText>Items</AllocatedByMenuItemText>
                <FlexSpacer />
                {wealthStore.listViewMode === 'items' && <SelectedAllocationIcon />}
              </AllocatedByMenuItem>
              <AllocateByTitle>Allocate by:</AllocateByTitle>
              <AllocatedByMenuItemList>
                {allocationMenuItems.map((item) => (
                  (!wealthStore.isMainResults && item.key === 'classes') || 
                  (!(wealthStore.isSingleClassFilterResults && filtersStore.isOnlyOneClassFilter && [9,3].includes( wealthStore.filterResultSingleClassId)) && item.key === 'accounts') ||
                  (!(wealthStore.isSingleClassFilterResults && filtersStore.isOnlyOneClassFilter && wealthStore.filterResultSingleClassId === 4) && item.key === 'propertyType' ) ||
                  (!(wealthStore.isSingleClassFilterResults && filtersStore.isOnlyOneClassFilter && [3,6].includes( wealthStore.filterResultSingleClassId)) && item.key === 'companySector') ? null :
                  <AllocatedByMenuItem key={item.key} 
                    onClick={(e) => handleAllocationChange(item.key, e)}
                    isSelected={wealthStore.listViewMode === 'allocations' && item.key === wealthStore.allocateBy}>
                    {item.icon}
                    <AllocatedByMenuItemText >
                      {item.text}
                    </AllocatedByMenuItemText>
                    {wealthStore.isMainResults && wealthStore.userDefaultAllocation === item.key && <PinIcon color={colors.darkGray1} />}
                    <FlexSpacer />
                    {wealthStore.isMainResults && wealthStore.userDefaultAllocation !== item.key && wealthStore.listViewMode === 'allocations' && item.key === wealthStore.allocateBy && <SetAsDefaultText onClick={(e) => {handleDefaultAllocationChange(item.key, e)}}>Set as default</SetAsDefaultText>}
                    {wealthStore.listViewMode === 'allocations' && item.key === wealthStore.allocateBy && <SelectedAllocationIcon />}
                  </AllocatedByMenuItem>
                ))}
              </AllocatedByMenuItemList>

          </Drawer>
          {showCustomClassEmptyStateModal && <CustomClassEmptyStateModal onClose={() => {setShowCustomClassEmptyStateModal(false)}} onCtaClick={() => {setShowAllocationDrawer(false);}} />}
            </>

                         

                        }    
            </HeaderTabsWrapper>
 
    )}</Observer>)
}

const AllocationItemsIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 6L16 6" stroke="#1A1B1D" strokeWidth="1.5"/>
      <path d="M4 11L16 11" stroke="#1A1B1D" strokeWidth="1.5"/>
      <path d="M4 16L16 16" stroke="#1A1B1D" strokeWidth="1.5"/>
    </svg>
  )
}

const AllocationDrawerCloseIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8" y="8" width="28" height="28" rx="14" fill="#E8E9ED"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M26.4194 27.7301L16.5199 17.8306L17.5806 16.7699L27.4801 26.6694L26.4194 27.7301Z" fill="#585D66"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M27.4802 17.8306L17.5807 27.7301L16.52 26.6694L26.4195 16.7699L27.4802 17.8306Z" fill="#585D66"/>
    </svg>
  )
}

const SelectedAllocationIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5103 8.60401L10.5059 15.6125L6.99983 12.1076" stroke="black" strokeWidth="2"/>
    </svg>
  )
}