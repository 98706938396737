// import { translations } from "../../../../../common/translations/translations.en"

// const { common } = translations;

const roiDescription = 'Return on Investment (ROI) represents the percentage return based on values and distributions relative to total contributions.'

export const cashAccountsOverviewData = {
    stats : [],
}

export const realEstateOverviewData = {
    stats : [
        {title:'ROI', dataKey: 'roi',type: 'perc', description: roiDescription},
        {title:'CoC', dataKey: 'coc_capital', type:'perc', description:'Cash on Cash Return measures the income your invested capital generates as a percentage of your total cash investment. It reflects the return on capital and excludes the return of capital (based on the total contributions made).'},
        {title:'Total contributions', dataKey: 'totalContributions' , multipleValues: true },
        {title:'Total distributions', dataKey: 'totalDistributions' , multipleValues: true},
    ],
}

export const privateCompaniesOverviewData = {
    stats : [
        {title:'Total cash invested', dataKey: 'totalContributions', multipleValues: true},
        {title:'Total cash received', dataKey: 'totalDistributions' , multipleValues: true},
        {},
        {},
    ],
}

export const privateLoansOverviewData = {
    stats : [
        {title: 'Interest rate', dataKey: 'apr', type : 'perc',  
        description: `This is the average interest rate represents the total return you can expect to receive from the loans you've invested in.`},
        // {title: 'IRR', dataKey: 'xirr' , type : 'perc'  },
        {title:'ROI', dataKey: 'roi',type: 'perc', description: roiDescription},
        {title:'Inflow', dataKey: 'totalDistributions', multipleValues: true },
        {title:'Outflow', dataKey: 'totalContributions', multipleValues: true},
    ],
}

export const brokerageAccountsOverviewData = {
    stats : [
        {title:'ROI', dataKey: 'roi',type: 'perc',  description: roiDescription},
        {title:'Total contributions', dataKey: 'totalContributions' , multipleValues: true },
        {title:'Total distributions', dataKey: 'totalDistributions' , multipleValues: true},
        {},
    ],
}

export const longTermSavingsOverviewData = {
    stats : [
        {title:'ROI', dataKey: 'roi',type: 'perc', description: roiDescription},
        {title:'Total contributions', dataKey: 'totalContributions' , multipleValues: true },
        {title:'Total distributions', dataKey: 'totalDistributions' , multipleValues: true},
        {},
    ],
}

export const cryptoOverviewData = {
    stats : [
        {title:'ROI', dataKey: 'roi',type: 'perc', description: roiDescription},
        {title:'Total contributions', dataKey: 'totalContributions' , multipleValues: true },
        {title:'Total distributions', dataKey: 'totalDistributions' , multipleValues: true},
        {},
    ],
}

export const otherOverviewData = {
    stats : [
        {title:'Total contributions', dataKey: 'totalContributions' , multipleValues: true },
        {title:'Total distributions', dataKey: 'totalDistributions' , multipleValues: true},
        {},
        {},
    ],
}

export const managedFundsOverviewData = {
    stats : [
        {title:'ROI', dataKey: 'roi',type: 'perc', description: roiDescription},
        // {title: 'IRR', dataKey: 'xirr' , type : 'perc', description: common.item_overview.IRR_DESCRIPTION  },
        {title: 'DPI', dataKey: 'dpi', description: 'Distribution to Paid-In Capital (DPI) measures the return of capital distributions to investors as a percentage of their invested capital.', type: 'perc' },
        {title:'Total contributions', dataKey: 'totalContributions' , multipleValues: true },
        {title:'Total distributions', dataKey: 'totalDistributions' , multipleValues: true},
    ],
}

export const loansOverviewData = {
    stats : [
        {title: 'APR', dataKey: 'apr' , type : 'perc', description: 'This is the yearly interest rate you’ll pay for the total cost of the loan (including fees and other charges)', specificDecimalScale: 3, },
        {title: 'LTV Ratio', dataKey: 'ltv', type : 'perc',  description: `The loan-to-value ratio is calculated by dividing the loan amount by the linked asset's value. 
        Ensure your liabilities are linked to assets in order to reflect them in this metric.`},
        {title:'Paid amount', dataKey: 'totalContributions', multipleValues: true},
        {title:'Total borrowed amount', dataKey: 'amount_borrowed'},
    ],
}

export const creditCardsOverviewData = {
    stats : [],
}

export const ASSET_CLASSES_OVERVIEW_DATA = {
    1: cashAccountsOverviewData,
    2: longTermSavingsOverviewData,
    3: brokerageAccountsOverviewData,
    4: realEstateOverviewData,
    5: privateLoansOverviewData,
    6: privateCompaniesOverviewData,
    7: otherOverviewData,
    8: managedFundsOverviewData,
    9: cryptoOverviewData,
    10: loansOverviewData,
    11: creditCardsOverviewData
}


const lifeInsuranceSingleCategoryOverviewData = {
    stats : [
        {title:'Death benefits', dataKey: 'death_benefit' },
        {title:'Performance', dataKey: 'roi',type: 'perc',},
        {title:'Inflow', dataKey: 'totalDistributions', multipleValues: true },
        {title:'Outflow', dataKey: 'totalContributions', multipleValues: true },
    ],
}

export const ASSET_SINGLE_CATEGORY_OVERVIEW_DATA = {
    40: lifeInsuranceSingleCategoryOverviewData,
}

export const MIXED_RESULT_HERO_DATA = {
    stats : [
        {title:'Total contributions', dataKey: 'totalContributions' , multipleValues: true },
        {title:'Total distributions', dataKey: 'totalDistributions', multipleValues: true  },
        {},
        {},
    ],
}
