import styled from "@emotion/styled/macro";
import { BaseModal } from "../../../../common/components/BaseModal/BaseModal";
import { DeepAnalysisSuccessModalFooter } from "../DeepAnalysisSuccessModal/DeepAnalysisSuccessModal.styles";
import { MagicboxAIModalHeader } from "../MagicboxAIModalHeader";
import { ReactComponent as EraseIcon } from './assets/erase-icon.svg';
import { PrimaryDeleteButton, SecondaryButton } from "../../../../common/components/buttons/NewButtons.styles";
import { useState } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { useStore } from "../../../app/data/root.store";
import { useTrackEvent } from "../../../../hooks/useTrackEvent/useTrackEvent";
import { useAnalytics } from "../../../app/data/AnalyticsProvider";

export const DeepAnalysisReviewDeleteModal = (props) => {
    const { userStore } = useStore();
    const { onClose, onDelete } = props;
    const [isDeleting, setIsDeleting] = useState(false);
    const { trackEvent } = useAnalytics();

    const onDeleteFile = async () => {
        trackEvent('CTA Clicked', {
            cta_text: 'Cancel and Delete Upload',
            location: 'Magic box ai deep analysis results',
            destination: 'delete upload',
            type: "Button",
            url: window.location.href
        });
        setIsDeleting(true);
        await onDelete();
        setIsDeleting(false);
        onClose();

    };

    const onContinue = () => {
        trackEvent('CTA Clicked', {
            cta_text: 'Continue with Analysis',
            location: 'Magic box ai deep analysis results',
            destination: 'continue analysis',
            type: "Button",
            url: window.location.href
        });
        onClose();
    }

    useTrackEvent('Popup Viewed', {
        title: 'Cancel Document Upload.',
        url: window.location.href
    })

    return (
        <BaseModal borderRadius={8} width={'520px'} height={'auto'} noSidebarMargin={userStore.isWelcome}>
            <MagicboxAIModalHeader onClose={onClose} />
            <DeepAnalysisDeleteModalBodyContent>
                <DeepAnalysisDeleteModalBody>
                    <EraseIcon />
                    <DeepAnalysisDeleteModalMainText>Are you sure you want to cancel the document analysis?</DeepAnalysisDeleteModalMainText>
                    <DeepAnalysisDeleteModalSecondaryText>If you cancel now, the uploaded file and all its data will be deleted, and no assets will be created in your Vyzer portfolio.</DeepAnalysisDeleteModalSecondaryText>
                </DeepAnalysisDeleteModalBody>
            </DeepAnalysisDeleteModalBodyContent>
            <DeepAnalysisSuccessModalFooter>
                <SecondaryButton onClick={onContinue}>Continue with Analysis</SecondaryButton>
                <PrimaryDeleteButton onClick={onDeleteFile} style={{ minWidth: '250px' }}>
                    {isDeleting ? <LoadingOutlined /> :
                        <>
                            <EraseIconWhite />
                            Cancel and Delete Upload
                        </>}
                </PrimaryDeleteButton>
            </DeepAnalysisSuccessModalFooter>
        </BaseModal>
    );
};

const EraseIconWhite = styled(EraseIcon)`
    path {
        stroke: white;
    }
`;

const DeepAnalysisDeleteModalBodyContent = styled.div`
    padding: 7px 0px 62px 0px;
    border-bottom: 1px solid var(--Gray-3, #DDE3EB);
`;

const DeepAnalysisDeleteModalBody = styled.div`
    display: flex;
    width: 520px;
    padding: 8px 40px 0px 40px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`;

const DeepAnalysisDeleteModalMainText = styled.div`
    color: var(--Dark-Gray-1, #1A1B1D);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const DeepAnalysisDeleteModalSecondaryText = styled.div`
    color: var(--Dark-Gray-2, #585D66);
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;