import axios from 'axios';
import { makeAutoObservable } from "mobx";
import { DEFAULT_ITEMS, SEARCH_OPTIONS } from "./Assistance.consts";
import { deactivateConversation, getLastConversation, sendMessage } from "./assistance-service";
export class AssistanceStore {

    history = [];
    isThinking = false;
    searchResults = [DEFAULT_ITEMS[0], DEFAULT_ITEMS[1], DEFAULT_ITEMS[2]];
    conversationId = null;
    isTyping = false;
    version = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchHistory() {
        try {
            const lastConversation = await getLastConversation();
            if (lastConversation) {
                this.parseConversation(lastConversation);
            }
        } catch (error) {
            console.error(error);
        }
    }

    async sendMessage(message) {
        const trimmedMessage = message.trim();
        this.addQuestion(trimmedMessage);
        this.setSearchResults([]);
        try {
            this.setIsThinking(true);
            const answer = await sendMessage(trimmedMessage, this.conversationId, this.version);
            this.addAnswer(answer);
        } catch (error) {
            const answer = {
                responseType: 'Error',
                conversationId: this.conversationId
            };
            this.addAnswer(answer);
        }
        finally {
            this.setIsThinking(false);
        }
    }

    addQuestion(message) {
        this.history.push({ q: message });
    }

    addAnswer(answer) {
        this.setConversationId(answer.conversationId);
        this.history[(this.history.length - 1)].a = answer;
        this.setIsThinking(false);
        this.setSearchResults([DEFAULT_ITEMS[0], DEFAULT_ITEMS[1], DEFAULT_ITEMS[2]]);
    }

    search(question) {
        const words = question.split(' ');
        const results = SEARCH_OPTIONS.filter((question) => (words.every((word) => question.toLowerCase().includes(word.trim().toLowerCase())))).slice(0, 3);
        this.setSearchResults(results);
    }

    async newChat() {
        if (!this.conversationId) {
            return;
        }

        try {
            await deactivateConversation(this.conversationId);
        } catch (error) {
            console.error(error);
        }

        this.history.splice(0);
        this.setConversationId(null);
    }

    parseConversation(conversation) {
        if (!conversation.messages || conversation.messages.length === 0) {
            return;
        }

        const { id, messages } = conversation;

        const history = [];
        for (let i = 0; i < messages.length; i += 2) {
            history.push({ q: messages[i].content });
            if (messages[i + 1]) {
                const answer = messages[i + 1];
                const content = answer.content;
                const responseType = answer.metadata.responseType;
                const feedback = answer.metadata.feedback;

                history[(history.length - 1)].a = { content, responseType, feedback };
            }
        }

        this.setHistory(history);
        this.setConversationId(id);
    }

    setHistory(history) {
        this.history = history;
    }

    setIsThinking(value) {
        this.isThinking = value;
    }

    setSearchResults(results) {
        this.searchResults = results;
    }

    setConversationId(id) {
        this.conversationId = id;
    }

    setIsTyping(value) {
        this.isTyping = value;
        if (!value) {
            delete this.history[(this.history.length - 1)].a?.isNew;
        }
    }

    setHistoryFeedback(history) {
        this.history = [...history];
    }

    async sendFeedback(messageIndex, feedbackType) {
        try {

            // Update the feedback in the history
            if (this.history[messageIndex] && this.history[messageIndex].a) {
                if (!this.history[messageIndex].a.feedback) {
                    this.history[messageIndex].a.feedback = feedbackType;
                }
                this.history[messageIndex].a.feedback = feedbackType;
                // Call API to save feedback
                // You will need to implement this service function
                await this.saveFeedbackToServer(messageIndex, feedbackType);
                this.setHistoryFeedback([...this.history]);
            }
        } catch (error) {
            console.error('Error sending feedback:', error);
        }
    }

    async saveFeedbackToServer(messageIndex, feedbackType) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}ask-my-data/feedback`, {
                conversationId: this.conversationId,
                messageIndex,
                feedbackType
            }, { withCredentials: true });

            if (!response.data) {
                throw new Error('Failed to save feedback');
            }

            return response.data;
        } catch (error) {
            console.error('Error saving feedback to server:', error);
            throw error;
        }
    }

    async sendFeedbackReason(messageIndex, feedbackType, reason) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}ask-my-data/feedback/reason`, {
                conversationId: this.conversationId,
                messageIndex,
                feedbackType,
                reason
            }, { withCredentials: true });

            if (!response.data) {
                throw new Error('Failed to save feedback reason');
            }

            return response.data;
        } catch (error) {
            console.error('Error saving feedback reason to server:', error);
            throw error;
        }
    }

    setVersion(userId) {
        if (process.env.REACT_APP_ENV_KEY === 'prod') {
            const group1 = [170, 702, 1300, 2340, 11746, 12206, 12441, 13158, 16458, 22299, 246, 26, 7, 141] //Shimri
            const group2 = [203, 488, 749, 1005, 1588, 3672, 11585, 12359, 16103, 17319, 18461, 8, 30, 25, 11715] //Eliav

            if (group1.includes(userId) || group2.includes(userId)) {
                this.version = 'v2'; //Shimri
            }

        } else {
            this.version = 'v2';
        }
    }

    get showAssistance() {
        return (!!this.version);
    }
}