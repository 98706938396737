/**
 * Calculator Monthly payment by Spitzer
 * @param loan positive number
 * @param interest number between 0 to 1
 * @param months the loan's duration
 * @returns monthly payment (number)
 */
export const calcMonthlyPayment = (loanAmount, interest, months) => {
    let R = interest / 12
    let Mi = loanAmount * (interest === 0 ? 1 : R)
    let Rp = Math.pow((1 + R), -months)
    let denominator = 1 - Rp
    return Mi / (interest === 0 ? months : denominator)
}


/**
 * Calculator months left for complete the loan by Spitzer
 * @param loan  positive number
 * @param interest number between 0 to 1
 * @param monthlyPayment the monthly payment value
 * @returns the loan's duration in months (number)
 */
export const calcMonthLeft = (remainingLoanAmount, interest, monthlyPayment) => {
    if (12 * monthlyPayment - remainingLoanAmount * interest <= 0) {
        return null
    }
    let b = (12 * monthlyPayment) / (12 * monthlyPayment - remainingLoanAmount * interest)
    let a = (12 + interest) / 12
    return Math.log(b) / Math.log(a)
}

export const calcMonthsLeftByPaymentPeriod = ({
    remainingLoanAmount: P,
    interest,
    monthlyPayment: mp,
    expectedDistributionPeriod,
    expectedDistributionCustomPeriod,
    expectedDistributionCustomInterval
}) => {
    let r = getIntersetByPeriod({
        interest,
        expectedDistributionPeriod,
        expectedDistributionCustomPeriod,
        expectedDistributionCustomInterval

    });
    const numOfPayments = (Math.log(mp / (mp - r * P)) / Math.log(1 + r));
    const monthsInPeriod = getMonthsInPeriod({
        expectedDistributionPeriod,
        expectedDistributionCustomPeriod,
        expectedDistributionCustomInterval

    });

    return Math.ceil(numOfPayments) * monthsInPeriod;
}

const getIntersetByPeriod = ({ interest, expectedDistributionPeriod, expectedDistributionCustomPeriod, expectedDistributionCustomInterval }) => {
    switch (expectedDistributionPeriod) {
        case 'Monthly':
            return (interest / 12);
        case 'Quarterly':
            return (interest / 4);
        case 'Semi-annually':
            return (interest / 2);
        case 'Annually':
            return interest;
        case 'custom':
            switch (expectedDistributionCustomPeriod) {
                case 'Months':
                    return ((interest * expectedDistributionCustomInterval) / 12);
                case 'Years':
                    return (interest * expectedDistributionCustomInterval);
                default:
                    throw new Error('Invalid expectedDistributionCustomPeriod');
            }
        default:
            return interest;
    }
};

const getMonthsInPeriod = ({ expectedDistributionPeriod, expectedDistributionCustomPeriod, expectedDistributionCustomInterval }) => {
    switch (expectedDistributionPeriod) {
        case 'Monthly':
            return 1;
        case 'Quarterly':
            return 3;
        case 'Semi-annually':
            return 6;
        case 'Annually':
            return 12;
        case 'custom':
            switch (expectedDistributionCustomPeriod) {
                case 'Months':
                    return expectedDistributionCustomInterval;
                case 'Years':
                    return (12 * expectedDistributionCustomInterval);
                default:
                    throw new Error('Invalid expectedDistributionCustomPeriod');
            }
        default:
            throw new Error('Invalid expectedDistributionPeriod');
    }
}