import { LoadingOutlined } from '@ant-design/icons'
import { Observer } from 'mobx-react'
import { useState } from 'react'
// import { PrimaryButton, SecondaryButton } from '../../../../common/components/buttons/NewButtons.styles'
import { useStore } from '../../../app/data/root.store'
import { RequestVipSyncHeaderIcon, RequestVipSyncHeaderSubTitle, RequestVipSyncHeaderTitle, RequestVipSyncHeaderWrapper, RequestVipSyncStepContent, RequestVipSyncStepFooter, RequestVipSyncStepWrapper } from '../AddItem.styles'

import { PrimaryButton, SecondaryButton } from '../../../../common/components/buttons/NewButtons.styles'
import { useAnalytics } from '../../../app/data/AnalyticsProvider'
import { FormWithStore } from '../../../form-displayer-connector/components/FormWithStore'
import { useFormHook } from '../../../form-displayer-connector/data/useFormHook'
import { errorNotification } from '../../../layout/components/Notifications/notifications'
import { requestVipSync } from '../../data/add-item.service'
import { isRequired, isValidEmailOrEmpty } from '../../data/input-validations'



export const RequestAddVip = ({ isActivationFlow, item, stepIndex }) => {
  const {  createItemStore,  uiStore } = useStore();


  const { trackEvent } = useAnalytics();
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);


  const formName = 'requestVipSyncForm';  

  const requestVipSyncForm = useFormHook(formName);

  const formSections = ({updateValue,updateField}) => {
    return [
      {
        rows: [
          {
            fields: [
              {
                key: 'name',
                label: 'Institution name',
                validations:[isRequired],
                type:'text',
              }
            ]
          },
          {
            fields: [
              {
                key:'website', 
                type:'text',
                label: 'Institution website',
                placeholder:'https://'
              }
            ]
          },
          {
            fields: [
              {
                key:'contactName', 
                type:'text',
                label: 'Institution contact person',
                description: `Provide the name and email of a representative from your institution who can grant Read-Only mode access for Vyzer. This could be someone from investor relations, administration, or operations.`,
                placeholder:'Name',
                customClassName:'contact-person-name-input'
              }
            ]
          },  
          {
            fields: [
              {
                key:'contactEmail', 
                type:'text',
                // label: 'Institution website',
                validations:[isValidEmailOrEmpty],
                placeholder:'Email',
                fieldCustomStyle: {marginTop: '-28px'},
                customClassName:'contact-person-email-input'
              }
            ]
          },  
        ]
      },
    ]
  }
  // const handleBackClick = () => {
  //   if (stepIndex === 0) {
  //     createItemStore.setInstitutionStep(createItemStore.syncMode);
  //   }
  //   const updatedIndex = stepIndex > 0 ? stepIndex - 1 : stepIndex;
  //   createItemStore.setVipSyncStepIndex(updatedIndex);
  // }

  const handleNextClick = async () => {
    const sectionValidations = requestVipSyncForm.currentSectionErrors;
    // if (!sectionValidations.isValid) return amortizationForm.setErrors(sectionValidations.errors);
    if (!sectionValidations.isValid){
      requestVipSyncForm.setErrors(sectionValidations.errors);
      requestVipSyncForm.setBottomErrors(sectionValidations.bottomErrors);
      return false;
    } 
    
    trackEvent('CTA Clicked', {
      cta_text: 'Send request',
      location: 'Request VIP Syncing Service',
      type: 'Button',
      destination: 'Success message',
    })

    setIsSendingRequest(true);
    try {
      await requestVipSync(requestVipSyncForm.values);
      setIsRequestSent(true);
      // }
    } catch (e) {
      errorNotification('Something went wrong')
    } finally {
      setIsSendingRequest(false);
    }

    // const isDisabled = stepIndex === 1 && !isConsentApproved;
    // if (isDisabled) return;
    // trackEvent('CTA Clicked', {
    //   cta_text: vipMixpanelByIndex[stepIndex].cta_text,
    //   destination: vipMixpanelByIndex[stepIndex].destination,
    //   location: 'VIP Sync Service',
    //   type: 'Button',
    //   institution: item.name,
    // })

   
  }

  const handleAddManually = () => {
    createItemStore.setCategoryStep(true);
    trackEvent('CTA Clicked', {
      cta_text: 'Add asset manually',
      location: 'Request VIP Syncing Service',
      type: 'Button',
      destination: 'add manual item',
    })
  }

  const handleSyncAnotherPortal = () => {
    createItemStore.setInstitutionStep('investor') 
    trackEvent('CTA Clicked', {
      cta_text: 'Sync other portal',
      location: 'Request VIP Syncing Service',
      type: 'Button',
      destination: 'syncing institution',
    })
  }
  // const handlePreferManuallyClick = () => {
  //   createItemStore.setCategoryStep(true);
  //   trackEvent('CTA Clicked', {
  //     cta_text: 'I prefer to add this item manually',
  //     location: 'VIP Sync Service',
  //     type: 'Button',
  //     destination: 'add manual item',
  //     institution: item.name,
  //   })
  // }

  // useEffect(() => {
  //   return () => {
  //     createItemStore.setIsFromVipVipSyncingTab(false);
  //   }
  // }, [createItemStore])
  

  return (<Observer>{() => (
    <RequestVipSyncStepWrapper>
      <RequestVipSyncHeaderWrapper isAfterSent={isRequestSent}>
        <RequestVipSyncHeaderIcon>{isRequestSent ? <CheckMarkIcon /> : <VipHeaderIcon />}</RequestVipSyncHeaderIcon>
        <RequestVipSyncHeaderTitle>{isRequestSent ? 'Request sent' : 'Experience seamless integration.'}</RequestVipSyncHeaderTitle>
        {isRequestSent ? 
        <RequestVipSyncHeaderSubTitle>Thank you for your request to add new institution for our VIP Syncing Service.<br/><br/>
        Once establishing this connection we’ll ask your permission to contact `{requestVipSyncForm?.values?.name}` to request read-only access.
        </RequestVipSyncHeaderSubTitle> :
        <RequestVipSyncHeaderSubTitle>Request to sync your portal with VIP sync. This Service requires <b>read-only</b> access to your account</RequestVipSyncHeaderSubTitle>
        }
      </RequestVipSyncHeaderWrapper>
      
      {!isRequestSent &&
      <RequestVipSyncStepContent>
      <FormWithStore
        fullWidth 
        isStepsDisplay
        defaultValues={{name: createItemStore.searchStr}}
        formName={formName} 
        sections={formSections} 
        //scrollContainerId={'amortization-loan-modal'} 
        
      />
      </RequestVipSyncStepContent>
      }

      
      <RequestVipSyncStepFooter>
        {!isRequestSent && <PrimaryButton onClick={handleNextClick} style={{ maxHeight: '38px', marginLeft: 'auto', ...(uiStore.isDesktopView ? {} : {width:'100%'} ) }}>{isSendingRequest ? <LoadingOutlined /> : 'Send request'}</PrimaryButton>}
        {isRequestSent && <SecondaryButton onClick={handleAddManually} style={{ maxHeight: '38px', ...(uiStore.isDesktopView ? {} : {width:'100%'} ) }}>Add asset manually</SecondaryButton>}
        {isRequestSent && <PrimaryButton onClick={handleSyncAnotherPortal} style={{ maxHeight: '38px',  ...(uiStore.isDesktopView ? {} : {width:'100%'} ) }}>Sync other portal</PrimaryButton>}
      </RequestVipSyncStepFooter>
    </RequestVipSyncStepWrapper>
  )}</Observer>)
}

const VipHeaderIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
    <path d="M7.49997 0.313477L10.3571 6.57237L14.6428 2.8645V11.0278H0.357117V2.8645L4.64283 6.57237L7.49997 0.313477Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.9286 14.2592H1.07141V12.5449H13.9286V14.2592Z" fill="white"/>
  </svg>
)

const CheckMarkIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
  <path d="M16.0151 1.29101L6.00871 11.3032L1.00011 6.29607" stroke="white" strokeWidth="2.85714"/>
</svg>
)