import { Drawer } from 'antd';
import React, { useState } from 'react';
import { 
  DrawerHeader, 
  DrawerTitle, 
  DrawerCloseIconWrapper,
  InsightItem,
  InsightItemIcon,
  InsightItemText,
  InsightItemArrow,
  ClearHistoryButton,
  ClearHistoryIcon,
  ClearHistoryText,
  InsightHeader,
  InsightItemTitle,
  InsightItemTextWrapper,
  InsightItemInnerContentWrapper,
  InsightItemInnerContentItemWrapper,
  InsightItemInnerContentItemTexts,
  InsightItemInnerContentItemTitle,
  InsightItemInnerContentItemDescription,
  InsightItemInnerContentLinkWrapper,
  DrawerContentWrapper
} from './MoreOnInsightDrawer.styles';



export const MoreOnInsightDrawer = ({ visible, onClose , onNewChat , isDemo }) => {

  const [isLearnMoreOpen, setIsLearnMoreOpen] = useState(false);

  const handleClearHistory = () => {
    onNewChat && onNewChat();
    onClose && onClose();
  };



  return (
    <Drawer
      closable={false}
      visible={visible}
      height="auto"
      placement="bottom"
      onClose={onClose}
      className="more-on-insights-drawer-mobile"
    >
      <DrawerHeader>
        <DrawerTitle>More on Insights</DrawerTitle>
        <DrawerCloseIconWrapper onClick={onClose}>
          <DrawerCloseIcon />
        </DrawerCloseIconWrapper>
      </DrawerHeader>
    <DrawerContentWrapper>
      <InsightItem onClick={() => {window.open('https://help.vyzer.co/en/articles/10697282-vyzer-ai-insights-security-privacy-guide', '_blank').focus();}}>
        <InsightHeader>
        <InsightItemIcon>
            <SecureIcon />
          </InsightItemIcon>
          <InsightItemTitle>Secure</InsightItemTitle>
        </InsightHeader>
        <InsightItemTextWrapper>
          <InsightItemText>Your data is secure in Vyzer AI chat.<br/>Vyzer does not use your conversations to train our models.<br/>This is a beta version of Vyzer's AI Insights.<br/>Read more</InsightItemText>
          <InsightItemArrow>
            <ArrowIcon style={{minWidth:'44px'}} />
          </InsightItemArrow>
        </InsightItemTextWrapper>
       
        
      </InsightItem>

      <InsightItem onClick={() => setIsLearnMoreOpen(!isLearnMoreOpen)} >
        <InsightHeader>
        <InsightItemIcon>
          <LearnIcon />
        </InsightItemIcon>
        <InsightItemTitle>Learn</InsightItemTitle>
        </InsightHeader>
        <InsightItemTextWrapper>
          <InsightItemText>Here's what you can do with Vyzer AI insights{isLearnMoreOpen ? ':' : '...'}</InsightItemText>
          <InsightItemArrow style={{transform: isLearnMoreOpen ? 'rotate(180deg)' : 'rotate(0deg)' , transition: 'transform 0.3s ease-in-out'}}>
            <LearnMoreArrowIcon  />
          </InsightItemArrow>
        </InsightItemTextWrapper>
        <InsightItemInnerContentWrapper 
          onClick={(e) => e.stopPropagation()}
          style={{
            maxHeight: isLearnMoreOpen ? '500px' : '0',
            opacity: isLearnMoreOpen ? 1 : 0,
            overflow: 'hidden',
            transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out, margin-top 0.3s ease-in-out',
            marginTop: isLearnMoreOpen ? '8px' : '0',
          }}
        >

          <InsightItemInnerContentItemWrapper>
            <LearnMoreUncoverIcon />
            <InsightItemInnerContentItemTexts>
              <InsightItemInnerContentItemTitle>Uncover insights</InsightItemInnerContentItemTitle>
              <InsightItemInnerContentItemDescription>
                {isDemo ? 'See how AI analyzes trends and performance in this Demo portfolio.' : 'Track trends and analyze performance across your portfolio in real time.'}
              </InsightItemInnerContentItemDescription>
            </InsightItemInnerContentItemTexts>
          </InsightItemInnerContentItemWrapper>


          <InsightItemInnerContentItemWrapper>
            <LearnMoreAskIcon />
            <InsightItemInnerContentItemTexts>
              <InsightItemInnerContentItemTitle>Ask questions</InsightItemInnerContentItemTitle>
              <InsightItemInnerContentItemDescription>
              {isDemo ? 'Ask about any financial topic from markets to investment strategies.' : 'Get answers to your financial questions, from market trends to strategies.'}
              </InsightItemInnerContentItemDescription>
            </InsightItemInnerContentItemTexts>
          </InsightItemInnerContentItemWrapper>
          
          

          <InsightItemInnerContentItemWrapper>
            <LearnMoreSaveTimeIcon />
            <InsightItemInnerContentItemTexts>
              <InsightItemInnerContentItemTitle>Save time</InsightItemInnerContentItemTitle>
              <InsightItemInnerContentItemDescription>
              No more digging through data. Get instant insights and answers in seconds.
              </InsightItemInnerContentItemDescription>
            </InsightItemInnerContentItemTexts>
          </InsightItemInnerContentItemWrapper>

          <InsightItemInnerContentLinkWrapper onClick={() => {window.open('https://help.vyzer.co/en/articles/10697312-maximize-your-financial-insights-with-vyzer-ai', '_blank').focus();}}>
              Read more
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path d="M8.37402 7.5C8.37402 7.5 11.7864 7.5 13.3322 7.5C13.3322 9.04575 13.3322 12.4581 13.3322 12.4581" stroke="#1A1B1D" strokeWidth="1.42493" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7 13.8359L13.1167 7.71926" stroke="#1A1B1D" strokeWidth="1.42493" strokeLinecap="round"/>
              </svg>
          </InsightItemInnerContentLinkWrapper>

        </InsightItemInnerContentWrapper>
      </InsightItem>

      <ClearHistoryButton onClick={handleClearHistory}>
        <ClearHistoryIcon>
          <NewChatIcon />
        </ClearHistoryIcon>
        <ClearHistoryText>New chat</ClearHistoryText>
      </ClearHistoryButton>
      </DrawerContentWrapper>
    </Drawer>
  );
};


const DrawerCloseIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8" y="8" width="28" height="28" rx="14" fill="#E8E9ED"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M26.4194 27.7301L16.5199 17.8306L17.5806 16.7699L27.4801 26.6694L26.4194 27.7301Z" fill="#585D66"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M27.4802 17.8306L17.5807 27.7301L16.52 26.6694L26.4195 16.7699L27.4802 17.8306Z" fill="#585D66"/>
    </svg>
  );
};

const SecureIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M5 8.36364L8.75 6.43478L12.5 4L16.25 6.43478L20 8.36364V9.21784C20 14.0264 17.0083 18.3274 12.5 20C7.99171 18.3274 5 14.0264 5 9.21784V8.36364ZM9.50338 7.73407L12.5 5.78844L15.4966 7.73407L18.4998 9.27879C18.4758 13.2473 16.1092 16.8093 12.5 18.3833C8.89081 16.8093 6.52419 13.2473 6.50018 9.27879L9.50338 7.73407ZM9.26141 12.0887L11.691 14.5175L16.0153 10.1907L14.9544 9.13033L11.6905 12.396L10.3219 11.0278L9.26141 12.0887Z" fill="#1A1B1D"/>
  </svg>
  );
};

const LearnIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4.80005" y="4.7998" width="14.4" height="14.4" rx="7.2" stroke="#1A1B1D" strokeWidth="1.54286"/>
      <path d="M12.4372 13.9802H11.2276C11.2052 13.8346 11.1828 13.689 11.1828 13.521C11.1828 12.9274 11.4292 12.3786 12.0676 11.897L12.6052 11.4938C12.9412 11.2474 13.0868 10.945 13.0868 10.609C13.0868 10.0938 12.7172 9.60101 11.9668 9.60101C11.1716 9.60101 10.8244 10.2394 10.8244 10.8666C10.8244 11.001 10.8356 11.1242 10.8692 11.225L9.45798 11.169C9.41318 11.0122 9.40198 10.833 9.40198 10.665C9.40198 9.45541 10.3092 8.29061 11.9668 8.29061C13.7028 8.29061 14.61 9.38821 14.61 10.5194C14.61 11.4042 14.162 12.0314 13.5012 12.5018L13.042 12.8266C12.65 13.1066 12.4372 13.4538 12.4372 13.913V13.9802ZM10.97 15.6042C10.97 15.1114 11.362 14.7082 11.8548 14.7082C12.3476 14.7082 12.7508 15.1114 12.7508 15.6042C12.7508 16.097 12.3476 16.489 11.8548 16.489C11.362 16.489 10.97 16.097 10.97 15.6042Z" fill="#1A1B1D"/>
    </svg>

  );
};

const ArrowIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.374 19.5C20.374 19.5 23.7864 19.5 25.3322 19.5C25.3322 21.0457 25.3322 24.4581 25.3322 24.4581" stroke="#585D66" strokeWidth="1.42493" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19 25.8359L25.1167 19.7193" stroke="#585D66" strokeWidth="1.42493" strokeLinecap="round"/>
    </svg>

  );
};

const LearnMoreArrowIcon = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.3334 19.667L22 24.3337L26.6667 19.667" stroke="#585D66" strokeWidth="1.4"/>
</svg>

)

const NewChatIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M13.4984 6.83301L9.00249 2.49145" stroke="white" strokeWidth="1.5"/>
   <path d="M7.25543 13.3092L7.25447 14.0592C7.45869 14.0595 7.65419 13.9764 7.7958 13.8293L7.25543 13.3092ZM2.75229 8.97512L2.21191 8.45503C2.0703 8.60217 1.99481 8.8007 2.00288 9.00476L2.75229 8.97512ZM2.9235 13.3037L2.17409 13.3333L2.20254 14.0527L2.92254 14.0537L2.9235 13.3037ZM11.2741 1.56293L14.3363 4.51011L15.3765 3.42936L12.3143 0.482179L11.2741 1.56293ZM14.343 4.8636L6.71505 12.7891L7.7958 13.8293L15.4238 5.90378L14.343 4.8636ZM3.29267 9.49521L10.9207 1.56969L9.8399 0.529513L2.21191 8.45503L3.29267 9.49521ZM7.25639 12.5592L2.92446 12.5537L2.92254 14.0537L7.25447 14.0592L7.25639 12.5592ZM3.67291 13.274L3.5017 8.94548L2.00288 9.00476L2.17409 13.3333L3.67291 13.274ZM14.3363 4.51011C14.4358 4.60585 14.4388 4.76412 14.343 4.8636L15.4238 5.90378C16.094 5.20741 16.0728 4.09958 15.3765 3.42936L14.3363 4.51011ZM12.3143 0.482179C11.618 -0.188043 10.5101 -0.166851 9.8399 0.529513L10.9207 1.56969C11.0164 1.47021 11.1747 1.46719 11.2741 1.56293L12.3143 0.482179Z" fill="white"/>
    <path d="M15.6666 13.334H9.83329" stroke="white" strokeWidth="1.5"/>
  </svg>
)

const LearnMoreAskIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M18 4.25H19C24.2467 4.25 28.5 8.5033 28.5 13.75V21.25H24.8098C24.9346 20.6025 25 19.9339 25 19.25C25 13.451 20.299 8.75 14.5 8.75H13.5C12.3786 8.75 11.2947 8.9105 10.2701 9.20978C11.6128 6.28284 14.569 4.25 18 4.25ZM8.4003 9.93972C9.61458 5.78496 13.4529 2.75 18 2.75H19C25.0751 2.75 30 7.67487 30 13.75V21.25V22.75H28.5H24.4025C22.9611 26.8282 19.0718 29.75 14.5 29.75H3.5H2V28.25V20.25C2 15.7302 4.6075 11.8193 8.4003 9.93972ZM13.5 10.25H14.5C19.4706 10.25 23.5 14.2794 23.5 19.25C23.5 24.2206 19.4706 28.25 14.5 28.25H3.5V20.25C3.5 14.7272 7.97715 10.25 13.5 10.25ZM9 18.75H14V17.25H9V18.75ZM17.75 23.25H9V21.75H17.75V23.25Z" fill="#585D66"/>
</svg>
)

const LearnMoreUncoverIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M9 5C6.37665 5 4.25 7.12665 4.25 9.75V22.5921C4.25 25.2155 6.37665 27.3421 9 27.3421H23C25.6234 27.3421 27.75 25.2155 27.75 22.5921V9.75C27.75 7.12665 25.6234 5 23 5H9ZM5.75 9.75C5.75 7.95507 7.20507 6.5 9 6.5H23C24.7949 6.5 26.25 7.95507 26.25 9.75V22.5921C26.25 24.387 24.7949 25.8421 23 25.8421H9C7.20507 25.8421 5.75 24.387 5.75 22.5921V9.75ZM10.1 16.25L10.1 22.0833H11.6L11.6 16.25H10.1ZM15.25 22.0834V11.5834H16.75V22.0834H15.25ZM20.4 13.9166V22.0833H21.9V13.9166H20.4Z" fill="#585D66"/>
  </svg>
  
)

const LearnMoreSaveTimeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M23 2.5V0.5H24V2.5H26V3.5H24V5.5H23V3.5H21V2.5H23ZM14.5 6H12V4.75H18.5V6H16V7.04484C21.6065 7.55007 26 12.262 26 18C26 24.0751 21.0751 29 15 29C8.92487 29 4 24.0751 4 18C4 12.0924 8.65693 7.27256 14.5 7.01116V6ZM15 27.5C9.7533 27.5 5.5 23.2467 5.5 18C5.5 12.7533 9.7533 8.5 15 8.5C20.2467 8.5 24.5 12.7533 24.5 18C24.5 23.2467 20.2467 27.5 15 27.5ZM20.0772 19.2506L15.5315 19.2505L15.5316 13.0005L14.0316 13.0005L14.0315 20.0005C14.0315 20.4147 14.3673 20.7505 14.7815 20.7505L20.0771 20.7506L20.0772 19.2506ZM29 9.5H27V10.5H29V12.5H30V10.5H32V9.5H30V7.5H29V9.5Z" fill="#585D66"/>
</svg>
)
