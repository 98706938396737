import { Tooltip } from "antd";
import { CountAnimation } from "../../../../../../../common/components/count-animation/CountAnimation";
import { floatFixed } from "../../../../../../../common/utils/number-display.utils";
import { PieValueContainer } from "../../../../../components/charts/WealthPieChart/WealthPieChart.styles";
import { TrendsDropdown } from "./TrendsDropdown";
import { TrendsCurrentValueContainer, TrendsHeroControlContainer, TrendsHeroControlCube, TrendsHeroControlOverview, TrendsHeroSegmented, TrendsHeroSegmentedButton, TrendsPercentageChange } from "./TrendsHero.style";



export const TrendsHeroControl = (props) => {
    const { onChangeTrendsType, selectedTrendsType, showAstric, onChangeTrendsPeriod, selectedTrendsPeriod, whiteTheme, diff, value, showCurrentCashPosition, showIrr, isDesktopView , netWorthTitle , netWorthCurrency, isLoading, showAbsoluteValues, from } = props;

    const filteredOptions = [
        { text: netWorthTitle, value: 'total_net_worth' },
        ...(showIrr ? [{ text: 'IRR', value: 'xirr' }] : []),
        ...(showCurrentCashPosition ? [{text: 'Current cash position', value: 'current_cash_position'}] : []),
    ];
    // const  = showCurrentCashPosition ? options : options.filter(option => option.value !== 'current_cash_position');

    return (
        <TrendsHeroControlContainer>
            <TrendsHeroControlOverview>
                <TrendsHeroControlCube whiteTheme={whiteTheme}>
                    <TrendsDropdown
                        options={filteredOptions}
                        value={selectedTrendsType}
                        onChangeTrendsType={(value) => onChangeTrendsType(value)}
                        whiteTheme={whiteTheme}
                        showAstric={showAstric}
                    />
                    <TrendsCurrentValueContainer isLoading={isLoading}>
                    <PieValueContainer >
                           <CountAnimation
                               style={{
                                   fontSize: `30px`,
                                   color: ((whiteTheme) ? "#000" : "#FFF"),
                                   fontWeight: '450',
                                   lineHeight: 'var(--font-line-height-lg, 40px)'
                               }}
                               currency={['total_net_worth', 'current_cash_position'].includes(selectedTrendsType) ? netWorthCurrency : undefined}
                               suffix={selectedTrendsType === 'xirr' ? '%' : undefined}
                               value={selectedTrendsType === 'xirr' ? floatFixed(value * 100, 2) : showAbsoluteValues ? Math.abs(value) : value}
                               noAnim={true}
                               decimalPlaces={selectedTrendsType === 'xirr' ? 2 : undefined}
                           />
                       </PieValueContainer>
                        {(selectedTrendsType === 'xirr') &&
                            <Tooltip overlayInnerStyle={{borderRadius:'6px', background: 'rgba(0, 0, 0, 0.9)', fontSize:'12px', fontWeight: '500'}} title={`% change in IRR since ${from}`}>
                                <TrendsPercentageChange sign={Math.sign(diff)}>{diff}</TrendsPercentageChange>
                            </Tooltip>
                        }
                    </TrendsCurrentValueContainer>
                </TrendsHeroControlCube>
            </TrendsHeroControlOverview>
            { isDesktopView && 
            <TrendsHeroSegmented>
                {
                    periodsOptions.map(option => (
                        <TrendsHeroSegmentedButton
                            key={option.key}
                            onClick={() => !option.block && onChangeTrendsPeriod(option.key)} 
                            isSelected={selectedTrendsPeriod === option.key}
                            whiteTheme={whiteTheme}
                            style={option.block ? {opacity: 0.5, cursor: 'not-allowed'} : {}}
                        >
                            {option.title}
                        </TrendsHeroSegmentedButton>))
                }
            </TrendsHeroSegmented> }
        </TrendsHeroControlContainer>
    );
}

const periodsOptions = [
    {
        key: 'ytd',
        title: 'YTD',
    },
    {
        key: 'one_year',
        title: '1Y'

    },
    {
        key: 'three_years',
        title: '3Y'

    },
    {
        key: 'max',
        title: 'Max'
    }
];
