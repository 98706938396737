import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Dropdown, Modal, Drawer } from 'antd';
import { Observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ConnectedAccountsIcon, HamburgerIcon, MobileThreeDots } from '../../../../../assets/icons/common/common-icons';
import { ActionLocked } from '../../../../../common/components/ActionLocked/ActionLocked';
import { ActionLockedIconWithTooltip } from '../../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
import { ConnectAssetModal } from '../../../../../common/components/connect-asset-modal/ConnectAssetModal';
import { ConnectCollateralModal } from '../../../../../common/components/connect-collateral-modal/ConnectCollateralModal';
import { ConnectedCollateralsModal } from '../../../../../common/components/connect-collateral-modal/ConnectedCollateralsModal';
import { ConnectLiabilityModal } from '../../../../../common/components/connect-liability-modal/ConnectLiabilityModal';
import { ConnectedLiabilitiesModal } from '../../../../../common/components/connect-liability-modal/ConnectedLiabilitiesModal';
import { navigateToUrl } from '../../../../../common/utils/navigation.utils';
import { useStore } from '../../../../app/data/root.store';
import { PlaidLink } from '../../../../connected-institutions/components/plaid-link/PlaidLink';
import { SaltedgeLink } from '../../../../connected-institutions/components/saltedge-link/SaltedgeLink';
import { ArchiveAssetIcon, EraseIcon, LinkLiabilityIcon, ManageSyncedIcon, UndoIcon, UnlinkLiabilityIcon } from '../Icons/icons';
import { AssetActionMenuContent, AssetActionMenuItem, AssetActionMenuItemChild, AssetActionMenuWrapper, ConnectedAccountsIconWrapper, HamburgerIconWrapper, ItemLoginRequiredBadge, OutstandingLoanDescription, OutstandingLoanWrapper, ProviderLinkWrapper } from './AssetPageHeader.styles';
import { MobilAssetActionsButtonWrapper } from './AssetPage.styles';
import { AkoyaLink } from '../../../../connected-institutions/components/akoya-link/AkoyaLink';
import { ROUTES } from '../../../../../common/constants/routes.const';

export const AssetActionsMenu = (props) => {
  let history = useHistory();

  const { metadataStore, itemStore, userStore, billingStore, filtersStore, wealthStore, createItemStore, uiStore } = useStore();
  const [showConnectLiabilityModal, setShowConnectLiabilityModal] = useState(false);
  const [showConnectedLiabilitiesModal, setShowConnectedLiabilitiesModal] = useState(false);
  const [showConnectAssetModal, setShowConnectAssetModal] = useState(false);
  const { confirm } = Modal;
  const [showItemMobileMenu, setShowItemMobileMenu] = useState(false);

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [menuWidth, setMenuWidth] = useState(null);
  const menuRef = useRef(null);

  useEffect(() => {
    if (isOverlayOpen && menuRef.current) {
      const timeoutId = setTimeout(() => {
        setMenuWidth(menuRef.current.offsetWidth);
      }, 10);
      return () => clearTimeout(timeoutId);
    }
  }, [isOverlayOpen]);

  const handleUpdateMetadata = () => {
    metadataStore.fetchData();
  }

  const showDeleteAssetConfirm = () => {
    confirm({
      title: `Are you sure you want to delete ${itemStore.item.title}${itemStore.item.title && itemStore.item.name ? ', ' : ''}${itemStore.item.name} and all its financial data?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const assetClassId = itemStore.item.categoryClass.id;
        itemStore.deleteAsset(itemStore.item.id, false, () => {
          metadataStore.fetchData();
          if (!wealthStore.isAnyFilterApplied) {
            filtersStore.handleSelectClass(assetClassId);
            const urlParams = filtersStore.setAppliedFiltersFromSelection();
            navigateToUrl(history, ROUTES.wealthOverview.url + `?filters=${urlParams}`, false, true);
          }
          wealthStore.getLastChanges(true);
          wealthStore.refreshWealthData(false, history);
          navigateToUrl(history, `/wealth/overview`)
        })
      },
      onCancel() { },
    });
  }

  const showUnlinkAssetConfirm = (liabilityId) => {
    confirm({
      title: `Are you sure you want to unlink ${itemStore.item.title}${itemStore.item.title && itemStore.item.name ? ', ' : ''}${itemStore.item.name} from this asset?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        itemStore.disconnectLiabilityFromAsset(itemStore.item.id, liabilityId, handleUpdateMetadata);
      },
      onCancel() { },
    });
  }

  const showUnlinkLoanFromCollateralConfirm = (liabilityIds) => {
    confirm({
      title: `Are you sure you want to unlink ${itemStore.item.title}${itemStore.item.title && itemStore.item.name ? ', ' : ''}${itemStore.item.name} from this asset?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        itemStore.disconnectLiabilityFromCollateral(itemStore.item.id, { ids: liabilityIds.map(id => '' + id) }, handleUpdateMetadata);
      },
      onCancel() { },
    });
  }

  const showUnlinkLiabilityConfirm = () => {
    confirm({
      title: `Are you sure you want to unlink ${metadataStore.itemsMeta[itemStore.item.connectedAssets[0].assetId].title} from this liability?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        itemStore.disconnectLiabilityFromAsset(itemStore.item?.connectedAssets[0].assetId, itemStore.item?.connectedAssets[0].id, handleUpdateMetadata);
      },
      onCancel() { },
    });
  }

  const handleConnectCollaterals = (liabilityIds) => {
    uiStore.setShowConnectCollateralModal(false);
    itemStore.connectCollaterals(itemStore.item.id, { ids: liabilityIds.map(id => '' + id) }, handleUpdateMetadata)
  }

  const handleConnectLiability = (liabilityId) => {
    setShowConnectLiabilityModal(false);
    itemStore.connectLiabilityToAsset(itemStore.item.id, { id: liabilityId }, handleUpdateMetadata)
  }

  const handleConnectAsset = (connectAssetId) => {
    setShowConnectAssetModal(false);
    itemStore.connectLiabilityToAsset(connectAssetId, { id: itemStore.item.id }, handleUpdateMetadata)
  }

  const handleReconnectError = () => {
    uiStore.setShowReconnectErrorModal(true, itemStore.item.connectedInstitutionName, itemStore.item.logoBase64, itemStore.item.logoUrl);
  }

  const handleInstReconnect = () => {
    window.location.reload();
  }

  const handleItemOpen = () => {
    itemStore.closeReopenAsset(itemStore.item.id, itemStore.item.closedAt, false, handleUpdateMetadata);
    itemStore.setShowOpenItemModal(true);
  }


  return (<Observer>{() => (
    <>


      {uiStore.isDesktopView &&
        <Dropdown
          overlay={AssetPageHeaderActionMenu({
            showDeleteAssetConfirm,
            setShowConnectLiabilityModal,
            setShowConnectedLiabilitiesModal,
            setShowConnectAssetModal,
            showUnlinkAssetConfirm,
            showUnlinkLiabilityConfirm,
            handleInstReconnect,
            handleReconnectError,
            handleItemOpen,
            asset: itemStore.item,
            isCollateralConnected: itemStore.connectedCollaterals?.length,
            isLiabilityConnected: itemStore.hasConnectedLiability,
            isAssetConnected: itemStore.item.connectedAssets?.length,
            isLiability: itemStore.item?.categoryClass?.isLiability,
            userStore: userStore,
            billingStore: billingStore,
            itemStore: itemStore,
            uiStore: uiStore,
            createItemStore: createItemStore,
            history: history,
            menuRef,
          })}
          trigger={['click']} placement="bottomLeft"
          getPopupContainer={() => document.getElementById('wealthPageContent')}
          overlayStyle={{ width: menuWidth , zIndex: 4 }}
          onVisibleChange={(visible) => setIsOverlayOpen(visible)} >
          <div className="action-button" onClick={e => e.preventDefault()}><HamburgerIconWrapper isMenuOpen={isOverlayOpen}><HamburgerIcon /></HamburgerIconWrapper></div>
        </Dropdown>

      }
      {!uiStore.isDesktopView && <>
        <MobilAssetActionsButtonWrapper onClick={() => { setShowItemMobileMenu(true) }}><MobileThreeDots /></MobilAssetActionsButtonWrapper>
        <Drawer
          closable={false}
          visible={showItemMobileMenu}
          width={340}
          height={200}
          className={'asset_actions_DrawerContainer_mobile'}
          placement='bottom'
          destroyOnClose={true}
          onClose={() => { setShowItemMobileMenu(false) }} >
          {AssetPageHeaderActionMenu({
            showDeleteAssetConfirm,
            setShowConnectLiabilityModal,
            setShowConnectedLiabilitiesModal,
            setShowConnectAssetModal,
            showUnlinkAssetConfirm,
            showUnlinkLiabilityConfirm,
            handleInstReconnect,
            handleReconnectError,
            handleItemOpen,
            asset: itemStore.item,
            isCollateralConnected: itemStore.connectedCollaterals?.length,
            isLiabilityConnected: itemStore.hasConnectedLiability,
            isAssetConnected: itemStore.item.connectedAssets?.length,
            isLiability: itemStore.item?.categoryClass?.isLiability,
            userStore: userStore,
            billingStore: billingStore,
            itemStore: itemStore,
            uiStore: uiStore,
            createItemStore: createItemStore,
            history: history,
            menuRef,
          })}

        </Drawer>
      </>}


      {uiStore.showConnectCollateralModal && <ConnectCollateralModal
        onClose={() => { uiStore.setShowConnectCollateralModal(false) }}
        onSelectLiabilityForCollateral={handleConnectCollaterals}
        loansWithoutCollaterals={metadataStore.loansWithoutCollaterals} />}

      {uiStore.showConnectedCollateralsModal && <ConnectedCollateralsModal
        onClose={() => { uiStore.setShowConnectedCollateralsModal(false) }}
        showUnlinkAssetConfirm={showUnlinkLoanFromCollateralConfirm}
        onOpenConnectCollateralModal={() => uiStore.setShowConnectCollateralModal(true)} />}

      {showConnectLiabilityModal && <ConnectLiabilityModal
        onClose={() => { setShowConnectLiabilityModal(false) }}
        onSelectLiability={handleConnectLiability}
        unconnectedLoans={metadataStore.unconnectedLoans}
        item={itemStore.item} />}

      {showConnectedLiabilitiesModal && <ConnectedLiabilitiesModal
        onClose={() => { setShowConnectedLiabilitiesModal(false) }}
        showUnlinkAssetConfirm={showUnlinkAssetConfirm}
        onOpenConnectLiabilityModal={() => setShowConnectLiabilityModal(true)}
        item={itemStore.item} />}

      {showConnectAssetModal && <ConnectAssetModal
        onClose={() => { setShowConnectAssetModal(false) }}
        onSelectAsset={handleConnectAsset}
        connectAssetSearchItems={metadataStore.connectAssetSearchItems} />}

    </>
  )}</Observer>)
}

function AssetPageHeaderActionMenu({
  asset,
  isLiabilityConnected,
  isAssetConnected,
  userStore,
  billingStore,
  setShowConnectAssetModal,
  setShowConnectLiabilityModal,
  setShowConnectedLiabilitiesModal,
  isLiability,
  isCollateralConnected,
  uiStore,
  itemStore,
  handleItemOpen,
  showDeleteAssetConfirm,
  showUnlinkAssetConfirm,
  handleInstReconnect,
  showUnlinkLiabilityConfirm,
  handleReconnectError,
  createItemStore,
  history,
  menuRef
}) {

  const itemTitle = itemTitleByClassId(asset?.categoryClass.id);
  const isLifeInsuranceItem = asset?.categoryId === 40;
  const showCloseReopenMenuItem = !asset.isConnectedSingleStock && !asset.isConnected;

  const handleItemLink = (e) => {
    if ((!isLiabilityConnected && asset.closedAt !== null) || userStore.isActionBlockedByRole('assetActionMenuLinkLiability') || userStore.isActionBlockedByRole('assetActionMenuLinkAsset')) {
      userStore.setShowLimitedSharedAccess(true);
    } else if (userStore.isDemoOrReadOnlyMode) {
      billingStore.setShowPricingTableModal(true);
    } else {
      isLiability ?
        setShowConnectAssetModal?.(true)
        :
        !isLiabilityConnected ?
          setShowConnectLiabilityModal?.(true)
          :
          setShowConnectedLiabilitiesModal?.(true)
    }
  }

  const handleUnlinkItem = () => {
    if (userStore.isActionBlockedByRole('assetActionMenuLinkAsset')) {
      userStore.setShowLimitedSharedAccess(true);
    } else {
      showUnlinkLiabilityConfirm?.();
    }
  }

  const handleOutstandingLoanMenuItemClick = () => {
    if (userStore.isActionBlockedByRole('assetActionMenuOutstandingLoan')) {
      userStore.setShowLimitedSharedAccess(true);
    } else if (userStore.isDemoOrReadOnlyMode) {
      billingStore.setShowPricingTableModal(true);
    } else {
      !isCollateralConnected ?
        uiStore.setShowConnectCollateralModal?.(true)
        :
        uiStore.setShowConnectedCollateralsModal?.(true)
    }
  }

  const handleItemStatusUpdate = (e) => {
    if (userStore.isActionBlockedByRole('assetActionMenuCloseReopen')) {
      userStore.setShowLimitedSharedAccess(true);
    } else if (userStore.isDemoOrReadOnlyMode) {
      billingStore.setShowPricingTableModal(true);
    } else {
      if (asset?.closedAt && userStore?.isCreateItemManuallyLimited) {
        return createItemStore.setShowAddManualItemLimitModal(true);
      }
      if (asset?.closedAt) {
        handleItemOpen?.();
      } else if (itemStore.item.isNotFound) {
        itemStore.setShowCloseAccountNotFoundItemModal(true);
      } else {
        itemStore.setShowCloseItemModal(true);
      }
    }
  }

  const handleManageSynced = () => {
    const { connectedInstitutionId, vipInstitutionId, vipInstitutionConnection, connectedProvider, localInstitutionId } = asset;
    navigateToUrl(history, `/profile/accounts?inst=${vipInstitutionId && vipInstitutionConnection === 'connected' ? vipInstitutionId : connectedProvider === 'plaid' ? connectedInstitutionId : localInstitutionId}&p=${connectedProvider}`);
  }

  const handleSyncNow = () => {
    const { isLoginRequired, connectedProvider, localInstitutionId } = asset;
    const { isSyncingInstitution, fetchInstitutionAccounts } = itemStore;
    if (isSyncingInstitution || isLoginRequired) {
      return;
    }
    fetchInstitutionAccounts(connectedProvider, localInstitutionId);
  }

  const handleDeleteAsset = () => {
    if (userStore.isActionBlockedByRole('assetActionMenuDeleteAsset')) {
      userStore.setShowLimitedSharedAccess(true);
    } else {
      showDeleteAssetConfirm?.();
    }
  }


  const SyncingIndicator = () => (
    <div style={{ display: 'flex', gap: '8px' }}>
      <ConnectedAccountsIconWrapper isSyncing={true}><ConnectedAccountsIcon /></ConnectedAccountsIconWrapper>Syncing...
    </div>
  );

  const ProviderLink = () => (
    asset.connectedProvider === 'plaid' ?
      <ProviderLinkWrapper>
        <ActionLocked action={'itemPageLoginRequired'}>
          <PlaidLink reconnectId={asset.connectedInstitutionId} onSuccess={() => handleInstReconnect()} >
            <div style={{ display: 'flex', gap: '8px' }}>
              <ConnectedAccountsIconWrapper><ConnectedAccountsIcon /></ConnectedAccountsIconWrapper>Sync now
            </div>
          </PlaidLink>
        </ActionLocked>
      </ProviderLinkWrapper>
      : asset.connectedProvider === 'saltEdge' ?
        <ProviderLinkWrapper>
          <ActionLocked action={'itemPageLoginRequired'}>
            <SaltedgeLink reconnectId={asset.connectedInstitutionId} onSuccess={(data) => { handleInstReconnect() }} loadingComp={<ItemLoginRequiredBadge>Reconnect...</ItemLoginRequiredBadge>}>
              <div style={{ display: 'flex', gap: '8px' }}>
                <ConnectedAccountsIconWrapper><ConnectedAccountsIcon /></ConnectedAccountsIconWrapper>Sync now
              </div>
            </SaltedgeLink>
          </ActionLocked>
        </ProviderLinkWrapper> :
        <ProviderLinkWrapper>
          <ActionLocked action={'itemPageLoginRequired'}>
            <AkoyaLink reconnectId={asset.connectedInstitutionId} onSuccess={(data) => { handleInstReconnect() }} onError={(data) => { handleReconnectError(data.error) }} loadingComp={<ItemLoginRequiredBadge>Reconnect...</ItemLoginRequiredBadge>}>
              <div style={{ display: 'flex', gap: '8px' }}>
                <ConnectedAccountsIconWrapper><ConnectedAccountsIcon /></ConnectedAccountsIconWrapper>Sync now
              </div>
            </AkoyaLink>
          </ActionLocked>
        </ProviderLinkWrapper>
  );

  const SyncNowIndicator = () => (
    <div style={{ display: 'flex', gap: '8px' }}>
      <ConnectedAccountsIconWrapper><ConnectedAccountsIcon /></ConnectedAccountsIconWrapper>Sync now
    </div>
  );

  const LifeInsuranceMenuItem = () => (
    <AssetActionMenuItem style={{ height: '71px' }} onClick={handleOutstandingLoanMenuItemClick}>
      <LinkLiabilityIcon />
      <OutstandingLoanWrapper>
        Outstanding loan
        <OutstandingLoanDescription>
          <div>Link your loans against cash value</div>
        </OutstandingLoanDescription>
      </OutstandingLoanWrapper>
      {userStore?.isActionBlockedByRole('assetActionMenuOutstandingLoan') && <ActionLockedIconWithTooltip iconColor={'#9097A3'} marginLeft={'auto'} isRoleBlocked={true} />}
    </AssetActionMenuItem>
  );

  const LiabilityLinkMenuItem = () => (
    <AssetActionMenuItem onClick={handleItemLink} disabled={!isLiabilityConnected && asset.closedAt !== null}>
      <LinkLiabilityIcon />{!isLiabilityConnected ? 'Link liability' : 'Linked liabilities'}
      {(userStore?.isDemoOrReadOnlyMode || userStore?.isActionBlockedByRole('assetActionMenuLinkLiability')) && <ActionLockedIconWithTooltip iconColor={'#9097A3'} marginLeft={'auto'} isRoleBlocked={userStore?.isActionBlockedByRole('assetActionMenuLinkLiability')} />}
    </AssetActionMenuItem>
  );

  const SyncNowMenuItem = () => (
    <AssetActionMenuItem onClick={handleSyncNow}>
      {itemStore.isSyncingInstitution ?
        <SyncingIndicator />
        :
        asset.isLoginRequired ?
          <ProviderLink />
          :
          <SyncNowIndicator />
      }
    </AssetActionMenuItem>
  );

  const ManageSyncedMenuItem = () => (
    <AssetActionMenuItem onClick={handleManageSynced}>
      <ManageSyncedIcon />Manage synced accounts <ArrowLinkSvg />
    </AssetActionMenuItem>
  );

  const LiabilityMenuItem = () => (
    isLiability ?
      (!isAssetConnected ?
        <AssetActionMenuItem onClick={handleItemLink}><LinkLiabilityIcon />Link liability to asset{(userStore?.isDemoOrReadOnlyMode || userStore.isActionBlockedByRole('assetActionMenuLinkAsset')) && <ActionLockedIconWithTooltip marginLeft={'auto'} iconColor={'#9097A3'} isRoleBlocked={userStore.isActionBlockedByRole('assetActionMenuLinkAsset')} />}</AssetActionMenuItem>
        :
        <AssetActionMenuItem onClick={handleUnlinkItem}><UnlinkLiabilityIcon />Unlink asset{userStore.isActionBlockedByRole('assetActionMenuLinkAsset') && <ActionLockedIconWithTooltip marginLeft={'auto'} iconColor={'#9097A3'} isRoleBlocked={true} />}</AssetActionMenuItem>
      )
      :
      isLifeInsuranceItem ?
        <LifeInsuranceMenuItem />
        :
        <LiabilityLinkMenuItem />
  );

  const CloseReopenMenuItem = () => (
    showCloseReopenMenuItem &&
    <AssetActionMenuItem onClick={handleItemStatusUpdate}>
      {asset?.closedAt ? <UndoIcon /> : <ArchiveAssetIcon style={{ flexShrink: 0 }} />}{asset?.closedAt ? `Reopen this ${itemTitle}` : `Close out this ${itemTitle}`}
      {(userStore?.isDemoOrReadOnlyMode || userStore?.isActionBlockedByRole('assetActionMenuCloseReopen')) && <ActionLockedIconWithTooltip iconColor={'#9097A3'} marginLeft={'auto'} isRoleBlocked={userStore?.isActionBlockedByRole('assetActionMenuCloseReopen')} />}
    </AssetActionMenuItem>
  );

  const DeleteAssetMenuItem = () => (
    !asset.isConnectedSingleStock &&
    <AssetActionMenuItem isRed onClick={handleDeleteAsset}>
      <AssetActionMenuItemChild ><EraseIcon />Delete {itemTitle}</AssetActionMenuItemChild>
      {userStore?.isActionBlockedByRole('assetActionMenuDeleteAsset') && <ActionLockedIconWithTooltip iconColor={'#9097A3'} marginLeft={'auto'} isRoleBlocked={true} />}
    </AssetActionMenuItem>
  );

  return (
    <AssetActionMenuWrapper>
      <AssetActionMenuContent ref={menuRef}>
        {asset.isConnected && <SyncNowMenuItem />}
        {(asset.isConnected || (asset.vipInstitutionId && asset.vipInstitutionConnection === 'connected')) && <ManageSyncedMenuItem />}
        {(asset?.category?.classId !== 1 && !asset.isConnectedSingleStock) && <LiabilityMenuItem />}
        <CloseReopenMenuItem />
        <DeleteAssetMenuItem />
      </AssetActionMenuContent>
    </AssetActionMenuWrapper>
  );
}

const ArrowLinkSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.2757 6.49292C13.6618 6.51131 13.9708 6.82023 13.9892 7.2064L14.2809 13.3322C14.3006 13.7459 13.9811 14.0973 13.5674 14.117C13.1536 14.1367 12.8023 13.8173 12.7826 13.4035L12.5715 8.97124L6.76943 14.7733C6.47653 15.0662 6.00166 15.0662 5.70877 14.7733C5.41587 14.4804 5.41587 14.0055 5.70877 13.7126L11.5108 7.91058L7.07854 7.69952C6.66479 7.67981 6.34536 7.32843 6.36506 6.91469C6.38476 6.50095 6.73614 6.18151 7.14988 6.20121L13.2757 6.49292Z" fill="#1A1B1D" />
    </svg>
  )
}

export const itemTitleByClassId = (classId) => {
  switch (classId) {
    case 1:
    case 2:
    case 3:
    case 9: // Cash accounts, Long-term savings, Public markets, and crypto
      return 'account';
    case 10:
    case 11: // Loans and credit cards
      return 'liability';
    default: // The rest
      return 'asset';
  }
};