import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useStore } from '../../app/data/root.store'
import { SectionWrapper } from './MagicBoxAiActivationFlow.styles'
import { MagicBoxAIDropArea } from './MagicBoxAIDropArea'
import { MagicBoxAiModals } from './MagicBoxAIModals/MagicBoxAIModals'

export const MagicBoxAiActivationFlow = observer(({ onFlowStepChange }) => {

  const { magicBoxAiStore } = useStore();
  useEffect(() => magicBoxAiStore.fetchData(), [magicBoxAiStore]);

  return (
    <>
      {
        !magicBoxAiStore.isInReview &&
        <SectionWrapper style={{ padding: '0' }}>
          <MagicBoxAIDropArea />
        </SectionWrapper>
      }
      <MagicBoxAiModals onFlowStepChange={onFlowStepChange} />
    </>
  );
});