import { makeAutoObservable } from "mobx";
import { fetchMetaData } from "./metadata.service";

export const unSupportedConnectedAccountCategories = [25, 33, 35, 36, 38, 39, 40, 41];
export const unSupportedMagicBoxAiCategories = [36,39];

export class MetadataStore {
    classes = [];
    categories = [];
    cashAccounts = [];
    loans = [];
    institutions = [];
    beneficiaries = [];
    containers = [];
    isLoading = false;
    isLoaded = false;
    isLoadingMapsApi = false;
    isMapsApiLoaded = false;
    defaultClassesListIds = [1, 4, 8];
    customClasses = [];
    magicBoxFilesInProcessCount = 0;
    showMagicBoxAI = false;

    constructor() {
        makeAutoObservable(this);
    }

    fetchData = async () => {
        this.setLoading(true);
        try {
            const data = await fetchMetaData();
            this.setData(data);
        }
        catch (e) { }
        finally {
            this.setLoading(false);
        }
    }

    setLoading(isLoading) {
        this.isLoading = isLoading;
        if (isLoading === false) {
            this.isLoaded = true;
        }
    }

    setData(data) {
        // console.log(data);
        this.classes = data.classes;
        this.customClasses = data.customClasses;
        this.categories = data.categories;
        this.cashAccounts = data.cashAccounts;
        this.loans = data.loans;
        this.institutions = data.institutions;
        this.connectedInstitutions = data.connectedInstitutions;
        this.beneficiaries = data.beneficiaries;
        this.containers = data.containers;
        this.magicBoxFilesInProcessCount = data.magicBoxFilesInProcessCount;
        this.showMagicBoxAI = data.showMagicBoxAI;
    }

    checkInstitutionExistence(instId) {
        return this.connectedInstitutions.find(ci => ci.providerInstId === instId) || false;
    }

    setIsLoadingMapsApi(isLoading) {
        this.isLoadingMapsApi = isLoading;
        if (!isLoading) {
            this.isMapsApiLoaded = true;
        }
    }

    setDefaultClassesListIds(ids) {
        this.defaultClassesListIds = ids;
    }

    updateCustomClassesOrder(order) {
        this.customClasses = order.map((id, index) => ({ ...this.customClasses.find(cls => cls.id === id), position: index + 1 }));
    }

    get assetClasses() {
        return this.classes.filter(cls => !cls.isLiability);
    };

    get liabilityClasses() {
        return this.classes.filter(cls => cls.isLiability);
    };

    get liabilityClassedIds() {
        return this.liabilityClasses.map(cls => cls.id);
    }
    get assetCategories() {
        return this.categories.filter(category => !this.liabilityClassedIds.includes(category.classId));
    };

    get liabilityCategories() {
        return this.categories.filter(category => this.liabilityClassedIds.includes(category.classId));
    };

    get liabilityCategoriesIds() {
        return this.liabilityCategories.map(cat => cat.id);
    }

    get assetsWithoutCashAccount() {
        return this.assetClasses.filter(ac => ac.id !== 1).reduce(
            (acc, curr) => (acc.push(curr.items.map(item => ({ ...item, classId: curr.id }))), acc), [] /* eslint-disable-line no-sequences*/
        ).flat().sort((a, b) => {
            const nameA = a.title.toUpperCase();
            const nameB = b.title.toUpperCase();
            return nameA > nameB ? 1 : -1;
        });
    }

    get classesObj() {
        return this.classes.reduce(
            (acc, curr) => ((acc[curr.id] = curr), acc), {} /* eslint-disable-line no-sequences*/
        );
    }

    get assetClassesObj() {
        return this.assetClasses.reduce(
            (acc, curr) => ((acc[curr.id] = curr), acc), {} /* eslint-disable-line no-sequences*/
        );
    }

    get liabilityClassesObj() {
        return this.liabilityClasses.reduce(
            (acc, curr) => ((acc[curr.id] = curr), acc), {} /* eslint-disable-line no-sequences*/
        );
    }

    get categoriesObj() {
        return this.categories.reduce(
            (acc, curr) => ((acc[curr.id] = curr), acc), {} /* eslint-disable-line no-sequences*/
        );
    }

    get assetCategoriesObj() {
        return this.assetCategories.reduce(
            (acc, curr) => ((acc[curr.id] = curr), acc), {} /* eslint-disable-line no-sequences*/
        );
    }

    get liabilityCategoriesObj() {
        return this.liabilityCategories.reduce(
            (acc, curr) => ((acc[curr.id] = curr), acc), {} /* eslint-disable-line no-sequences*/
        );
    }

    get loansObj() {
        return this.loans.reduce(
            (acc, curr) => ((acc[curr.id] = curr), acc), {} /* eslint-disable-line no-sequences*/
        );
    }

    get sortedInstitutions() {
        return this.institutions.sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            return nameA > nameB ? 1 : -1;
        });
    }

    get sortedBeneficiaries() {
        return this.beneficiaries.slice().sort((a, b) => {
            return a.id - b.id;
        });
    }

    get sortedContainers() {
        return this.containers.map(i => ({ value: i.name })).sort((a, b) => {
            const nameA = a.value.toUpperCase();
            const nameB = b.value.toUpperCase();
            return nameA > nameB ? 1 : -1;
        });
    }

    get itemsMeta() {
        return this.classes.flatMap(cls => cls.items.map(i => ({ ...i, classId: cls.id }))).reduce(
            (acc, curr) => ((acc[curr.id] = curr), acc), {} /* eslint-disable-line no-sequences*/
        );
    }

    get headerSearchItems() {
        return this.classes.flatMap(cls => cls.items.map(i => ({ ...i, classId: cls.id, categoryTitle: this.categoriesObj[i.categoryId].title, classTitle: cls.title, classColor: cls.color, classIcon: cls.icon })))
            .sort((a, b) => a.title < b.title ? 1 : -1)
    }

    get hasNonCashAccountAssets() {
        return this.classes.flatMap(cls => cls.items.map(i => ({ ...i, classId: cls.id }))).filter(item => item.classId !== 1).length > 0;
    }

    get connectAssetSearchItems() {
        return this.headerSearchItems.filter(item => item.classId !== 10 && item.classId !== 1 && item.classId !== 11 && item.categoryId !== 40);
    }

    get unconnectedLoans() {
        return this.loans.filter(item => !item.isConnectedToAsset);
    }

    get loansWithCollaterals() {
        return this.loans.filter(item => item.isConnectedToCollateral);
    }

    get loansWithoutCollaterals() {
        return this.loans.filter(item => !item.isConnectedToCollateral);
    }

    get totalItemsCount() {
        return this.classes.flatMap(cls => cls.items).length;
    }
    get classWithItemsCount() {
        return this.classes.filter(cls => cls.items.length > 0).length;
    }

    get assetClassesWithCategories() {
        return this.assetClasses.map(cls => {
            const classCategories = this.categories.filter(cat => cat.classId === cls.id && !cat.isHidden );
            const isSingleCategory = classCategories.length === 1;
            return {
                ...cls,
                categories: classCategories,
                isSingleCategory
            }
        }).filter(cls => cls.categories.length);
    }

    get assetClassWithCategoriesForMagicBoxAi(){
        return this.assetClasses.map(cls => {
            const classCategories = this.categories.filter(cat => cat.classId === cls.id && !cat.isHidden && !unSupportedMagicBoxAiCategories.includes(cat.id));
            const isSingleCategory = classCategories.length === 1;
            return {
                ...cls,
                categories: classCategories,
                isSingleCategory
            }
        }).filter(cls => cls.categories.length);
    }

    get assetClassesWithCategoriesForSync() {
        return this.assetClasses.map(cls => {
            const classCategories = this.categories.filter(cat => cat.classId === cls.id && !cat.isHidden && !unSupportedConnectedAccountCategories.includes(cat.id));
            const isSingleCategory = classCategories.length === 1;
            return {
                ...cls,
                categories: classCategories,
                isSingleCategory
            }
        }).filter(cls => cls.categories.length);
    }

    get liabilityClassesWithCategories() {
        return this.liabilityClasses.map(cls => {
            const classCategories = this.categories.filter(cat => cat.classId === cls.id && !cat.isHidden );
            const isSingleCategory = classCategories.length === 1;
            return {
                ...cls,
                categories: classCategories,
                isSingleCategory
            }
        }).filter(cls => cls.categories.length);
    }

    get liabilityClassesWithCategoriesForMagicBoxAi() {
        return this.liabilityClasses.map(cls => {
            const classCategories = this.categories.filter(cat => cat.classId === cls.id && !cat.isHidden && !unSupportedMagicBoxAiCategories.includes(cat.id) );
            const isSingleCategory = classCategories.length === 1;
            return {
                ...cls,
                categories: classCategories,
                isSingleCategory
            }
        }).filter(cls => cls.categories.length);
    }

    get liabilityClassesWithCategoriesForSync() {
        return this.liabilityClasses.map(cls => {
            const classCategories = this.categories.filter(cat => cat.classId === cls.id && !cat.isHidden && !unSupportedConnectedAccountCategories.includes(cat.id));
            const isSingleCategory = classCategories.length === 1;
            return {
                ...cls,
                categories: classCategories,
                isSingleCategory
            }
        }).filter(cls => cls.categories.length);
    }

    get categoriesTitleObj() {
        const obj = {};
        this.categories.reduce((acc, curr) => {
            if((!curr.isHidden) && (!unSupportedConnectedAccountCategories.includes(curr.id))) {
                acc[curr.title.toLowerCase()] = curr;
            }

            return acc;
        }, obj);
        
        return obj;
    }

    get classesWithCategoriesForChecking() {
        return [...this.assetClassesWithCategoriesForSync.filter(acwc => acwc.id === 1), ...this.liabilityClassesWithCategoriesForSync]
    }

    get classesWithCategoriesForAssetsWithoutChecking() {
        return this.assetClassesWithCategoriesForSync.filter(acwc => acwc.id !== 1);
    }

    get availableClassesForSync() {
        return [...this.assetClassesWithCategoriesForSync, ...this.liabilityClassesWithCategoriesForSync]
    }

    get classesWithCategoriesForOther() {
        return [...this.classesWithCategoriesForAssetsWithoutChecking, ...this.liabilityClassesWithCategoriesForSync.filter(acwc => acwc.id === 10)]
    }

    get manualItemsCount() {
        return this.classes.filter(cls => ![1, 11].includes(cls.id)).flatMap(cls => cls.items.filter(i => !i.isConnected && !i.isClosed && ![39].includes(i.categoryId))).length;
    }

    get customClassObj() {
        return this.customClasses.reduce(
            (acc, curr) => ((acc[curr.id] = curr), acc), {} /* eslint-disable-line no-sequences*/
        );
    }

    get customSubClassesObj() {
        return this.customClasses.flatMap(cls => cls.customSubClasses).reduce(
            (acc, curr) => ((acc[curr.id] = curr), acc), {} /* eslint-disable-line no-sequences*/
        );
    }

    get sortedCategories() {
        return this.classes.flatMap(cls => {
            const classCategories = this.categories.filter(cat => cat.classId === cls.id);
            return classCategories;
        })
    }

}