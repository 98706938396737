import moment from "moment";
import { translations } from "../../../../../../../common/translations/translations.en";

const { IRR_DESCRIPTION } = translations.common.item_overview;

export const businessEquityOverviewData = {
    value: {title:'Current asset worth', subTitle: 'This will be reflected in your net worth'},
    stats : [
        {title:'Shares', subTitle : 'Current share amount' , dataKey: 'sharesPurchased' , type: 'number',
        childs:[
            {title:'Current PPS', subTitle : 'Updated price per share' , dataKey: 'pricePerShare' },
            {title:'Purchase PPS', subTitle : 'Average price per share' , dataKey: 'avgPricePerShare' },
        ]
        },
        {title:'Purchase PPS', subTitle : 'Average price per share' , dataKey: 'avgPricePerShare' },
        {title:'Total Cash Invested', subTitle : 'Including all expenses' , dataKey: 'contributions', multipleValues: true  ,
        childs:[
            {title:'Net exposure', subTitle : 'Cash in risk' , dataKey: 'cashInRisk' },
        ]},
        {title:'Total cash received', subTitle : '' , dataKey: 'distributions' , multipleValues: true },
        
        // {title:'Remaining Time', subTitle : 'End Date: ' , dataKey: '' , type: 'date'}
    ],
    visuals : []
}

export const newBusinessEquityOverviewData = {
    value: {title:'Current asset worth', subTitle: 'This will be reflected in your net worth'},
    stats : [
        {title:'PPS', dataKey: 'pps' ,  multipleValues: true , digits:4  },
        {title:'Number of shares',  dataKey: 'number_of_shares', type: 'number' ,  multipleValues: true },
        {title:'Total Cash Invested', dataKey: 'contributions', multipleValues: true  },
        {title:'Multiplier', type:'perc' , dataKey: 'moic' , multipleValues: true },
        
        // {title:'Total cash received', subTitle : '' , dataKey: 'distributions' , multipleValues: true },
        
        // {title:'Remaining Time', subTitle : 'End Date: ' , dataKey: '' , type: 'date'}
    ],
    visuals : []
}

export const businessOptionsOverviewData = {
    value: {title:'Current asset worth', subTitle: 'This will be reflected in your net worth'},
    stats : [
        {title:'Total number of options', subTitle : 'The total amount of options accumulated to date' , dataKey: 'numberOfShares' , type: 'number',
        childs:[
            {title:'Current PPS', subTitle : `The company's most updated price per share` , dataKey: 'pricePerShare' },
            {title:'Exercise / Strike PPS', subTitle : 'The price per share you will pay to exercise your options' , dataKey: 'exercisePricePerShare' },
        ]},
        {title:'Remaining Time', dataKey: 'remainingMonths', type: 'number',
        valueFn: (itemData) => { return itemData.remainingMonths + (itemData.remainingMonths === 1 ? ' Month' : ' Months')},
        subTitleFn : (itemData) => {
            return itemData.endDate ? 'Full vesting date: ' + moment(itemData.endDate).format('MMM, yyyy') : 'Missing Data: Grant date & Time to maturity'
        }  ,  
        // childs:[
            //     {title:'Next Vesting', subTitle : `` , dataKey: 'nextVesting', type: 'date' },
            // ]
        },
        {},
        {},
    ],
    visuals : []
}

export const businessTraditionalOverviewData = {
    value: {title:'Current asset worth', subTitle: 'This will be reflected in your net worth'},
    stats : [
        {title:'ROI', subTitle : 'roi' , dataKey: 'roi' , type: 'perc', multipleValues:true , description: 'Return on Investment (ROI) represents the percentage return based on asset value and distributions relative to total contributions.' },
        {title:'IRR', subTitle : 'Expected annual increase in value' , dataKey: 'xirr', description: IRR_DESCRIPTION, type: 'perc', multipleValues:true, hasMetricBars: true },
        {title:'Total cash invested', subTitle : 'Including all expenses' , dataKey: 'contributions', multipleValues: true},
        {title:'Total cash received' ,dataKey: 'distributions' , multipleValues: true },
    ],
    visuals : []
}