import styled from "@emotion/styled";
import { flexCenter } from "../../../../common/styles/combos.styles";
// import { colors, views } from "../../../common/styles/theme.styles";

export const AssistanceMobileHeader = styled.div`
    position: ${props=>props.isHeaderFixed ? `fixed` : `relative`};
    top: 0;
    background-color: #111213;
    z-index: 2;
    width: 100%;
    min-height: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 8px 0 24px;
    ${flexCenter}
    justify-content: flex-start;
    gap: 8px;
`;

export const MobileHeaderTitle = styled.div`
    font-weight: 450;
    font-size: 18px;
    color: #fff;
`;

export const HeaderMoreMenu = styled.div`
    ${flexCenter}
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: ${props=>props.isMoreDrawerOpen ? '#222324' : 'transparent'};
    cursor: pointer;
    margin-left: auto;
    svg{
        * {
            fill: #fff;
        }
    }
`;


export const MobilePageWrapper = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    background-color: #111213;
    min-height: calc(100% - ${props=>props.isWithTopBanner ? '176px' : '128px'});
    // padding:0 24px;
    // justify-content: center;
    // align-items: center;
    h2{
        color: #fff;
    }
    padding-bottom: ${props=>props.isMobileFooterHidden ? '0px' : '64px'};
    
`;
