import { Player } from "@lottiefiles/react-lottie-player";
import { Tooltip } from 'antd';
import magicBoxAiAnimatedIconData from '../assets/magic-box-animated-icon-gray.json'
import styled from "@emotion/styled/macro";

export const MagicBoxAnimatedIconGrey = (props) => {
  const width = props?.width || '24px';
  const margin = props?.margin || '0';
  const display = props?.display || 'block';

  const tooltipText = 'The data in your portifolio will be updated within 24 hours, and you will be notified by email once the process has been completed.';
  return (
    <Tooltip placement={'top'} overlayInnerStyle={{ borderRadius: '6px', background: 'rgba(0, 0, 0, 0.9)' }} title={tooltipText}>
      <MagicBoxAnimatedIconStyled fill={props.fill}>
        <Player
          loop={true}
          src={magicBoxAiAnimatedIconData}
          speed={1}
          autoplay
          style={{ width: width, margin: margin, display: display }}
        />
      </MagicBoxAnimatedIconStyled>
    </Tooltip>
  );
};

const MagicBoxAnimatedIconStyled = styled.div`
  g {
    path {
      fill: ${({ fill }) => fill};
    }
  }
    
  display: flex;
  height: 100%;
  align-items: center;
`;