import { AutoComplete } from "antd";
import { Observer } from 'mobx-react';
import React from 'react';
import { useStore } from '../../../../../modules/app/data/root.store';
import { navigateToUrl } from '../../../../utils/navigation.utils';
import { useHistory } from "react-router-dom";
import { ContainerAutocompleteWrapper, HoverActionSvgWrapper } from "./ContainerAutocomplete.styles";
import { ModifiedTooltip } from "../../../ModifiedTooltip/ModifiedTooltip";
import { ROUTES } from "../../../../constants/routes.const";

export function ContainerAutocomplete({ inputKey, handleChange, disabled, value, scrollContainerId, withHoverAction }) {
    const { metadataStore, filtersStore, itemStore } = useStore();
    const history = useHistory()

    const applyFilter = () => {
        filtersStore.handleClearFilterSelections();
        filtersStore.handleSelectContainer(itemStore.item.container);
        filtersStore.setAppliedFiltersFromSelection();
        const urlParams = filtersStore.setAppliedFiltersFromSelection();
        navigateToUrl(history, ROUTES.wealthOverview.url + `?filters=${urlParams}`, false, true);
    }

    return (<Observer>{() => (
        <ContainerAutocompleteWrapper withHoverAction={withHoverAction}>
            <AutoComplete value={value} options={metadataStore.sortedContainers}
                disabled={disabled} filterOption={true}
                onChange={(val) => { handleChange(inputKey, val) }}
                getPopupContainer={() => scrollContainerId ? (document.getElementById(scrollContainerId) || document.body) : document.body} />
            {withHoverAction ?
                <HoverActionSvgWrapper onClick={applyFilter}>
                    <ModifiedTooltip title={'Go to account page'}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <HoverActionSvg />
                        </div>
                    </ModifiedTooltip>
                </HoverActionSvgWrapper> : null}
        </ContainerAutocompleteWrapper>
    )}</Observer>)

}

const HoverActionSvg = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3" y="2" width="24" height="24" rx="12" fill="#F1F3F5" />
            <path d="M12.6785 10.429C12.6785 10.429 17.5533 10.429 19.7615 10.429C19.7615 12.6372 19.7615 17.512 19.7615 17.512" stroke="#1A1B1D" strokeWidth="1.71428" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.7145 19.4802L19.4526 10.7421" stroke="#1A1B1D" strokeWidth="1.71429" strokeLinecap="round" />
        </svg>

    )
}