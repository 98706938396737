
import { ChatIcon, ChatTimeIcon, GraphIcon } from '../assets/icons';
import { AssistanceEmptyStateBox, AssistanceEmptyStateBoxContainer, AssistanceEmptyStateBoxContent, AssistanceEmptyStateBoxIconWrapper, AssistanceEmptyStateBoxTitle, AssistanceEmptyStateWrapper } from './Assistance.styles';

export const AssistanceEmptyState = ({isDemo}) => {
    return (
        <AssistanceEmptyStateWrapper>
            <AssistanceEmptyStateBoxContainer>
                
                <AssistanceEmptyStateBox>
                    <AssistanceEmptyStateBoxIconWrapper>
                        <GraphIcon />
                    </AssistanceEmptyStateBoxIconWrapper>
                    <AssistanceEmptyStateBoxTitle>Uncover insights</AssistanceEmptyStateBoxTitle>
                    <AssistanceEmptyStateBoxContent>{isDemo ? 'See how AI analyzes trends and performance in this Demo portfolio.' : 'Explore trends and performance across your portfolio'}</AssistanceEmptyStateBoxContent>
                </AssistanceEmptyStateBox>

                <AssistanceEmptyStateBox>
                    <AssistanceEmptyStateBoxIconWrapper>
                        <ChatIcon />
                    </AssistanceEmptyStateBoxIconWrapper>
                    <AssistanceEmptyStateBoxTitle>Ask questions</AssistanceEmptyStateBoxTitle>
                    <AssistanceEmptyStateBoxContent>{isDemo ? 'Ask about any financial topic from markets to investment strategies.' : 'Get answers to your financial questions, from market trends to strategies.'}</AssistanceEmptyStateBoxContent>
                </AssistanceEmptyStateBox>
                
                <AssistanceEmptyStateBox>
                    <AssistanceEmptyStateBoxIconWrapper>
                        <ChatTimeIcon />
                    </AssistanceEmptyStateBoxIconWrapper>
                    <AssistanceEmptyStateBoxTitle>Save time</AssistanceEmptyStateBoxTitle>
                    <AssistanceEmptyStateBoxContent>No more digging through data. Get instant insights and answers in seconds.</AssistanceEmptyStateBoxContent>

                </AssistanceEmptyStateBox>
            </AssistanceEmptyStateBoxContainer>
        </AssistanceEmptyStateWrapper>
    )
};