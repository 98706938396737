import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../common/styles/combos.styles';
import { MenuItemWrapperWealthPie, MenuWrapperWealthPie } from '../../../common/components/DropDownMenu/DropDownMenu.styles';
import { colors } from '../../../common/styles/theme.styles';
// import { colors, shadows, sizes, views } from '../../../common/styles/theme.styles';

export const AllocateByMenuButton = styled.div`
    color: #9097A3;
    font-size:14px;
    font-weight: normal;
    cursor: pointer;
    ${flexCenter};
    pointer-events:auto;
    svg {
        path {
            stroke:#9097A3;
        }
    }
    ${props=>props.isActive ? `
        background-color: #80808045;
    ` : `
    
    `}
`;

export const ArrowIconWrapper = styled.div`
    ${flexCenter};
    margin-left:8px;
    svg {
        transition: transform 0.3s;
        transform: rotate(${props=>props.isOpen ? '180' : '0'}deg);
    }
`;


export const AllocatedByMenuWrapper = styled(MenuWrapperWealthPie)`
    width: 260px;
    padding: 8px;
    gap:4px;
    display:flex;
    flex-direction:column;
`;

export const AllocatedByMenuItemWrapper = styled(MenuItemWrapperWealthPie)`
    justify-content: flex-start;
    border-radius: 6px;
    height: 40px;
    padding: 0 6px 0 16px;
    :hover{
        background-color: #303236;
        background-image: none;
    }
    ${props=>props.isSelected ? `
        background-color: ${colors.darkGray2};
    ` : `
    
    `}
`;

export const PinIconWrapper = styled.div`
    height:28px;
    width:28px;
    border-radius: 30px;
    background-color: none;
    ${flexCenter};
    display:none;
    ${AllocatedByMenuItemWrapper}:hover & {
        display:flex;
    }
    ${props=>props.isSelected ? `
        display:flex;
    ` : `
        :hover{
            background-color: rgba(255, 255, 255, 0.10);
        }    
    `}
    svg{
        path{
            fill-opacity:${props=>props.isSelected ? '1' : '0.3'};   
        }
    }
`;

export const AllocatedByMenuItemText = styled.div`
    flex:1;
    padding-left: 8px;
`;

export const InstitutionLogoWrapper = styled.div`
  ${flexCenter}
  height:32px;
  width:32px;
  border-radius: 23px;
  img{
      height:32px;
      width:32px;
  }
`;

