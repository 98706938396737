import { ModalCloseIcon } from "../../../../assets/icons/common/common-icons";
import { BaseModal } from "../../../../common/components/BaseModal/BaseModal";
import { useStore } from "../../../app/data/root.store";
import { EraseIcon } from "../../../wealth/pages/asset/Icons/icons";
import { BulkEditErrorIcon } from "../../../wealth/pages/overview/components/icons/icons";
import { ActionButton, BulkEditButtonText, DeleteComponent, DeleteComponentChild, DeleteComponentIcon, DeleteComponentMainText, ModalCloseButtonWrapper, ModalContent, ModalFooter, ModalHeader, WhiteEraseIcon } from "../../../wealth/pages/overview/components/items-list/BulkEditModal/BulkEditModal.styles";

export const DeepAnalysisReviewBulkEditDeleteModal = (props) => {
    const { userStore: { isWelcome } } = useStore();
    const { onClose, onDelete, count } = props;

    const pluralSuffix = (1 === count) ? '' : 's';
    const message = `Are you sure you want to delete ${count} item${pluralSuffix} and all its financial data?`;
    const buttonText = `Delete ${count} item${pluralSuffix}`;

    return (
        <BaseModal width={'600px'} height={'auto'} borderRadius={8} onBackDropClick={onClose} noSidebarMargin={isWelcome}>
            <ModalHeader noBorderBottom>
                <ModalCloseButtonWrapper onClick={onClose}>
                    <ModalCloseIcon />
                </ModalCloseButtonWrapper>
            </ModalHeader>
            <ModalContent padding={"12px 24px"}>
                <DeleteComponent>
                    <DeleteComponentChild>
                        <DeleteComponentIcon>
                            <BulkEditErrorIcon />
                        </DeleteComponentIcon>
                        <DeleteComponentMainText>{message}</DeleteComponentMainText>
                    </DeleteComponentChild>
                </DeleteComponent>
            </ModalContent>
            <ModalFooter padding={"12px 24px"}>
                <ActionButton onClick={onDelete} backgroundColor={"var(--Color-System-Error, #F00)"} whiteText withIcon isDelete>
                    <WhiteEraseIcon>
                        <EraseIcon />
                    </WhiteEraseIcon>
                    <BulkEditButtonText>
                        {buttonText}
                    </BulkEditButtonText>
                </ActionButton>
            </ModalFooter>
        </BaseModal>
    );
};