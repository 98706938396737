import { BaseModal } from "../../../common/components/BaseModal/BaseModal";
import { AssistanceHeroLogoText, AssistanceHeroLogoWrapper, ModalBgWrapper, WelcomeAssistanceButtonWrapper, WelcomeFooterText, WelcomeFooterTextBoldParts, WelcomeMainText, WelcomeMainTextBoldParts, WelcomeModalBody, WelcomeModalButton, WelcomeModalButtonText, WelcomeModalButtonWrapper, WelcomeModalCloseButton, WelcomeModalCloseButtonWrapper, WelcomeModalContent, WelcomeModalFooter, WelcomeModalHeader, WelcomeModalSubtitle, WelcomeModalTitle } from "./Assistance.styles";
import { VyzerAIAssistanceIcon, WelcomeCloseIcon, WelcomeRightArrow } from "../assets/icons";

export const AssistanceWelcomeModal = ({ onClose }) => {
    return (
        <BaseModal width={'724px'} height={'554px'} borderRadius={'12'}>
            <ModalBgWrapper>
                <WelcomeModalHeader>
                    <WelcomeAssistanceButtonWrapper>
                        <AssistanceHeroLogoWrapper>
                            <VyzerAIAssistanceIcon />
                            <AssistanceHeroLogoText>{'AI-BETA'}</AssistanceHeroLogoText>
                        </AssistanceHeroLogoWrapper>
                    </WelcomeAssistanceButtonWrapper>
                    <WelcomeModalCloseButtonWrapper>
                        <WelcomeModalCloseButton onClick={onClose}>
                            <WelcomeCloseIcon />
                        </WelcomeModalCloseButton>
                    </WelcomeModalCloseButtonWrapper>
                </WelcomeModalHeader>
                <WelcomeModalBody>
                    <WelcomeModalBody>
                        <WelcomeModalContent>
                            <WelcomeModalTitle />
                            <WelcomeModalSubtitle />
                            <WelcomeMainText>
                                Ask about <WelcomeMainTextBoldParts>your portfolio</WelcomeMainTextBoldParts>, discover <WelcomeMainTextBoldParts>performance trends</WelcomeMainTextBoldParts>, or learn about financial topics.<br /> From market strategies to asset allocation to understanding your exposure
                            </WelcomeMainText>
                        </WelcomeModalContent>
                        <WelcomeModalFooter>
                            <WelcomeModalButtonWrapper>
                                <WelcomeModalButton onClick={onClose}>
                                    <WelcomeModalButtonText />
                                    <WelcomeRightArrow />
                                </WelcomeModalButton>
                            </WelcomeModalButtonWrapper>
                            <WelcomeFooterText>
                                This feature is in <WelcomeFooterTextBoldParts>Beta</WelcomeFooterTextBoldParts> — your feedback helps improve it for you.
                            </WelcomeFooterText>
                        </WelcomeModalFooter>
                    </WelcomeModalBody>
                </WelcomeModalBody>
            </ModalBgWrapper>
        </BaseModal>
    );
}