import {ReactComponent as CalendarSvg} from './calendar.svg';
import {ReactComponent as WhiteCalendarSvg} from './white-calendar.svg';
import {ReactComponent as EditPencilSvg} from './edit-pencil.svg';
import {ReactComponent as ManageMyWealthSvg} from './manage-my-wealth.svg';
import {ReactComponent as ManageOthersWealthSvg} from './manage-others-wealth.svg';
import {ReactComponent as QuestionMarkSvg} from './question-mark.svg';
import {ReactComponent as StrategyAndPlanningSvg} from './strategy-and-planning.svg';
import {ReactComponent as ToolsToManageWealthSvg} from './tools-to-manage-wealth.svg';
import {ReactComponent as WalletSvg} from './wallet.svg';
import {ReactComponent as WhereAndHowToInvestSvg} from './where-and-how-to-invest.svg';
import {ReactComponent as WhiteArrowRightIcon} from './white-arrow-right-icon.svg';
import {ReactComponent as MagnifierSvg} from './magnifier.svg';
import {ReactComponent as ThreeDBox} from './3d-box.svg';
import {ReactComponent as VillageSvg} from './village.svg';
import {ReactComponent as MarshmallowsSvg} from './marshmallows.svg';
import {ReactComponent as RightArrow} from './right-arrow.svg';

export const questionnaireIcons = {
  RightArrow,
  WhiteCalendarSvg,
  CalendarSvg,
  EditPencilSvg,
  ManageMyWealthSvg,
  ManageOthersWealthSvg,
  QuestionMarkSvg,
  StrategyAndPlanningSvg,
  ToolsToManageWealthSvg,
  WalletSvg,
  WhereAndHowToInvestSvg,
  WhiteArrowRightIcon,
  MagnifierSvg,
  ThreeDBox,
  VillageSvg,
  MarshmallowsSvg,
}