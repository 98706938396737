import React from 'react';

const ThumbDown = (props) => (
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
        d="M18.5309 17.8299L16.483 22.4771C16.343 22.7949 16.0284 23 15.6811 23L14.4124 23C13.9284 23 13.5361 22.6077 13.5361 22.1237L13.5361 18.1804C13.5361 17.9868 13.3792 17.8299 13.1856 17.8299L8.24466 17.8299C7.10448 17.8299 6.26788 16.7584 6.54441 15.6523L7.96772 9.959C8.20178 9.02277 9.04299 8.36598 10.008 8.36598L17.3164 8.36598C17.9161 8.36598 18.4279 8.79955 18.5265 9.39109L18.5309 9.41752M18.5309 17.8299L22.1237 17.8299C22.6077 17.8299 23 17.4376 23 16.9536L23 9.22525C23 8.74799 22.6181 8.35849 22.1409 8.34913L19.4244 8.29587C18.9338 8.28625 18.5309 8.68131 18.5309 9.17199L18.5309 9.41752M18.5309 17.8299L18.5309 9.41752"
        stroke="#585D66" stroke-width="1.25" />
</svg>
);

export default ThumbDown;