import debounce from 'lodash/debounce';
import { Observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ItemRowLoader } from '../../../../../modules/add-item/components/steps/addItemInstitutionStep.styles';
import { searchInstitutions } from '../../../../../modules/add-item/data/add-item.service';
import { banksDefaultInstitutions, investorsDefaultInstitutions } from '../../../../../modules/add-item/data/create-item-institutions.const';
import { useStore } from '../../../../../modules/app/data/root.store';
import { PlaidLink } from '../../../../../modules/connected-institutions/components/plaid-link/PlaidLink';
import { SaltedgeLink } from '../../../../../modules/connected-institutions/components/saltedge-link/SaltedgeLink';
import { errorNotification, infoNotification } from '../../../../../modules/layout/components/Notifications/notifications';
import { navigateToUrl } from '../../../../utils/navigation.utils';
import { isNullOrUndefined } from '../../../../utils/object.utils';
import { DotsLoader } from '../../../Loaders/DotsLoader.styles';
import { LogoLoader } from '../../../LogoLoader/LogoLoader';
import { AutoSyncPopoverContent, AutoSyncPopoverContentRow, AutoSyncPopoverWrapper, AutoSyncSubTitle, AutoSyncTitle, CloseIconWrapper, InstitutionAutoCompleteWrapper, InstitutionsResultsListWrapper, ListAndLoaderWrapper, LoadMoreLoaderWrapper, OnClickPropagationWrapper, SearchInput, SearchInputAndResultsWrapper, SearchingLoaderWrapper, } from './InstitutionAutoComplete.styles';
import { InstitutionAutoCompleteResultRow } from './InstitutionAutoCompleteResultRow';
import { AkoyaLink } from '../../../../../modules/connected-institutions/components/akoya-link/AkoyaLink';

const inputSearchDebounce = debounce((cb) => { cb() }, 1000);
const scrollBottomDebounce = debounce((cb) => { cb() }, 200);


export const InstitutionAutoComplete = (props) => {
  const { value, onChange, classId, showOnlyManual, withAutoSyncSuggestion , withAutoSyncSuggestionOnlyForVip, disabled, isDesktopView } = props;
  const [searchInput, setSearchInput] = useState(value?.text || '');
  const [searchMeta, setSearchMeta] = useState(null);
  const [isLoadingMoreInstitutions, setIsLoadingMoreInstitutions] = useState(false);
  const [isSearchingInstitutions, setIsSearchingInstitutions] = useState(false);
  const [institutionSearchResults, setInstitutionSearchResults] = useState(showOnlyManual ? [] : (!value?.text ? ([1,3].includes(classId) ? banksDefaultInstitutions : investorsDefaultInstitutions) : []));
  const [isFocusingInput, setIsFocusingInput] = useState(false);
  const [isShowAutoSync, setIsShowAutoSync] = useState(value?.instObj ? true : false);
  const [isShowAlreadyConnected, setIsShowAlreadyConnected] = useState(value?.instObj ? true : false);
  const [isSelectedInstAlreadyConnected, setIsSelectedInstAlreadyConnected ] = useState(false);
  const { metadataStore, createItemStore, userStore } = useStore();
  const history = useHistory();
  const listRef = useRef();
  const plaidErrorRef = useRef(null);
  const plaidDoneRef = useRef(false);

  const searchForInst = async (str) => {    
    if (str === '') {
      const defaultInsts = classId === 1 ? banksDefaultInstitutions : investorsDefaultInstitutions;
      setSearchResults('', null, defaultInsts);
      setIsSearchingInstitutions(false);
    } else {
      setIsSearchingInstitutions(true);
      try {
        if (!showOnlyManual) {
          const data = await searchInstitutions(str);
          setSearchResults(str, data.meta, data.items);
        } else {
          setSearchResults(str, null, []);
        }
      }
      //catch (e) {} 
      finally {
        setIsSearchingInstitutions(false);
      }
    }
  }

  const setSearchResults = (input, meta, items) => {   
    console.log(items);
         
    const inputLowerCased = input.toLowerCase();

    setSearchInput(inputLowerCased);
    setSearchMeta(meta);

    const userInstitutions = (inputLowerCased ? metadataStore.sortedInstitutions.filter(item => item.name.toLowerCase().includes(inputLowerCased)) : metadataStore.sortedInstitutions).map(inst => ({
      ...inst,
      isManual: true
    }));

    const dbInstitutions = items.filter(i => !userInstitutions.find(ui => ui.guid === i.guid));
    
    setInstitutionSearchResults([
      ...userInstitutions,
      ...((userInstitutions.length > 0 && items.length > 0) ? [{ isSeparator: true }] : []),
      ...dbInstitutions]);
  }

  const checkForLoadingMore = async () => {
    if (searchMeta !== null && searchMeta.currentPage < searchMeta.totalPages && !showOnlyManual) {
      setIsLoadingMoreInstitutions(true);
      try {
        const data = await searchInstitutions(searchInput, searchMeta.currentPage + 1, searchMeta.itemsPerPage);
        setSearchResults(searchInput, data.meta, [...institutionSearchResults.filter(i => i.id), ...data.items]);
      }
      //catch (e) {} 
      finally {
        setIsLoadingMoreInstitutions(false);
      }
    }
  }

  const handleChange = (e) => {
    setIsShowAutoSync(false);
    setIsShowAlreadyConnected(false);
    onChange?.({ inst: null, instObj: null, text: e.target.value });
    setIsSearchingInstitutions(true);
    inputSearchDebounce(() => {
      searchForInst(e.target.value);
    })
  }

  const handleFocusIn = () => {
    setSearchResults(searchInput, searchMeta, institutionSearchResults.filter(i => i.id));
    setIsFocusingInput(true);
    setIsShowAutoSync(!isNullOrUndefined(value?.inst));
    setIsShowAlreadyConnected(!isNullOrUndefined(value?.inst));
  }
  const handleInstClick = (inst) => {
    
    onChange?.({ inst: inst.guid, instObj: inst , text: inst.name });
    
    if (!inst.isManual){
      setIsShowAutoSync(true);
      const isExist = metadataStore.checkInstitutionExistence( inst.institutionId);
      setIsShowAlreadyConnected(isExist ? true : false);
      setIsSelectedInstAlreadyConnected(isExist ? true : false);
    }
    
    
  }

  const handleAutoSyncClick = (inst) => {
    createItemStore.setInstitutionStep('bank');
    navigateToUrl(history, `?inst=${inst.name}`);
    if (inst.providerName === 'vip') {
      if (userStore.isVipSyncInstitutionLimited){
        createItemStore.setShowAddVipSyncLimitModal(true);
      } else {
        createItemStore.setSelectedVipSyncInstitution(inst);
        createItemStore.setVipSyncStep();
      }
    }
    setIsShowAutoSync(false);
    setIsShowAlreadyConnected(false);
  }

  const handleManageSynced = (inst) => {
    createItemStore.setInstitutionStep('bank');
    createItemStore.setShowModal(false);
    const { institutionId, providerName } = inst;
    const existingInstitution = metadataStore.checkInstitutionExistence(institutionId);
    navigateToUrl(history, `/profile/accounts?inst=${existingInstitution.id}&p=${providerName}`);
  }

  const onScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        scrollBottomDebounce(() => {
          checkForLoadingMore();
        })
      }
    }
  };

  const plaidConnectSuccess = (data) => {
    if (data.error) {
      if (data.error === 'Institution exist') {
        if (plaidDoneRef.current === false) {
          plaidErrorRef.current = "INST_EXIST";
        } else {
          infoNotification('Institution already connected');
          plaidDoneRef.current = false;
        }
      } else {
        errorNotification('Something went wrong');
      }
    }
    else {
      createItemStore.setPlaidAccounts(data.accounts, data.institution, metadataStore);
    }
    createItemStore.setIsPlaidInProcess(false);
  }

  const saltedgeConnectSuccess = (data) => {
    if (data.error) {
      errorNotification('Something went wrong');
    }
    else {
      const { accounts, ...instProp } = data;
      const { institutionName: name, id, logoURL } = instProp
      createItemStore.setSaltedgeAccounts(accounts, { name, id, logoURL }, metadataStore);
    }
  }

  const akoyaConnectSuccess = (accounts) => {
    createItemStore.setAkoyaAccounts(accounts, metadataStore);
  }

  

  const plaidDone = () => {
    if (plaidErrorRef.current === "INST_EXIST") {
      infoNotification('Institution already connected');
      plaidErrorRef.current = null;
    }
    plaidDoneRef.current = true;
    createItemStore.setIsPlaidInProcess(false);
  }

  const onSaltEdgeLoad = (inst, isInstLoading) => {
    createItemStore.setIsSaltedgeInProcess(isInstLoading);
  }

  const onPlaidLoad = (inst, isInstLoading) => {
    createItemStore.setIsPlaidInProcess(isInstLoading);
  }

  return (<Observer>{() => (
    <InstitutionAutoCompleteWrapper>
      <SearchInputAndResultsWrapper>
        <SearchInput type="text" //autoFocus
          disabled={disabled}
          value={value?.text} onChange={handleChange}
          onFocus={() => { handleFocusIn() }}
          onBlur={() => setIsFocusingInput(false)}
          maxLength={100}
        //ref={searchInputRef} 
        // value={transactionsStore.filtersValues.str} onChange={(e)=>{transactionsStore.setFilterStr(e.target.value)}} 
        />
        {withAutoSyncSuggestion && (!withAutoSyncSuggestionOnlyForVip || institutionSearchResults.find(inst => inst.guid === value.inst)?.providerName === 'vip' ) && isShowAutoSync && !isSelectedInstAlreadyConnected ? <AutoSyncPopoverWrapper>
          <AutoSyncPopoverContent>
            <AutoSyncPopoverContentRow>
              <AutoSyncTitle>Auto-sync this account</AutoSyncTitle>
              <AutoSyncSubTitle>Get real time updates for this account</AutoSyncSubTitle>
              <CloseIconWrapper onClick={() => { setIsShowAutoSync(false) }}><CloseIconSvg /></CloseIconWrapper>
            </AutoSyncPopoverContentRow>
            {institutionSearchResults.find(inst => inst.guid === value.inst)?.providerName === 'plaid' ?
              <PlaidLink onSuccess={plaidConnectSuccess} onDone={plaidDone} onConnectionStart={() => onPlaidLoad(institutionSearchResults.find(inst => inst.guid === value.inst), true)} onExit={plaidDone}>
                <OnClickPropagationWrapper>
                  <InstitutionAutoCompleteResultRow searchStr={''} inst={institutionSearchResults.find(inst => inst.guid === value.inst)} isAutoSync />
                </OnClickPropagationWrapper>
              </PlaidLink>
              : institutionSearchResults.find(inst => inst.guid === value.inst)?.providerName === 'saltEdge' ?
                <SaltedgeLink providerCode={institutionSearchResults.find(inst => inst.guid === value.inst).institutionCode} onSuccess={saltedgeConnectSuccess} waitingForResponseCB={(isLoading) => { onSaltEdgeLoad(institutionSearchResults.find(inst => inst.guid === value.inst), isLoading) }} loadingComp={
                  <ItemRowLoader ><DotsLoader black /></ItemRowLoader>
                } >
                  <OnClickPropagationWrapper>
                    <InstitutionAutoCompleteResultRow searchStr={''} inst={institutionSearchResults.find(inst => inst.guid === value.inst)} isAutoSync />
                  </OnClickPropagationWrapper>
                </SaltedgeLink>
                  : institutionSearchResults.find(inst => inst.guid === value.inst)?.providerName === 'akoya' ?
                  <AkoyaLink connectorId={institutionSearchResults.find(inst => inst.guid === value.inst).institutionId} 
                    onSuccess={akoyaConnectSuccess} waitingForResponseCB={(isLoading) => { onSaltEdgeLoad(institutionSearchResults.find(inst => inst.guid === value.inst), isLoading) }} loadingComp={
                    <ItemRowLoader ><DotsLoader black /></ItemRowLoader>
                  } >
                    <OnClickPropagationWrapper>
                      <InstitutionAutoCompleteResultRow searchStr={''} inst={institutionSearchResults.find(inst => inst.guid === value.inst)} isAutoSync />
                    </OnClickPropagationWrapper>
                  </AkoyaLink>
                : <InstitutionAutoCompleteResultRow searchStr={''} inst={value.instObj} handleItemClick={handleAutoSyncClick} isAutoSync />}

          </AutoSyncPopoverContent>
        </AutoSyncPopoverWrapper> :
          withAutoSyncSuggestion && isSelectedInstAlreadyConnected && isShowAlreadyConnected ?
          <AutoSyncPopoverWrapper>
            <AutoSyncPopoverContent>
              <AutoSyncPopoverContentRow>
                <AutoSyncSubTitle>You already have accounts from {value.instObj.name} in your synced account list.</AutoSyncSubTitle>
                <CloseIconWrapper onClick={() => { setIsShowAlreadyConnected(false) }}><CloseIconSvg /></CloseIconWrapper>
              </AutoSyncPopoverContentRow>
              <InstitutionAutoCompleteResultRow searchStr={''} inst={value.instObj} handleItemClick={handleManageSynced} isAlreadyConnected />
            </AutoSyncPopoverContent>
          </AutoSyncPopoverWrapper> :
           isFocusingInput && (institutionSearchResults.length > 0 || isSearchingInstitutions) && <ListAndLoaderWrapper>
              {/* <ListAndLoaderWrapper> */}
            <InstitutionsResultsListWrapper ref={listRef} onScroll={onScroll}>
              {institutionSearchResults.map((inst, ind) => (
                // <InstitutionItemSwitcherWrapper key={inst.id}>
                //   {inst.providerName === 'plaid' &&
                //     <PlaidLink onSuccess={plaidConnectSuccess} onDone={plaidDone}>
                //       <InstitutionAutoCompleteResultRow value={value} inst={inst} />
                //     </PlaidLink>
                //   }
                //   {inst.providerName === 'saltEdge' &&
                //     <SaltedgeLink providerCode={inst.institutionCode} onSuccess={saltedgeConnectSuccess} loadingComp={
                //       <ItemRowLoader ><DotsLoader black /></ItemRowLoader>
                //     } >
                //       <InstitutionAutoCompleteResultRow value={value} inst={inst} />
                //     </SaltedgeLink>
                //   }
                // </InstitutionItemSwitcherWrapper>
                <InstitutionAutoCompleteResultRow key={ind} searchStr={value?.text} inst={inst} isSeparator={inst.isSeparator} handleItemClick={handleInstClick} isDesktopView={isDesktopView} />
              ))}
              {isLoadingMoreInstitutions && <LoadMoreLoaderWrapper>
                <LogoLoader wbg />
              </LoadMoreLoaderWrapper>}
            </InstitutionsResultsListWrapper>
            {isSearchingInstitutions && <SearchingLoaderWrapper >
              <LogoLoader wbg />
            </SearchingLoaderWrapper>}
          </ListAndLoaderWrapper>}
        {/* {
          value !== '' && isFocusingInput && <AddManuallyButtonWrapper onClick={() => { createItemStore.setCategoryStep({ name: value }) }}>
            <AddIconWrapper><PlusIcon /></AddIconWrapper>
            <AddButtonText>Add "{value}" Manually</AddButtonText>
          </AddManuallyButtonWrapper>
        } */}
        {process.env.NODE_ENV === 'development' && !value?.text && !showOnlyManual ? <div style={{position:'absolute', right:4,top:'50%',color:'red', transform:`translateY(-50%)`}}>PROD INSTITUTIONS</div> : null}
      </SearchInputAndResultsWrapper>
      {/* {
        value === '' &&
        <CleanSearchAddManuallyMessage onClick={() => { createItemStore.setCategoryStep() }}>
          <span className='visible-text'>Can't find your sponsor / operator ?</span>
          <span className='add-manually-text' >Add manually →</span>
        </CleanSearchAddManuallyMessage>
      } */}
    </InstitutionAutoCompleteWrapper>
  )}</Observer>)

  // return (
  //   <Observer>{() => (
  //     <InstitutionAutoCompleteWrapper>
  //       <DebounceSelect
  //         // defaultOpen
  //         // open={true}
  //         // allowClear
  //         getPopupContainer={() => scrollContainerId ? (document.getElementById(scrollContainerId) || document.body) : document.body}
  //         fetchOptions={fetchIntritutionList}
  //         onChange={(newValue) => setInputValue(newValue)}
  //         showSearch
  //         // placeholder={<SearchPlaceholderWrapper>Search your assets...</SearchPlaceholderWrapper>}
  //         optionFilterProp='label'
  //         suffixIcon={null}
  //         style={{ width: '100%' }}
  //         onSelect={(selected, option) => { }}
  //         // onSelect={(selected, option) => { handleItemClick(option.children.props.inst) }}
  //         // value={null}
  //         value={inputValue}
  //         // onSearch={(value) => { setInputValue(value); createItemStore.searchForInst(value); }}
  //         dropdownClassName='InstitutionAutoCompleteDropdown'
  //         // filterSort={handleSearchResultsFilter}
  //         listHeight={325}
  //       >
  //       </DebounceSelect>
  //       {/* <SearchIconWrapper hasSearchInput={inputValue.length > 0} ><SearchResultsIcon /></SearchIconWrapper> */}
  //     </InstitutionAutoCompleteWrapper>
  //   )}</Observer>)
}

// const DebounceSelect = ({ fetchOptions, debounceTimeout = 800, ...props }) => {
//   const [fetching, setFetching] = useState(false);
//   const [options, setOptions] = useState([]);
//   const { createItemStore } = useStore();
//   const fetchRef = useRef(0);
//   const { Option } = Select;

//   const debounceFetcher = useMemo(() => {
//     const loadOptions = (value) => {
//       fetchRef.current += 1;
//       const fetchId = fetchRef.current;
//       setOptions([]);
//       setFetching(true);
//       fetchOptions(value).then((newOptions) => {
//         if (fetchId !== fetchRef.current) {
//           // for fetch callback order
//           return;
//         }
//         setOptions(newOptions);
//         setFetching(false);
//       });
//     };

//     return debounce(loadOptions, debounceTimeout);
//   }, [fetchOptions, debounceTimeout]);

//   return (
//     <>
//       <Select
//         // open={true}
//         // labelInValue
//         optionLabelProp='label'
//         allowClear={true}
//         filterOption={false}
//         onSearch={debounceFetcher}
//         notFoundContent={fetching ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', width: '100%', height: '100%' }}><LogoLoader wbg /></div> : null}
//         // notFoundContent={fetching ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', width: '100%', height: '100%' }}><LogoLoader wbg /></div> : null}
//         {...props}

//       // options={options.map(opt=>opt.props.children)}
//       >
//         {options.map((inst, ind) => (
//           <Option key={ind} value={inst.id} label={inst.name}>
//             {/* <div>{inst.name}</div> */}
//             <InstitutionAutoCompleteResultRow inst={inst} inputValue={inst.inputValue} handleItemClick={inst.handleItemClick} />
//             {/* <InstitutionAutoCompleteResultRow item={inst} handleItemClick={inst.handleItemClick} inputValue={inst.inputValue} /> */}
//           </Option>

//         ))}

//       </Select>
//       {createItemStore.isInstitutionSupported && <SyncInstitutionButton><SyncedLightningIcon /> Sync</SyncInstitutionButton>}
//     </>
//   );
// } // Usage of DebounceSelect

// const fetchUserList = async (username) => {
//   const res = await fetch('https://randomuser.me/api/?results=5')
//   const resJSON = await res.json();
//   const toReturn = resJSON.body.results.map((user) => ({
//     label: `${user.name.first} ${user.name.last}`,
//     value: user.login.username,
//   }))
//   return toReturn;
//   // return fetch('https://randomuser.me/api/?results=5')
//   // .then((response) => response.json())
//   //   .then((body) =>
//   //     body.results.map((user) => ({
//   //       label: `${user.name.first} ${user.name.last}`,
//   //       value: user.login.username,
//   //     })),
//   //   );
// }

const CloseIconSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.30293 13.3939L13.374 6.32285" stroke="#9097A3" strokeWidth="1.33333" strokeLinejoin="round" />
      <path d="M13.3739 13.3937L6.30283 6.32265" stroke="#9097A3" strokeWidth="1.33333" strokeLinejoin="round" />
    </svg>
  )
}