import { LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react'
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal'
import { PrimaryButton } from '../../../../common/components/buttons/NewButtons.styles'
import { displayMoneyValue } from '../../../../common/utils/number-display.utils'
import { useStore } from '../../../app/data/root.store'
import { DeepAnalysisResultsRow } from './DeepAnalysisResultsRow'
import { DeepAnalysisInProgressPrompt, DeepAnalysisReviewResultContent, DeepAnalysisReviewResultFooter, DeepAnalysisReviewResultHeader, DeepAnalysisReviewResultHeaderRow, DeepAnalysisReviewResultHeaderTitle, DeepAnalysisReviewResultHero, DeepAnalysisReviewResultHeroBox, DeepAnalysisReviewResultHeroBoxTitle, DeepAnalysisReviewResultHeroBoxValue, DeepAnalysisReviewResultsWrapper, RowTitle } from './DeepAnalysisReviewResultModal.styles'
import { ReactComponent as ModalCloseIcon } from './assets/modal-close-icon.svg'
import { DeepAnalysisReviewDeleteModal } from './DeepAnalysisReviewDeleteModal'
import { ChatRow } from '../../../user-activation-flow/components/ChatRow/ChatRow';
import { AI_LOADING_ACCOUNT, AI_LOADING_MSG_WELCOME } from '../../data/magic-box-ai-store';
import { useTypewriter } from '../../hooks/useTypewriter';
import { useTrackEvent } from '../../../../hooks/useTrackEvent/useTrackEvent';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { docTypes } from '../DeepAnalysisSuccessModal/assets/docTypes';
import { DeepAnalysisWhatsNext } from './DeepAnalysisWhatsNext';
import { ItemSelectedCheckbox, ItemSelectedCheckboxWrapper } from '../../../wealth/pages/class/components/ClassPage.styles';
import { ItemSelectedIcon } from '../../../wealth/pages/overview/components/items-list/ItemsListView';
import { colors } from '../../../../common/styles/theme.styles';
import { DeepAnalysisReviewBulkEdit } from './DeepAnalysisReviewBulkEdit';

export const DeepAnalysisReviewResultModal = observer(({ onFlowStepChange }) => {
  const { uiStore, metadataStore, magicBoxAiStore, userStore } = useStore();
  const contentRef = useRef(null);
  const [editedRowIndex, setEditedRowIndex] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { trackEvent } = useAnalytics();
  const [isResultsReceived, setIsResultsReceived] = useState(false);

  const assetClasses = metadataStore.assetClassWithCategoriesForMagicBoxAi;
  const liabilityClasses = metadataStore.liabilityClassesWithCategoriesForMagicBoxAi;
  const relevantClasses = [...assetClasses, ...liabilityClasses];
  const { totals, processedLLMResult, llmResult } = magicBoxAiStore;
  const fileName = magicBoxAiStore.file?.originalName;

  useTrackEvent('Popup Viewed', {
    title: 'Magic Box AI deep analysis results',
    url: window.location.href
  })

  useEffect(() => {
    if (llmResult && !isResultsReceived) {
      setIsResultsReceived(true);
      trackEvent('Magic box ai results received', {
        url: window.location.href
      });
    }
  }, [llmResult, isResultsReceived, trackEvent]);

  const handleCategoryChange = (index, categoryId) => magicBoxAiStore.changeReviewedItemCategory(index, categoryId);

  const onScroll = () => {
    if (contentRef.current) {
      const { scrollTop } = contentRef.current
      setIsSticky(scrollTop > 8);
    }
  }

  const onDeleteItem = (itemForDelete) => {
    setEditedRowIndex(null);
    magicBoxAiStore.deleteReviewedItem(itemForDelete);
  }
  const onAddItems = async () => {
    setIsLoading(true);
    await magicBoxAiStore.addItems();
    setIsLoading(false);
  }

  const onClick = async () => {
    if (llmResult) {
      onAddItems();
      trackEvent('CTA Clicked', {
        cta_text: 'Add Items',
        location: isWelcome ? 'Magic box ai activation flow' : 'Magic box ai page',
        // destination: 'manual magic box',
        type: "Button",
        url: window.location.href
      })
      trackEvent('Magic box ai items added', {
        // cta_text: 'Add Items',
        // location: isWelcome ? 'Magic box ai activation flow' : 'Magic box ai page',
        // destination: 'manual magic box',
        // type: "Button",
        url: window.location.href
      })
    } else {
      trackEvent('CTA Clicked', {
        cta_text: isWelcome ? 'Go to sync bank' : 'Continue to explore',
        location: isWelcome ? 'Magic box ai activation flow' : 'Magic box ai page',
        // destination: 'manual magic box',
        type: "Button",
        url: window.location.href
      })
      if (isWelcome) {
        onFlowStepChange(1);
      } else {
        window.location.href = 'wealth';
      }
    }
  }

  const isWelcome = userStore.isWelcome;
  const onCloseModal = () => { setShowDeleteModal(true) };
  const onDeleteFile = async () => await magicBoxAiStore.deleteDocument();

  return (
    <BaseModal borderRadius={8} width={'960px'} customModalWrapperStyles={{ maxWidth: '960px', height: 'auto', maxHeight: 'min(calc(100% - 48px), 1000px)', position: 'relative' }} noSidebarMargin={isWelcome} >
      <div style={{ display: 'flex', flexDirection: 'column', }}>
        <DeepAnalysisReviewResultHeader>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <DeepAnalysisReviewResultHeaderTitle>Review the Magic Box AI initial results</DeepAnalysisReviewResultHeaderTitle>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <DeepAnalysisWhatsNext />
            <ModalCloseIcon style={{ cursor: 'pointer' }} onClick={onCloseModal} />
          </div>
        </DeepAnalysisReviewResultHeader>

        {llmResult ?
          <ReviewReadyResultHeader totals={totals} isFetchAnyRate={magicBoxAiStore.isFetchAnyRate} isIncognitoMode={uiStore.isIncognitoMode} /> :
          <ReviewLoadingHeader isWelcome={isWelcome} />
        }

        <ReviewHeaderRow
          totals={totals}
          isSticky={isSticky}
          hasResult={!!llmResult}
          toggleAllItemsForBulkEdit={() => magicBoxAiStore.toggleAllItemsForBulkEdit()}
          isAllItemsSelectedForBulkEdit={magicBoxAiStore.isAllItemsSelectedForBulkEdit}
          isInBulkEditMode={magicBoxAiStore.isInBulkEditMode}
        />
      </div>

      <DeepAnalysisReviewResultContent ref={contentRef} onScroll={onScroll}>
        <DeepAnalysisReviewResultsWrapper>
          {
            llmResult ?
              <>
                {
                  processedLLMResult?.map((item, itemIndex) => (
                    <DeepAnalysisResultsRow
                      key={itemIndex}
                      item={item}
                      itemIndex={itemIndex}
                      relevantClasses={relevantClasses}
                      scrollToMe={magicBoxAiStore.showMissingFieldsError && magicBoxAiStore.firstAccountWithoutAllFields === itemIndex ? magicBoxAiStore.showMissingFieldsError : 0}
                      handleCategoryChange={(_classId, categoryId) => handleCategoryChange(itemIndex, categoryId)}
                      isEdited={editedRowIndex === itemIndex}
                      onEditedIndexChange={(index) => setEditedRowIndex(index)}
                      onClickDelete={() => onDeleteItem(itemIndex)}
                      isInBulkEditMode={magicBoxAiStore.isInBulkEditMode}
                      isItemForBulkEdit={item.isItemForBulkEdit}
                      onBulkEditChange={() => magicBoxAiStore.toggleItemForBulkEdit(itemIndex)}
                    />
                  ))
                }
                {
                  !magicBoxAiStore.isInBulkEditMode &&
                  <DeepAnalysisResultsRow
                    isNew
                    itemIndex={-1}
                    relevantClasses={relevantClasses}
                    isEdited={editedRowIndex === -1}
                    onEditedIndexChange={(index) => setEditedRowIndex(index)}
                  />}
              </>
              :
              <SkeletonRows count={8} />
          }
        </DeepAnalysisReviewResultsWrapper>
      </DeepAnalysisReviewResultContent>

      <DeepAnalysisReviewBulkEdit
        isInBulkEditMode={magicBoxAiStore.isInBulkEditMode}
        relevantClasses={relevantClasses}
      />
      {!magicBoxAiStore.isInBulkEditMode &&
        <DeepAnalysisReviewResultFooter>
          <FooterNote show={!!llmResult} fileName={fileName} />
          <PrimaryButton onClick={onClick} disabled={magicBoxAiStore.isTyping} style={{ borderRadius: '8px', minWidth: '190px' }}>{isLoading ? <LoadingOutlined /> : `Continue to deep analysis`}</PrimaryButton>
        </DeepAnalysisReviewResultFooter>
      }

      {showDeleteModal && <DeepAnalysisReviewDeleteModal onClose={() => setShowDeleteModal(false)} onDelete={onDeleteFile} />}

    </BaseModal>
  )
})

const ReviewHeaderRow = ({ totals, isSticky, hasResult, toggleAllItemsForBulkEdit, isAllItemsSelectedForBulkEdit, isInBulkEditMode }) => {
  const count = hasResult ? `(${(totals.assets.count + totals.liabilities.count)})` : '';
  return (
    <DeepAnalysisReviewResultHeaderRow isSticky={isSticky}>
      <RowTitle noGap>
        <ItemSelectedCheckboxWrapper
          onClick={toggleAllItemsForBulkEdit}
          isSelected={isAllItemsSelectedForBulkEdit}
          isHeaderItem={true}
        >
          <ItemSelectedCheckbox
            isSelected={isAllItemsSelectedForBulkEdit}
            isPartialSelected={isInBulkEditMode}>
            {isAllItemsSelectedForBulkEdit && <ItemSelectedIcon />}
            {isInBulkEditMode && !isAllItemsSelectedForBulkEdit && <div style={{ width: '4px', height: '1px', marginTop: '1px', backgroundColor: colors.darkGray2 }} />}
          </ItemSelectedCheckbox>
        </ItemSelectedCheckboxWrapper>
        <div>Asset / liability name {count}</div>
      </RowTitle>
      <div style={{ display: 'flex', width: '504px', alignItems: 'center' }}>
        <RowTitle style={{ width: '184px' }}>Current value</RowTitle>
        <RowTitle>Choose category</RowTitle>
      </div>

    </DeepAnalysisReviewResultHeaderRow>
  );
};

const ReviewReadyResultHeader = ({ totals, isFetchAnyRate, isIncognitoMode }) => {
  return (
    <DeepAnalysisReviewResultHero>
      <DeepAnalysisReviewResultHeroBox>
        <DeepAnalysisReviewResultHeroBoxTitle>Total Assets ({totals.assets.count})</DeepAnalysisReviewResultHeroBoxTitle>
        <DeepAnalysisReviewResultHeroBoxValue>{isFetchAnyRate ? <LoadingOutlined /> : displayMoneyValue(totals.assets.sum, 'USD', isIncognitoMode, 100)}</DeepAnalysisReviewResultHeroBoxValue>
      </DeepAnalysisReviewResultHeroBox>
      <DeepAnalysisReviewResultHeroBox>
        <DeepAnalysisReviewResultHeroBoxTitle>Total Liabilities ({totals.liabilities.count})</DeepAnalysisReviewResultHeroBoxTitle>
        <DeepAnalysisReviewResultHeroBoxValue>{isFetchAnyRate ? <LoadingOutlined /> : displayMoneyValue(totals.liabilities.sum, 'USD', isIncognitoMode, 100)}</DeepAnalysisReviewResultHeroBoxValue>
      </DeepAnalysisReviewResultHeroBox>
      <DeepAnalysisReviewResultHeroBox>
        <DeepAnalysisReviewResultHeroBoxTitle>Total Net</DeepAnalysisReviewResultHeroBoxTitle>
        <DeepAnalysisReviewResultHeroBoxValue>{isFetchAnyRate ? <LoadingOutlined /> : displayMoneyValue((totals.assets.sum - totals.liabilities.sum), 'USD', isIncognitoMode, 100)}</DeepAnalysisReviewResultHeroBoxValue>
      </DeepAnalysisReviewResultHeroBox>
    </DeepAnalysisReviewResultHero>

  );
};

const ReviewLoadingHeader = ({ isWelcome }) => {
  const { magicBoxAiStore } = useStore();

  const displayedText = useTypewriter({
    text: ((isWelcome) ? AI_LOADING_MSG_WELCOME : AI_LOADING_ACCOUNT),
    setIsTyping: useCallback((isTyping) => magicBoxAiStore.setIsTyping(isTyping), [magicBoxAiStore])
  });

  return (
    <DeepAnalysisReviewResultHero>
      <ChatRow duration={0}>
        <div style={{ minHeight: '63.5px' }}>
          <span style={{ fontWeight: 600 }}>Analyzing... </span>
          <span>{displayedText}</span>
        </div>
      </ChatRow>
    </DeepAnalysisReviewResultHero>);
};

const FooterNote = ({ show, fileName }) => {
  const fileType = fileName?.split('.').pop()?.toLowerCase();
  return (
    <DeepAnalysisInProgressPrompt>
      {
        show &&
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <div>{docTypes[fileType]}</div>
          <div>{fileName}</div>
        </div>}
    </DeepAnalysisInProgressPrompt>
  );
};

const SkeletonRows = ({ count }) => {
  return (
    Array.from({ length: count }, (_, index) => (
      <DeepAnalysisResultsRow key={index} showSkeleton={true} />
    ))
  );
};

