import { Tooltip } from "antd";


export const AssistanceTooltip = ({ children, title, isSmall }) => {
    return (
        <Tooltip
            title={title}
            arrow={false}
            color='var(--Dark-Gray-1, #1A1B1D)'
            overlayStyle={{
                filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.05)) drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.05))',
                maxWidth: '400px'
            }}
            overlayInnerStyle={{
                display: 'inline-flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: `${isSmall ? '4px 12px 5px 12px' : '8px 24px'}`,
                borderRadius: `${isSmall ? '6px' : '8px'}`,
                background: 'var(--Dark-Gray-1, #1A1B1D)',
                boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.12)',
                border: `${isSmall ? 'none' : '1px solid var(--Main-Colors-Dark-Grey-2, #585D66)'}`,
                color: '#FFF',
                fontSize: '14px',
                lineHeight: '22px',
                fontWeight: '450'
            }}
        >
            {children}
        </Tooltip>
    );
};