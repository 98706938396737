import { CURRENCY_SYMBOL } from "../../../../../../../common/constants/currency-symbol.const"
import { translations } from "../../../../../../../common/translations/translations.en"
const { IRR_DESCRIPTION } = translations.common.item_overview;
// export const asdasdasd = {
//     value: {title:'Current Value', showPropValue : 'itemCurrencyValue' , showCoreBalance: 'stock.symbol', showTickerLastUpdateDate:  true},
//     stats : [
//         {title:'Current PPS',  dataKey: 'stockLastPrice'  , digits:4, ignoreHoldings: true },
//         {title:'ROI', dataKey: 'roi' , type: 'perc' },
//         {
//             isArray: true , stats: [
//                 {title: (item) =>  `Total cost (${CURRENCY_SYMBOL[item.currency] || '$' })`, menuDescription: 'The price you paid for these shares', dataKey: 'cost_basis'  },
//                 {title:'Cost per share', menuDescription: "The average PPS of the stocks you currently own", dataKey: 'cost_per_share' },
//             ]
//         },
//         {title: (item) =>  `Gain (${CURRENCY_SYMBOL[item.currency] || '$' })`, dataKey: 'gain'},
//     ],
//     visuals : []
// }

export const syncedCryptoOverviewData = {
    value: {title:'Current Value', showPropValue : 'itemCurrencyTotalValue', showCoreBalance: 'cryptoCoin.symbol' ,  showCryptoLastUpdateDate:  true},
    stats : [
          // {title:'Crypto Value', subTitle : 'Original currency' , dataKey: 'value' },
        {title:'Current Price',  dataKey: 'cryptoLastPrice'  , digits:4, ignoreHoldings: true   },
    //    { title: 'ROI', subTitle: 'ROI', dataKey: 'roi', type: 'perc', multipleValues: true },
    //    { title:'ROI', dataKey: 'roi' , type: 'perc' },
       {
        isArray: true , stats: [
            {title:'ROI', dataKey: 'roi' , type: 'perc', menuDescription: 'Return on Investment', description: `Return on Investment (ROI) represents the percentage return based on asset value and distributions relative to total contributions.` },
            {title:'IRR', dataKey: 'xirr', type: 'perc', menuDescription: 'Internal Rate of Return', description: IRR_DESCRIPTION, multipleValues:true},
        ]
        },
        {
            isArray: true , stats: [
                {title: (item) =>  `Total cost (${CURRENCY_SYMBOL[item.currency] || '$' })`, menuDescription: 'The price you paid for these coins', dataKey: 'cost_basis'  },
                {title:'Average cost', menuDescription: "The average cost of the coins you currently own", dataKey: 'cost_per_coin' , ignoreHoldings: true },
            ]
       },
       {title: (item) =>  `Gain (${CURRENCY_SYMBOL[item.currency] || '$' })`, dataKey: 'gain'},
    //    { title: 'Total cost', subTitle: 'Including all contributions', dataKey: 'contributions', multipleValues: true },
    //    { title: 'Total cash return', subTitle: 'Including all contributions', dataKey: 'distributions', multipleValues: true },
    ],
    visuals : []
}

export const cryptoWalletOverviewData = {
    value: {title:'Current Value'},
    stats : [
        { title: 'ROI', subTitle: 'ROI', dataKey: 'roi', type: 'perc', multipleValues: true },
        // {title:'Crypto Value', subTitle : 'Original currency' , dataKey: 'value' },
        {title:'Total Contributions', subTitle : 'Including all contributions' , dataKey: 'contributions', multipleValues: true,},
        {title:'Total Distributions', subTitle : 'Including all distributions' , dataKey: 'distributions', multipleValues: true,},
        {},
    ],
    visuals : []
}