import { getData, postData, putData } from "../../../common/utils/network.utils";

export const getLastConversation = async () => {
    return (await getData(`ask-my-data/conversations/last`)).data;
};

export const sendMessage = async (query, conversationId, version) => {
    return await postData(`ask-my-data/query`, { query, conversationId, version });
};

export const deactivateConversation = async (conversationId) => {
    return await putData(`ask-my-data/conversations/${conversationId}/deactivate`);
};