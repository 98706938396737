import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../app/data/root.store";
import { AssistanceMobileHeader, MobileHeaderTitle, MobilePageWrapper, HeaderMoreMenu } from "./AssitanceMobile.styles";
import { AssistanceMobileWelcomeModal } from "./AssistanceMobileWelcomeModal/AssistanceMobileWelcomeModal";
import { AssistanceHeroLogoText, AssistanceHeroLogoWrapper } from "../Assistance.styles";
import { VyzerAIAssistanceIcon } from "../../assets/icons";
import { ThreeDotsIcon } from "../../../../assets/icons/common/common-icons";
import { MoreOnInsightDrawer } from "./MoreOnInsightDrawer/MoreOnInsightDrawer";
import { AssistanceChat } from "../AssistanceChat";
import { ModifiedTooltip } from "../../../../common/components/ModifiedTooltip/ModifiedTooltip";
import { colors } from "../../../../common/styles/theme.styles";
import { ls } from "../../../../common/utils/localstorage.util";

export const AssistanceMobilePage = observer(() => {
    const { uiStore, userStore, assistanceStore } = useStore();
    const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(ls.get('assistance_welcome_modal_shown_' + userStore.data.id) === '1' ? false : true);
    const [isMoreDrawerOpen, setIsMoreDrawerOpen] = useState(false);
    useEffect(() => {
        // Add style to #root
        const rootElement = document.getElementById('root');
        if (rootElement) {
            rootElement.style.height = '100%';
        }
    
        // Cleanup function
        return () => {
            if (rootElement) {
                rootElement.style.height = '';
            }
        };
    }, []);

    const handleNewChat = () => {
        assistanceStore.newChat();
    }

    return (
        <>
            <AssistanceMobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}>
                <MobileHeaderTitle>Insight AI</MobileHeaderTitle>
                <ModifiedTooltip placement="bottom" title="Like all AI, I'm still learning and may make mistakes" trigger="click" overlayInnerStyle={{fontSize: '14px', borderRadius: '8px', border:`1px solid ${colors.darkGray2}`}}>
                    <AssistanceHeroLogoWrapper>
                            <VyzerAIAssistanceIcon />
                            <AssistanceHeroLogoText>{'AI-BETA'}</AssistanceHeroLogoText>
                    </AssistanceHeroLogoWrapper>
                </ModifiedTooltip>
                <HeaderMoreMenu isMoreDrawerOpen={isMoreDrawerOpen} onClick={()=>setIsMoreDrawerOpen(true)}>
                    <ThreeDotsIcon />
                </HeaderMoreMenu>
            </AssistanceMobileHeader>
            <MobilePageWrapper isWithTopBanner={userStore.isDemoMode || userStore.isHavingSharedPortfolios} isMobileFooterHidden={uiStore.isMobileFooterHidden}>
                <AssistanceChat isMobile={true}/>
            </MobilePageWrapper>
            {isWelcomeModalOpen && <AssistanceMobileWelcomeModal onClose={()=>{
                setIsWelcomeModalOpen(false);
                ls.set('assistance_welcome_modal_shown_' + userStore.data.id, '1');
            }} />}
             <MoreOnInsightDrawer onClose={()=>setIsMoreDrawerOpen(false)} visible={isMoreDrawerOpen} onNewChat={handleNewChat} isDemo={userStore.isDemoMode} />
        </>
    );
});
