
import React , { useState } from 'react';
import NumberFormat from 'react-number-format';
import { colors } from '../../../../styles/theme.styles';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../../../utils/object.utils';
import { HoldingsBadgeText, HoldingsBadgeTriangle, HoldingsBadgeWrapper, InputWithBudgeWrapper } from './NumberWithHoldings.styles';
import { SuffixWrapper } from '../Number/NumberInput.styles';

export function NumberWithHoldings(props) {
    //const { thousandSeparator, customInput, suffix, prefix, style, value, disabled, autoComplete, onValueChange, onBlur, placeholder, decimalScale, allowNegative, useHoldingsValue, holdings } = props;
    const { onChange , customInput, useHoldingsValue, holdings, value, style, ...restProps } = props;
    // const {metadataStore} = useStore();

    const [focusedInput,setFocusedInput] = useState(0);

    return (
        <div style={{display:'flex'}}>
            {holdings !== 100 && !useHoldingsValue && <NumberFormat 
                {...restProps}
                    className={'holdings-left'}
                    style={{...style,width:'50%'}}
                    customInput={customInput}
                    value={( isNullOrUndefinedOrEmptyString(value) ) ? '' : parseFloat( (value * (holdings/100)).toFixed((restProps.decimalScale || 2))) }
                    onFocus={()=>{setFocusedInput(1)}}
                    onValueChange={(values) => {
                        if ( 
                            // ''+value !== values.value && 
                             focusedInput === 1 && 
                             (!isNullOrUndefined(value) || !isNullOrUndefined(values.floatValue))){
                                onChange(( values.floatValue || values.floatValue === 0 ) ? values.floatValue * (100/holdings) : null)
                        }
                    }}
            />}
            <InputWithBudgeWrapper isWithHoldings={holdings !== 100}>
            <NumberFormat 
                    {...restProps}
                    customInput={customInput}
                    style={{...props.style,...((!useHoldingsValue && holdings !== 100) ? {paddingLeft:'60px',color:colors.holdingsValueColor} : {})}}
                    className={holdings !== 100 ? 'holdings-right' : ''}
                    // thousandSeparator={true} 
                    // suffix={'%'} decimalScale={2} 
                    // placeholder={field.placeholder || '%'}
                    // customInput={Input}
                    // // style={isIncognito && field.shouldBeHiddenIncognito ? {WebkitTextSecurity:'disc'}:{}} 
                    // //value={( isNullOrUndefined(value) || value === 0) ? '' : value}
                    value={( isNullOrUndefinedOrEmptyString(value) ) ? '' : parseFloat( (+value).toFixed((restProps.decimalScale || 2))) }
                    // disabled={isItemConnected && field.disableIfConnected} 
                    // autoComplete="off"
                    // // onValueChange={(values) => {handleChange(inputKey,values.floatValue ? values.floatValue : 0)}}
                    onFocus={()=>{setFocusedInput(2)}}
                    onValueChange={(values) => {
                        if ( ''+value !== values.value && focusedInput === 2 &&
                             (!isNullOrUndefined(value) || !isNullOrUndefined(values.floatValue))){
                                onChange(( values.floatValue || values.floatValue === 0 ) ? values.floatValue : null)
                        }
                    }}
                    // onBlur={(e)=>{handleBlur && handleBlur(inputKey)}}
                />
                {(!useHoldingsValue && holdings !== 100) && <HoldingsBadgeWrapper><HoldingsBadgeText>100%</HoldingsBadgeText><HoldingsBadgeTriangle /></HoldingsBadgeWrapper> }
                {restProps.abssuffix ? <SuffixWrapper>{restProps.abssuffix}</SuffixWrapper> : null }
                </InputWithBudgeWrapper>
                {/* {holdings !== 100 && useHoldingsValue && <NumberFormat 
                    customInput={customInput}
                    value={( isNullOrUndefined(value) ) ? '' : value * (100/holdings)}
                />} */}
        </div>
        
    )

}