import styled from "@emotion/styled/macro";
import { flexCenter, flexColumn } from "../../../../common/styles/combos.styles";
import { colors, shadows } from "../../../../common/styles/theme.styles";
import { Skeleton } from "antd";
import { SecuredPromptHoverEffect } from "../../../user-activation-flow/components/UserActivationFlowPage.styles";

export const DeepAnalysisReviewResultHeader = styled.div`
  ${flexCenter};
  align-items: center;
  gap: 6px;
  padding: 16px 16px 16px 40px;
`;

export const DeepAnalysisReviewResultHeaderTitle = styled.div`
  color: ${colors.DarkGray1};
  font-family: Circular;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const DeepAnalysisReviewResultHeaderSubtitle = styled(DeepAnalysisReviewResultHeaderTitle)`
  color: ${colors.darkGray2};
  font-size: 14px;
  font-weight: 450;
`;

export const DeepAnalysisReviewResultHero = styled.div`
  display: flex;
  padding: 8px 40px 16px 40px;
  align-items: center;
  gap: 12px;
`;

export const DeepAnalysisReviewResultHeroBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex:1;
  height: 120px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${colors.gray3};


  @media (max-height: 680px) {
    height: 80px; /* Height for screens smaller than 680px */
  }

`;

export const DeepAnalysisReviewResultHeroBoxTitle = styled.div`
  color: ${colors.darkGray2};
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const DeepAnalysisReviewResultHeroBoxValue = styled.div`
  color: ${colors.DarkGray1};
  font-family: Circular;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const DeepAnalysisReviewResultHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
  padding: 0px 40px;
  flex-shrink: 0;
  background: #FFF;
  border-bottom: ${({ isSticky }) => isSticky ? `1px solid ${colors.gray3}` : 'none'};
`;

export const RowTitle = styled.div`
  display: flex;
  padding: 0px 16px 0px 12px;
  align-items: center;
  gap: ${({ noGap }) => noGap ? '0' : '8px'};
  align-self: stretch;
  color: ${colors.darkGray2};
  font-size: 14px;
  font-weight: 450;
`;

export const DeepAnalysisReviewResultContent = styled.div`
  ${flexColumn};
  flex:1;
  overflow-y: auto;
`;

export const DeepAnalysisReviewResultsWrapper = styled.div`
  display: flex;
  width: 100%;
  // width: 880px;
  padding: 0 40px 16px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const DeepAnalysisReviewResultsRow = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  background: ${colors.lightGray1};
  padding: 12px 4px 12px 8px;
  border-radius: 12px;
  height: 72px;
  cursor: pointer;
  ${({ isEdited, isLoading }) => (isEdited || isLoading) ? `
    cursor: default;
    ` : ``}
  ${({ isLoading }) => isLoading ? `
    pointer-events: none;
    ` : ``}

  ${({ isNew }) => isNew ? `
    padding-right: 44px;
  ` : ``}

`;

export const DeepAnalysisReviewResultsRowValue = styled.div`
  width: 132px;
`;

export const DeepAnalysisReviewResultsRowCategory = styled.div`
  padding-left: 4px;
`;

export const DeepAnalysisReviewResultFooter = styled.div`
  ${flexCenter};
  justify-content: space-between;
  padding: 16px 24px;
  border-top: 1px solid ${colors.gray3};
  background: #FFF;
  box-shadow: ${shadows.cardShadow};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const DeepAnalysisInProgressPrompt = styled.div`
  ${flexCenter};
  gap: 6px;
  color: ${colors.darkGray2};
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const ResultRowCell = styled.div`
    display: flex;
    align-items: center;
    padding-inline: 4px;
    width: 100%;
    text-align: left;
    min-height: 35px;
    position: relative;

    .ant-input,
    .ant-input-affix-wrapper {
        background: ${colors.lightGray1};
        border: 1px solid transparent;
        transition: all 0.3s;

        &:hover,
        &:focus,
        &:active {
            border-color: ${colors.darkGray1};
            box-shadow: none;
        }
    }

    .ant-input-affix-wrapper {
        .ant-input {
            font-size: 14px;
            background-color: transparent;
            border-color: transparent;

            &:hover,
            &:focus,
            &:active {
                border-color: transparent;
            }
        }
    }

    .ant-input-group-wrapper{
      display: flex;
      align-items: center;
      align-self: stretch;
      border-radius: 8px 0px 0px 8px;
      background: var(--Main-Colors-White, #FFF);
      height: 48px;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;

        &:hover,
        &:focus,
        &:active {
          border-radius: 8px;
          border-color: var(--Main-Colors-Black, #000);

          /* Menu Popup */
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05), 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
        }

        .ant-input-wrapper.ant-input-group{
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1 0 0;
          align-self: stretch;
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          height: 100%;

          .ant-input{
            display: flex;
            padding: 8px 0px 8px 12px;
            align-items: center;
            flex: 1 0 0;
            align-self: stretch;
            width: 92px;

            border-radius: 8px 0px 0px 8px;
            background: var(--Main-Colors-White, #FFF);

            color: var(--Dark-Gray-1, #1A1B1D);
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            
            font-size: 14px;
            background-color: transparent;
            border-color: transparent;

            &:hover,
            &:focus,
            &:active {
                border-color: transparent;
            }
          }

          .ant-input-group-addon{
            display: flex;
            width: 64px;
            padding: 8px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            height: 100%;

            border-radius: 0px 8px 8px 0px;
            border: none;
            border-left: 1px solid var(--Border-Border-01, #DDE3EB);
            background: var(--Main-Colors-White, #FFF);

            .ant-select-selector{
              box-shadow: none;
              background-color: transparent;
              border-color: transparent;
              outline: 0;

              .ant-select-selection-item,
              .ant-select-selection-search {
                outline: 0;
              }
            }
            
            .ant-select-focused .ant-select-selector{
              color: #bfbfbf !important;
            }
          }
        }
    }

    ${props => props.isActive ? `
        .ant-picker,
        .ant-input,
        .ant-input-affix-wrapper {
            background-color: #fff;
            // border-color: ${colors.darkGray1};
        }
    ` : ''}
`

export const DeleteActionWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

// export const AddNewItemButtonWrapper = styled.div`
//     ${flexCenter};
//     justify-content: flex-start;
//     gap:8px;
//     border:1px dashed ${colors.gray3};
//     border-radius: 8px;
//     padding:8px 11px;
//     margin:8px 12px 24px;
//     cursor: pointer;
//     font-size: 14px;
//     font-weight: 450;
//     color:${colors.darkGray2};
//     cursor: pointer;
//     ${views.desktop}{
//         :hover{
//             border-color:${colors.darkGray3};
//         }
//     }
// `;


export const ReviewNameSkeleton = styled(Skeleton)`
display: flex;
align-items: center;
gap: 7px;

.ant-skeleton-title{
  margin: 6px 0 !important;
}

.ant-skeleton-content {
  .ant-skeleton-title{}
}
`;

export const ReviewValueSkeleton = styled(ReviewNameSkeleton)`
    padding: 0px 8px;
`;

export const WhatsNextPromptHoverEffect = styled(SecuredPromptHoverEffect)`
`;

export const WhatsNextTitle = styled.div`
    display: inline-flex;
    height: 32px;
    padding: 0px 12px 0px 8px;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;

    border-radius: 8px;
    border: 1px solid var(--Gray-3, #DDE3EB);
    background: var(--White, #FFF);

    /* Toggle Shadow */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
    color: var(--Dark-Gray-1, #1A1B1D);
    
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;

export const WhatsNextProptWrapper = styled.div`
  position: relative;

  :hover {
      ${WhatsNextPromptHoverEffect} {
          display: flex !important;
          z-index: 1;
          opacity:1;
          pointer-events:auto;
      }

      ${WhatsNextTitle} {
          background: var(--Main-Colors-Dark-Grey-0, #111213);
          color: var(--Main-Colors-White, #FFF);
          border: none;

          svg {
            path {
              fill: white;
            }
        }
      }
  }
`;

export const WhatsNextPropt = styled.div`
      display: flex;
      padding-bottom: 8px;
      flex-direction: column;
      align-items: flex-start;

      border-radius: 8px;
      background: var(--Dark-Gray-1, #1A1B1D);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
`;

export const WhatsNextProptTitle = styled.div`
      display: flex;
      padding: 16px 8px 16px 28px;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;

      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
`;

export const WhatsNextProptSpacer = styled.div`
      display: flex;
      height: 0px;
      padding: 0px 28px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
`;

export const WhatsNextProptSpacerLine = styled.div`
    width: 100%;
    height: 1px;

    background: var(--Dark-Gray-2, #585D66);
`;

export const WhatsNextCompleteIconWrapper = styled.div`
      display: flex;
      width: 16px;
      height: 16px;
      justify-content: center;
      align-items: center;

      border-radius: 23px;
      background: #A6EDCF;
      color: #111213;
`;

export const DeepAnalysisIconWrapper = styled.div`
      display: flex;
      width: 24px;
      height: 24px;
      padding: 3px;
      justify-content: center;
      align-items: center;
  `;

export const WhatsNextStepWrapper = styled.div`
      display: flex;
      padding: 16px 20px 16px 24px;
      align-items: center;
      gap: 24px;
      align-self: stretch;
`;

export const WhatsNextRowTitle = styled.div`
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
`;

export const WhatsNextRowContent = styled.div`
      color: var(--Gray-2, #ACB1BB);
      font-size: 13px;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
`;

export const FooterWrapper = styled.div`
      position: absolute;
      display: flex;
      width: 100%;
      padding: 5px 40px 16px 40px;
      justify-content: space-between;
      align-items: flex-end;
      flex-shrink: 0;
      bottom: 0;

      transition: bottom 0.3s;

      ${({ show }) => show ? `
        bottom: 0;
        height: auto;
      ` : `
        bottom: -80px;
        height: 0;
        padding: 0;

        ${BulkEditFloatingMenu}{
          display: none;
        }
      `}
`;

export const BulkEditFloatingMenu = styled.div`
      display: flex;
      width: 880px;
      padding: 11px 13px 11px 24px;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;

      border-radius: 31px;
      background: var(--Main-Colors-Dark-Grey-0, #111213);
`;

export const BulkEditFloatingMenuText = styled.div`
      display: flex;
      align-items: center;
      gap: 12px;

      color: var(--Main-Colors-White, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
`;

export const BulkEditFloatingMenuOptions = styled.div`
      display: flex;
      align-items: center;
      gap: 8px;
`;

export const BulkEditFloatingMenuButtonDark = styled.div`
      display: flex;
      height: 36px;
      padding: 0px 16px 0px 12px;
      justify-content: center;
      align-items: center;
      gap: 2px;

      border-radius: 20px;
      background: #2F3236;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
      border: 1px solid var(--Main-Colors-Grey-2, #2F3236);

      :hover {
        border: 1px solid var(--Main-Colors-Grey-2, #ACB1BB);
        cursor: default;
      }
`;

export const BulkEditFloatingMenuButtonLight = styled(BulkEditFloatingMenuButtonDark)`
      background: var(--Main-Colors-White, #FFF);

      /* Toggle Shadow */
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
      border: 1px solid var(--Main-Colors-Grey-2, #FFF);

      :hover {
        border: 1px solid #FFF;
        background: var(--Main-Colors-Light-Grey-2, #F1F3F5);
      }
`;

export const BulkEditFloatingMenuButtonIcon = styled.div`
      display: flex;
      width: 24px;
      height: 24px;
      padding: 4px;
      justify-content: center;
      align-items: center;
`;

export const BulkEditFloatingMenuButtonDarkText = styled.div`
      color: var(--Main-Colors-White, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
`;

export const BulkEditFloatingMenuButtonLightText = styled.div`
      color: var(--Main-Colors-Dark-Grey-0, #111213);
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
`;

export const BulkEditFloatingMenuModal = styled.div`
      border-radius: 12px;
      background: #FDFEFE;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.32), 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
`;

export const BulkEditFloatingMenuModalHeader = styled.div`
      display: flex;
      justify-content: space-between;
      width: 100%;


      background: var(--White, #FFF);
      border-radius: 12px 12px 0px 0px;
      box-shadow: 0px -1px 0px 0px #DDE3EB inset;
      flex-shrink: 0;
      padding: 0px 15px 0px 24px;
`;

export const BulkEditFloatingMenuModalHeaderText = styled.div`
      padding: 22.5px 0 21.5px 0;

      color: var(--Dark-Gray-1, #1A1B1D);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
`;

export const BulkEditFloatingMenuModalHeaderCloseIcon = styled.div`
      padding: 16px 0 32px 0;

      :hover {
        cursor: pointer;
        filter: brightness(0.8);
      }
`;

export const BulkEditFloatingMenuModalBody = styled.div`
      padding: 24px 68px 96px 68px;
`;

export const BulkEditFloatingMenuModalFooter = styled.div`
      display: flex;
      width: 600px;
      height: 65px;
      padding: 13px 24px 14px 472px;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;

      background: var(--White, #FFF);
      box-shadow: 0px 1px 0px 0px #E7E8EA inset;
      border-radius: 0px 0px 12px 12px;
`;

export const BulkEditFloatingMenuModalFooterButton = styled.div`
      display: flex;
      width: 104px;
      height: 38px;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;

      border-radius: 8px;
      background: var(--Dark-Gray-1, #1A1B1D);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.16);

      color: var(--White, #FFF);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
`;

export const BulkEditValueEdit = styled.div`
      width: 100%;
      .ant-input-group-wrapper{
        border: 1px solid var(--Gray-3, #DDE3EB);
      }
`;

export const BulkEditCurrencyEdit = styled.div`
      display: flex;

      .ant-select-focused .ant-select-selector{
          border-color:#000!important;
          box-shadow: none!important;
      }
      
      .ant-select{
        width: 100%;

        .ant-select-selector{
          display: flex;
          align-items: center;
          border-radius: 8px;
          border: 1px solid var(--Gray-3, #DDE3EB);
          background: #FFF;
          height: 40px;

          :hover{
            border-color: var(--Main-Colors-Black, #000);
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05), 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
          }
        }
      }
`;

export const BulkEditUndoModal = styled.div`
      display: flex;
      justify-content: center;
      width: 100%;
`;

export const BulkEditUndoModalCot = styled.div`
      display: inline-flex;
      height: 48px;
      padding: 5.5px 8px 5.5px 20px;
      align-items: center;
      gap: 28px;
      flex-shrink: 0;
      
      border-radius: 26px;
      background: var(--Dark-Gray-1, #1A1B1D);
`;

export const BulkEditUndoModalBody = styled.div`
      display: flex;
      padding-bottom: 2px;
      align-items: center;
      gap: 22px;
      align-self: stretch;
`;

export const BulkEditUndoModalText = styled.div`
      color: var(--White, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
`;

export const BulkEditUndoModalUndoText = styled(BulkEditUndoModalText)`
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;

      :hover {
        cursor: pointer;
      }
`;

export const BulkEditUndoModalSeparator = styled.div`
      width: 1px;
      height: 100%;
      background: #585D66;
`;

export const BulkEditUndoModalIcon = styled.div`
      display: flex;
      width: 24px;
      height: 24px;
      padding: 7px;
      justify-content: center;
      align-items: center;

      border-radius: 67px;
      background: rgba(255, 255, 255, 0.10);

      :hover {
        cursor: pointer;
        filter: brightness(0.8);
      }
`;

export const BulkEditValueInputWrapper = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      width: 100%;
`;

export const BulkEditValueLabelWrapper = styled.div`
      display: flex;
      align-items: center;
      gap: 2px;
      align-self: stretch;
`;

export const BulkEditValueInputText = styled.div`
      color: var(--Dark-Gray-1, #1A1B1D);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
`;

export const BulkEditValueLabelIcon = styled.div`
      display: flex;
      width: 16px;
      height: 16px;
      padding: 2.5px;
      justify-content: center;
      align-items: center;
`;
