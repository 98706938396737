import { useEffect, useState, useRef } from "react";

export const useTypingEffect = (text, showTypingEffect, setIsTypingFunc) => {
    const [displayText, setDisplayText] = useState('');
    const setIsTypingFuncRef = useRef(setIsTypingFunc);
    
    // Update the ref when the function changes
    useEffect(() => {
        setIsTypingFuncRef.current = setIsTypingFunc;
    }, [setIsTypingFunc]);

    useEffect(() => {
        let timeout;
        let index = 0;
        let count = 0;

        const type = () => {
            timeout = setTimeout(() => {
                if (index < text?.length) {
                    setDisplayText((prev) => (prev + text?.charAt(index)));
                    index++;
                    type();
                } else {
                    setIsTypingFuncRef.current && setIsTypingFuncRef.current(false);
                }
            }, ((Math.random() * 10)));
        };

        const thinkingEffect = () => {
            timeout = setTimeout(() => {
                setDisplayText([".", "..", "..."][(count % 4)]);
                count++;
                thinkingEffect();
            }, 400);
        };

        if (!text) {
            thinkingEffect();
        } else {
            if (!showTypingEffect) {
                setDisplayText(text);
                setIsTypingFuncRef.current && setIsTypingFuncRef.current(false);
                return;
            };
            
            setDisplayText('');
            setIsTypingFuncRef.current && setIsTypingFuncRef.current(true);
            type();
        }

        return () => {
            clearTimeout(timeout);
        };
        // Remove setIsTypingFunc from dependencies to prevent effect reruns
    }, [text, showTypingEffect]);

    return displayText;
}