import { Tabs } from 'antd';
import { Observer } from 'mobx-react';
import React, { useState } from 'react';
import DetectableOverflow from 'react-detectable-overflow';
import { useHistory, useLocation } from 'react-router-dom';
import { ActionLocked } from '../../../../../common/components/ActionLocked/ActionLocked';
import { CommonBadge } from '../../../../../common/components/CommonBadge/CommonBadge';
import { ModifiedTooltip } from '../../../../../common/components/ModifiedTooltip/ModifiedTooltip';
import { ROUTES } from '../../../../../common/constants/routes.const';
import { navigateToUrl } from '../../../../../common/utils/navigation.utils';
import { useStore } from '../../../../app/data/root.store';
import { AssetClassIcon } from '../../../../assets/components/AssetClassIcon';
import { AkoyaLink } from '../../../../connected-institutions/components/akoya-link/AkoyaLink';
import { PlaidLink } from '../../../../connected-institutions/components/plaid-link/PlaidLink';
import { SaltedgeLink } from '../../../../connected-institutions/components/saltedge-link/SaltedgeLink';
import { AssetActionsMenu } from './AssetActionsMenu';
import { AssetPageHeaderTab, AssetPageHeaderTabsWrapper, ContainerLinkWrapper, ItemClassTitleWrapper, ItemIconAndTitleWrapper, ItemIconWrapper, ItemInfoWrapper, ItemLoginRequiredBadge, ItemLogoWrapper, ItemTitleWrapper, ItemViewActionsWrapper, ItemViewHeaderWrapper, LogoPlaceHolder, OwnershipToggleLeftSideWrapper, OwnershipToggleRightSideWrapper, OwnershipToggleWrapper, ProviderLinkWrapper, SingleFilterClassWrapper, TitleTextWrapper } from './AssetPageHeader.styles';
import { stockTypeTitleMapper } from './Tickers/tickers.utils';

export const AssetPageHeader = (props) => {
  let history = useHistory();
  let location = useLocation();
  const { isFixed } = props;
  const { itemStore, filtersStore, wealthStore, uiStore } = useStore();
  const [isTitleTooltipNeeded, setIsTitleTooltipNeeded] = useState(false);

  const handleInstReconnect = () => {
    window.location.reload();
  }

  const handleReconnectError = () => {
    uiStore.setShowReconnectErrorModal(true, itemStore.item.connectedInstitutionName, itemStore.item.logoBase64, itemStore.item.logoUrl);
  }
  
  const handleTitleOverflowChange = (isOverflowed) => {
    setIsTitleTooltipNeeded(isOverflowed);
  }

  const handleOwnershipToggleClick = () => {
    itemStore.setHoldingsView(!itemStore.isHoldingsView);
  }

  const handleContainerClick = () => {
    filtersStore.handleClearFilterSelections();
    filtersStore.handleSelectContainer(itemStore.item.container);
    const urlParams = filtersStore.setAppliedFiltersFromSelection();
    navigateToUrl(history, ROUTES.wealthOverview.url + `?filters=${urlParams}`, false, true);
  }

  const handleHeroTabChange = (key) => {
    // wealthStore.setAssetViewType(key);
    // const searchParams = new URLSearchParams(window.location.search);
    // searchParams.set('ahvm', key);
    // const currentRoute = location.pathname;
    // navigateToUrl(history, `${currentRoute}?${searchParams.toString()}`, false);

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('ahvm', key);
    const currentRoute = location.pathname;
    navigateToUrl(history, `${currentRoute}?${searchParams.toString()}`, false);
  }
  
  return (<Observer>{() => (
    <ItemViewHeaderWrapper isFixed={isFixed} color={itemStore.item.categoryClass.color} >
      <ItemIconAndTitleWrapper>
        <ItemLogo item={itemStore.item} />
        <ItemInfoWrapper isFixed={isFixed}>
          <ItemTitleWrapper isFixed={isFixed}>
            <TitleTextWrapper isFixed={isFixed}>
            {/* !uiStore.isDesktopView && isFixed */}
            {uiStore.isDesktopView ? 
              <DetectableOverflow onChange={handleTitleOverflowChange}>
                <ModifiedTooltip title={isTitleTooltipNeeded ? `${itemStore.item.title}${itemStore.item.title !== itemStore.item.name && itemStore.item.name ? `, ${itemStore.item.name}` : ``}` : ''}>
              {itemStore.item.title}{itemStore.item.title !== itemStore.item.name && itemStore.item.name ? `, ${itemStore.item.name}` : ``}
              </ModifiedTooltip>
              </DetectableOverflow> : <>
                {itemStore.item.title}{itemStore.item.title !== itemStore.item.name && itemStore.item.name ? `, ${itemStore.item.name}` : ``}
              </>}
            </TitleTextWrapper>
            {itemStore.item.closedAt && <CommonBadge noMargin={!uiStore.isDesktopView} badgeType={'closed'} />}
            {!itemStore.item.isLoginRequired && itemStore.item.isConnected && <CommonBadge noMargin={!uiStore.isDesktopView} badgeType={'connected'} lastSync={itemStore.item.lastSync} />}
            {itemStore.item.vipInstitutionId && itemStore.item.vipInstitutionConnection === 'connected' ? <CommonBadge noMargin={!uiStore.isDesktopView} badgeType={'vipService'} color={'#DEB482'} lastSync={itemStore.item.vipInstitution?.latestPulling}/> : null}
            {itemStore.item.isLoginRequired ?
              (itemStore.item.connectedProvider === 'plaid' ?
                <ProviderLinkWrapper>
                  <ActionLocked action={'itemPageLoginRequired'}>
                    <PlaidLink reconnectId={itemStore.item.connectedInstitutionId} onSuccess={() => handleInstReconnect()} >
                      <CommonBadge noMargin={!uiStore.isDesktopView} isCTA badgeType={'loginRequired'} lastSync={itemStore.item.lastSync} />
                    </PlaidLink>
                  </ActionLocked>
                </ProviderLinkWrapper>
                : itemStore.item.connectedProvider === 'saltEdge' ?
                  <ProviderLinkWrapper>
                    <ActionLocked action={'itemPageLoginRequired'}>
                      <SaltedgeLink reconnectId={itemStore.item.connectedInstitutionId} onSuccess={(data) => { handleInstReconnect() }} loadingComp={<ItemLoginRequiredBadge>Reconnect...</ItemLoginRequiredBadge>}>
                        <CommonBadge noMargin={!uiStore.isDesktopView} isCTA badgeType={'loginRequired'} lastSync={itemStore.item.lastSync} />
                      </SaltedgeLink>
                    </ActionLocked>
                  </ProviderLinkWrapper>
                  :
                  <ProviderLinkWrapper>
                    <ActionLocked action={'itemPageLoginRequired'}>
                      <AkoyaLink reconnectId={itemStore.item.connectedInstitutionId} onError={(data) => { handleReconnectError(data.error) }} onSuccess={(data) => { handleInstReconnect() }} loadingComp={<ItemLoginRequiredBadge>Reconnect...</ItemLoginRequiredBadge>}>
                        <CommonBadge noMargin={!uiStore.isDesktopView} isCTA badgeType={'loginRequired'} lastSync={itemStore.item.lastSync} />
                      </AkoyaLink>
                    </ActionLocked>
                  </ProviderLinkWrapper>
              ) : null}

          </ItemTitleWrapper>
          <ItemClassTitleWrapper isFixed={isFixed}>
            {(!wealthStore.isSingleClassFilterResults || !filtersStore.singleClassFilter) && <SingleFilterClassWrapper>{itemStore.item.categoryClass.title}</SingleFilterClassWrapper>}
            {itemStore.item?.stock ? stockTypeTitleMapper(itemStore.item.stock.type) : itemStore.item?.category?.title}
            {itemStore.item?.container && !itemStore.item?.isConnectedStock ?
              <>
                {` · `}
                <ContainerLinkWrapper onClick={handleContainerClick}>
                  {itemStore.item.container}
                </ContainerLinkWrapper>
              </>
              : ''}
          </ItemClassTitleWrapper>
        </ItemInfoWrapper>
      </ItemIconAndTitleWrapper>
      
        
      {(uiStore.isDesktopView || itemStore.item?.holdings !== 100) &&
        <ItemViewActionsWrapper>
          {itemStore.item?.holdings !== 100 &&
            <>
              <OwnershipToggleWrapper isFixed={isFixed} onClick={handleOwnershipToggleClick}><OwnershipToggleLeftSideWrapper isSelected={itemStore.isHoldingsView} isFixed={isFixed}>{itemStore.item.holdings}% {!uiStore.isDesktopView && isFixed ? '' : 'Ownership'}</OwnershipToggleLeftSideWrapper><OwnershipToggleRightSideWrapper isSelected={!itemStore.isHoldingsView} isFixed={isFixed}>100%</OwnershipToggleRightSideWrapper></OwnershipToggleWrapper>
              {/* {uiStore.isDesktopView ? <VerticalSeparator /> : null} */}
            </>
          }
          
          {uiStore.isDesktopView && <>
            <AssetPageHeaderTabsWrapper>
              <Tabs activeKey={wealthStore.assetViewType} onChange={(key) => handleHeroTabChange(key)} >
                <Tabs.TabPane tab={<AssetPageHeaderTab>{'Overview'}</AssetPageHeaderTab>} key="overview" />
                <Tabs.TabPane tab={<AssetPageHeaderTab>{'Trends'}</AssetPageHeaderTab>} key="trends" />
              </Tabs>
            </AssetPageHeaderTabsWrapper>
            <AssetActionsMenu />
          </> }

        </ItemViewActionsWrapper> }
      

    </ItemViewHeaderWrapper>
  )}</Observer>)
}

const ItemLogo = ({ item }) => {
  const [isCorruptedImage, setIsCorruptedImage] = useState(false);

  return (
    item.categoryId === 39 && item.connectedProvider !== 'vip' ?
      item?.stock?.logo && !isCorruptedImage ? <ItemLogoWrapper><img alt={item.stock.symbol} src={item?.stock?.logo} onError={() => { setIsCorruptedImage(true) }} /></ItemLogoWrapper> :
        <LogoPlaceHolder>
          {(item.title && item.title !== '') ? item.title.charAt(0).toUpperCase() : (item.symbol && item.symbol !== '') ? item.symbol.charAt(0).toUpperCase() : (item.type && item.type !== '') ? item.type.charAt(0).toUpperCase() : ''}
        </LogoPlaceHolder> :

      item.categoryId === 36 && !item.logoUrl ?
        <LogoPlaceHolder crypto>
          {(item.title && item.title !== '') ? item.title.charAt(0).toUpperCase() : (item.symbol && item.symbol !== '') ? item.symbol.charAt(0).toUpperCase() : (item.type && item.type !== '') ? item.type.charAt(0).toUpperCase() : ''}
        </LogoPlaceHolder> :

        item.logoBase64 ? <ItemLogoWrapper><img alt={item.connectedInstitutionName} src={'data:image/png;base64,' + item.logoBase64} /></ItemLogoWrapper> :
          item.logoUrl && !isCorruptedImage ? <ItemLogoWrapper><img alt={item.connectedInstitutionName} src={item.logoUrl} onError={() => { setIsCorruptedImage(true) }} /></ItemLogoWrapper> :
            <ItemIconWrapper color={item.categoryClass.color}>
              <AssetClassIcon name={item.categoryClass.icon} />
            </ItemIconWrapper>
  )
}