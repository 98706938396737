import { Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useStore } from '../../../../app/data/root.store';
import { OnBoardingHero } from '../../../../onboarding/components/OnBoardingHero/OnBoardingHero';
import { PortfolioUnlockModal } from '../../../../onboarding/components/PortfolioUnlockModal/PortfolioUnlockModal';
import {
    CardContentBoxWrapper,
    ItemsListWrapper,
} from '../../../components/wealth.commons.styles';
import { ClassPageQuickAdd } from '../../class/components/QuickAdd/ClassPageQuickAdd';
import { WealthHero } from './hero/WealthHero';
import { ItemsListHeader } from './items-list/ItemsListHeader';
import { ItemsListViewSwitcher } from './items-list/ItemsListViewSwitcher';
import {
    InflowOutflowCalculationMessage,
    PageWrapper,
} from './WealthOverviewPage.styles';


// import { LiabilityClassIcon } from '../../../../liabilities/components/LiabilityClassIcon';
// import { AssetTotalValue, HoldingValue, ItemMoreInfos, ItemText, MoreInfosItem } from '../../class/components/ClassPage.styles';
// import { Badge } from 'antd';
// import moment from 'moment';
// import {ItemIconWrapper as AssetItemIconWrapper} from '../../class/components/ClassPage.styles'

export function WealthOverviewPage(props) {

    const { wealthStore, uiStore, filtersStore, metadataStore, userStore } = useStore();
    // const [addNewAssetClass, setAddNewAssetClass] = useState(0);
    // const [newAssetCategory,setNewAssetCategory] = useState(null);
    // const newAssetNameRef= useRef(null);
    // const newAssetValueRef= useRef(null);
    // const [addNewLiabilityClass, setAddNewLiabilityClass] = useState(0);
    // const [newLiabilityCategory,setNewLiabilityCategory] = useState(null);
    // const newLiabilityNameRef= useRef(null);
    // const newLiabilityValueRef= useRef(null);
    // const [showConnectModal,setShowConnectModal] =  useState(false);
    // const isFirstRenderRef = useRef(true);
    const location = useLocation();

    useEffect(() => {
        wealthStore.setUnSelectedClass();
        wealthStore.setViewOf('all');
        // wealthStore.setUnSelectedAssetClass();
        // wealthStore.setUnSelectedLiabilityClass();
        return () => { }
    }, [wealthStore,metadataStore]);

    useEffect(() => {
        // Only refresh wealth data if it's not the first render
        if (wealthStore.isLoadedOnce) {
          filtersStore.setAppliedFiltersFromUrlParams();
          filtersStore.setSelectedStateBasedOnAppliedFilters();
          wealthStore.refreshWealthDataFromUrlParams();
        } 
        // else {
        //   isFirstRenderRef.current = false;
        // }
      }, [filtersStore, wealthStore, location.search]);

    return (<Observer>{() => (
        <>
        <PageWrapper>
            {metadataStore.classWithItemsCount < 1 && wealthStore.isMainResults ?
                <OnBoardingHero /> :
                <WealthHero />}
            {!wealthStore.isEmptyFilterResults ? <CardContentBoxWrapper borderlessTop>
                {(metadataStore.classWithItemsCount > 1 || wealthStore.listViewMode !== 'allocations') && <ItemsListHeader />}
                {(!wealthStore.isEmptyFilterResults || filtersStore.singleClassFilter) ? <ItemsListWrapper withoutPadding={!wealthStore.isAllocationsViewMode}>
                    <ItemsListViewSwitcher isIncognito={uiStore.isIncognitoMode} />
                </ItemsListWrapper> : null}
            </CardContentBoxWrapper> : null}
            { uiStore.isDesktopView && wealthStore.isSingleClassFilterResults && filtersStore.singleClassFilter && wealthStore.selectedClass?.id !== 10 &&
                <ClassPageQuickAdd scrollContainerId={'wealthPageContent'} />
            }
                {(wealthStore.viewType === 'cashflow') && <InflowOutflowCalculationMessage>* Cash accounts and credit cards are not included in total inflows and outflows calculations.</InflowOutflowCalculationMessage>}
                {(wealthStore.showIRRTrendsNote) && <InflowOutflowCalculationMessage>* Items that are connected and synced to institutions are excluded from the Trend graph IRR calculations.</InflowOutflowCalculationMessage>}
        </PageWrapper>
        { metadataStore.classWithItemsCount > 1 && userStore.data?.profile?.showPortfolioUnlockPopup && <PortfolioUnlockModal onBackDropClick={() => {userStore.closePortfolioUnlockModal()}} /> }
        </>
    )}</Observer>)
}   