import { BrowserRouter as Router } from "react-router-dom";
import { Layout } from '../../layout/components/Layout';
import { AnalyticsProvider } from "../data/AnalyticsProvider";
import { RootStoreProvider } from '../data/root.store';

function App() {

  return (
    <RootStoreProvider>
      <AnalyticsProvider>
        <Router>
          <Layout />
        </Router>
      </AnalyticsProvider>
    </RootStoreProvider>
  );
}

export default App;