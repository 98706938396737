import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../common/styles/combos.styles";
import { colors, shadows } from "../../../common/styles/theme.styles";
import { PrimaryButton } from "../../../common/components/buttons/NewButtons.styles";


export const PanelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height:100%;
    flex:1;
`;

export const PanelHeader = styled.div`
    display: flex;
    padding:16px;
    background-color:${colors.lightGray1};
    border-bottom:1px solid ${colors.gray3};
`;

export const HeaderTitle = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
`


export const HeaderCloseButton = styled.div`
    ${flexCenter};
    cursor: pointer;
    svg {
        path {
            stroke-width: 1.5;
        }
    }
`;

export const FiltersPanelContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex:1;
    overflow-y:scroll;
`

export const ParameterPanelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom:1px solid ${colors.gray3};
`;

export const ParamHeaderWrapper = styled.div`
    width:100%;
    ${flexCenter};
    padding:16px;
    justify-content:flex-start;
    position: relative;
    cursor: pointer;
    ${props=>props.isOpen ? `
        background-color:${colors.lightGray1};
    ` : ``}
    :hover {
        background-color:${colors.lightGray1};
        ::after{
            content:'';
            position:absolute;
            left:0;
            top:0;
            width:2px;
            height:100%;
            background-color:${colors.darkGray1};
        }
    }
`;


export const ParamContentWrapper = styled.div`
    width:100%;
    ${flexCenter};
    justify-content:flex-start;
`;

export const ParamIconWrapper = styled.div`
    width:24px;
    height:24px;
    margin-right:8px;
    svg{
        width:24px;
        height:24px;
    }
`
export const ParamTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    flex:1;
`;

export const ParamTitleSelectionCount = styled.div`
    ${flexCenter}
    background: ${colors.darkGray1};
    border-radius: 8px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    min-width: 16px;
    width: 16px;
    height:16px;
    letter-spacing: .02em;
    font-weight: 500;
`;

export const ParamPanelAnimationWrapper = styled.div`
    transform: scaleY(${props=>props.isOpen ? '1' : '0'});
`;

export const ParamRowWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0;
    box-shadow: ${shadows.toggleShadow};
    border-radius: 26px;
`

export const ParamRowTitle = styled.div`
    ${flexCenter}
    font-size: 13px;
    line-height: 16px;
    color: ${colors.darkGray1};
    padding: 6px 8px;
`


export const PanelFooter = styled.div`
    ${flexCenter};
    padding: 16px 20px;
    gap: 24px;
    /* background-color:${colors.lightGray1}; */
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.05);
`;

export const ClearFilterButton = styled.div`
    ${flexCenter};
    color: ${colors.darkGray1};
    font-size: 14px;
    line-height: 17px;
    /* padding: 10px 8px; */
    min-width: 50px;
    min-height: 36px;
    cursor: pointer;
`;

export const ApplyFilterButton = styled(PrimaryButton)`
    // ${flexCenter};
    // color: #fff;
    // background: ${colors.darkGray1};
    opacity: ${props=>props.isDisabled ? `0.3` : `1`};
    border-radius: 4px;
    min-width: 200px;
    min-height: 36px;
    /* padding: 10px 64px; */
    // font-weight: 500;
    // font-size: 14px;
    // line-height: 17px;
    // cursor: pointer;
    `;

export const EmptyParamWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 18px;
`;

export const HashtagIconWrapper = styled.div`
    height: 24px;
    margin-bottom: 4px;
`;

export const EmptyParamTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 4px;
    color: ${colors.darkGray1};
`;

export const EmptyParamText = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${colors.darkGray3};
`;

