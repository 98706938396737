import { observer } from 'mobx-react';
import { useEffect, useState } from "react";
import { AppLoader } from "../../../../../../../common/components/Loaders/AppLoader";
import { useStore } from "../../../../../../app/data/root.store";
import { errorNotification } from "../../../../../../layout/components/Notifications/notifications";
import { HeroContent } from "../../../../../components/WealthLayout.styles";
import { fetchTrendsData } from "../../../../../data/wealth.service";
import { TrendsHeroContainer, TrendsHeroSegmented, TrendsHeroSegmentedButton } from "./TrendsHero.style";
import { TrendsHeroControl } from "./TrendsHeroControl";
import { TrendsHeroGraph } from "./TrendsHeroGraph";
import { getDiffByTrendsType } from "./TrendsUtils";

export const TrendsHeroContent = observer(({ id }) => {

    const { metadataStore: { classesObj }, filtersStore: { appliedFilters }, wealthStore, itemStore, uiStore } = useStore();
    // const [graphData, setGraphData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [reqKey, setReqKey] = useState('');
    // Rule of thumb: show current cash position if there is at least one asset in the first group
    const showCurrentCashPosition = !!wealthStore.assetsGroups?.[0]?.items?.length > 0 && wealthStore.viewOf !== 'item' && !(wealthStore.isSingleClassFilterResults && wealthStore.filterResultSingleClassId === 1);
    

    // Reset to total_net_worth if current_cash_position selected but not available
    if (
        (wealthStore.selectedTrendsView === 'current_cash_position' && (!showCurrentCashPosition || wealthStore.viewOf === 'item')) || 
        (!wealthStore.isAnyCategoryOfRelevantIrrExist && wealthStore.selectedTrendsView === 'xirr') ||
        (wealthStore.viewOf === 'item' && !itemStore.shouldShowIrrTrend)
    ){
        wealthStore.setTrendsView('total_net_worth');
    }
    

    const whiteTheme = (!!id);

    useEffect(() => {

        const getTrends = async () => {
            if (isLoading) return;
            
            try {
                const req = { timeframe: wealthStore.selectedTrendsTimeFrame, trendType: wealthStore.selectedTrendsView, allocateBy: wealthStore.allocateBy };
                if (id) {
                    req.assetIds = [+id];
                    req.isSingleAsset = true;
                } else if (Object.values(appliedFilters).length >= 1) {
                    req.assetIds = [...wealthStore.allItemsIds];
                }
                
                if (wealthStore.trendsReqKey === JSON.stringify(req) && !wealthStore.shouldRefreshTrendsData) {
                    return;
                }
                
                setIsLoading(true);
                // setReqKey(JSON.stringify(req));
                wealthStore.setTrendsGraphData([]);
                wealthStore.setTrendsReqKey(JSON.stringify(req));
                wealthStore.setShouldRefreshTrendsData(false);
                const [data] = await Promise.all([
                    fetchTrendsData(req),
                    new Promise(resolve => setTimeout(resolve, 200))
                ]);
                // setGraphData(data);
                wealthStore.setTrendsGraphData(data);
                setIsLoading(false);
            } catch (error) {
                // setGraphData([]);
                wealthStore.setTrendsGraphData([]);
                errorNotification('Error fetching trends data');
            } finally {
                setIsLoading(false);
            }
        };
        if (!wealthStore.isLoading) {
            getTrends();
        }

    }, [ isLoading, wealthStore, wealthStore.isLoading, wealthStore.trendsReqKey, wealthStore.selectedTrendsView, wealthStore.allocateBy, wealthStore.selectedTrendsTimeFrame, wealthStore.allItemsIds, wealthStore.shouldRefreshTrendsData, appliedFilters, id]);

    let lineColor = '#FFFFFF';
    if (wealthStore.viewOf === 'item') {
        lineColor = itemStore.item.categoryClass.color;
    } else if (appliedFilters?.assetsClasses?.length === 1) {
        const classId = appliedFilters?.assetsClasses[0];
        lineColor = classesObj[classId]?.color ?? '#FFFFFF';
    }

    const lastValue = wealthStore.trendsGraphData[(wealthStore.trendsGraphData.length - 1)]?.value ?? 0;
    const firstValue = wealthStore.trendsGraphData[0]?.value ?? 0;
    const diff = getDiffByTrendsType(firstValue, lastValue, wealthStore.selectedTrendsView);

    const onChangeTrendsType = (value) => {
        wealthStore.setTrendsView(value);
    }

    const getNetWorthTitle = () => {
        
        if (wealthStore.viewOf === 'item'){
            if (itemStore.hasConnectedLiability){
                return 'Gross asset value'
            }
            if ([5,10,11].includes(itemStore.item.categoryClass?.id) ){
                return 'Balance'
            }
            return 'Asset value'
            
        }
        if (wealthStore.isMainResults){
            return 'Total net value'
        }
        //if the selected class is Loans show the following title
        if (wealthStore.selectedClass?.id === 10) {
          return 'Total balance'
        }
        //Otherwise show the following default title
        return 'Total items worth'
    }

    const getNetWorthCurrency = () => {
        return wealthStore.viewOf === 'item' ? itemStore.item.currency : 'USD';
    }

    return (
        <HeroContent>
            <TrendsHeroContainer>
                <TrendsHeroControl
                    onChangeTrendsType={onChangeTrendsType}
                    selectedTrendsType={wealthStore.selectedTrendsView}
                    showAstric={(!id && ('xirr' === wealthStore.selectedTrendsView) && wealthStore.isContainConnectedItems)}
                    onChangeTrendsPeriod={wealthStore.setTrendsTimeFrame}
                    selectedTrendsPeriod={wealthStore.selectedTrendsTimeFrame}
                    whiteTheme={whiteTheme}
                    diff={diff}
                    value={lastValue}
                    showCurrentCashPosition={showCurrentCashPosition}
                    showIrr={wealthStore.isAnyCategoryOfRelevantIrrExist && !(wealthStore.viewOf === 'item' && !itemStore.shouldShowIrrTrend)}
                    isDesktopView={uiStore.isDesktopView}
                    isMainResults={wealthStore.isMainResults}
                    netWorthTitle={getNetWorthTitle()}
                    netWorthCurrency={getNetWorthCurrency()}
                    isLoading={isLoading}
                    showAbsoluteValues={wealthStore.selectedTrendsView !== 'xirr' && ( wealthStore.viewOf === 'item' || wealthStore.isFilterOnlyLiabilities)}
                    from={wealthStore.trendsGraphData?.[0]?.date}
                />
                <TrendsHeroGraph
                    data={wealthStore.trendsGraphData}
                    whiteTheme={whiteTheme}
                    lineColor={lineColor}
                    lineBgColor={lineColor}
                    trendType={wealthStore.selectedTrendsView}
                    showAbsoluteValues={wealthStore.selectedTrendsView !== 'xirr' && ( wealthStore.viewOf === 'item' || wealthStore.isFilterOnlyLiabilities)}
                />
                {!uiStore.isDesktopView &&
                    <TrendsHeroSegmented>
                        {
                            periodsOptions.map(option => (
                                <TrendsHeroSegmentedButton
                                    key={option.key}
                                    onClick={() => !option.block && wealthStore.setTrendsTimeFrame(option.key)}
                                    isSelected={wealthStore.selectedTrendsTimeFrame === option.key}
                                    whiteTheme={whiteTheme}
                                    style={option.block ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                                >
                                    {option.title}
                                </TrendsHeroSegmentedButton>))
                        }
                    </TrendsHeroSegmented>}
                {isLoading && <AppLoader backdrop absolute backgroundColor="#000000" logoColor="#FFFFFF" />}
            </TrendsHeroContainer>
        </HeroContent>
    )
});

const periodsOptions = [
    {
        key: 'ytd',
        title: 'YTD',
    },
    {
        key: 'one_year',
        title: '1Y'

    },
    {
        key: 'three_years',
        title: '3Y'

    },
    {
        key: 'max',
        title: 'Max'
    }
];