import "katex/dist/katex.min.css";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import ThumbDown from "../../../assets/images/ThumbDown";
import ThumbUp from "../../../assets/images/ThumbUp";
import { useStore } from "../../app/data/root.store";
import { ChatAvatarIcon } from "../assets/icons";
import { CUSTOM_DATA } from "../data/Assistance.consts";
import { useTypingEffect } from "../hooks/useTypingEffect";
import { AssistanceChatMessage, AssistanceChatMessageContent, AssistanceChatMessageTitle, AssistanceChatMessageWrapper, AssistanceMessageAvatar, AssistanceMessageContainer, AssistanceMessagesWrapper, AssistanceMessageUserAvatar, AssistanceMessageUserAvatarWrapper, AssistanceUserMessage, AssistanceUserMessageWrapper, FeedbackButton, FeedbackContainer } from "./Assistance.styles";
import { AssistanceChatMessageButton } from "./AssistanceChatMessageButton";
import ThumbUpDownFilled from "../../../assets/images/ThumbDownFilled";
import ThumbUpFilled from "../../../assets/images/ThumbUpFilled";
import { Tooltip } from "antd";
import { FeedbackDialog, FeedbackDialogWrapper } from "./FeedbackDialog";
import { successNotification } from "../../layout/components/Notifications/notifications";

export const AvatarMessage = ({ message, isLast, isTypingChanged, messageIndex }) => {
    const { userStore, assistanceStore } = useStore();
    const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
    const setIsTyping = useCallback((isTyping) => { 
        assistanceStore.setIsTyping(isTyping); 
        isTypingChanged && isTypingChanged(isTyping) 
    }, [assistanceStore, isTypingChanged]);
    const text = CUSTOM_DATA[message.a?.responseType]?.content ?? message.a?.content;
    const isCustom = !!CUSTOM_DATA[message.a?.responseType];
    const showTypingEffect = (isLast && (!!message.a?.isNew) && !isCustom);
    const displayText = useTypingEffect(text, showTypingEffect, setIsTyping);
    const assistanceRef = useRef(null);


    useEffect(() => {
        if (isLast) {
            assistanceRef.current.scrollIntoView({ block: 'end', behavior: 'instant' });
        }
    }, [isLast]);

    // Custom components configuration for ReactMarkdown
    const customComponents = {
        a: ({ node, ...props }) => (
            <a {...props} target="_blank" rel="noopener noreferrer" >{props.children}</a>
        )
    };

    const handleDislikeClick = () => {
        assistanceStore.sendFeedback(messageIndex, 'dislike');
        setShowFeedbackDialog(true);
    };

    const handleFeedbackSubmit = (option) => {
        // Here you can handle the specific feedback option
        assistanceStore.sendFeedbackReason(messageIndex, 'dislike', option);
        successNotification('Thanks for the input! Every bit helps us improve');
        setShowFeedbackDialog(false);
    };

    return (
        <AssistanceMessagesWrapper isLast={isLast} ref={assistanceRef}>
            <AssistanceMessageContainer isUser>
                <AssistanceUserMessageWrapper>
                    <AssistanceUserMessage>
                        <ReactMarkdown
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeKatex]}
                            components={customComponents}
                        >
                            {message.q?.replace(/\n/g, "\n\n")}
                        </ReactMarkdown>
                    </AssistanceUserMessage>
                </AssistanceUserMessageWrapper>
                <AssistanceMessageUserAvatarWrapper>
                    <AssistanceMessageUserAvatar>
                        {userStore.firstNameCapitalize[0] ?? 'U'}
                    </AssistanceMessageUserAvatar>
                </AssistanceMessageUserAvatarWrapper>
            </AssistanceMessageContainer>
            {
                <AssistanceMessageContainer>
                    <AssistanceMessageAvatar>
                        <ChatAvatarIcon />
                    </AssistanceMessageAvatar>
                    <AssistanceChatMessageWrapper>
                        {message.a?.title && <AssistanceChatMessageTitle>{message.a.title}</AssistanceChatMessageTitle>}
                        <AssistanceChatMessage>
                            {message.a?.title && <AssistanceChatMessageTitle>{message.a.title}</AssistanceChatMessageTitle>}
                            <AssistanceChatMessageContent>
                                {<ReactMarkdown
                                    remarkPlugins={[remarkMath, remarkGfm]}
                                    rehypePlugins={[rehypeKatex]}
                                    components={customComponents}
                                >
                                    {displayText?.replace(/\$/g, "\\$").replace(/\\[()[\]]/g, "$$")}
                                </ReactMarkdown>}
                                {isCustom && <AssistanceChatMessageButton type={message.a.responseType} q={message.q} />}
                            </AssistanceChatMessageContent>
                            {message.a && !message.a?.isNew && (
                                <FeedbackContainer>
                                    <Tooltip placement="bottom" title="Helpful" overlayInnerStyle={{ borderRadius: '6px', background: 'var(--Dark-Gray-1, #1A1B1D)' }}>
                                        <FeedbackButton
                                            onClick={() => assistanceStore.sendFeedback(messageIndex, 'like')}
                                            selected={message.a?.feedback === 'like'}
                                            aria-label="Like answer"
                                        >
                                            {message.a?.feedback === 'like' ? <ThumbUpFilled /> : <ThumbUp />}
                                        </FeedbackButton>
                                    </Tooltip>
                                    <Tooltip placement="bottom" title="Not helpful" overlayInnerStyle={{ borderRadius: '6px', background: 'var(--Dark-Gray-1, #1A1B1D)' }}>
                                        <FeedbackButton
                                            onClick={handleDislikeClick}
                                            selected={message.a?.feedback === 'dislike'}
                                            aria-label="Dislike answer"
                                        >
                                            {message.a?.feedback === 'dislike' ? <ThumbUpDownFilled /> : <ThumbDown />}
                                        </FeedbackButton>
                                    </Tooltip>
                                </FeedbackContainer>
                            )}
                            {showFeedbackDialog && message.a?.feedback === 'dislike' && (
                                <FeedbackDialogWrapper>
                                    <FeedbackDialog
                                        onClose={() => setShowFeedbackDialog(false)}
                                        onSubmit={handleFeedbackSubmit}
                                    />
                                </FeedbackDialogWrapper>
                            )}
                        </AssistanceChatMessage>
                    </AssistanceChatMessageWrapper>
                </AssistanceMessageContainer>
            }
        </AssistanceMessagesWrapper>
    );

};