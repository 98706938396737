import { Observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { AppLoader } from '../../../common/components/Loaders/AppLoader';
import { LogoLoader } from '../../../common/components/LogoLoader/LogoLoader';
import { removeUrlParams } from '../../../common/utils/navigation.utils';
import { ActivationChecklistPane } from '../../activation/components/checklist-pane/ChecklistPane';
import { DemoOverviewPane } from '../../activation/components/demo-overview-pane/DemoOverviewPane';
import { useStore } from '../../app/data/root.store';
import { DataLayersPanel } from '../../data-layers/components/DataLayersPanel';
import { Breadcrumbs } from '../../layout/components/Breadcrumbs/Breadcrumbs';
import { AppHeader } from '../../layout/components/Header/Header';
import { RightSidePushMenuWrapper } from '../../layout/components/Layout.styles';
import { GoodByeModal } from '../../onboarding/components/GoodbyeModal/GoodbyeModal';
import { DowngradePlanModal } from '../../onboarding/components/WelcomeModal/DowngradePlanModal';
import { WelcomeModal } from '../../onboarding/components/WelcomeModal/WelcomeModal';
import { AssetPage } from '../pages/asset/components/AssetPage';
import { WealthOverviewPage } from '../pages/overview/components/WealthOverviewPage';
import { CenterContent, PageContent, PageWrapper, } from './WealthLayout.styles';
import { UpgradePlanModal } from '../../onboarding/components/WelcomeModal/UpgradePlanModal';
import { ConnectedStockAssetPage } from '../pages/asset/components/ConnectedStockAsset/ConnectedStockAssetPage';
// import { AppMobileHeader } from '../../layout/components/Header/MobileHeader';
import { AfterReconnectModal } from '../../connected-institutions/components/after-reconnect-modal/AfterReconnectModal';

export function WealthLayout(props) {
  let { path } = useRouteMatch();
  const urlParams = new URLSearchParams(useLocation().search);
  const isAfterPayment = urlParams.get('subscribed_success') !== null;
  const shouldShowWelcomePopup = urlParams.get('sw') !== null;
  const [showSuccessPayment, setShowSuccessPayment] = useState(isAfterPayment && shouldShowWelcomePopup); // urlParams.get('subscribed_success') !== null && urlParams.get();
  const [showSuccessUpgrade, setShowSuccessUpgrade] = useState(isAfterPayment && !shouldShowWelcomePopup);


  // const isFirstPayment = new URLSearchParams(useLocation().search).get('fp') !== null;

  const wealthPageContentRef = useRef();
  
  const { wealthStore, filtersStore, itemStore, uiStore, userStore, connectedInstitutionsStore } = useStore();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    uiStore.setInWealthView(true);
    filtersStore.setAppliedFiltersFromUrlParams();
    wealthStore.getData();
    wealthStore.checkUpdates(itemStore, connectedInstitutionsStore);
    
    return () => {
      uiStore.setInWealthView(false);
      wealthStore.setViewType('overview');
    }
  }, [wealthStore, filtersStore, itemStore, connectedInstitutionsStore, uiStore]);



  useEffect(() => {
    const isShowingFamilyOffice = new URLSearchParams(location.search).get('fo') === '1';
    if (isShowingFamilyOffice) {
      userStore.setShowTier4FirstTimeExperienceModal(true);
    }
    return () => { }
  }, [location, userStore]);

  useEffect(() => {
    if (wealthPageContentRef) {
      uiStore.setWealthPageContentRef(wealthPageContentRef)
    }
    // return () => {}
  }, [wealthPageContentRef, uiStore])

  useEffect(() => {
    if (showSuccessPayment) {
      if (uiStore.isMobileScreenSize) {
        userStore.setShowAfterPaymentMobileModal(true);
      }
      userStore.isSuccessPayment()
    }
    if (showSuccessPayment || isAfterPayment) {
      removeUrlParams(history);
    }
    if (userStore.isDemoMode) {
      uiStore.setIsRightMenuOpen(uiStore.isDemoOverviewOpen);
    }
    // return () => {}
  }, [showSuccessPayment, isAfterPayment, userStore, uiStore, history])

  const onScroll = () => {
    if (wealthPageContentRef.current) {
      const { scrollTop } = wealthPageContentRef.current;
      if (scrollTop === 0) {
        uiStore.setIsBreadcrumbsWrapperSticky(false);
      } else {
        uiStore.setIsBreadcrumbsWrapperSticky(true);
      }
      if (scrollTop >= 404) {
        uiStore.setIsAssetEventsHeaderSticky(true);
      } else {
        uiStore.setIsAssetEventsHeaderSticky(false);
      }
    }
  };

  const handleLetsGo = () => {
    setShowSuccessPayment(false);
    window.location.reload();
  }

  return (<Observer>{() => (
    <> {uiStore.isDesktopView ?  <AppHeader withFilters createBlack /> : null } 
      {uiStore.isDesktopView && 
      <RightSidePushMenuWrapper >
        {userStore.isViewingOwnPortfolio &&  userStore.isGetStarted && <ActivationChecklistPane />}
        {userStore.isDemoMode && <DemoOverviewPane />}
      </RightSidePushMenuWrapper > }
      <PageWrapper isItemPageView={wealthStore.viewOf === 'item'}>
        {wealthStore.isLoadedOnce ? <>

          <PageContent ref={wealthPageContentRef} onScroll={onScroll} id="wealthPageContent">
            {uiStore.isDesktopView ? <Breadcrumbs /> : null }
            <CenterContent isCashFlowView={wealthStore.isCashFlowView}>
              <Switch>
                <Route exact path={`${path}/`}>
                  <Redirect to={`${path}/overview`} />
                </Route>
                <Route exact path={[`${path}/overview`]}>
                  <WealthOverviewPage />
                </Route>
                <Route exact path={[`${path}/projection`]}>
                  <Redirect to={`${path}/overview`} />
                </Route>
                <Route exact path={[
                  `${path}/assets/cash-accounts/projection`
                ]} >
                </Route>
                <Route exact path={`${path}/`}>
                  <Redirect to={`${path}/overview`} />
                </Route>
                <Route exact path={[`${path}/assets/:className/overview`]} >
                  <Redirect to={`${path}/overview`} />
                </Route>
                <Route path={[`${path}/assets/:className/:id/stock/:stockId/:activeTab`, `${path}/assets/:className/:id/stock/:stockId`]} >
                  <ConnectedStockAssetPage />
                </Route>
                <Route path={[`${path}/assets/:className/:id/:activeTab`, `${path}/assets/:className/:id`]} >
                  <AssetPage isWithBanner={userStore.isDemoMode} />
                </Route>
              </Switch>
            </CenterContent>
          </PageContent>
          <DataLayersPanel />

          {userStore.data?.profile?.showSuccessUpdatePlanPopup?.show ? (
            userStore.data?.profile?.showSuccessUpdatePlanPopup.isDowngrade ?
              <DowngradePlanModal onBackDropClick={() => { userStore.closeUpdatePlanModal() }} /> :
              <UpgradePlanModal onBackDropClick={() => { setShowSuccessUpgrade(false); userStore.closeUpdatePlanModal() }} />
          ) : null}
          {/* First time user Pay */}
            {showSuccessPayment && !uiStore.isMobileScreenSize ? <WelcomeModal onBackDropClick={handleLetsGo} /> : null}
            {/* First time pay - after tier_1 , sometimes user is still on free */}
            {showSuccessUpgrade && !userStore.data?.profile?.showSuccessUpdatePlanPopup?.show && <UpgradePlanModal onBackDropClick={() => { setShowSuccessUpgrade(false); userStore.closeUpdatePlanModal() }} />}
            {userStore.data?.profile?.showCancelSubscriptionPopup && <GoodByeModal onBackDropClick={() => { userStore.closeGoodBeyModal() }} />}
            {uiStore.reconnectErrorModal.show  && <AfterReconnectModal onBackDropClick={()=>{uiStore.setShowReconnectErrorModal(false)}} /> }
            {wealthStore.isLoading && <LogoLoader backdrop absolute />}

          </> : <AppLoader backdrop absolute mobileFixed/>}

        </PageWrapper>
      </>
  )}</Observer>)
}