import { makeAutoObservable } from "mobx";
import { isAmountBetweenThreshold } from "../../../common/utils/numbers.utils";
import { createEvent, deleteEvent, updateEvent , searchTransactions } from "./events.service";

export class EventsStore {

    isLoading = false;
    shouldReloadTransactions = false;
    optionalTransactions = [];
    isLoadingTransactions = false;
    isLoadingMoreTransactions = false;
    filterStr = '';
    isCurrentEventDistribution = false;
    currentEventTrnValue = 0;
    currentEventMonth = 0;
    currentEventDate = null;
    shouldShowLinkedTransaction = true;
    currentEventCurrency = 'USD';
    markedEventIndex = null;
    nextLink = '';

    constructor(){
        makeAutoObservable(this);
    }

    setIsLoading = (isLoading) => {
        this.isLoading = isLoading;
    }
    
    setShouldReloadTransactions = (shouldReload) => {
        this.shouldReloadTransactions = shouldReload;
    }

    setIsCurrentEventDistribution = (isDistribution) => {
        this.isCurrentEventDistribution = isDistribution;
    }

    setCurrentEventDetails = (month, date, isDistribution,trnValue,currency,showLinkedTrn) => {
        this.currentEventMonth = month;
        this.currentEventDate = date;
        this.isCurrentEventDistribution = isDistribution;
        this.currentEventTrnValue = trnValue;
        this.currentEventCurrency = currency;
        this.shouldShowLinkedTransaction = showLinkedTrn;
    }

    createEvent = async (itemId, eventData, itemStore = null, eventIndex= null) => {
        if(!this.isLoading) {
            this.setIsLoading(true);
            try {
                const createdEventData = await createEvent(itemId, eventData);
                itemStore && itemStore.showMessageAndUpdateItemAndWealthData(createdEventData,'Something went wrong','Updated successfully',
                ()=>{
                    if (eventIndex !== null) {this.markEvent(eventIndex) };
                });
                return createdEventData;
            } 
            //catch (e) {}
            finally {
                this.setIsLoading(false);
            }
        }
    }

    updateEvent = async (itemId, eventData, itemStore = null, eventIndex= null) => {
        if(!this.isLoading) {
            this.isLoading = true;
            try {
                const createdEventData = await updateEvent(itemId, eventData);
                itemStore && itemStore.showMessageAndUpdateItemAndWealthData(createdEventData,'Something went wrong','Updated successfully',
                ()=>{
                    if (eventIndex !== null) {this.markEvent(eventIndex) };
                });
            } 
            //catch (e) {}
            finally {
                this.isLoading = false;
            }
        }
    }

    deleteEvent = async (itemId, itemStore = null, eventIndex= null) => {
        try {
            const deleteData = await deleteEvent(itemId);
            itemStore && itemStore.showMessageAndUpdateItemAndWealthData(deleteData,'Something went wrong','Updated successfully',
            ()=>{
                if (eventIndex !== null) {this.markEvent(eventIndex) };
            });
        }
        //catch (e) {}
        finally {}
    }

    setIsLoadingTransaction = (isLoading) => {
        this.isLoadingTransactions = isLoading;
    }

    setIsLoadingMoreTransactions = (isLoading) => {
        this.isLoadingMoreTransactions = isLoading;
    }

    setNextLink = (link) => {
        this.nextLink = link;
    }

    async fetchTransactions(str, itemHoldings) {
        const eventValue = this.currentEventTrnValue * ( this.isCurrentEventDistribution ? -1 : 1 ) * (itemHoldings / 100);
        try {
            this.setIsLoadingTransaction(true)

            // Fetch data from the server
            const data = await searchTransactions(str,null,this.currentEventDate,eventValue,this.currentEventCurrency);
            this.setFilterStr(str);
            this.setNextLink(data?.links?.next);
            this.setOptionalTransactions(data.items);
        }
        finally {
            this.setIsLoadingTransaction(false)
        }
    }

    async callNext() {
        const eventValue = this.currentEventTrnValue * ( this.isCurrentEventDistribution ? -1 : 1 );
        try {
            this.setIsLoadingMoreTransactions(true)
            const data = await searchTransactions(this.filterStr, this.nextLink,this.currentEventDate,eventValue,this.currentEventCurrency);
            this.setNextLink(data?.links?.next);
            this.setOptionalTransactions([...this.optionalTransactions, ...data.items]);
        } finally {
            this.setIsLoadingMoreTransactions(false)
        }
    }


    // fetchTransactions = async() => {
    //     if ((this.optionalTransactions.length === 0 || this.shouldReloadTransactions ) && !this.isLoadingTransactions){
    //         this.setIsLoadingTransaction(true);
    //         try {
    //             const newTransactions  = await getTransactionsForEvent();
    //             // console.log(newTransactions);
    //             this.setOptionalTransactions(newTransactions)
    //         } 
    //         //catch (e) {}
    //         finally {
    //             this.setIsLoadingTransaction(false);
    //             this.setShouldReloadTransactions(false);
    //         }
    //     }
    // }
    setOptionalTransactions = (transactions) => {
        this.optionalTransactions = transactions;
    }

    setFilterStr(str){
        this.filterStr = str;
    }

    markEvent(ind){
        this.setMarkedEventIndex(ind);
        setTimeout(()=>{
            this.setMarkedEventIndex(null);
        },3000);
    }

    setMarkedEventIndex(ind){
        this.markedEventIndex = ind;
    }

    get displayedOptionalTransactions(){
        let filteredResults = [];
        // value filter 
        if (this.isCurrentEventDistribution){
            filteredResults = this.optionalTransactions.filter(trn=> trn.value < 0);
        } else {
            filteredResults = this.optionalTransactions.filter(trn=> trn.value > 0);
        }
        filteredResults = filteredResults.sort((a,b)=>{
            if ( a.currency !== this.currentEventCurrency && b.currency === this.currentEventCurrency ){return 1}
            if ( a.currency === this.currentEventCurrency && b.currency !== this.currentEventCurrency ){return -1}
            // if (a.currency !== this.currentEventCurrency && b.currency === this.currentEventCurrency ? 1 : a.currency === this.currentEventCurrency && b.currency !== this.currentEventCurrency){
            //     return -1;
            // }
            
            if (isAmountBetweenThreshold(a.value,b.value,20)){
                const firstItemMonthsDistance = Math.abs( a.month - this.currentEventMonth) ;
                const secondItemMonthsDistance = Math.abs( b.month - this.currentEventMonth );
                return firstItemMonthsDistance > secondItemMonthsDistance ? 1 : firstItemMonthsDistance < secondItemMonthsDistance ? -1 : 0;
            } else {
                const firstItemAmountDistance = Math.abs( Math.abs(a.value) - this.currentEventTrnValue );
                const secondItemAmountDistance = Math.abs( Math.abs(b.value) - this.currentEventTrnValue );
                return firstItemAmountDistance > secondItemAmountDistance ? 1 : firstItemAmountDistance < secondItemAmountDistance ? -1 : 0;
                //return b.month > a.month;
            }
            // return a.currency !== this.currentEventCurrency && b.currency === this.currentEventCurrency ? 1 : a.currency === this.currentEventCurrency && b.currency !== this.currentEventCurrency ? -1 :
            // ( Math.abs( Math.abs(a.value) - this.currentEventTrnValue ) > Math.abs( Math.abs(b.value) - this.currentEventTrnValue ) ? 1 : Math.abs( Math.abs(a.value) - this.currentEventTrnValue ) < Math.abs( Math.abs(b.value) - this.currentEventTrnValue ) ? -1 : 0);
        })
        filteredResults = filteredResults.filter(trn=>trn.remarks.toLowerCase().includes(this.filterStr.toLowerCase()) || (''+trn.value).includes(this.filterStr));
        return filteredResults; //.sort() ; // .slice(0,5);
    }
}
