import { Observer } from 'mobx-react';
import React from 'react'
import { CloseXIcon } from '../../../../assets/icons/common/common-icons';
import { useStore } from '../../../app/data/root.store';
import { CloseXIconWrapper, FilterTagTitle, FilterTagWrapper, LogoWrapper } from './FilterTagsList.styles';
import { ReactComponent as InstitutionPlaceholderIcon } from '../../../../assets/icons/common/institution-placeholder-icon.svg';
import { useHistory } from 'react-router-dom';
import { CommonTextOverflow } from '../../../../common/components/CommonTextOverflow/CommonTextOverflow';
import { navigateToUrl } from '../../../../common/utils/navigation.utils';
import { ROUTES } from '../../../../common/constants/routes.const';
export const FilterTag = (props) => {
  const { tag } = props;
  const { filtersStore } = useStore();
  let history = useHistory();

  const handleFilterTagWrapperOnClick = async () => {
    await filtersStore.fetchData();
    filtersStore.setParameterOpenByType(tag.type);
    filtersStore.setShowDrawer(true);
  }

  const handleCloseXIconWrapperOnClick = (e) => {
    e.stopPropagation();
    const filterHashParam = filtersStore.removeFilter(tag.type, tag?.deselectData);
    navigateToUrl(history, ROUTES.wealthOverview.url + `${filterHashParam ? `?filters=${filterHashParam}` : ''}` , false , true );
  }


  const InstitutionLogo = () => {
      if (tag.logoBase64) return <LogoWrapper><img alt={tag.connectedInstitutionName} src={'data:image/png;base64,' + tag.logoBase64} /></LogoWrapper>
      if (tag.logoURL) return <LogoWrapper><img alt={tag.connectedInstitutionName} src={tag.logoURL} /></LogoWrapper>
      return <LogoWrapper placeholder="true"><InstitutionPlaceholderIcon /></LogoWrapper>
  }

  const FilterIcon = () => {
    if (tag.type === 'institutions') return <InstitutionLogo />
    return null;
  }

  return (<Observer>{() => (
    <FilterTagWrapper
      tagColor={tag.tagColor}
      className='filterParameterBreadcrumbChild'
      onClick={handleFilterTagWrapperOnClick}>

      <FilterIcon />
      <FilterTagTitle>
      <CommonTextOverflow text={`${tag.titlePrefix + tag.title}${tag.selectedCategoriesCount ? ` +${tag.selectedCategoriesCount} filters` : ``}`} placement={'bottomLeft'} isLongText={true}/>
     
          </FilterTagTitle>
      <CloseXIconWrapper onClick={handleCloseXIconWrapperOnClick}><CloseXIcon /></CloseXIconWrapper>
    </FilterTagWrapper>
  )}</Observer>)
}
