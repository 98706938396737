import { observer } from "mobx-react";
import { AssistanceChatContainer, AssistanceChatFooter, AssistanceChatTextArea, AssistanceChatInputWrapper, AssistanceChatSendButton, AssistanceFooterContainer, AssistanceMessagesContainer, AssistanceDropdownList, AssistanceDropdownItem, AssistanceDropdownItemHighlightedWord, AssistanceDropdownItemWord, AssistanceDropdownItemText, AssistanceChatMobileScrollButton, AssistanceClarificationMessage, AssistanceClarificationMessageText } from "./Assistance.styles";
import { useEffect, useRef, useState, useCallback } from "react";
import { SendMessageIcon } from "../assets/icons";
import { useStore } from "../../app/data/root.store";
import { AvatarMessage } from "./AvatarMessage";
import { AssistanceEmptyState } from "./AssistanceEmptyState";
import { AssistanceTooltip } from "./AssistanceTooltip";
import { useAnalytics } from "../../app/data/AnalyticsProvider";
import debounce from 'lodash/debounce';

export const AssistanceChat = observer((props) => {
    const { isMobile } = props;
    const [message, setMessage] = useState('');
    const [isChatInputFocused, setIsChatInputFocused] = useState(false);
    const [isChatHaveScroll, setIsChatHaveScroll] = useState(false);
    const [isChatScrollPositionOnBottom, setIsChatScrollPositionOnBottom] = useState(true);
    const textareaRef = useRef(null);
    const chatRef = useRef(null);
    const { assistanceStore, uiStore, userStore } = useStore();

    const { trackEvent } = useAnalytics();

    useEffect(() => {
        const fetch = async () => {
            await assistanceStore.fetchHistory();
            if (assistanceStore.conversationId) {
                setTimeout(() => {
                    chatRef.current.scrollTo(0, chatRef.current.scrollHeight);
                }, 1);
                setIsChatInputFocused(false);
            }
        }
        fetch();
    }, [assistanceStore]);

    useEffect(() => {
        const closeDropdown = () => {
            setIsChatInputFocused(false);
        };
        window.addEventListener('onMouseDown', closeDropdown);

        return () => {
            window.removeEventListener('onMouseDown', closeDropdown);
        }
    }, []);

    useEffect(() => {
        return () => {
            uiStore.setIsMobileFooterHidden(false);
        }
    }, [uiStore]);

    useEffect(() => {
        const adjustTextareaHeight = () => {
            const textarea = textareaRef.current;
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        };

        adjustTextareaHeight();
    }, [message]);

    const checkScrollPosition = () => {
        if (chatRef.current) {
            const chatElement = chatRef.current;
            const scrollPosition = chatElement.scrollTop + chatElement.clientHeight;
            const clientHeight = chatElement.clientHeight;
            const scrollHeight = chatElement.scrollHeight;
            setIsChatHaveScroll(scrollHeight > clientHeight);
            setIsChatScrollPositionOnBottom(scrollPosition >= scrollHeight);
        }
    }

    const onSendMessage = (message) => {
        assistanceStore.sendMessage(message);
        setMessage('');
    };

    const handleTypingChanged = useCallback((isTyping) => {
        // Clear any existing interval when state changes
        if (window.typingCheckInterval) {
            clearInterval(window.typingCheckInterval);
            window.typingCheckInterval = null;
        }

        if (isTyping) {
            // Set up an interval to check for changes every 200ms while typing
            window.typingCheckInterval = setInterval(() => {
                checkScrollPosition();
            }, 200);
        }
        // When typing stops, the interval is cleared but no additional action needed
    }, []); // Empty dependency array to ensure stable function reference

    const handleKeyDown = (e) => {
        if (message && (e.key === "Enter") && !e.shiftKey) {
            e.preventDefault();
            onSendMessage(message);
            setIsChatInputFocused(false);
        }
    };

    const onDropdownItemClick = (item) => {
        onSendMessage(item);
        setIsChatInputFocused(false);
        trackEvent('CTA Clicked', {
            // destination: 'Assistance conversation dropdown',
            location: 'Assistance chat conversation dropdown',
            cta_text: item,
            type: 'Conversation starter selection',
            url: window.location.href
        });
    };

    const onInputChange = (e) => {
        const value = e.target.value;
        setMessage(value);
        assistanceStore.search(value);
    };

    const onInputFocus = () => {
        setIsChatInputFocused(true);
        if (uiStore.isMobileView || uiStore.isMobileAppView) {
            uiStore.setIsMobileFooterHidden(true);
        }
    }

    const onInputBlur = () => {
        setTimeout(() => {
            setIsChatInputFocused(false)
            if (uiStore.isMobileView || uiStore.isMobileAppView) {
                uiStore.setIsMobileFooterHidden(false);
            }
        }, 100);

    }

    return (
        <AssistanceChatContainer>
            <AssistanceMessagesContainer ref={chatRef} onScroll={debounce(checkScrollPosition, 100)}>
                {!isMobile && <AssistanceEmptyState isDemo={userStore.isDemoMode} />}
                {
                    assistanceStore.history.map((message, index) => (
                        <AvatarMessage
                            key={index}
                            message={message}
                            isLast={index === (assistanceStore.history.length - 1)}
                            isTypingChanged={handleTypingChanged}
                            messageIndex={index}
                        />
                    ))
                }
            </AssistanceMessagesContainer>
            <AssistanceChatFooter>
                {isMobile && !isChatInputFocused && isChatHaveScroll && !isChatScrollPositionOnBottom && <AssistanceChatMobileScrollButton onClick={() => chatRef.current.scrollTo({ top: chatRef.current.scrollHeight, behavior: 'smooth' })}>
                    <AssistanceChatMobileScrollButtonIcon />
                </AssistanceChatMobileScrollButton>}
                <AssistanceFooterContainer>
                    <AssistanceChatInputWrapper disabled={assistanceStore.isThinking || assistanceStore.isTyping}>
                        <AssistanceChatTextArea
                            ref={textareaRef}
                            placeholder={userStore.isDemoMode ? "Try a question about the demo portfolio..." : "Ask Vyzer AI a question..."}
                            value={message}
                            onChange={onInputChange}
                            onKeyPress={handleKeyDown}
                            disabled={assistanceStore.isThinking || assistanceStore.isTyping}
                            rows="1"
                            onFocus={onInputFocus}
                            onBlur={onInputBlur}
                        />
                        <DropdownList
                            isOpen={isChatInputFocused || (assistanceStore.history.length === 0 && window.innerHeight > (userStore.isDemoMode ? 788 : 730))}
                            items={assistanceStore.searchResults}
                            search={message}
                            onChooseItem={onDropdownItemClick}
                        />
                        {
                            message &&
                            <AssistanceTooltip title={'Send'} isSmall={true}>
                                <AssistanceChatSendButton onClick={(() => onSendMessage(message))}>
                                    <SendMessageIcon />
                                </AssistanceChatSendButton>
                            </AssistanceTooltip>
                        }
                        {/* {
                            assistanceStore.isThinking &&
                            <AssistanceTooltip title={'Stop response'} isSmall={true}>
                                <AssistanceChatThinkingButton>
                                    <SquareIcon />
                                </AssistanceChatThinkingButton>
                            </AssistanceTooltip>
                        } */}
                    </AssistanceChatInputWrapper>
                    <AssistanceClarificationMessage>
                        <AssistanceClarificationMessageText>
                            Like all AI, I am still learning and may make mistakes
                        </AssistanceClarificationMessageText>
                    </AssistanceClarificationMessage>
                </AssistanceFooterContainer>
            </AssistanceChatFooter>
        </AssistanceChatContainer>
    );
});

const DropdownList = ({ isOpen, items, search, onChooseItem }) => {
    if (!isOpen || !items.length) return null;

    return (
        <AssistanceDropdownList>
            {items.map((item, index) => (
                <AssistanceDropdownItem key={index} onMouseDown={() => onChooseItem(item)}>
                    <AssistanceDropdownItemText>{getHighlightedText(item, search)}</AssistanceDropdownItemText>
                </AssistanceDropdownItem>
            ))}
        </AssistanceDropdownList>
    );
};

const getHighlightedText = (text, highlight) => {
    if (!highlight?.trim()) return text;

    const words = highlight.split(/\s+/).filter(Boolean); // Split search term into words
    const regex = new RegExp(`\\b(${words.join("|")})\\b`, "gi"); // Match full words only

    return text.split(/(\s+|[^a-zA-Z0-9])/).map((word, index) =>
        regex.test(word) ? (
            <AssistanceDropdownItemHighlightedWord key={index}>{word}</AssistanceDropdownItemHighlightedWord>
        ) : (
            <AssistanceDropdownItemWord key={index}>{word}</AssistanceDropdownItemWord>
        )
    );
};

const AssistanceChatMobileScrollButtonIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ minWidth: '24px', minHeight: '24px' }}>
        <path d="M12 18L12 6M12 18L18 13M12 18L6 13" stroke="white" strokeWidth="1.5" />
    </svg>
)