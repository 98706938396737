import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react';
import { displayMoneyValue } from '../../../../../../common/utils/number-display.utils';
import { ApproveIcon, ClockIcon, CalendarIcon, TransactionSuggestionIcon } from '../../../../../../assets/icons/common/common-icons';
import { EventRow, EventRowSection, EventRowClockIconWrapper, EventRowLinkedTransactionIconWrapper, BackToTodayButton, HiddenDiv, TargetEventRowBadgeWrapper, ActualEventRowBadgeWrapper, EventRowIndicationAndDateWrapper, IndicationWrapper, CellText, EventPredictedAmount, EventAmount, OverdueEventRowBadgeWrapper, CanceledEventRowBadgeWrapper, EventAmountAndLinkIconWWrapper, IndicationCircle, EventRowSuggestionBugIndication, EventRowSuggestionBugIndicationText, EventRemarks, EventHoverColumn, HoverColumnRemarksWrapper, HoverColumnActionsWrapper, ActionButtonWrapper, MobileEventRowIconWrapper, MobileEventRowWrapper, MobileEventRowDescriptionWrapper, MobileEventRowValueWrapper, MobileEventTitle, MobileEventMoreDetails, MobileEventMoreDetailsSeparator, MobileEventMoreDetailsWrapper } from './AssetEventRow.styles';
import { Tooltip } from "antd";
import moment from 'moment';
import { useStore } from '../../../../../app/data/root.store';
import { EditEvent } from '../Actuals/EditEvent';
import { addMonths } from '../../../../../../common/utils/dates.utils';
import { useInView } from 'react-cool-inview';
import { ApproveRowIcon, ArrowUpIcon, EditRowIcon } from './assets/icons';
import { HoveredRemarks } from './HoveredRemarks';
import { additionalEventsAttributesByCategoryAndItemProps, categoriesEventsAfterAmountAttributes, categoriesEventsAttributes, categoriesTransactionAmountAdditionalDataFunction, categoriesValueDisplayFunction } from './categories-events-attributes.const';
import { ActionLocked } from '../../../../../../common/components/ActionLocked/ActionLocked';
import { CURRENCY_SYMBOL } from '../../../../../../common/constants/currency-symbol.const';
import { PredictedAmountDisplay } from './PredictedAmountDisplay/PredictedAmountDisplay';
import { columnsWithCurrencyIfNeeded } from './AssetEventsTab/AssetEventsTab';

export const AssetPredictedEventRow = (props) => {
  const { event, itemId, itemHoldings, itemCategoryId, itemCurrency, eventIndex, isMarked, isIncognito, openEditEventModal, onCloseEditEventModal, isEditDisabled } = props;
  const today = new Date();
  const pointData = addMonths(today, event.month);
  const eventDate = moment(pointData).utc().set('D',15).startOf('D');
  const [showEditEventModal, setShowEditEventModal] = useState(false);
  const { uiStore, eventsStore, itemStore, wealthStore, notificationsStore } = useStore();
  const isToday = eventIndex === itemStore.eventsSliderDefaultIndex;
  const { observe, inView, entry } = useInView({});
  const wealthPageContentElement = document.querySelector('#wealthPageContent');
  const listElm = document.querySelector('#item-events-list-wrapper');
  const [isHoveredOn, setIsHoveredOn] = useState(false);  

  const categoryAttributeColumns = categoriesEventsAttributes[itemCategoryId] || [];
  // const categoryAttributeAfterAmountColumns = categoriesEventsAfterAmountAttributes[itemCategoryId] || [];
  const categoryAttributeAfterAmountColumns = [
    ...(categoriesEventsAfterAmountAttributes[itemCategoryId] || []),
    ...(additionalEventsAttributesByCategoryAndItemProps.hasOwnProperty(itemCategoryId) ? additionalEventsAttributesByCategoryAndItemProps[itemCategoryId](itemStore.item) : [])
  ];
  const categoryAmountAdditionalDataFn = categoriesTransactionAmountAdditionalDataFunction[itemCategoryId] || null;
  const categoryValueDisplayFn = categoriesValueDisplayFunction[itemCategoryId] || null;

  useEffect(() => {
    if (openEditEventModal === event.eventType) {
      setShowEditEventModal(true);
    }
  
    // return () => {}
  }, [openEditEventModal, event.eventType])

  const handleCancelEventOk = async (eventType, formValues, createdEventDate, selectedTransaction, isCanceled, valueAtDate) => {
    const eventBody = {
      date: createdEventDate,
      title: formValues.eventTitle,
      eventType: props.event.eventType,
      isCanceled,
      creationType: isCanceled ? 'cancellation' : 'approval_edited',
      remarks: formValues.eventRemarks,
      eventData: {
        transactions: isCanceled ? [] :
          [...(
            selectedTransaction ? [
              {
                ...selectedTransaction,
              }
            ] : formValues.transactionValue ? [
              {
                date: createdEventDate,
                value: formValues.transactionValue * (eventType.isDistribution ? 1 : -1),
                ...(event.eventData && event.eventData.transactions && event.eventData.transactions.length && event.eventData.transactions[0].provider === 'manuall' ? { // DB TYPO ( sorry... )
                  id: event.eventData.transactions[0].id
                } : {})

              }
            ] : []), ...(eventType.otherTrnsFn ? eventType.otherTrnsFn(createdEventDate, formValues, event.eventData && event.eventData.transactions ? event.eventData.transactions : []) : [])],
        attributes: isCanceled ? [] : eventType.attributesToSend ?
          eventType.attributesToSend.map(attr => {
            const attrValue = attr.setFnForSave ? attr.setFnForSave(eventType.isDistribution, formValues[attr.fieldKey], formValues) : formValues[attr.fieldKey];
            if (attrValue === null){
              return null;
            }
            const attribute = {
              date: createdEventDate,
              data: {
                [attr.dataKey]: attrValue,
                isEditedManually: true
              }
            }

            const id = (event.eventData?.attributes?.find(at => at.data[attr.dataKey]))?.id
            if (id) {
              attribute.id = id
            }

            return attribute
          }).filter(a=>a) : [],
        valueUpdates: isCanceled ? [] : ((formValues.updatedValue || formValues.updatedValue === 0) && ((+formValues.updatedValue !== +valueAtDate) || eventType.sendValueUpdateAnyway)) ? [
          {
            date: createdEventDate,
            value: formValues.updatedValue,
            ...(event.eventData?.valueUpdates && event.eventData?.valueUpdates.length ? { id: event.eventData.valueUpdates[0].id } : {})
          }
        ] : [],
      }
    }
    if (event.eventId) {
      await eventsStore.updateEvent(itemId, { ...eventBody, id: event.eventId }, itemStore, eventIndex);
    } else {
      await eventsStore.createEvent(itemId, eventBody, itemStore, eventBody);
      const { eventType, month } = event;
      notificationsStore.removeNotification(itemId, eventType, month);
    }
    wealthStore.setGetLastChangesDataAfterExitPage();
    if ( eventType?.hasOwnProperty('showTargetModalAfterSaveFn')){
      const haveTargets = itemStore.itemEvents.slice().reverse().find((eve)=> !eve.id && !eve.eventId);
      if (haveTargets && eventType.showTargetModalAfterSaveFn({formValues})){
        itemStore.setShowEditTargetModal(true,eventType.targetFieldLink);
      }
    } 
  }

  const handleBackToToday = () => {
    const element = entry.target;
    const todayOffSetFromListTop = element.getBoundingClientRect().y - listElm.getBoundingClientRect().y;
    wealthPageContentElement.scrollTo({ top: 280 + todayOffSetFromListTop, behavior: 'smooth' })
  }

  const handleUnlinkTransactionSuggestion = () => {
    if (event.trnDetails) {
      itemStore.unlinkTransactionSuggestion(event.trnId, event.date);
    }
    
  }


  return (<Observer>{() => (
    <>
      {isToday && <HiddenDiv ref={observe} />}

      {uiStore.isDesktopView ? 

      <ActionLocked action={'editPredictedEventRow'}>
        <EventRow categoryAttributeCount={categoryAttributeColumns.length + categoryAttributeAfterAmountColumns.length} hasHappened={event.id || event.hasHappened} isOverdue={!event.isCanceled && !event.id && !event.hasHappened && event.month < 0} isMarked={isMarked} onClick={() => setShowEditEventModal(true)}
          onMouseEnter={() => { setIsHoveredOn(true) }} onMouseLeave={() => { setIsHoveredOn(false) }}>
          <EventRowSection>
            <EventRowIndicationAndDateWrapper>
              <IndicationWrapper>
                {!event.eventId && event.trnId ? <IndicationCircle isSuggestion /> :
                  !event.eventId && event.month < 0 ? <IndicationCircle /> : null}
              </IndicationWrapper>
              <CellText>{eventDate.format('MM-YYYY')}</CellText>
            </EventRowIndicationAndDateWrapper>
          </EventRowSection>
          {/* <EventRowSection>
          <EventRowDateWrapper isHidden={!event.isFirstInMonth && !isFirstRow}>
            <EventRowShortnameMonth>{eventDate.format('MMM')}</EventRowShortnameMonth>
            <EventRowFullYear>{eventDate.format('YYYY')}</EventRowFullYear>
          </EventRowDateWrapper>
        </EventRowSection> */}
          <EventRowSection >
            {/* <CellText><Tooltip title={'mmmmmm'} placement="top">{event.description}</Tooltip></CellText> */}
            
            <CellText>
              {event.fullDescription ? <Tooltip title={event.fullDescription} placement="top">{event.description}</Tooltip> :  
              event.description }</CellText>
              <PredictedAmountDisplay predictedAmount={event?.predictedAmount || event.amount} />
          </EventRowSection>

          {categoryAttributeColumns.map((column) => {
            return (
              <EventRowSection key={column.atrKey}>
              </EventRowSection>
            )
          })}

          <EventRowSection >
            {!event.eventId && event.trnId &&
              <EventRowSuggestionBugIndication>
                <EventRowSuggestionBugIndicationText>Suggested</EventRowSuggestionBugIndicationText>
                <Tooltip title={'Vyzer identified a bank transaction that matches this event'} placement="top">
                  <EventRowLinkedTransactionIconWrapper isSuggested><TransactionSuggestionIcon /></EventRowLinkedTransactionIconWrapper>
                </Tooltip>
              </EventRowSuggestionBugIndication>}

            {event.eventId && !event.isCanceled &&
              <EventAmountAndLinkIconWWrapper>
                <EventAmount isDistribution={event.amount * (event.eventData?.transactions?.length > 0 ? 1 : 0) * (event.trnDetails ? -1 : 1) > 0}>
                  {displayMoneyValue(
                    Math.abs(event.amount * (event.eventData?.transactions?.length > 0 ? 1 : 0)), (CURRENCY_SYMBOL[itemCurrency] || itemCurrency).length> 2 ? '' : itemCurrency, isIncognito, (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100)}
                </EventAmount>
                {event.trnDetails && <EventRowLinkedTransactionIconWrapper isDistribution={event.amount < 0} >
                  <Tooltip title={event.trnDetails?.remarks || 'Linked transaction'} placement="top">
                    <TransactionSuggestionIcon />
                  </Tooltip>
                </EventRowLinkedTransactionIconWrapper>}
              </EventAmountAndLinkIconWWrapper>}
              {categoryAmountAdditionalDataFn ? categoryAmountAdditionalDataFn(event, itemCurrency, isIncognito,  (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100) : null }
          </EventRowSection>

          {categoryAttributeAfterAmountColumns.map((column) => {
            return (
              <EventRowSection key={column.atrKey}>
                {/* {event.eventData?.attributes && event.eventData?.attributes.find(atr=> column.atrKey in atr.data) ? 'A' : event.eventData?.attributes.find(atr=> atr.data.hasOwnProperty('interestPayment')) ? '1' : } */}
                {event.eventData?.attributes && event.eventData?.attributes.find(atr => column.atrKey in atr.data) ? <EventAmount>
                  {displayMoneyValue(
                    event.eventData?.attributes.find(atr => column.atrKey in atr.data).data[column.atrKey],
                    columnsWithCurrencyIfNeeded.includes(column.title) && (CURRENCY_SYMBOL[itemCurrency] || itemCurrency).length> 2 ? '' : itemCurrency, 
                    isIncognito, (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100)}
                </EventAmount> : ( column.isPredictedAttribute ? 
                  <EventPredictedAmount>
                    {displayMoneyValue(event.predictedAttributes[column.atrKey], 
                      columnsWithCurrencyIfNeeded.includes(column.title) && (CURRENCY_SYMBOL[itemCurrency] || itemCurrency).length> 2 ? '' : itemCurrency, 
                      isIncognito, (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100)}
                    <EventRowClockIconWrapper hasHappened={event.id || event.hasHappened}>
                      <Tooltip title={'This is an expected event'} placement="top">
                        <ClockIcon />
                      </Tooltip>
                    </EventRowClockIconWrapper>
                  </EventPredictedAmount> : null )
                }
              </EventRowSection>
            )
          })}

          <EventRowSection isCurrencySymbolNotSupported={!CURRENCY_SYMBOL[itemCurrency]} >
            {event.eventData?.valueUpdates && event.eventData?.valueUpdates.length > 0 ?
              <EventAmount>
                {categoryValueDisplayFn ? categoryValueDisplayFn({value : event.eventData.valueUpdates[0].value}) : <>
                  {displayMoneyValue(
                  Math.abs(event.eventData.valueUpdates[0].value), 
                  (CURRENCY_SYMBOL[itemCurrency] || itemCurrency).length> 2 ? '' : itemCurrency, 
                  isIncognito, (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100)}
                </>}
              </EventAmount> :
              (!event.hasHappened &&  ( event.predictedValue || event.predictedValue === 0)) ?

                <EventPredictedAmount>
                  {displayMoneyValue(
                    Math.abs(event.predictedValue), 
                    (CURRENCY_SYMBOL[itemCurrency] || itemCurrency).length> 2 ? '' : itemCurrency, 
                    isIncognito, (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100)}

                  <EventRowClockIconWrapper hasHappened={event.id || event.hasHappened}>
                    <Tooltip title={'This is an expected event'} placement="top">
                      <ClockIcon />
                    </Tooltip>
                  </EventRowClockIconWrapper>
                </EventPredictedAmount> : null}
          </EventRowSection>


          <EventRowSection hiddenOverflow className="hideOnHover">
            <EventRemarks>{event.remarks}</EventRemarks>
          </EventRowSection>

          {/* <EventRowSection>
          {!event.isCanceled && !event.hasHappened &&
            <Tooltip title={`${!event.eventId && event.trnId ? `Vyzer identified a bank transaction that matches this event` : event.month < 0 ? `Vyzer wasn't able to confirm that this event occurred` : `Confirm this event occurred`}`} placement="top">
              <EventRowActionBtnWrapper>
                <ApproveIcon />
                {event.month < 0 && <ExclamationIconWrapper><ExclamationIcon /></ExclamationIconWrapper>}
                {!event.eventId && event.trnId && <TransactionSuggestionIconWrapper><TransactionSuggestionIcon /></TransactionSuggestionIconWrapper>}
              </EventRowActionBtnWrapper>
            </Tooltip>}
        </EventRowSection> */}

          <EventRowSection className="hideOnHover">
            {event.isCanceled ? <CanceledEventRowBadgeWrapper><ClockIcon />Canceled</CanceledEventRowBadgeWrapper> :
              !event.eventId && event.month < 0 ? <OverdueEventRowBadgeWrapper><CalendarIcon />Overdue</OverdueEventRowBadgeWrapper> :
                event.id || event.hasHappened ? <ActualEventRowBadgeWrapper><ApproveIcon />Actual</ActualEventRowBadgeWrapper> :
                  <TargetEventRowBadgeWrapper><ClockIcon />Target</TargetEventRowBadgeWrapper>}
          </EventRowSection>

          <EventRowSection hiddenOverflow className="showOnHover">
            <EventHoverColumn>
              <HoverColumnRemarksWrapper>
                {(isHoveredOn) && <HoveredRemarks remarks={event.remarks}></HoveredRemarks>}
              </HoverColumnRemarksWrapper>
              <HoverColumnActionsWrapper>
                {!event.eventId && event.trnId &&
                  <ActionButtonWrapper >
                    <EditRowIcon />Review
                  </ActionButtonWrapper>
                }
                {!event.eventId && <ActionButtonWrapper isApprove>
                  <ApproveRowIcon />Approve
                </ActionButtonWrapper>}
                {(event.eventId || !event.trnId) && <ActionButtonWrapper isOnlyIcon>
                  <EditRowIcon />
                </ActionButtonWrapper>}
              </HoverColumnActionsWrapper>
            </EventHoverColumn>
          </EventRowSection>
          {/* <EventRowSection>
          <ThreeDotsIconWrapper>
            <ThreeDotsIcon />
          </ThreeDotsIconWrapper>
        </EventRowSection> */}
        </EventRow>
      </ActionLocked> : 
      <ActionLocked action={'editPredictedEventRow'}>
        <MobileEventRowWrapper isMarked={isMarked} onClick={() => setShowEditEventModal(true)}>
          <MobileEventRowIconWrapper>
        {event.isCanceled ? <CanceledEventRowBadgeWrapper mobileBox><ClockIcon /></CanceledEventRowBadgeWrapper> :
              !event.eventId && event.month < 0 ? <OverdueEventRowBadgeWrapper mobileBox><CalendarIcon /></OverdueEventRowBadgeWrapper> :
                event.id || event.hasHappened ? <ActualEventRowBadgeWrapper mobileBox><ApproveIcon /></ActualEventRowBadgeWrapper> :
                  <TargetEventRowBadgeWrapper mobileBox><ClockIcon /></TargetEventRowBadgeWrapper>}
          </MobileEventRowIconWrapper>
          <MobileEventRowDescriptionWrapper>
              <MobileEventTitle>
                {event.fullDescription || event.description}
              </MobileEventTitle>
              <MobileEventMoreDetailsWrapper>
                <MobileEventMoreDetails>{eventDate.format('MM-YYYY')}</MobileEventMoreDetails>
                <MobileEventMoreDetailsSeparator>|</MobileEventMoreDetailsSeparator>
                <PredictedAmountDisplay predictedAmount={event?.predictedAmount || event.amount} />
              
              </MobileEventMoreDetailsWrapper>
          </MobileEventRowDescriptionWrapper>
          <MobileEventRowValueWrapper>
          {event.eventId && !event.isCanceled &&
              <EventAmountAndLinkIconWWrapper>
                <EventAmount isDistribution={event.amount * (event.eventData?.transactions?.length > 0 ? 1 : 0) * (event.trnDetails ? -1 : 1) > 0}>
                  {displayMoneyValue(
                    Math.abs(event.amount * (event.eventData?.transactions?.length > 0 ? 1 : 0)), itemCurrency, isIncognito, (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100)}
                </EventAmount>
                {event.trnDetails && <EventRowLinkedTransactionIconWrapper isDistribution={event.amount < 0} >
                  {/* <Tooltip title={'Linked transaction'} placement="top"> */}
                    <TransactionSuggestionIcon />
                  {/* </Tooltip> */}
                </EventRowLinkedTransactionIconWrapper>}
              </EventAmountAndLinkIconWWrapper>}
              {categoryAmountAdditionalDataFn ? categoryAmountAdditionalDataFn(event, itemCurrency, isIncognito,  (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100) : null }
          </MobileEventRowValueWrapper>
        </MobileEventRowWrapper>
      </ActionLocked>}
      {uiStore.isDesktopView && isToday && !inView && <BackToTodayButton isJumpDown={entry && entry.target.getBoundingClientRect().y - listElm.getBoundingClientRect().y > wealthPageContentElement.scrollTop} onClick={handleBackToToday} ><ArrowUpIcon />Back to today</BackToTodayButton>}

      {showEditEventModal && <EditEvent event={event}
        isFormDisabled={isEditDisabled}
        itemId={itemId} 
        itemCurrency={itemCurrency}
        itemHoldings={itemHoldings}
        eventIndex={eventIndex}
        onClose={() => { setShowEditEventModal(false); onCloseEditEventModal?.() }} 
        itemCategoryId={itemCategoryId}
        onSave={async ({ eventType, formValues, eventDate, selectedTransaction, valueAtDate }) => { await handleCancelEventOk(eventType, formValues, eventDate, selectedTransaction, false, valueAtDate) }}
        onCancel={({ eventType, formValues, selectedTransaction }) => { handleCancelEventOk(eventType, formValues, eventDate, selectedTransaction, true) }}
        handleUnlinkTransactionSuggestion={handleUnlinkTransactionSuggestion}
      />}
    </>
  )}</Observer>)
}