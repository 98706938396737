import styled from '@emotion/styled/macro';
import { colors } from '../../../../../common/styles/theme.styles';

export const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px 8px 20px;
  border-bottom: 1px solid #E8E9ED;
`;

export const DrawerTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.darkGray1};
`;

export const DrawerCloseIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DrawerContentWrapper = styled.div`
  max-height: calc(100vh - 192px);
  overflow-y: auto;
`;

export const InsightItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 8px 16px 16px;
  gap: 4px;
  border-bottom: 1px solid #E8E9ED;
  cursor: pointer;
  ${props=>props.npb && `padding-bottom: 0px;`}
`;

export const InsightHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const InsightItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;
export const InsightItemTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.darkGray1};
`;

export const InsightItemTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // gap:8px;
  width: 100%;
  padding-left:8px;
`;

export const InsightItemText = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: 450;
  color: ${colors.darkGray2};
  line-height: 20px;
`;

export const InsightItemArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
`;

export const InsightItemInnerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding:0 16px 0 8px;
`;

export const InsightItemInnerContentItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;;
  justify-content: flex-start;
  width: 100%;
  padding:12px 16px 16px 0;
  gap: 8px;
  border-top: 1px solid #E8E9ED;
`;

export const InsightItemInnerContentItemTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;

export const InsightItemInnerContentItemTitle = styled.div`
   font-size: 14px;
  font-weight: 700;
  color: ${colors.darkGray2};
`;

export const InsightItemInnerContentItemDescription = styled.div`
  font-size: 13px;
  font-weight: 450;
  color: ${colors.darkGray3};
`;

export const InsightItemInnerContentLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size:13px;
  font-weight: 450;
  color: ${colors.darkGray1};
  width: 100%;
  padding: 12px 16px;
  border:1px solid ${colors.gray3};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
`;

export const ClearHistoryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 48px);
  margin: 16px 24px;
  padding: 12px;
  gap: 8px;
  background-color: #1A1B1D;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  
  &:hover {
    background-color: #000000;
  }
`;

export const ClearHistoryIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg path {
    fill: #FFFFFF;
  }
`;

export const ClearHistoryText = styled.span`
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
`; 