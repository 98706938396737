export const navigateToUrl = (history, url, replaceState = false, forceReplace = false) => {
  // console.log(history.location.search)
  if (forceReplace || history.location.pathname !== url){
    if (replaceState){
      // console.log('replaceState', url)
      // Extract query parameters from the URL if present
      const [path, queryString] = url.split('?');
      const search = queryString ? `?${queryString}` : '';
      history.replace({pathname: path, search})
    } else {
      // console.log('push', url)
      history.push(url);  
    }
  }
} 

export const navigateToUrlWithQuery = (history, url, replaceState = false) => {
  // console.log(history.location.search)
  const search = history.location.search;
  if (history.location.pathname !== url){
    if (replaceState){
      history.replace({pathname: url,search})
    } else {
      history.push({pathname: url,search});  
    }
  }
} 

export const removeUrlParams = (history) => {
  history.replace({
    search: '',
  })
}

export const removeUrlParam = (history, location, param) => {
  const queryParams = new URLSearchParams(location.search)
  if (queryParams.has(param)) {
    queryParams.delete(param);
    history.replace({
      search: queryParams.toString(),
    })
  }
  
}