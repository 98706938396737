import { BulkEditUndoModal, BulkEditUndoModalBody, BulkEditUndoModalCot, BulkEditUndoModalIcon, BulkEditUndoModalSeparator, BulkEditUndoModalText, BulkEditUndoModalUndoText } from "./DeepAnalysisReviewResultModal.styles";

export const DeepAnalysisReviewBulkEditUndo = ({ onUndo, onClose, text }) => {
    return (
        <BulkEditUndoModal>
            <BulkEditUndoModalCot>
                <BulkEditUndoModalBody>
                    <BulkEditUndoModalUndoText onClick={onUndo}>Undo</BulkEditUndoModalUndoText>
                    <BulkEditUndoModalSeparator />
                    <BulkEditUndoModalText>{text}</BulkEditUndoModalText>
                    <BulkEditUndoModalIcon onClick={() => onClose()} >
                        <CloseIcon />
                    </BulkEditUndoModalIcon>
                </BulkEditUndoModalBody>
            </BulkEditUndoModalCot>
        </BulkEditUndoModal>
    );
};

export const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <g clip-path="url(#clip0_97_4519)">
                <path d="M1.25 1.25L8.75 8.75M8.75 1.25L1.25 8.75" stroke="#ACB1BB" stroke-width="1.2" />
            </g>
            <defs>
                <clipPath id="clip0_97_4519">
                    <rect width="10" height="10" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};