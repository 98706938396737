import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../common/styles/combos.styles';
import { colors, shadows, sizes, views } from '../../../common/styles/theme.styles';

export const PageWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1 1;
    // overflow-y: scroll;
    // padding:0px 0px 16px;
    // align-items: center;
    background-color: ${colors.lightGray2};
    position:relative;
    overflow:hidden;
`   

export const PageSideMenu = styled.div`
    width:336px;
    background: #FFFFFF;
    box-shadow: 0px 115px 70px rgba(0, 0, 0, 0.08);
    z-index: 2;
`

export const PageContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow-y: auto;
    position: relative;
    padding-bottom:56px;
    /* padding:16px 0px; */
    // padding-inline-start:calc(100vw - 100%);
    /* padding:0px 0px 16px; */
    ${views.tablet}{
        padding-bottom: calc( env(safe-area-inset-bottom) + 64px );
    }
`;

export const CenterContent = styled.div`
    width: 100%;
    max-width: ${props=> (props.isCashFlowView ? sizes.contentWidth : sizes.wealthContentWidth) + 32}px;
    margin: 0 auto;
    padding: 0;
    ${views.desktop}{
        padding: 0 16px;
    }
`;

export const ViewTypeSwitcherWrapper = styled.div`
    width:100%;
    padding:4px;
    background-color:${colors.gray3};
    height:44px;
    border-radius:5px;
    display:flex;
    //margin-left: 24px;
    margin-bottom: 16px;
    ${flexCenter};
    position:relative;
`;

export const ViewTypeSwitcherButton = styled.div`
    color:${colors.darkGray1};
    font-size:14px;
    font-weight:500;
    flex:1;
    ${flexCenter};
    align-self: stretch;
    padding:0px 12px;
    z-index:1;
    cursor: pointer;
    /* :hover{
        color:${colors.darkGray1};
    } */
    &.selected{
        /* background-color:#fff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
        border-radius: 4px; */
    }
`;

export const ViewTypeSwitcherSelectedBg = styled.div`
    flex:0.5;
    background-color:#fff;
    align-self: stretch;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
    position:absolute;
    width:calc(50% - 4px);
    height:calc(100% - 8px);
    left:4px;
    margin-left: ${props=>props.isOverView ? '0px' : 'calc(50% - 4px)'};
    transition: margin 0.2s ease-out;
    
`;

export const HeroWrapper = styled.div`
    
    background-color: ${colors.darkHeroBackground};
    /* Card Shadow */
    box-shadow: ${shadows.cardShadow};
    
    // padding:62px;
    display:flex;
    flex-direction:column;
    /* margin-bottom:16px; */
    margin-top:${props=>props.topOffset ? props.topOffset : 0}px;
    ${props => props.borderlessBottom ? `border-bottom:none; border-bottom-left-radius:0px; border-bottom-right-radius:0px;` : ``}
    height:calc(100% - 116px);
    ${views.desktop}{
        border: 1px solid #DDE3EB;
        border-radius: ${props => props.borderlessBottom ? `8px 8px 0 0` : `8px`};    
        min-height:412px;
    }

`;

export const HeroHeader = styled.div`
    /* min-height:40px; */
    display:flex;
    /* align-items: flex-end; */
    justify-content:flex-start;
    align-items: center;
    
    
    gap: 12px;
    height: 52px;
    min-height: 52px;
    padding: 0px 8px 0 20px;
  
    ${views.desktop}{
        padding: 0px 24px;
        min-height: 70px;
        border-bottom:1px solid ${colors.darkGray2};
    }
    position:relative;
    ${views.tablet}{
        transition: padding 0.2s ease-out;
        position: ${props=>props.headerNotFixed || !props.isFixed ? `relative` : `fixed`};
        top: 0;
        background-color: #111213;
        z-index: 2;
        width:100%;
        min-height: 64px;
        height: 64px;
        ${props=>props.withFilters ? `padding-left: 32px;` : ``}
    }
    ${props=>props.isSearchOpen ? `padding-left: 8px;` : ``}
    ${props=>props.isFixed ? `` : ``}
    ${props=>props.headerNotFixed ? `
        position: relative;
    ` : ``}
`;

export const HeroMobileBackButtonWrapper = styled.div`
    position:absolute;
    top:0;
    bottom:0;
    left:8px;
    width:20px;
    ${flexCenter};
    svg{
        transform:rotate(90deg) scaleX(1.5);
        path{
            stroke:#fff;
        }
    }
    
    

`;


export const HeroTitleWrapper = styled.div`
    font-weight:500;
    font-size:16px;
    color:#fff;
    flex:1;
    ${flexCenter};
    justify-content:flex-start;
    max-width: 100%;
    overflow: hidden;
`;

export const HeroTitleTextWrapper = styled.div`
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
`;

export const HeroClassIconWrapper = styled.div`
    height:32px;
    width:32px;
    border-radius: 2px;
    background-color:${props=>props.color};
    ${props => props.round ? 'border-radius: 50%;' : ''}
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:12px;
    svg{
        width:24px;
        height:24px;
        rect{
            ${props => props.withoutFillColor ? `` : `fill:#fff;`}
            ${props => props.strokeColor ? `stroke: ${props.strokeColor}` : null}
        }
    }
    path{
        ${props => props.withoutFillColor ? `` : `fill:#fff;`}
        ${props => props.strokeColor ? `stroke: ${props.strokeColor}` : null}
    }
    img {
        width: 100%;
    }
`;

export const HeroTabsWrapper = styled.div`
    margin-bottom: -1px;
    margin-right: 8px;
    .ant-tabs-tab{
        margin:0px;
        padding: 14px 0
        
        
    }
    ${views.desktop}{
        .ant-tabs-tab{
            padding: 24px 0
        }
    }   
    .ant-tabs-tab-btn{
        color:${colors.gray1};
        font-weight: normal;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        //color:#000;
        color:#FFF;
        font-weight:normal;
    }

    .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {

        /* background-color:${colors.gray1}; */
        background-color:#fff;
        height: 2px;
        // background-color:#000;
    }

    .ant-tabs-top > .ant-tabs-nav{
        margin-bottom: 0px;
        /* padding: 0px 16px 0; */
        ::before{
            border-bottom:none;
        }
    }
`;

export const  HeroTabTitleWrapper = styled.div`
    /* padding:0 12px 0 12px; */
    display:flex;
    align-items:center;
`;

export const HeroTabTitle = styled.div`

`;

export const MobileIconTabWrapper = styled.div`
    padding:0px 8px;
    ${flexCenter};
`;

export const MobileHeaderActionsWrapper = styled.div`
    ${flexCenter};
    flex: ${props=>props.isSearchOpen ? 1 : 0};
`;

export const HeroContent = styled.div`
    display:flex;
    flex:1;
    flex-direction:column;
    align-items:stretch;
    ${views.desktop}{
        flex-direction: row;
        align-items:inherit;
    }
    
`;

export const HeroMetricsPane = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${views.desktop}{
        flex: 5;
    }
    
`;

export const HeroGraphPane = styled.div`
    flex: 3.8;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const HeroDataBox = styled.div`
    flex:1;
    
`;
export const ChartWrapper = styled.div`
     // padding:16px 0px;
     flex:1;
     /* padding:16px 0px; */
     max-width:276px;
     width:100%;
     ${views.tablet}{
        width:80%;
        padding:24px 0px;
     }
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center
     
`;

export const SyncingValueIndicatorWrapper = styled.div`
    display: flex;
    align-items: center;
    color: white;
    font-size:14px;
    gap: 8px;
    position:absolute;
    bottom:10px;
`;

export const ComingSoonWrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const ComingSoonGraph = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url('${props => props.img ? props.img : ''}');
    border-radius: 4px;
`;

export const ComingSoonText = styled.h2`
    color: #9097A3;
    position: absolute;
    left: 50%;
    top: 90px;
    transform: translateX(-50%);
`;

export const CommunitySubtitle = styled.div`
    color: ${colors.gray2};
    font-size: 12px;
`;

export const StockIconWrapper = styled.div`
  ${flexCenter};
  border-radius: 23px;
  img{
    border-radius: 23px;
    object-fit: contain;
    background-color: #fff;
  }
`;

export const StockIconPlaceholder = styled.div`
  ${flexCenter};
  font-weight: 500;
  border-radius: 50%;
  width: 100%;
  color: ${colors.darkGray1};
`;

export const MobileOverViewInflowOutflowSelectorWrapper = styled.div`
position: absolute;
top: ${props => props.isHeaderFixed ? '14px' : '78px'};
left: 0;
height: 30px;
z-index: 1;
display: flex;
padding-left: 20px;
${props => props.isAssetPage ? `
    top:14px;
    ` : ``}
`;

export const MobileOverViewInflowOutflowSelectorButton = styled.div`
    // position: absolute;
    color: #9097A3;
    font-size:14px;
    svg {margin-left:8px}
    // margin-top:8px;
    cursor: pointer;
    ${flexCenter};
    pointer-events:auto;
    svg {
        path {
            stroke:#9097A3;
        }
    }
    ${props=>props.isActive ? `
        background-color: #80808045;
    ` : `
    
    `}
`;

export const OverviewInflowOutflowDrawerCloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;