import { Observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
// import moment from 'moment';
import {
  PieTextBigValue,
  PieTextContent,
  PieTextHoverWrapper
  // , ClassIconWrapper, ClassTitleText, ClassSumText
  ,


  PieTextLabel,
  PieTrendsLink,
  PieValueContainer,
  PieWrapper
} from './WealthPieChart.styles';
// import { Doughnut } from 'react-chartjs-2';
import { useStore } from '../../../../app/data/root.store';
// import { AssetClassIcon } from '../../../../assets/components/AssetClassIcon';
// import { LiabilityClassIcon } from '../../../../liabilities/components/LiabilityClassIcon';
import { displayMoneyValue } from '../../../../../common/utils/number-display.utils';
// import { customTooltip } from './chartTooltips';

import { GoToIcon } from '../../../../../assets/icons/common/common-icons';
import { ChartComponent } from '../../../../../common/components/charts/ChartComponent';
// import { CountAnimation } from '../../../../../common/components/count-animation/CountAnimation';
import { allocationByFilterOptions } from '../../../../allocations/data/allocations.const';
import { MagicBoxAnimatedIconGrey } from '../../../../magic-box/components/MagicBoxAnimatedIconGrey';
import { ROUTES } from '../../../../../common/constants/routes.const';
import { navigateToUrl } from '../../../../../common/utils/navigation.utils';


export function WealthAllocationPieChart(props) {

  const { wealthStore, uiStore, filtersStore } = useStore();
  let history = useHistory();
  const { bigTextSize } = props;
  const [hoverData, setHoverData] = useState({ datasetIndex: null, index: null, isHoveredOnce: false });
  const containerRef = useRef();
  const textRef = useRef();
  const [textSize, setTextSize] = useState(bigTextSize ?? 32);
  // const optionsRef = useRef(null);
  const [chartOptions, setChartOptions] = useState({ cutout: '90%' });
  // const getTooltipItem = (dataSetIndex, dataIndex) => {
  //     // console.log("mm",wealthStore.isLibabilityClassView)
  //     // const assetGroup = wealthStore.assetsGroups[dataIndex];
  //     const relevantGroup = (dataSetIndex === 1 ? ( dataIndex < 2 ? wealthStore.liabilitiesGroups[dataIndex] : null ) : wealthStore.assetsGroups[dataIndex]) ;
  //     return relevantGroup ? {
  //       label: relevantGroup.title,
  //       value: relevantGroup.totalUsdValue,
  //       isIncognito: uiStore.isIncognitoMode,
  //       show: true
  //     } : {
  //       show: false
  //     };
  //   }

  // const someCallBack = useCallback(() => {
  //   console.log("Here");
  //   setHoveredClass(null);
  // },[setHoveredClass]);

  const getChartOptions = useCallback(() => {
    return {
      maintainAspectRatio: true,
      // animation:false,
      // tooltips: { enabled: false },
      // tooltips: customTooltip(getTooltipItem),
      cutout: '90%',
      onHover: (event, chartElement) => {
        // console.log(event,chartElement);
        // const canvasPosition = getRelativePosition(e, chart);
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';

        // if (chartElement[0] && chartElement[0]._index === 4){
        //   someCallBack();
        // }
        if (chartElement.length) {
          const relevantGroup = wealthStore.chartAssetsDataForTooltips[chartElement[0].index];
          // if (datasetIndex === 1){
          //     const liabilities =  wealthStore.chartAssetsDataForTooltips.filter(g => g.isLiability || g.usdValue < 0);
          //     if (dataIndex < liabilities.length) {
          //       relevantGroup = liabilities[dataIndex];
          //     }
          // } else {
          //   relevantGroup = wealthStore.chartAssetsDataForTooltips.filter(g => !g.isLiability && g.usdValue > 0)[dataIndex];
          // }
          setHoverData(relevantGroup ? {
            // datasetIndex,
            // dataIndex,
            show: true,
            title: relevantGroup.title,
            value: relevantGroup.usdValue,
            isHoveredOnce: true
          } : { show: false, isHoveredOnce: true })
        } else {
          setHoverData({
            show: false,
            isHoveredOnce: true
            // datasetIndex:null,
            // dataIndex:null
          })
        }
        // setSomeNumber(someNumber+1);
      },

      onClick: (event, chartElement) => {

        const relevantGroup = wealthStore.chartAssetsDataForTooltips[chartElement[0].index];
        const allocationFilterOptions = allocationByFilterOptions[wealthStore.allocateBy];
        let refreshData = true;
        if (wealthStore.allocateBy === 'customClasses') {
          filtersStore.handleSelectCustomClass(relevantGroup.filterValue);
        } else if (wealthStore.allocateBy === 'customSubClasses') {
          filtersStore.handleSelectSubClassFromAllocation(relevantGroup.customClassId, relevantGroup.filterValue);
        }
        else if (allocationFilterOptions) {
          if (allocationFilterOptions.filterType === 'list') {
            filtersStore.setListOptionSelectedFromAllocation(allocationFilterOptions.filterPropName, relevantGroup.filterValue);
          }
        } else {
          refreshData = false
        }
        if (refreshData) {
          const urlParams = filtersStore.setAppliedFiltersFromSelection();
          navigateToUrl(history, ROUTES.wealthOverview.url + `?filters=${urlParams}`, false, true);
          // filtersStore.setAppliedFiltersFromSelection();
          // wealthStore.refreshWealthData(false, history);
        }
      },
      plugins: {
        tooltip: { enabled: false },
      }
    }
  }, [
    filtersStore, history,
    wealthStore])

  useEffect(() => {
    setChartOptions(getChartOptions());
    return () => {
    }
  }, [getChartOptions, wealthStore.allocateBy])

  useEffect(() => {
    const containerElement = containerRef.current;
    const textElement = textRef.current;

    if (containerElement && textElement) {
      const availableWidth = containerElement.clientWidth - 80;
      const textWidth = textElement.scrollWidth;

      setTextSize(textWidth > availableWidth ? (availableWidth / textWidth) * 32 : 32);
    }
  }, []);

  const goToTrendsView = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('hvm', 'trends');
    navigateToUrl(history, `overview?${searchParams.toString()}`, false);
    wealthStore.setTrendsView('total_net_worth')
  }

  return (<Observer>{() => (
    <PieWrapper>

      <ChartComponent key="catCharts" type='doughnut' data={wealthStore.chartAssetsData} options={chartOptions} height={props.height} />




      {/* <Doughnut 
        // getElementAtEvent={onDataPointClick}
        // redraw={false}
        data={wealthStore.chartAssetsData}
        height={props.height}
        options={chartOptions} /> */}
      <PieTextContent contentTop={props.contentTop} ref={containerRef}>
      <PieTextHoverWrapper onClick={() => { goToTrendsView() }}>

        <PieTextLabel>{hoverData.show ? hoverData.title : `Total ${wealthStore.isMainResults ? 'net' : 'items'} worth`}</PieTextLabel>

        <PieTextBigValue isIncognito={uiStore.isIncognitoMode} fontSize={textSize} ref={textRef}>
          {uiStore.isIncognitoMode ? displayMoneyValue(hoverData.show ? hoverData.value : wealthStore.totalNetWorthUsdValue, 'USD', true) :
            // displayMoneyValue(hoverData.show ? hoverData.value : wealthStore.totalNetWorthUsdValue, 'USD', false)
            <PieValueContainer>
              <span  style={{ fontSize: `${textSize}px` }}>{displayMoneyValue(hoverData.show ? hoverData.value : wealthStore.totalNetWorthUsdValue, 'USD', uiStore.isIncognitoMode)}</span>
              {/* <CountAnimation style={{ fontSize: `${textSize}px` }} currency={'USD'} value={hoverData.show ? hoverData.value : wealthStore.totalNetWorthUsdValue} noAnim={hoverData.isHoveredOnce} /> */}
              {wealthStore.hasLLMInProcessItem && <MagicBoxAnimatedIconGrey width='16px' display='flex' fill='rgb(241,243,245)' />}
            </PieValueContainer>
          }
          <span style={{ position: 'absolute', color: 'transparent', left: 0, top: 0 }}>{displayMoneyValue(hoverData.show ? hoverData.value : wealthStore.totalNetWorthUsdValue, 'USD', false)}</span>
        </PieTextBigValue>
        <PieTrendsLink >See trend <GoToIcon /></PieTrendsLink>
        </PieTextHoverWrapper>
      </PieTextContent>
    </PieWrapper>
  )}</Observer>)
}