import { observer } from 'mobx-react';
import { NewChatIcon, VyzerAIAssistanceIcon } from '../assets/icons';
import { AssistanceHeroHeader, AssistanceHeroHeaderWrapper, AssistanceHeroInfoBoxWhite, AssistanceHeroInfoWrapper, AssistanceHeroLogoText, AssistanceHeroLogoWrapper, AssistanceHeroSubtitle, AssistanceHeroTitle, AssistanceHeroTitleWrapper, AssistanceHeroWrapper } from './Assistance.styles';
import { useStore } from '../../app/data/root.store';
import { AssistanceInfo } from './AssistanceInfo';
import { LEARN_ITEM, SECURE_ITEM } from '../data/Assistance.consts';
import { AssistanceTooltip } from './AssistanceTooltip';
import { useAnalytics } from '../../app/data/AnalyticsProvider';

export const AssistanceHero = observer(() => {
    const { userStore: { firstNameCapitalize, isDemoMode }, assistanceStore } = useStore();
    const { trackEvent } = useAnalytics();

    const handleNewChatClick = () => {
        trackEvent('CTA Clicked', {
            destination: 'New chat',
            type: 'Button',
            text: 'New',
            location: 'Assistance hero',
            url: window.location.href
        });
        assistanceStore.newChat();
    }
    return (
        <AssistanceHeroWrapper>
            <AssistanceHeroHeader>
                <AssistanceHeroHeaderWrapper>
                    <AssistanceTooltip title="Like all AI, I am still learning and may make mistakes">
                        <AssistanceHeroLogoWrapper>
                            <VyzerAIAssistanceIcon />
                            <AssistanceHeroLogoText>{'AI-BETA'}</AssistanceHeroLogoText>
                        </AssistanceHeroLogoWrapper>
                    </AssistanceTooltip>
                    <AssistanceHeroInfoWrapper>
                        {
                            assistanceStore.conversationId &&
                            <AssistanceHeroInfoBoxWhite onClick={handleNewChatClick} disabled={assistanceStore.isThinking || assistanceStore.isTyping}>
                                <NewChatIcon />
                                <span>New</span>
                            </AssistanceHeroInfoBoxWhite>
                        }
                        <AssistanceInfo isDemo={isDemoMode} item={SECURE_ITEM} />
                        <AssistanceInfo isDemo={isDemoMode} item={LEARN_ITEM} />
                    </AssistanceHeroInfoWrapper>
                </AssistanceHeroHeaderWrapper>
            </AssistanceHeroHeader>
            <AssistanceHeroTitleWrapper>
                <AssistanceHeroTitle>Hello, {firstNameCapitalize}</AssistanceHeroTitle>
                <AssistanceHeroSubtitle>
                    <div>I'm Vyzer AI {isDemoMode ? 'in demo mode' : 'Insights'}.</div>
                    <div>{isDemoMode ? 'Ask me anything about this sample portfolio.' : 'Ask me about your portfolio or any financial questions.'}</div>
                    <div>{`Just remember, I share financial information to explore, not advice to act on.`}</div>
                </AssistanceHeroSubtitle>
            </AssistanceHeroTitleWrapper>
        </AssistanceHeroWrapper>
    );
});