import { observer } from "mobx-react";
import { BaseModal } from "../../../../common/components/BaseModal/BaseModal";
import { BulkEditCurrencyEdit, BulkEditFloatingMenuModalBody, BulkEditFloatingMenuModalFooter, BulkEditFloatingMenuModalFooterButton, BulkEditFloatingMenuModalHeader, BulkEditFloatingMenuModalHeaderCloseIcon, BulkEditFloatingMenuModalHeaderText, BulkEditValueEdit, BulkEditValueInputText, BulkEditValueInputWrapper, BulkEditValueLabelIcon, BulkEditValueLabelWrapper, DeepAnalysisReviewResultsRowCategory, ResultRowCell } from "./DeepAnalysisReviewResultModal.styles";
import { useStore } from "../../../app/data/root.store";
import { CategorySelector } from "../../../../common/components/CategorySelector/CategorySelector";
import { useEffect, useState } from "react";
import { DeepAnalysisReviewValueCell } from "./DeepAnalysisReviewValueCell";
import { DeepAnalysisResultCurrencyDropdown } from "./DeepAnalysisResultCurrencyDropdown";
import { Tooltip } from "antd";


export const DeepAnalysisReviewBulkEditModal = observer(({ onClose, relevantClasses, editField, fieldChangeFunc, onSave }) => {
    const { magicBoxAiStore, userStore } = useStore();
    const [currFieldValue, setCurrFieldValue] = useState(null);

    useEffect(() => {
        const getDefaultFieldValue = (field) => {
            switch (field) {
                case 'value':
                    return ({
                        amount: (magicBoxAiStore.bulkEditFieldValue('amount') ?? null),
                        currency: (magicBoxAiStore.bulkEditFieldValue('currency') ?? 'USD')
                    });
                case 'category':
                    return ({
                        categoryId: (magicBoxAiStore.bulkEditFieldValue('categoryId') ?? null),
                        classId: (magicBoxAiStore.bulkEditFieldValue('classId') ?? null)
                    });
                case 'currency':
                    return ({
                        currency: (magicBoxAiStore.bulkEditFieldValue('currency') ?? 'USD')
                    });
                default:
                    throw new Error('Invalid field');
            }
        };

        const fieldValue = getDefaultFieldValue(editField);
        setCurrFieldValue(fieldValue);
    }, [editField, magicBoxAiStore]);

    const handleItemCategoryChange = (classId, categoryId) => setCurrFieldValue({ classId, categoryId })
    const handleValueChange = (field, value) => setCurrFieldValue((prev) => ({ ...prev, [field]: value }));
    const onChangeCurrnecy = (value) => setCurrFieldValue({ currency: value });

    return (
        <BaseModal borderRadius={8} width={'600px'} height={'auto'} noSidebarMargin={userStore.isWelcome}>
            <BulkEditFloatingMenuModalHeader>
                <BulkEditFloatingMenuModalHeaderText>Edit selected item{(magicBoxAiStore.bulkEditItemsCount > 1) && 's'} ({magicBoxAiStore.bulkEditItemsCount})</BulkEditFloatingMenuModalHeaderText>
                <BulkEditFloatingMenuModalHeaderCloseIcon onClick={onClose}><CloseIcon /></BulkEditFloatingMenuModalHeaderCloseIcon>
            </BulkEditFloatingMenuModalHeader>
            <BulkEditFloatingMenuModalBody>
                {('value' === editField) && < BulkEditValue value={currFieldValue} onChange={handleValueChange} />}
                {('currency' === editField) && <BulkEditCurrency value={currFieldValue?.currency} onChange={onChangeCurrnecy} />}
                {('category' === editField) && <BulkEditCategory relevantClasses={relevantClasses} value={currFieldValue?.categoryId} onChange={handleItemCategoryChange} />}
            </BulkEditFloatingMenuModalBody>

            <BulkEditFloatingMenuModalFooter>
                <BulkEditFloatingMenuModalFooterButton onClick={() => onSave(currFieldValue)}>Save</BulkEditFloatingMenuModalFooterButton>
            </BulkEditFloatingMenuModalFooter>
        </BaseModal>
    );
});


export const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M2 2L14 14M14 2L2 14" stroke="#75818D" stroke-width="1.5" />
        </svg>
    );
}

const InfoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 6.00087C11.5 9.03835 9.03661 11.5 5.99913 11.5C2.96165 11.5 0.5 9.03835 0.5 6.00087C0.5 2.96165 2.96165 0.5 5.99913 0.5C9.03661 0.5 11.5 2.96165 11.5 6.00087Z" fill="#9097A3" />
            <path d="M5.5415 5.28076H6.45802V8.94728H5.5415V5.28076Z" fill="white" stroke="white" stroke-width="0.251429" />
            <path d="M5.54175 3.56568C5.54175 3.31259 5.74692 3.10742 6.00001 3.10742C6.2531 3.10742 6.45827 3.31259 6.45827 3.56568C6.45827 3.81877 6.2531 4.02394 6.00001 4.02394C5.74692 4.02394 5.54175 3.81877 5.54175 3.56568Z" fill="white" stroke="white" stroke-width="0.628571" />
        </svg>
    );
};

const BulkEditCategory = ({ relevantClasses, value, onChange }) => {
    return (
        <BulkEditValueInputWrapper>
            <BulkEditValueLabelWrapper>
                <BulkEditValueInputText>Asset / liability category</BulkEditValueInputText>
                <Tooltip title="This value will update the category for all selected assets and liabilities.">
                    <BulkEditValueLabelIcon>
                        <InfoIcon />
                    </BulkEditValueLabelIcon>
                </Tooltip>
            </BulkEditValueLabelWrapper>
            <DeepAnalysisReviewResultsRowCategory style={{ width: '100%' }}>
                <CategorySelector
                    defaultBorderColor={'var(--Gray-3, #DDE3EB)'}
                    value={value}
                    options={relevantClasses}
                    onCategoryChange={onChange}
                    selectMinHeight='33px'
                />
            </DeepAnalysisReviewResultsRowCategory>
        </BulkEditValueInputWrapper>
    );
}

const BulkEditValue = ({ onChange, value }) => {
    const currentItem = value ?? { amount: null, currency: 'USD' };
    return (
        <BulkEditValueInputWrapper>
            <BulkEditValueLabelWrapper>
                <BulkEditValueInputText>Asset / liability value</BulkEditValueInputText>
                <Tooltip title="This value will update the current amount for all selected assets and liabilities.">
                    <BulkEditValueLabelIcon>
                        <InfoIcon />
                    </BulkEditValueLabelIcon>
                </Tooltip>
            </BulkEditValueLabelWrapper>
            <ResultRowCell isActive={true} style={{ width: '100%' }}>
                <BulkEditValueEdit>
                    <DeepAnalysisReviewValueCell
                        handleFieldChange={onChange}
                        currentItem={currentItem}
                        isEdited={true}
                        isHoverOn={true}
                    />
                </BulkEditValueEdit>
            </ResultRowCell>
        </BulkEditValueInputWrapper>
    );
};

const BulkEditCurrency = ({ onChange, value }) => {
    return (
        <BulkEditValueInputWrapper>
            <BulkEditValueLabelWrapper>
                <BulkEditValueInputText>Asset / liability currency</BulkEditValueInputText>
                <Tooltip title="This value will update the currency for all selected assets and liabilities.">
                    <BulkEditValueLabelIcon>
                        <InfoIcon />
                    </BulkEditValueLabelIcon>
                </Tooltip>
            </BulkEditValueLabelWrapper>
            <BulkEditCurrencyEdit isActive={true} style={{ width: '100%' }}>
                <DeepAnalysisResultCurrencyDropdown value={value} onChange={onChange} />
            </BulkEditCurrencyEdit>
        </BulkEditValueInputWrapper>
    );
};