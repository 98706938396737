import styled from "@emotion/styled/macro";
import { keyframes } from '@emotion/react';
import { flexCenter } from "../../../common/styles/combos.styles";
import { colors, shadows, views } from "../../../common/styles/theme.styles";
import plaidProviderImg from '../assets/plaid-provider.png';
import saltedgeProviderImg from '../assets/saltedge-provider.png';
import { easeIntoView } from "../../../common/styles/animations.styles";


const quickIntroEaseIntoView = keyframes`
    0% {
       padding-top:0px;
       height:0px;
       
    }
    100% {
        padding-top:8px;
        height:376px;
    }
`;

export const UserActivationFlowPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  
  grid-area:content;
  background-color: ${colors.lightGray1};
  ${views.desktop}{
    height: 100%;
    overflow: hidden;
  }
  ${views.tablet}{
    min-height: calc(100vh - 56px);
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 45px;
  background-color: ${colors.lightGray1};
  ${props => props.withHeader ? `` : `
    grid-area:content;
  `}
  overflow:auto;
  min-height:100%;
  & > div {
    width: 100%;
    max-width: 880px;
    margin-bottom:45px;
  }
`

export const StepAnimationWrapper = styled.div`
  
  transition: ${props => props.isEnterPosition ? '0' : '0.6'}s;
  opacity: ${props => props.isAnimatedOut ? '0' : '1'};
  

  display: flex;
  align-items: center;
  // justify-content: ${props=>props.isLongStep ? 'flex-start' : 'center'};
  justify-content:flex-start;
  flex-direction: column;
  padding: 45px;
  background-color: ${colors.lightGray1};
  ${props => props.withHeader ? `` : `
    grid-area:content;
  `}
  
  
  & > div {
    width: 100%;
    max-width: 880px;
    // margin-bottom:45px;
  }
  ${views.desktop}{
    min-height:100%;
    overflow:auto;
    transform: translateY(${props => props.isAnimatedOut ? ( props.isAnimatedBack ? '100%' : '-100%' ) : props.isEnterPosition ? ( props.isAnimatedBack ? '-100%' : '100%' ) : '0%'});
  }
  ${views.tablet}{
    // min-height: 100vh;
    min-height: calc(100vh - 56px);
    padding: 0px 24px 0px;
    transform: translateY(${props => props.isAnimatedOut ? ( props.isAnimatedBack ? '100%' : '-100%' ) : props.isEnterPosition ? ( props.isAnimatedBack ? '-100%' : '100%' ) : 'none'});
    
  }

`;

export const ActivationFlowStepWrapper = styled.div`
  ${flexCenter};
  flex-direction: column;
  flex:1;
  // min-height: 100%;
  ${views.tablet}{
    justify-content:flex-start;
  }
`;

export const AdamTitle = styled.div`
  color: ${colors.darkGray1};
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
`;

export const AdamSubTitle = styled.div`
  color: ${colors.darkGray1};
  text-align: center;
  font-family: Circular;
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const StepHeader = styled.div`
  ${flexCenter};
  flex-direction: column;
  gap: 16px;
`;

export const StepContent = styled.div`
  ${flexCenter};
  flex-direction: column;
  padding-top: ${props=> props.paddingTop || 0}px;
  flex: ${props=> props.flex || 1};
  width: 100%;
  ${views.tablet}{
    justify-content: flex-start;
    ${props=>props.withFixedActions ? `padding-bottom: 108px;` : ``}
  }
`;

export const StepFooter = styled.div`
  display: flex;
  width: 100%;
  padding-top: 32px;
  padding-left: ${props=>props.paddingLeft || 0}px;
  visibility: ${props=>props.isHidden ? 'hidden' : 'visible'};
  ${views.tablet}{
    ${props=>props.isFixedToBottom ? `
      position: fixed;
      bottom:0;
      left:0;
      padding:24px;
      background-color: #f7f8f9;
    ` : ``}
  }
  
`;

export const ActionButtonsWrapper = styled.div`
    
    display: flex;
    justify-content: flex-end;
    flex: 1;
    gap: 10px;
    ${views.desktop}{
      padding-bottom: 24px;
      border-bottom: 1px solid ${colors.gray3};
    }
    ${views.tablet}{
      flex-direction:${props=> props.isMobileReversed ? 'column-reverse' : 'row'};
    }
`;

export const ActionButton = styled.div`
    background-color: ${props => props.black ? colors.darkGray1 : colors.lightGray1} ;
    color: ${props => props.black ? '#fff' : '#000'};
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    padding: ${props => props.isBack ? '10px 20px' : '8px 16px'};
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: opacity 1s;
    ${props => props.withIcon ? `gap: 6px` : ``};
    ${props => props.isBack ? `margin-right: auto` : ``};
    ${props => props.disabled ? `opacity: 0.2; cursor: default;` : ``}
    ${views.tablet}{
      justify-content: center;
      padding: ${props => props.isBack ? '10px 20px' : '16px'};
      height: 56px;
      ${props=>props.fullWidth ? `flex: 1` : ``}
    }
`;

export const CheckmarkIconWrapper = styled.div`
  ${flexCenter};
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const HeightAnimationWrapper = styled.div`
    width: 100%;
    height: ${props => props.show ? props.height ? props.height : 400 : 0}px; 
    transition:height ${props => 'duration' in props ? (props.duration + 'ms') : '600ms'};
    ${views.desktop}{
      overflow:hidden;  
    }
    ${views.tablet}{
      height:auto;  
    }
`;

export const ProTipBadge = styled.span`
  /* display: inline;
  float: left;
  clear: left; */
  margin-right: 8px;
  padding: 4px 8px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  border-radius: 6px;
  background-color: #2565E7;
`;

export const AnimatedActivationFlowWrapper = styled.div`
  width: 100%;
  // animation-name: ${easeIntoView};
  // animation-duration: 800ms;
  // animation-timing-function: linear;
  ${props=>props.padding ? `padding: ${props.padding}` : ``};
  /* padding-left: 62px;
  padding-bottom: 16px; */
`

export const AnimatedQuickIntroVideoWrapper = styled.div`
  width: 100%;
  padding-top:8px;
  // animation: ${quickIntroEaseIntoView} 600ms linear forwards;
  ${props=>props.padding ? `padding: ${props.padding}` : ``};
  /* padding-left: 62px;
  padding-bottom: 16px; */
  iframe{
    border: none;
    border-radius: 8px;
  }
  ${views.desktop}{
    height:376px;
  }
`

export const SecuredPromptHoverEffect = styled.div`
  pointer-events:none;
  flex-direction: column;
  position: absolute;
  border-radius: 8px;
  background-color: ${colors.darkGray1};
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  bottom: -4px;
  padding: 16px;
  right: 0;
  width: 400px;
  transform: translateY(100%);
  z-index: 1;
  box-shadow: ${shadows.popupShadow};
  transition: all 0.2s;
  opacity:0;
`;

export const SecuredPromptWrapper = styled.div`
  ${flexCenter};
  gap: 4px;
  border-radius: 6px;
  box-shadow: ${shadows.cardShadow};
  background-color: #fff;
  position: ${props => props.position || 'fixed'};
  padding: 6px 8px;
  right: ${props => props.rightInput || '16'}px;
  top: 16px;
  /* border: 1px solid #fff; */
  transition: all 0.2s;
  :hover {
      background-color: ${colors.darkGray1};
      color: #fff;
      svg {
        path {
            /* fill: #fff; */
              stroke: #fff;
          }
      }
      ${SecuredPromptHoverEffect} {
          display: flex;
          z-index: 1;
          opacity:1;
          pointer-events:auto;
      }
  }
`;

export const StepRowWrapper = styled.div`
  display: flex;
  padding: 16px 0 16px 0;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-top: 1px solid ${colors.darkGray2};
`;

export const StepRowDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1;

  color: ${colors.gray2};
  font-family: Circular;
  font-size: 13px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const StepRowTitle = styled.div`
  color: #FFF;
  font-family: Circular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;


export const DocumentTypesHoverEffect = styled(SecuredPromptHoverEffect)`
  align-items: center;
  gap: 10px;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 450;
  width: 368px;
`;

export const DocumentTypesImg = styled.div`
  background: url(${props => props.bgImg}) no-repeat center;
  background-size: contain;
  width: 72px;
  height: 72px;
`;

export const LineSeparator = styled.div`
width: 320px;
height: 1px;
background: ${colors.darkGray2};
margin: 16px;
`;

export const RecommendedFilesText = styled.div`
  font-size: 13px;
  color: ${colors.gray2};
`;


export const DocumentTypesPromptWrapper = styled(SecuredPromptWrapper)`
  right: 250px;
    :hover {
      ${DocumentTypesHoverEffect} {
          display: flex;
          z-index: 1;
          opacity:1;
          pointer-events:auto;
      }
    }
`;

export const HowItWorksHoverEffect = styled(SecuredPromptHoverEffect)`
padding:0;
`;

export const HowItWorksPromptWrapper = styled(SecuredPromptWrapper)`
    right: 16px;
    :hover {
      ${HowItWorksHoverEffect} {
          display: flex;
          z-index: 1;
          opacity:1;
          pointer-events:auto;
      }
    }
`;

export const LearnPromptHoverEffect = styled.div`
  pointer-events:none;
  flex-direction: column;
  width: 396px;
  position: absolute;
  border-radius: 8px 0px 8px 8px;
  background-color: ${colors.lightGray1};
  color: ${colors.darkGray1};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* border: 1px solid ${colors.gray3}; */
  bottom: -4px;
  /* padding: 16px; */
  right: 0;
  width: 400px;
  transform: translateY(100%);
  z-index: 1;
  box-shadow: ${shadows.popupShadow};
  transition: all 0.2s;
  opacity:0;
`;

export const LearnPromptButton = styled.div`
  ${flexCenter};
  gap: 4px;
  padding: 6px 8px;
  box-shadow: ${shadows.cardShadow};
  background-color: #ECEFF5;
  border-radius: 6px;
`;

export const LearnPromptWrapper = styled.div`
  position: fixed;
  border-radius: 6px;
  right: 16px;
  top: 16px;
  z-index: 1;
  /* border: 1px solid #fff; */
  transition: all 0.2s;
  :hover {
      ${LearnPromptButton} {
        background-color: ${colors.darkGray1};
        color: #fff;
        svg {
          path {
            fill: #fff;
          }
          rect {
            stroke: #fff;
          }
        }
      }
      ${LearnPromptHoverEffect} {
          display: flex;
          z-index: 1;
          opacity:1;
          pointer-events:auto;
      }
    }
`;

export const MenuWrapper = styled.div`
    /* width:${props => props.width || '200px'};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12); */
    border-radius: 8px 0px 8px 8px;
    /* border: 1px solid ${colors.gray3};  */
    background: ${colors.lightGray1};
    padding: 8px 28px;
`;

export const MenuTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.darkGray1};
  padding: 8px 0 16px;
`;

export const MenuItemWrapper = styled.div`
    padding:8px 0;
    ${flexCenter};
    color:${colors.darkGray1};
    border-top: 1px solid ${colors.gray3};
    /* ${props => !props.disabled ? `
    :hover{
        background-color: ${colors.lightGray1};
    }
    cursor: pointer;
    ` : null} */
`;

export const WhatYouGetMenuItemWrapper = styled.div`
    ${flexCenter};
    padding:8px 0px;
    gap: 20px;
`;

export const WhatYouGetMenuItemIcon = styled.div`
    /* width: 80px; */
    display: flex;
    align-items: center;
`;

export const WhatYouGetMenuItemTextContent = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    align-items: flex-start;
`;

export const WhatYouGetMenuItemTitle = styled.div`
    color:${colors.darkGray1};
    font-size:16px;
    font-weight:500;
    line-height:19px;
    margin-bottom:4px;
`;

export const WhatYouGetMenuItemSubTitle = styled.div`
    color:${colors.darkGray3};
    font-size: 12px;
`;

export const ProvidersWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const PlaidProviderImage = styled.div`
  background: url(${plaidProviderImg}) no-repeat;
  background-size: 70px 28px;
  width: 70px;
  height: 28px;
`

export const SaltedgeProviderImage = styled.div`
  background: url(${saltedgeProviderImg}) no-repeat;
  background-size: 70px 28px;
  width: 70px;
  height: 28px;
`

export const AdamImage = styled.img`
  width: ${props => props.size || 150}px;
  height: ${props => props.size || 150}px;
  // margin-bottom: ${props => props.marginBottom || 0}px;
  margin-bottom: 12px;
  ${views.tablet} {
    align-self: center;
  }
      transition: all 0.3s;
`;