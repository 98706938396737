import styled from '@emotion/styled/macro';
import { Checkbox, Input } from 'antd';
import { shadows, colors, views } from '../../../common/styles/theme.styles';
import { flexCenter } from '../../../common/styles/combos.styles';

export const ReportsContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${views.desktop}{
        gap: 16px;
    }
`;

export const DataBox = styled.div`
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow:${shadows.cardShadow};
    border-radius: 8px;
    padding: 24px 24px 12px;
    ${views.tablet}{
        border-radius: 0px;
    }
`;

export const DataBoxHeader = styled.div`
    padding-bottom: 24px;
`;

export const DataBoxHeaderWrapper = styled.div`
`;

export const MobileHeaderWrapper = styled.div`
    position: relative;
    font-size: 14px;
    font-weight: 450;
    color:${colors.darkGray2};
    background-color: #fff;
    padding:16px 24px 0px;
`;

export const DataBoxFormContentWrapper = styled.div`
    border: 1px solid ${colors.gray3};
    border-radius: 6px;
    margin-bottom: 12px;
    ${props=>props.isLimited ? `` : `
        :hover {
            border: 1px solid ${colors.gray2};
        }
    `}
`;

export const FormHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 13px 0 13px 20px;
    cursor: pointer;
    ${props => props.isOpen &&
        `background-color: #F7F8F9;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom: 1px solid ${colors.lightGray2};`
    };
`;

export const HeaderTextWrapper = styled.div`
    
`;

export const FormTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.darkGray1};
    ${props=>props.isLimited ? `
        display: flex;
        align-items: center;
        gap: 4px;
    ` : ``}
`;

export const FormSubtitle = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.darkGray3};
`;

export const NewBadge = styled.div`
    ${flexCenter};
    padding: 2px 6px;
    gap: 10px;
    border-radius: 4px;
    background: #B3E8CF;
    color: ${colors.darkGray1};
    font-family: Circular;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const HeaderIconWrapper = styled.div`
    ${flexCenter}
    width: 66px;
    svg{
        ${props => props.isOpen &&
        'transform: rotate(180deg);'}

        path{
            stroke: ${props=>props.isLimited ? colors.gray2 : colors.darkGray1};
        }
    }
`;

export const FormContentWrapper = styled.div`
    padding: 17px 0  24px 20px;
    ${views.tablet}{
        padding: 17px 20px  24px 20px;
    }
`;

export const DataBoxInputWrapper = styled.div`
    margin-bottom: 24px;
    display:flex;
    flex-direction:column;
    width: 444px;
    ${views.tablet}{
        width: 100%;
    }
`;

export const DataBoxInputLabel = styled.div`
    font-weight: 400;
    font-size: ${props => props.fontSize ? props.fontSize : '12px'};
    line-height: 14px;
    margin-bottom:4px;
    color: ${colors.darkGray2};

    ${props => props.withIcon &&
        `display: flex;
    gap: 6px;
    align-items: center;`
    }
`;

export const DataBoxInputElement = styled.div`
`;

export const PfsPasswordWrapper = styled(Input.Password)`
    display: flex;
    height: 36px;
    padding: 8px 12px 8px 16px;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    border-radius: 29px;
    background: ${colors.lightGray1};

    &.ant-input-affix-wrapper {
        border: 1px solid transparent;
    }

    &.ant-input-affix-wrapper .ant-input {
        background: transparent;
        font-size: 14px;
        letter-spacing: 2px;
        font-weight: 500;
    }

    &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
        &:hover {
            border: 1px solid transparent;
            box-shadow: none;
        }

        :focus-within {
            border: 1px solid transparent;
            box-shadow: none;
        }
    }
`;

export const CheckboxWrapper = styled.div`
    ${props => props.width &&
        `width:100%`
    }
`;

export const GroupedByWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap:8px;
    color: ${colors.darkGray1};
    font-family: Circular;
    font-size: 15px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;

    .ant-radio-checked .ant-radio-inner {
        border: 1px solid ${colors.darkGray1};
        ::after {
            background-color: ${colors.darkGray1};
        }
    }
    .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
        border: 1px solid ${colors.darkGray1};
    }
`;

export const StyledCheckbox = styled(Checkbox)`
    .ant-checkbox-input, .ant-checkbox-inner {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        :focus{
            border-color:${colors.darkGray1};
        }
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color:${colors.darkGray1};
    }
    .ant-checkbox-checked{
        .ant-checkbox-inner {
            background-color:${colors.darkGray1};
            border-color:${colors.darkGray1};
            ::after{
                transform: rotate(45deg) scale(1) translate(-56%, -58%);
            }
        }
        ::after {
            border-color:${colors.darkGray1};
            border-radius: 4px;

        }
    }
    :hover{
        .ant-checkbox-inner {
            border-color:${colors.darkGray1};
        }
    }
    &.ant-checkbox-wrapper-checked:hover{
        .ant-checkbox-inner {
            border-color:${colors.darkGray1};
        }
    }
`;

export const ActionButtonsWrapper = styled.div`
    padding:12px 24px;
    border-top:1px solid #f1f3f5;
    background-color:#FFFFFF;
    display:flex;
    flex-direction:row-reverse;
    border-radius:0 0 8px 8px;
    gap: 10px;
    @media (max-width: 500px){
        flex-direction: column
    }
`;

export const CheckmarkIconWrapper = styled.div`
    svg{
        path{
            stroke:black;
        };
    };
`;

export const HeroWrapper = styled.div`
    
    background-color: ${colors.darkHeroBackground};
    /* Card Shadow */
    box-shadow: ${shadows.cardShadow};
    border: 1px solid #DDE3EB;
    border-radius: 8px;
    // padding:62px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    // margin-bottom:16px;
    ${views.desktop}{
        height:300px;
    }
    ${views.tablet}{
        border:none;
        border-radius: 0;
        padding-bottom:24px;
    }
`

export const HeroFlexWrapper = styled.div`
    display: flex;
    flex-direction: row;
    ${views.tablet}{
        flex-direction: column;
    }
`;

export const HeroTitle = styled.div`
    font-size: 24px;
    line-height:29px;
    font-weight:500;
    color:#FFFFFF;
    margin:0 0 8px 0;
    ${views.tablet}{
        color: ${colors.gray2};
        font-size: 14px;
        font-weight: 450;
    }
`;

export const LeftPanelContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 24px 0 56px;
    ${views.tablet}{
        padding: 0 24px;
        width:100%;
    }
`;

export const AddAssetButton = styled.div`
    border: 1px solid #C0D4FC;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.32)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.04));
    border-radius: 6px;
    color: #C0D4FC;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    padding: 8px 16px;
    display:flex;
`;


export const EmptyStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow:${shadows.cardShadow};
    border-radius: 8px;
    padding: 48px 24px;
    align-items: center;
    position: relative;
`;

export const EmptyStateTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    font-family: Domine;
`;

export const EmptyStateDescription = styled.div`
    font-weight: 450;
    font-size: 14px;
    margin-bottom: 24px;
`;

export const LinkArrowIconWrapper = styled.div`
  ${flexCenter};
  svg {
    path {
      stroke: #fff;
    }
  }
`;

export const ModalCloseButtonWrapper = styled.div`
    position:absolute;
    right:16px;
    top:16px;
    width:24px;
    height:24px;
    cursor: pointer;
`;

export const RequestPfsModalTitle = styled.div`
    color: ${colors.darkGray1};
    font-family: Domine;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 24px;
`;

export const RequestPfsModalSubTitle = styled.div`
    color: #26282C;
    text-align: center;
    font-family: Circular;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 22px;
    max-width: 390px;
    padding-top: 8px;
    padding-bottom: 32px;
`;

export const CopyTextWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
    color: #2565E7;
    font-family: Circular;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    cursor: pointer;
`;

export const ReportsMobileHeader = styled.div`
    position: ${props=>props.isHeaderFixed ? `fixed` : `relative`};
    top: 0;
    background-color: #111213;
    z-index: 2;
    width: 100%;
    min-height: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 24px 0 0;
    // ${flexCenter}
`;

export const HeaderTitle = styled.div`
    font-weight: 450;
    font-size: 18px;
    color: #fff;
`;