import { CURRENCY_SYMBOL } from "../constants/currency-symbol.const";
import { isNullOrUndefinedOrEmptyString } from "./object.utils";

export const numberWithCommas = (x) => {
  if (isNullOrUndefinedOrEmptyString(x)) return '';
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
} 

export const floatFixed = (val,decimals) => {
  return parseFloat(val.toFixed(decimals));
}

export const clamp = (num, min, max) => {
  return Math.min(Math.max(num, min), max);
}

export const nFormatter = (num, digits) => {
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "k" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "Bn" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export const displayMoneyFormatter = (value,currency,digits = 2) => {
   return (value<0?'-':'') + (CURRENCY_SYMBOL[currency] || currency) + nFormatter(Math.abs(value),digits);
}

export const displayMoneyValue = (value, currency, isIncognito = false, holdings = 100) => {
  if (isIncognito) {
    return numberWithCommas(100).replace(/[0-9]/g, "★");
  }

  const numValue = Number(value) * (holdings / 100);
  const num = ('' + numValue).split('.')[0];
  const dec = ('' + numValue).split('.')[1];
  const decLen = num.length >= 4 ? 0 : (dec && dec.length > 2 ? 2 : (dec ? dec.length : 0));
  const returnValue = `${value < 0 ? '-' : ''}${CURRENCY_SYMBOL[currency] || currency}${numberWithCommas(Math.abs(numValue.toFixed(decLen)))}`;
  return returnValue;
}

export const displayMoneyValueWithDigits = (value,currency,isIncognito = false,holdings = 100, digits = 2) => {
  if (isIncognito) {
    return numberWithCommas(100).replace(/[0-9]/g, "★");
  }
  
  const numValue = Number(value) * (holdings / 100); 
  const num = (''+numValue).split('.')[0];
  const dec = (''+numValue).split('.')[1];
  const decLen = num.length > 4 ? 0 : ( dec && dec.length > digits ? digits : (dec ? dec.length : 0));
  const returnValue = `${value<0?'-':''}${CURRENCY_SYMBOL[currency] || currency}${numberWithCommas(Math.abs(numValue.toFixed(decLen)))}`;
  return returnValue;
}

export const percentageCalc = (numerator, denominator = 100) => {
  const formulaResult = numerator / denominator * 100;
  return parseFloat(formulaResult.toFixed(2));
}

export const percentageDisplay = (numerator, denominator = 100, specificDecimalScale = 2) => {
  const formulaResult = numerator / denominator * 100;
  const returnValue = numberWithCommas(parseFloat(formulaResult.toFixed(specificDecimalScale)))+'%';
  return returnValue;
}

// export const quantityFormat = (quantity, numbersAfterZero = 2) => {
//   if (quantity >= 1) {
//     return parseFloat((+quantity).toFixed(numbersAfterZero));
//   } else {
//     const decimalPart = Math.abs(quantity).toString().split('.')[1];
//     const decimalPlaces = decimalPart ? decimalPart.match(/(0+)(\d+)/)[2].length + 2 : 0;
//     return (+quantity).toFixed(decimalPlaces);
//   }
// };

export const quantityFormat = (quantity, numbersAfterZero = 2) => {
  if (quantity >= 1) {
    return parseFloat((+quantity).toFixed(numbersAfterZero));
  } else {
    const decimalPart = Math.abs(quantity).toString().split('.')[1];
    if (decimalPart) {
      let decimalPlaces = decimalPart.match(/(0+)/);
      decimalPlaces = decimalPlaces ? decimalPlaces[0].length + numbersAfterZero : numbersAfterZero;
      return parseFloat((+quantity).toFixed(decimalPlaces));
    } else {
      return parseFloat((+quantity).toFixed(numbersAfterZero));
    }
  }
};

export const quantityFormatWithCommas = (quantity, numbersAfterZero = 2) => {
  return numberWithCommas(quantityFormat(quantity, numbersAfterZero));
}

export const countDecimals = (value) => {
  if (Math.floor(value) === value) return 0;
  const str = value.toString();
  if (str.includes('.')) {
      return str.split(".")[1].length;
  }
  return 0; // No decimal point found
}

export const CeilTo10 = (number) => {
    const divisor = Math.pow(10, Math.floor(Math.log10(Math.abs(number))));
    return (Math.ceil(number / divisor) * divisor);
}

export const FloorTo10 = (number) => {
  const divisor = Math.pow(10, Math.floor(Math.log10(Math.abs(number))));
  return (Math.floor(number / divisor) * divisor);
}