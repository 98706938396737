import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors } from "../../../../common/styles/theme.styles";


export const StickyWrapper = styled.div`
    position: sticky;
    top: 0px;
    width: 100%;
    padding-top: 16px;
    background-color: ${props=>props.isCommunity ? '#fff' : colors.lightGray2};
    
    ${props => props.isSticky ? `
        border-bottom: 1px solid ${colors.gray3};
        z-index: 5;
    ` : null}
`;

export const TagsWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    font-weight: 500;
    height: 20px;
`;

export const TagsListWrapper = styled.div`
    display: flex;
    align-items: center;
    /* ${flexCenter}; */
    width: 100%;
    max-width: 100%;
    /* gap: 8px; */
    /* margin-left: 8px; */
    ${props=>props.isHidden && `
        height: 0;
        overflow: hidden;
    `}

`;


export const FilterTagWrapper = styled.div`
    ${flexCenter};
    gap: 4px;
    padding: 4px 4px 4px 8px;
    border-radius: 22px;
    font-size: 12px;
    line-height: 14px;
    background: ${props=>props.tagColor ? props.tagColor : colors.darkGray2};
    flex-shrink: 0;
    cursor: pointer;
    &:not(:last-child) {
        margin-right: 8px;
    }
`;

export const FilterTagTitle = styled.div`
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    max-width: 220px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;


export const CloseXIconWrapper = styled.div`
    ${flexCenter};
    border-radius: 30px;
    width: 16px;
    height: 16px;
    min-width: 16px;
    background: rgba(255, 255, 255, 10%);
    opacity: 70%;
   
    :hover{
        background: rgba(255, 255, 255, 30%); 
        opacity: 100%;
    }

    svg{
        width: 12px;
        height: 12px;
        path {
            stroke-width: 2;
        }
    }
`;


export const TagPlusMore = styled.div`
    display: flex;
    flex-shrink: 0;
    padding-left: 8px;
    /* flex: 1; */
    /* justify-content: flex-end; */
    /* min-width: 100px; */
`;

const placeHolderStyle = `
    padding: 1px;
    background: ${colors.darkGray3};
    svg {
        filter: brightness(0.4);
    }
`

export const LogoWrapper = styled.div`
    ${flexCenter};
    height:16px;
    width:16px;
    min-width: 16px;
    border-radius: 50%;
    overflow: hidden;
    ${props => props.placeholder ? placeHolderStyle : `background-color: #fff;`}
    img {
        max-width: 100%;
    }
`;
