import { observer } from "mobx-react";
import { PageContent, PageWrapper } from "./Assistance.styles";
import { AppHeader } from "../../layout/components/Header/Header";
import { RightSidePushMenuWrapper } from "../../layout/components/Layout.styles";
import { useStore } from "../../app/data/root.store";
import { AssistanceHero } from "./AssitanceHero";
import { AssistanceChat } from "./AssistanceChat";
import { ActivationChecklistPane } from "../../activation/components/checklist-pane/ChecklistPane";
import { useState } from "react";
import { ls } from "../../../common/utils/localstorage.util";
import { AssistanceWelcomeModal } from "./AssistanceWelcomeModal";

export const AssistancePage = observer(() => {
    const { uiStore, userStore } = useStore();
    const [showModal, setShowModal] = useState(uiStore.isDesktopView && (ls.get('assistanceWelcomeShown_' + userStore.data.id) !== '1'));

    const handleCloseWelcomeModal = () => {
        setShowModal(false);
        ls.set(('assistanceWelcomeShown_' + userStore.data.id), 1);
    }

    return (
        <>
            {uiStore.isDesktopView && <AppHeader className="header" />}
            {uiStore.isDesktopView &&
                <RightSidePushMenuWrapper>
                    {userStore.isViewingOwnPortfolio && userStore.isGetStarted && <ActivationChecklistPane />}
                </RightSidePushMenuWrapper>}
            <PageWrapper>
                <PageContent>
                    <AssistanceHero />
                    <AssistanceChat />
                </PageContent>
            </PageWrapper>
            {showModal && <AssistanceWelcomeModal onClose={() => handleCloseWelcomeModal()} />}
        </>
    );
});