import { useState } from "react";
import DetectableOverflow from "react-detectable-overflow";
import { ModifiedTooltip } from "../ModifiedTooltip/ModifiedTooltip";

export const CommonTextOverflow = ({ text, placement , isLongText = false }) => {
  const [isTooltipNeeded, setIsTooltipNeeded] = useState(false);

  const handleOverflowChange = (isOverflowed) => {
    setIsTooltipNeeded(isOverflowed);
  }

  return (
    <DetectableOverflow onChange={handleOverflowChange}>
      <ModifiedTooltip title={isTooltipNeeded ? text : ''} placement={placement} isLongText={isLongText}>{text}</ModifiedTooltip>
    </DetectableOverflow>
  )
}