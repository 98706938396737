import { ReadMoreIcon } from "../assets/icons";
import { AssistanceHeroInfoBox, AssistanceInfoItem, AssistanceInfoItemDescription, AssistanceInfoItemIconWrapper, AssistanceInfoItemText, AssistanceInfoItemTextContent, AssistanceInfoItemTextTitle, AssistanceInfoReadMoreWrapper, AssistanceInfoWrapper, AssistanceItemInfoHoverWrapper, AssistanceItemInfoWrapper } from "./Assistance.styles";

export const AssistanceInfo = ({ isDemo, item }) => {

    return (
        <AssistanceInfoWrapper>

            <AssistanceHeroInfoBox>
                {item.icon}
                <span>{item.title}</span>
            </AssistanceHeroInfoBox>

            <AssistanceItemInfoHoverWrapper  className='assistance_info_items_hover'>
                <AssistanceItemInfoWrapper width={item.width}>
                    <AssistanceInfoItemDescription>{item.description}</AssistanceInfoItemDescription>
                    {
                        item.moreInfo?.map((info, index) => (
                            <AssistanceInfoItem key={index}>
                                <AssistanceInfoItemIconWrapper>{info.icon}</AssistanceInfoItemIconWrapper>
                                <AssistanceInfoItemText>
                                    <AssistanceInfoItemTextTitle>{info.title}</AssistanceInfoItemTextTitle>
                                    <AssistanceInfoItemTextContent>{(isDemo && info.demoContent) ? info.demoContent : info.content}</AssistanceInfoItemTextContent>
                                </AssistanceInfoItemText>
                            </AssistanceInfoItem>
                        ))
                    }
                    <AssistanceInfoReadMoreWrapper target='_blank' href={item.url}>
                        <span>Read more</span>
                        <ReadMoreIcon />
                    </AssistanceInfoReadMoreWrapper>
                </AssistanceItemInfoWrapper>
            </AssistanceItemInfoHoverWrapper>
        </AssistanceInfoWrapper>
    );
};