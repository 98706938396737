import { Observer } from 'mobx-react';
import React from 'react'
import { percentageDisplay } from '../../../../common/utils/number-display.utils'
import { CommunityPillarPercentage, CommunityPillarRectangle, CommunityPillarRectangleHoverEffect, CommunityPillarRectangleWrapper, CommunityPillarTotal, CommunityPillarWrapper } from './CommunityOverviewHero.styles'

export const CommunityPillar = (props) => {
  const { communityStore, isClassView, pillarIndex, classDetails, pillarData , isMobileView , maxPillarPercentages } = props;
  const { percentage, title, mobileTitle } = pillarData;

  return (<Observer>{() => (
    <CommunityPillarWrapper isClassView={isClassView} maxPillarPercentages={maxPillarPercentages}>
      <CommunityPillarRectangleWrapper onMouseOut={() => { communityStore.setPillarHoverData(-1) }} onMouseOver={() => { communityStore.setPillarHoverData(pillarIndex) }}>
        <CommunityPillarPercentage>
          {percentageDisplay(percentage)}
          {!isMobileView &&
          <CommunityPillarRectangleHoverEffect>
            {isClassView ?
              `Community members ${pillarIndex === 0 ? 'with ' + title.substr(0,2).toLowerCase() + title.substr(2) : 'that have ' + title} net worth invest ${percentageDisplay(percentage)} of their portfolio in ${classDetails.title}.` :
              `${percentageDisplay(percentage)} of Vyzer’s community wealth is held by investors with a ${title} portfolio`}
          </CommunityPillarRectangleHoverEffect>}
        </CommunityPillarPercentage>
        <CommunityPillarRectangle isDimmed={communityStore.pillarHoverData?.index !== -1 && communityStore.pillarHoverData?.index !== pillarIndex} classColor={classDetails?.color} height={percentage} maxPillarPercentages={maxPillarPercentages}/>
      </CommunityPillarRectangleWrapper>
      <CommunityPillarTotal isClassView={isClassView}>{isMobileView && mobileTitle ? mobileTitle : title}</CommunityPillarTotal>
    </CommunityPillarWrapper>
  )}</Observer>)
}
