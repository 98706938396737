
import { Drawer, Tabs } from 'antd';
import { Observer } from 'mobx-react';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ArrowDownIcon, MobileLeftCornerMask, MobileRightCornerMask } from '../../../../../assets/icons/common/common-icons';
import { LogoLoader } from '../../../../../common/components/LogoLoader/LogoLoader';
import { navigateToUrl } from '../../../../../common/utils/navigation.utils';
import { useStore } from '../../../../app/data/root.store';
import { CardContentBoxWrapper, ItemCardContentBoxWrapper } from '../../../components/wealth.commons.styles';
import { WealthHero } from '../../overview/components/hero/WealthHero';
import { AssetActionsMenu } from './AssetActionsMenu';
import { AssetOverviewHeroContent } from './AssetOverviewHeroContent';
import './AssetPage.css';
import { CenterContent, EventsTabIndicationsWrapper, PageWrapper, TabTitlePassedEventsCount, TabTitleText, TabTitleTransactionsCount, TabTitleVipSyncCount, TabTitleWrapper, TabsContentWrapper, TabsWrapper } from './AssetPage.styles';
import { AssetPageHeader } from './AssetPageHeader';
import { CloseItemModal } from './CloseItemModal/CloseItemModal';
import { AssetDocumentsTab } from './Documents/DocumentsTab';
import { AssetEventsTab } from './Events/AssetEventsTab/AssetEventsTab';
import { EditTargetEvent } from './Events/EditTargetEvent';
import { AmortizationLoansInfoModal } from './Info/LoansAdditionalDataModals/AmortizationLoansInfoModal';
import { AssetEditForm } from './Info/AssetEditForm';
import { OpenItemModal } from './OpenItemModal/OpenItemModal';
import { AssetsTickersTab } from './Tickers/AssetTickersTab/AssetsTickersTab';
import { AssetTransactionsTab } from './Transactions/AssetTransactionsTab';
import { UnlockMagicBoxModal } from './UnlockMagicBoxModal';
import { AssetVipSyncingTab } from './VipSyncing/AssetVipSyncingTab';
import { CloseAccountNotFoundItemModal } from './CloseAccountNotFoundItemModal/CloseAccountNotFoundItemModal';
import { NonAmortizationLoansInfoModal } from './Info/LoansAdditionalDataModals/NonAmortizationLoansInfoModal';
import { categoriesWithTickersHoldingsWhenIsConnected } from '../../../../../common/constants/tickers.const';
import { TrendsHeroContent } from '../../overview/components/hero/TrendsHeroContent/TrendsHeroContent';
import { HeroWrapper } from './AssetOverviewHeroContent.styles';
import { MobileOverViewInflowOutflowSelectorButton, MobileOverViewInflowOutflowSelectorWrapper, OverviewInflowOutflowDrawerCloseIconWrapper } from '../../../components/WealthLayout.styles';
import { DrawerHeader, DrawerHeaderTitle } from '../../overview/components/items-list/ItemsListHeaderTabs.styles';
import { OverviewInflowOutflowMenuItem, OverviewInflowOutflowMenuItemDescription, OverviewInflowOutflowMenuItemList, OverviewInflowOutflowMenuItemText, OverviewInflowOutflowMenuItemTitle } from '../../overview/components/hero/WealthHero.styles';

const eligibleTabs = ['events', 'holdings', 'transactions', 'documents', 'info'];



export function AssetPage(props) {
  let { id, activeTab, className } = useParams();
  const { isWithBanner } = props;
  let history = useHistory();
  const location = useLocation();
  const tabsHeaderRef = useRef(null)
  const { wealthStore, itemStore, uiStore, documentsStore, tickersStore, filtersStore, userStore, metadataStore } = useStore();
  const { TabPane } = Tabs;
  const [showAmortizationLoanModal, setShowAmortizationLoanModal] = useState(false)
  const [showNonAmortizationLoanModal, setShowNonAmortizationLoanModal] = useState(false)
  const [isHeaderFixed,setIsHeaderFixed] = useState(false);
  const [isTabsWrapperFixed,setIsTabsWrapperFixed] = useState(false);
  const [showItemMobileMenu , setShowItemMobileMenu] = useState(false);
  const [showOverviewTrendsDrawer, setShowOverviewTrendsDrawer] = useState(false);

  const overviewInflowOutflowMenuItems = [
    { title: 'Overview', description: 'Display the current value of the selected item\nalong with key performance metrics.', key: 'overview', icon: <OverviewMobileIcon color={'#000'} /> },
    { title: 'Trends', description: 'Visualize the performance trends\nof the selected item over a specified timeframe.', key: 'trends', icon: <TrendsMobileIcon color={'#000'} /> },
    // { title: 'Inflow / Outflow', description: `The total per timeframe of the actual\nand target inflow / out flow events `, key: 'cashflow', icon: <CashflowMobileIcon color={'#000'} /> }
  ]
  

  const handleTabChange = (key) => {
    const searchParams = new URLSearchParams(window.location.search);
    console.log(searchParams.size);
    navigateToUrl(history, (activeTab ? '' : (id + '/')) + key + `${searchParams.size > 0 ? `?${searchParams.toString()}` : ``}`, false)
  }

  const closeAmortizationUpdateModal = () => {
    setShowAmortizationLoanModal(false)
  }

  const closeNonAmortizationUpdateModal = () => {
    setShowNonAmortizationLoanModal(false)
  }

  const updateLoanInitialDetails = (data,isAmortization) => {
    itemStore.updateLoanAmortizationInitialDetails(id,data,isAmortization);
  }

  const handleLoansModals = (itemStoreRef) => {
    const { categoryId, isConnected } = itemStoreRef.item;
    if ( ![37,42].includes(categoryId) || !isConnected) return false;
    if (categoryId === 37) {
      const { interest, fixedMonthlyPayment, amount_borrowed, value, startDate, principalFirstRepayment} = itemStoreRef.item;
      if ([interest, fixedMonthlyPayment, amount_borrowed, value, startDate, principalFirstRepayment].some(i => i === null)) {
        setShowAmortizationLoanModal(true)
        return true;
      }
    } else {
      const { loanType } = itemStoreRef.item;
      if (loanType === null) {
        setShowNonAmortizationLoanModal(true)
        return true;
      }
    }
  };


  useEffect(() => {
    
    window.scrollTo(0,1);
    return () => {
      
    }
  }, []);
  
  const checkScroll = useCallback(() => {
    const { scrollY } = window;
    setIsHeaderFixed(scrollY > ( isWithBanner ? 112 : 64));
    if (tabsHeaderRef.current) {
      const { y } = tabsHeaderRef.current.getBoundingClientRect();
      // console.log(y);
      setIsTabsWrapperFixed(y < 74);
    }
    // documentsStore.uploadDocuments(acceptedFiles)
  }, [setIsHeaderFixed,setIsTabsWrapperFixed, isWithBanner]);

  useLayoutEffect(() => {
    window.addEventListener('scroll', checkScroll);
    
    // Initial check to set the state correctly on mount
    setTimeout(checkScroll, 0);

    return () => window.removeEventListener('scroll', checkScroll);
  }, [checkScroll]);

  useEffect(() => {
    (async function getItem() {

      await itemStore.getItemData(id);
      wealthStore.setShouldCheckLastChangesWhenExitItemPage(false);
      handleLoansModals(itemStore);
      // if (filtersStore.countActiveFilters === 0 ){
      //   filtersStore.handleSelectClass(itemStore.item.categoryClass.id);
      //   filtersStore.setAppliedFiltersFromSelection();
      //   wealthStore.refreshWealthData(false);
      // }
    })();

    return () => {
      itemStore.setHoldingsView(true);
      tickersStore.setData([]);
      if (wealthStore.shouldCheckLastChangesWhenExitItemPage) {
        wealthStore.getLastChanges(true);
      }
      // wealthStore.exitAssetView();
    }
  }, [id, itemStore, filtersStore, wealthStore, tickersStore]);

  useEffect(() => {
    if (!activeTab || eligibleTabs.includes(activeTab) || (activeTab === 'vip-sync' && itemStore.isShowingVipSyncAwareness && ['TIER_1','TIER_2','TIER_3','TIER_4'].includes(userStore.data?.profile?.tier))) {
      itemStore.setActiveTabUrl(activeTab);
    } else {
      navigateToUrl(history, `/wealth/assets/${className}/${id}`, true);
    }
    // return () => { }
  }, [activeTab, itemStore, history, id, className, userStore]);

  useEffect(() => {
    wealthStore.setSelectedClassUrlName(className);
    //setSelectedTab(activeTab||'overview');
    return () => {
      //cleanup
    }
  }, [className, wealthStore]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    // setTimeout(() => {
      wealthStore.setAssetViewType(searchParams.get('ahvm') || 'overview');
    // }, 1000);
  }, [ wealthStore, location.search]);

  const handleUnlockModalPromptClick = () => {
    userStore.updateSettings('promptDismissed', { unlockMagicBox: true });
    itemStore.updateItemSettings({ showUnlockMagicBoxModal: false });
  }

  const handleUpdateMetadata = () => {
    metadataStore.fetchData();
  }

  const handleItemClose = () => {
    itemStore.closeReopenAsset(itemStore.item.id, itemStore.item.closedAt, false, handleUpdateMetadata);
  }


  const handleViewTypeChange = (key) => {
    // wealthStore.setAssetViewType(key);
    setShowOverviewTrendsDrawer(false);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('ahvm', key);
    const currentRoute = location.pathname;
    navigateToUrl(history, `${currentRoute}?${searchParams.toString()}`, false);
    // navigateToUrl(history, (activeTab ? '' : (id + '/')) + key, false)
  }

  return (
    <Observer>{() => (
      <PageWrapper isHeaderFixed={isHeaderFixed}>
        {showAmortizationLoanModal && <AmortizationLoansInfoModal item={itemStore.item} onClose={closeAmortizationUpdateModal} onSave={(data)=>{updateLoanInitialDetails(data,true)}} />}
        {showNonAmortizationLoanModal && <NonAmortizationLoansInfoModal item={itemStore.item} onClose={closeNonAmortizationUpdateModal} onSave={(data)=>{updateLoanInitialDetails(data,false)}} />}
        
        {(!itemStore.isLoadedOnce || !itemStore.item?.id) ?
            <LogoLoader backdrop absolute/>
          :
          <>
          {!uiStore.isDesktopView && <WealthHero isOnlyHeader headerNotFixed withoutIcon/>}
            <ItemCardContentBoxWrapper>
              <AssetPageHeader isFixed={isHeaderFixed} />
              <HeroWrapper>
                {wealthStore.assetViewType === 'overview' ? <AssetOverviewHeroContent /> : <TrendsHeroContent id={id} whiteTheme />}
                {!uiStore.isDesktopView && 
                <MobileOverViewInflowOutflowSelectorWrapper isAssetPage={true} >
                <MobileOverViewInflowOutflowSelectorButton style={{left:'12px', top:'0px' , marginTop:'0px'}} onClick={() => { setShowOverviewTrendsDrawer(true) }}>
                {overviewInflowOutflowMenuItems.find(item => item.key === wealthStore.assetViewType)?.title}<ArrowDownIcon />
              </MobileOverViewInflowOutflowSelectorButton>
              </MobileOverViewInflowOutflowSelectorWrapper>
              }
              </HeroWrapper>
            </ItemCardContentBoxWrapper>
            <CardContentBoxWrapper marginTop={uiStore.isDesktopView  ? 16 : 0}>
              <div ref={tabsHeaderRef} style={{width:'100%' , height: isTabsWrapperFixed ? '44px' : 0}}></div>
              <TabsWrapper isFixed={isTabsWrapperFixed}>

                <Tabs activeKey={itemStore.activeTab} onChange={handleTabChange} >
                  {/* <TabPane tab={<TabTitleWrapper>
                  <TabTitleText>Overview</TabTitleText></TabTitleWrapper>} key='overview' /> */}

                  { [1,11].includes(itemStore.item.categoryClass.id) && <TabPane tab={<TabTitleWrapper>
                    {/* <InfoTabIcon/> */}
                    <TabTitleText>Transactions</TabTitleText></TabTitleWrapper>} key='transactions' />}

                  {!(categoriesWithTickersHoldingsWhenIsConnected.includes(itemStore.item.categoryId) && itemStore.item.isConnected) && ( ![1,11].includes(itemStore.item.categoryClass.id) || !itemStore.item.isConnected) &&
                    <TabPane tab={
                      <TabTitleWrapper>
                        <TabTitleText hasAnyBadges={itemStore.transactionsEventNotificationsCount > 0 || itemStore.pastEventNotificationsCount > 0}>Events</TabTitleText>
                        <EventsTabIndicationsWrapper>
                          {itemStore.transactionsEventNotificationsCount > 0 && <TabTitleTransactionsCount>{itemStore.transactionsEventNotificationsCount}</TabTitleTransactionsCount>}
                          {itemStore.pastEventNotificationsCount > 0 && <TabTitlePassedEventsCount hasTrns={itemStore.transactionsEventNotificationsCount > 0} >{itemStore.pastEventNotificationsCount}</TabTitlePassedEventsCount>}
                        </EventsTabIndicationsWrapper>
                      </TabTitleWrapper>} key='events' />
                  }

                  { categoriesWithTickersHoldingsWhenIsConnected.includes(itemStore.item.categoryId) && itemStore.item.isConnected &&
                    <TabPane tab={
                      <TabTitleWrapper>
                        <TabTitleText>Holdings {tickersStore.tickers?.length ? `(${tickersStore.tickers.length})` : ''}</TabTitleText>
                      </TabTitleWrapper>} key='holdings' />
                  }

                  <TabPane tab={<TabTitleWrapper>
                    {/* <InfoTabIcon/> */}
                    <TabTitleText>{itemStore.item.categoryClass.isLiability ? 'Info & terms' : 'Asset info'} </TabTitleText></TabTitleWrapper>} key='info' />
                  <TabPane tab={<TabTitleWrapper>
                    {/* <DocumentsTabIcon/> */}
                    <TabTitleText>{uiStore.isDesktopView ? 'Documents' : 'Docs'} {documentsStore.documents.length && uiStore.isDesktopView ? `(${documentsStore.documents.length})` : ''}</TabTitleText></TabTitleWrapper>} key='documents' />

                    {itemStore.isShowingVipSyncAwareness && ['TIER_1','TIER_2','TIER_3','TIER_4'].includes(userStore.data?.profile?.tier) &&
                    <TabPane tab={
                      <TabTitleWrapper style={{gap:'2px'}}>
                        <TabTitleText>Vip Syncing</TabTitleText>
                        {!itemStore.item?.settings?.promptDismissed?.vipSyncAwareness ? <EventsTabIndicationsWrapper>
                          <TabTitleVipSyncCount>1</TabTitleVipSyncCount>
                        </EventsTabIndicationsWrapper> : null}
                      </TabTitleWrapper>} key='vip-sync' />
                  }
                </Tabs>

                {!uiStore.isDesktopView && <AssetActionsMenu />}
                {/* {!uiStore.isDesktopView && <MobilAssetActionsButtonWrapper onClick={()=>{setShowItemMobileMenu(true)}}><MobileThreeDots /></MobilAssetActionsButtonWrapper> } */}

                {!uiStore.isDesktopView && isTabsWrapperFixed && 
              <>
          
                  <MobileLeftCornerMask style={{position:'absolute', top:'0' , left:'0'}}/>
                <MobileRightCornerMask style={{position:'absolute', top:'0' , right:'0'}}/>
              </>
              }

              </TabsWrapper>
              <TabsContentWrapper id='tabs-content-wrapper'>


                {itemStore.activeTab === 'events' && <AssetEventsTab id={id} item={itemStore.item} isIncognito={uiStore.isIncognitoMode} handleAmortizationModal={handleLoansModals} />}

                {itemStore.activeTab === 'holdings' && <AssetsTickersTab id={id} item={itemStore.item} tickers={tickersStore.tickers} isIncognito={uiStore.isIncognitoMode} />}

                {itemStore.activeTab === 'transactions' && <CenterContent><AssetTransactionsTab id={id} item={itemStore.item} isIncognito={uiStore.isIncognitoMode} /></CenterContent>}

                {itemStore.activeTab === 'documents' &&  <AssetDocumentsTab /> }

                {itemStore.activeTab === 'info' && <CenterContent><AssetEditForm isIncognito={uiStore.isIncognitoMode} id={id} asset={itemStore.item} /></CenterContent>}

                {itemStore.activeTab === 'vip-sync' && <CenterContent><AssetVipSyncingTab id={id} item={itemStore.item} onClose={() => {navigateToUrl(history, `/wealth/assets/${className}/${id}`, true);}} /></CenterContent>}

              </TabsContentWrapper>

            </CardContentBoxWrapper></>}
            {itemStore?.itemSettings?.showUnlockMagicBoxModal && <UnlockMagicBoxModal onClose={handleUnlockModalPromptClick} />}
            {!uiStore.isDesktopView && <Drawer
              closable={false}
              visible={showItemMobileMenu}
            width={  340  }
            // height={'calc(100% - 32px)'}
            className={'asset_actions_DrawerContainer_mobile'}
            placement='bottom'
            destroyOnClose={true}
            onClose={()=>{setShowItemMobileMenu(false)}} >
              <h2>Hello</h2>
              <h2>Hello</h2>
              <h2>Hello</h2>
            </Drawer>}
            {itemStore.editTargetsModal.show && <EditTargetEvent field={itemStore.editTargetsModal.field} onClose={()=>{itemStore.setShowEditTargetModal(false,'')}} />}
            {itemStore.showCloseItemModal && <CloseItemModal onItemClose={handleItemClose} onClose={()=>{itemStore.setShowCloseItemModal(false)}} />}
            {itemStore.showCloseAccountNotFoundItemModal && <CloseAccountNotFoundItemModal onItemClose={handleItemClose} onClose={()=>{itemStore.setShowCloseAccountNotFoundItemModal(false)}} />}
            {itemStore.showOpenItemModal && <OpenItemModal userStore={userStore} itemName={itemStore.item.title} onClose={()=>{itemStore.setShowOpenItemModal(false)}} /> }


            
              <Drawer
                closable={false}
                visible={showOverviewTrendsDrawer}
                width={340}
                height={'auto'}
                className={'overviewInflowOutflowDrawer overviewInflowOutflowDrawer_mobile'}
                placement={'bottom'}
                onClose={() => { setShowOverviewTrendsDrawer(false) }}
              >
                <DrawerHeader>
                  <DrawerHeaderTitle>Select view</DrawerHeaderTitle>
                  <OverviewInflowOutflowDrawerCloseIconWrapper onClick={() => { setShowOverviewTrendsDrawer(false) }}><DrawerCloseIcon /></OverviewInflowOutflowDrawerCloseIconWrapper>
                </DrawerHeader>

                <OverviewInflowOutflowMenuItemList>
                  {overviewInflowOutflowMenuItems.map((item) => (
                    <OverviewInflowOutflowMenuItem key={item.key} isSelected={item.key === wealthStore.assetViewType}>
                      {item.icon}
                      <OverviewInflowOutflowMenuItemText onClick={() => handleViewTypeChange(item.key)}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <OverviewInflowOutflowMenuItemTitle>{item.title}</OverviewInflowOutflowMenuItemTitle>
                          <OverviewInflowOutflowMenuItemDescription>{item.description}</OverviewInflowOutflowMenuItemDescription>
                        </div>
                      </OverviewInflowOutflowMenuItemText>
                      {item.key === wealthStore.assetViewType && <SelectedMenuItemIcon />}
                    </OverviewInflowOutflowMenuItem>
                  ))}
                </OverviewInflowOutflowMenuItemList>
              </Drawer>
      </PageWrapper>
    )}</Observer>)
}

const OverviewMobileIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 12L22.75 12C22.75 11.8011 22.671 11.6103 22.5303 11.4697C22.3897 11.329 22.1989 11.25 22 11.25L22 12ZM11.5 12L10.75 12C10.75 12.4142 11.0858 12.75 11.5 12.75L11.5 12ZM11.5 2.01228L12.25 2.01228C12.25 1.80697 12.1658 1.61063 12.0171 1.46907C11.8684 1.3275 11.6682 1.25309 11.4631 1.26319L11.5 2.01228ZM10.75 21.9877C10.75 22.4019 11.0858 22.7377 11.5 22.7377C11.9142 22.7377 12.25 22.4019 12.25 21.9877L10.75 21.9877ZM12.25 10.5C12.25 10.0858 11.9142 9.75 11.5 9.75C11.0858 9.75 10.75 10.0858 10.75 10.5L12.25 10.5ZM21.25 12C21.25 17.1086 17.1086 21.25 12 21.25L12 22.75C17.9371 22.75 22.75 17.9371 22.75 12L21.25 12ZM22 11.25L11.5 11.25L11.5 12.75L22 12.75L22 11.25ZM12.25 12L12.25 2.01228L10.75 2.01228L10.75 12L12.25 12ZM2.75 12C2.75 7.04668 6.64373 3.00233 11.5369 2.76137L11.4631 1.26319C5.77514 1.54329 1.25 6.24296 1.25 12L2.75 12ZM12 21.25C6.89137 21.25 2.75 17.1086 2.75 12L1.25 12C1.25 17.9371 6.06294 22.75 12 22.75L12 21.25ZM12.25 21.9877L12.25 10.5L10.75 10.5L10.75 21.9877L12.25 21.9877Z" fill={color || "white"} />
      <path d="M12 2.75C17.1086 2.75 21.25 6.89137 21.25 12L22.75 12C22.75 6.06294 17.9371 1.25 12 1.25L12 2.75ZM2.75 12C2.75 6.89136 6.89137 2.75 12 2.75L12 1.25C6.06294 1.25 1.25 6.06294 1.25 12L2.75 12ZM12 21.25C6.89137 21.25 2.75 17.1086 2.75 12L1.25 12C1.25 17.9371 6.06294 22.75 12 22.75L12 21.25ZM12 22.75C17.9371 22.75 22.75 17.9371 22.75 12L21.25 12C21.25 17.1086 17.1086 21.25 12 21.25L12 22.75Z" fill={color || "white"} />
    </svg>
  )
}

const TrendsMobileIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M3 17.0014L9 11.0014L13 15.0014L20.625 7.375" stroke="#585D66" stroke-width="1.5" stroke-linecap="square" />
      <path d="M14 7L21 7L21 14" stroke="#585D66" stroke-width="1.5" stroke-linecap="square" />
    </svg>
  )
}

const DrawerCloseIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8" y="8" width="28" height="28" rx="14" fill="#E8E9ED" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.4194 27.7301L16.5199 17.8306L17.5806 16.7699L27.4801 26.6694L26.4194 27.7301Z" fill="#585D66" />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.4802 17.8306L17.5807 27.7301L16.52 26.6694L26.4195 16.7699L27.4802 17.8306Z" fill="#585D66" />
    </svg>
  )
}

const SelectedMenuItemIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5103 8.60401L10.5059 15.6125L6.99983 12.1076" stroke="black" strokeWidth="2" />
    </svg>
  )
}
