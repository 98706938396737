import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../../common/styles/combos.styles";
import { colors, shadows } from "../../../../../common/styles/theme.styles";

const inputPaddingLeft = 24;

export const FilterOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 16px 24px;
`;

export const SearchBoxWrapper = styled.div`
    width: 100%;
    position: relative;
     .ant-input {
        border: 1px solid ${colors.gray3};
        box-shadow: none;
        border-radius: 30px;
        height: 36px;
        padding-left: ${inputPaddingLeft + 8}px;
        :focus {
          border: 1px solid ${colors.darkGray1};
        }
    }
`;

export const SearchPlaceholderWrapper = styled.span`
    padding-left: ${inputPaddingLeft}px;
    line-height: 34px;
`;

export const ListParameterOption = styled.div`
  ${flexCenter}
  box-shadow: ${shadows.toggleShadow};
  border-radius: 16px;
  padding: 4px 8px;
  width: fit-content;
  min-width: 32px;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  ${props=>props.isSelected ? `background:${colors.darkGray3}; color:#fff;` : ``};
  user-select: none;
`;

export const OptionLogoWrapper = styled.div`
  ${flexCenter}
  height:16px;
  width:16px;
  border-radius: 23px;
  margin-right: 6px;
  /* margin-right:12px; */
  img{
      height:16px;
      width:16px;
  }
`;


export const OptionLogoPlaceholder = styled.div`
  border-radius: 16px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 6px;
  background: ${colors.lightGray2};
`;



export const SponsorParameterFilterEmptyStateWrapper = styled.div``;

export const SearchIconEmptyStateWrapper = styled.div`
    ${flexCenter};
    svg{
        width: 32px;
        height: 32px;
    }
    padding-top: 8px;
    padding-bottom: 16px;
`;

export const SearchTextWrapper = styled.div`
    ${flexCenter};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.darkGray1};
    margin-bottom: 4px;
`;

export const SearchAltTextWrapper = styled(SearchTextWrapper)`
    color: ${colors.darkGray3};
    font-weight: 400;
    padding-bottom: 8px;
    text-align: center;
`;

export const SearchIconWrapper = styled.div`
    display: flex;
    position: absolute;
    top: 10px;
    left: 12px;
    align-items: center;
    svg{
        width: 16px;
        height: 16px;
        circle, line {
            stroke: ${props => props.hasSearchInput ? colors.darkGray1 : '#9097A3' }
        }
    }
`;