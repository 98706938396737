import styled from '@emotion/styled/macro';
import { colors, shadows, views } from '../../../common/styles/theme.styles';
import { flexCenter } from '../../../common/styles/combos.styles';


export const CardContentBoxWrapper = styled.div`
    background-color:#fff;
    box-shadow:${props => props.noBoxShadow ? '' : shadows.cardShadow};
    border-radius: 8px;
    width:100%;
    
    ${props => props.borderlessTop || props.borderless ? `border-top:none; border-top-left-radius: 0px; border-top-right-radius: 0px;` : ``}
    ${props => props.marginBottom ? `margin-bottom:${props.marginBottom}px;` : ``}
    ${props => props.marginTop ? `margin-top:${props.marginTop}px;` : ``}
    ${props => props.height ? `height:${props.height}px;` : ``}

    ${props => props.borderLess ? `box-shadow:none;border:none;` : ``}
    ${views.desktop}{
        ${props => props.borderLess ? `` : `
            border: 1px solid ${colors.gray3};
        `}
    }
`;

export const ItemCardContentBoxWrapper = styled(CardContentBoxWrapper)`
    
    ${views.tablet}{
        background-color:${colors.lightGray1};
        border-radius: 0px;
    }
`;

export const ItemsListHeaderWrapper = styled.div`
    padding:8px 0px;
    ${flexCenter};
    box-shadow: ${shadows.cardShadow};
    border-bottom: 1px solid ${colors.gray3};
    min-height: 55px;
`;

export const HeaderTitle = styled.div`
    padding:8px;
    font-size:14px;
    color:${colors.darkGray1};
    font-weight:500;
    flex: 1;
`;

export const HeaderTabsWrapper = styled.div`
    ${flexCenter};
    justify-content:flex-start;
    gap:12px;
    padding-left:24px;
    ${views.desktop}{
        margin-bottom: -9px;
    }
        
    ${props => props.mobileTabs ? `
        ${views.tablet}{
            margin-bottom: -9px;
        }    
    ` : ``}
    
    width: 100%;
    
    .ant-tabs-tab{
        margin:0px;
        padding-top: 8px;
    }
    .ant-tabs-tab-btn{
        color:#3F3F45;
        font-weight: normal;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        color:#3F3F45;
        font-weight:normal;
    }

    .ant-tabs-ink-bar{
        background-color:#3F3F45;
    }

    .ant-tabs-top > .ant-tabs-nav{
        margin-bottom: 0px;
    }

    .ant-tabs-nav-more {
        position: absolute;
        visibility: hidden;
        pointer-events: none;
    }
`;

export const SortHoverIconWrapper = styled.div`
    display: none;
    ${props => props.isValue ? `margin-right:8px;` : `margin-left:8px;`}
`;

export const HeaderTitleTab = styled.div`
    display:inline-flex;
    cursor: pointer;

    :hover{
        ${SortHoverIconWrapper} {
            display: flex;
            align-items: center;
            ${props => props.isValue ? `margin-right:8px;` : `margin-left:8px;`}
        }
    }

    ${props => props.isSelected ? `color: ${colors.darkGray1}` : `color: ${colors.gray1};`};

`;

export const TableHeaderSortIconWrapper = styled.div`
    margin-left:8px;
    ${props => props.isAsc ? 'transform: rotate(180deg)' : ''} 
`;

export const HeaderActionAndTitle = styled.div`
    ${flexCenter};
`;

export const HeaderActions = styled.div`
    display:flex;
    flex: ${props => props.withFlex ? 1 : ``};
    flex-direction:row;
    padding-left:24px;
`;




export const HeaderButtonWrapper = styled.div`
    /* box-shadow:${shadows.toggleShadow};
    border-radius:4px;
    height:28px;
    ${flexCenter};
    padding:6px 3px;
    :not(:last-child){
        margin-right: 8px;
    }  */
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* height: 25px; */
    background: #F1F3F5;
    border-radius: 6px;
    padding: 0 2px;
    margin-right:16px;
    `;

export const ViewModeIconsWrapper = styled.div`

`;
export const ViewModeIconWrapper = styled.div`
    cursor: pointer;
    font-weight:400;
    font-size:14px; 
    padding: 4px 12px;
    /* height: 21px; */
    color: #9097A3;
 
    ${props => props.isSelected ? `
        background: #FFFFFF;
        padding: 4px 12px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        color: #1A1B1D;
    ` : ``}
`;

export const ItemsListWrapper = styled.div`
    padding-bottom: ${props => props.withoutPadding ? 0 : 8}px;
    ${views.tablet}{
        padding-bottom: 0px;
    }
`;

export const MobileTabsSelectorButton = styled.div`
    color: ${colors.darkGray1};
    font-size:14px;
    font-weight: 450;
    
    padding:8px 0;
    cursor: pointer;
    ${flexCenter};
    pointer-events:auto;
    svg {margin-left:8px}
    svg {
        path {
            stroke:${colors.darkGray1};
        }
    }
    
`;
