import { ChatButtonArrowIcon, ChatIconWhite, ChatTimeIconWhite, GraphIconWhite, SecureIcon } from "../assets/icons"
import { AssistanceLearnIcon } from "../components/Assistance.styles"

export const CUSTOM_DATA = {
    'PRODUCT_SUPPORT': {
        title: 'Ask support',
        icon: <ChatButtonArrowIcon />
    },
    'Error': {
        title: 'Resend question',
        content: 'It seems like there was an error processing your request. Please try again later.',
        icon: <ChatButtonArrowIcon />
    }
};

export const LEARN_ITEM = {
    title: 'Learn',
    icon: <AssistanceLearnIcon />,
    width: '344px',
    description: `Here's what you can do with Vyzer AI insights:`,
    moreInfo: [
        {
            icon: <GraphIconWhite />,
            title: 'Uncover insights',
            content: 'Track trends and analyze performance across your portfolio in real time.',
            demoContent: 'See how AI analyzes trends and performance in this Demo portfolio.'
        },
        {
            icon: <ChatIconWhite />,
            title: 'Ask questions',
            content: 'Get answers to your financial questions, from market trends to strategies.',
            demoContent: 'Ask about any financial topic from markets to investment strategies.'
        },
        {
            icon: <ChatTimeIconWhite />,
            title: 'Save time',
            content: 'No more digging through data. Get instant insights and answers in seconds.',
            demoContent: 'No more digging through data. Get instant insights and answers in seconds.'
        }
    ],
    url: 'https://help.vyzer.co/en/articles/10697312-maximize-your-financial-insights-with-vyzer-ai'
}

export const SECURE_ITEM = {
    title: 'Secure',
    icon: <SecureIcon />,
    description: (<div>
        <div>Your data is secure in Vyzer AI chat.</div>
        <div>Vyzer does not use your conversations to train our models.</div>
        <div>This is a beta version of Vyzer's AI Insights.</div>
    </div>),
    url: 'https://help.vyzer.co/en/articles/10697282-vyzer-ai-insights-security-privacy-guide'
}

export const DEFAULT_ITEMS = [
    'What is the best way to allocate a portfolio?',
    'What is the best-performing asset in my portfolio?',
    "What is my exposure to public markets?",
    'How can I optimize my portfolio for risk and return?',
    'How has my portfolio performed in the last year?',
    'How much did I earn in dividends last year?',
    'What is my total debt/liabilities?',
];

export const INVESTMENT_QUESTIONS = [
    "What is IRR?",
    "What is CoC return?",
    "How do I calculate my net worth?",
    "What are the best tax strategies for investors?",
    "What is the difference between IRR and ROI?",
    "How do I hedge against inflation?",
    "What are common mistakes in portfolio management?",
    "How can I reduce my investment risk?",
    "What are the different types of investment fees?",
    "How does leverage impact my investments?",
    "What is the best way to invest idle cash?",
    "How do I calculate the total return on my investments?",
    "What are the key metrics to track for investment performance?",
    "How does compounding work in investments?",
    "What are the best practices for estate planning?",
    "How should I rebalance my portfolio?",
    "What is the impact of interest rates on my investments?",
    "How do I analyze a private equity deal?",
    "What is a capital call and how does it work?",
    "What are the benefits of investing in alternative assets?",
    "How does depreciation affect my real estate investments?",
    "What is the best strategy for long-term wealth growth?",
    "How do I compare my portfolio’s performance to public markets?"
];

export const PROTFOLIO_INQUIRIES = [
    "What is my net worth?",
    "What is my total portfolio value?",
    "How many investments do I have?",
    "What is my current liquidity?",
    "How much cash do I have available?",
    "What is my IRR?",
    "What is my CoC return?",
    "What is my biggest investment?",
    "What is my asset allocation breakdown?",
    "How much did I contribute to my investments this year?",
    "What is my next capital call?",
    "How much have I withdrawn from my portfolio?",
    "What are my upcoming distributions?",
    "What is my exposure to real estate?",
    "What is my exposure to private equity?",
    "What is the value of my crypto holdings?",
    "How many open loans do I have?",
    "How has my net worth changed over time?",
    "What is the IRR of my private equity investments?",
    "How does my portfolio compare to the S&P 500?"
];

export const SEARCH_OPTIONS = [...DEFAULT_ITEMS, ...INVESTMENT_QUESTIONS, ...PROTFOLIO_INQUIRIES];