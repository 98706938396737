import React, { useState } from 'react';
import { Observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom'
import { Drawer } from "antd";
import { useStore } from "../../../app/data/root.store";
import { AddButtonWrapper, FooterWrapper, MenuButtonText, MenuButtonWrapper, MenuLinkButtonWrapper } from "./MobileFooter.styles";
import { CreateMenu } from "../Header/Header";
import { navigateToUrl } from '../../../../common/utils/navigation.utils';
import { NewCreateItemModal } from '../../../add-item/components/NewCreateItemModal';
import { LimitationModal } from '../../../../common/components/LimitationModal/LimitationModal';
import { ROUTES } from '../../../../common/constants/routes.const';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';

const dealFlowNotificationCount = 2;

export function MobileFooter(props) {
    const {isHidden} = props;
    const history = useHistory();
    const location = useLocation();
    const { uiStore , createItemStore , userStore,filtersStore, billingStore, wealthStore, assistanceStore } = useStore();

    const { trackEvent } = useAnalytics();

    const [isCreateMenuOpen, setIsCreateMenuOpen] = useState(false);

    const handleMenuItemClick = (destination) => {
      trackEvent('CTA Clicked', {
        destination,
        location: 'Mobile footer menu',
        cta_text: destination,
        type: 'Button',
        url: window.location.href
      })
    }
  

    const handlePortfolioClick = () => {
        filtersStore.handleClearFilterSelections();
        filtersStore.closeAllParameters();
        wealthStore.setViewType('overview');
        if (location.pathname.includes(ROUTES.wealth.url)) {
          wealthStore.refreshWealthData();
        }
        // uiStore.setIsRightMenuOpen(!uiStore.isRightMenuOpen)
      }

    const onCreateSyncClick = (syncMode) => {
        createItemStore.setInstitutionStep(syncMode);
        createItemStore.setShowModal(true);
        setIsCreateMenuOpen(false);
      }
    
      const onCreateManuallyClick = () => {
        createItemStore.setCategoryStep(true);
        createItemStore.setShowModal(true);
        setIsCreateMenuOpen(false);
      }
    
      const onMagicBoxClick = () => {
        navigateToUrl(history, ROUTES.magicBox.url);
        setIsCreateMenuOpen(false);
      }
    
      const handleItemAdded = () => {
        createItemStore.setShowModal(false);
        userStore.updateData();
      }
    
      const handleCreateItemModalClose = () => {
        createItemStore.setIsPlaidInProcess(false);
        createItemStore.setIsSaltedgeInProcess(false);
        createItemStore.setShowModal(false);
      }

      return <Observer>{() => (
        <FooterWrapper isHidden={isHidden} id="mobile_footer">
            <MenuLinkButtonWrapper to={ROUTES.wealthOverview.url} activeStyle={{ opacity : uiStore.isAppMobileMenuOpen ? 0.5 : 1 }} onClick={()=>{handleMenuItemClick('my portfolio');uiStore.setIsAppMobileMenuOpen(false);handlePortfolioClick()}} >
                {/* <HomeIcon className={'normalIcon'} isActive={false}/>
                <HomeIcon className={'selectedIcon'} isActive={true}/> */}
                <HomeIcon />
                <MenuButtonText>Portfolio</MenuButtonText>
            </MenuLinkButtonWrapper>
            {/* <MenuLinkButtonWrapper to={ROUTES.cashflow.url}  activeStyle={{ opacity : uiStore.isAppMobileMenuOpen ? 0.5 : 1 }} onClick={()=>{uiStore.setIsAppMobileMenuOpen(false)}}>
                    <PlanningIcon />
                    <MenuButtonText>Planning</MenuButtonText>
            </MenuLinkButtonWrapper> */}
            {
            assistanceStore.showAssistance &&
            <MenuLinkButtonWrapper to={ROUTES.assistance.url} activeStyle={{ opacity: uiStore.isAppMobileMenuOpen ? 0.5 : 1 }} onClick={() => { handleMenuItemClick('insights ai') }}>
              <AssistanceIcon />
              <MenuButtonText>Insights</MenuButtonText>
            </MenuLinkButtonWrapper>
            }
            <AddButtonWrapper onClick={()=>{handleMenuItemClick('Add item');setIsCreateMenuOpen(true)}}>
                    <AddIcon />
            </AddButtonWrapper>
{/*        
            <MenuLinkButtonWrapper to={ROUTES.reports.url}  activeStyle={{ opacity : uiStore.isAppMobileMenuOpen ? 0.5 : 1 }}>
                    <ReportsIcon />
                    <MenuButtonText>Reports</MenuButtonText>
            </MenuLinkButtonWrapper> */}
            <MenuLinkButtonWrapper to={ROUTES.dealFlow.url}  activeStyle={{ opacity : uiStore.isAppMobileMenuOpen ? 0.5 : 1 }} onClick={()=>{handleMenuItemClick('dealFlow')}}>
                    <DealsIcon />
                    <MenuButtonText>Deals {dealFlowNotificationCount > 0 ? `(${dealFlowNotificationCount})` : ''}</MenuButtonText>
            </MenuLinkButtonWrapper>
            <MenuButtonWrapper isSelected={uiStore.isAppMobileMenuOpen} onClick={()=>{handleMenuItemClick('More menu');uiStore.setIsAppMobileMenuOpen(!uiStore.isAppMobileMenuOpen)}}>
                    <MoreIcon />
                    <MenuButtonText>More</MenuButtonText>
            </MenuButtonWrapper>

            <Drawer
                closable={false}
                visible={isCreateMenuOpen }
            width={ '100%' }
            height={'auto'}
            className={'addItemDrawerContainer_mobile'}
            placement={'bottom'}
            onClose={()=>{setIsCreateMenuOpen(false)}}>
                <CreateMenu fullWidth 
                onSyncClick={onCreateSyncClick}
                onManuallyClick={onCreateManuallyClick}
                onMagicClick={onMagicBoxClick}
                userStore={userStore}
                isMobileMenu
                onClose={()=>{setIsCreateMenuOpen(false)}}
                setMenuVisibility={setIsCreateMenuOpen} />
            </Drawer>

            {createItemStore.showModal &&
                <NewCreateItemModal
                    onAddItem={() => { handleItemAdded() }}
                    onClose={handleCreateItemModalClose}
                />}

{ createItemStore.isShowAddManualItemLimitModal && <LimitationModal modalType="addItems" 
        paramsForText={[userStore.userLimitations.manualActiveItems.manualItems]}
        onUpgrade={()=>{billingStore.setShowPricingTableModal(true)}}
        onClose={()=>createItemStore.setShowAddManualItemLimitModal(false) } />}

        { createItemStore.isShowAddSyncLimitModal && <LimitationModal modalType="instSync" 
        paramsForText={[userStore.userLimitations.financialInstitutionSync.syncedInstitutions]}
        onUpgrade={()=>{billingStore.setShowPricingTableModal(true)}}
        onClose={()=>createItemStore.setShowAddSyncLimitModal(false) } />}

        { createItemStore.isShowAddVipSyncLimitModal && <LimitationModal modalType="vipSync" 
        paramsForText={[userStore.userLimitations.financialInstitutionSync.syncedInstitutions]}
        onUpgrade={()=>{billingStore.setShowPricingTableModal(true)}}
        onClose={()=>createItemStore.setShowAddVipSyncLimitModal(false) } />}


        </FooterWrapper>
    )}</Observer>
}


const HomeIcon = ({isActive,className}) => {
    return (<svg className={className} width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.9 20.5V10.2562C20.9 9.76069 20.6553 9.29711 20.2461 9.0176L14.0281 4.7705C13.3478 4.30585 12.4522 4.30585 11.772 4.7705L5.55399 9.0176C5.14478 9.29711 4.90002 9.76069 4.90002 10.2562V20.5C4.90002 20.7761 5.12388 21 5.40002 21H10C10.2762 21 10.5 20.7761 10.5 20.5V17.1676C10.5 16.6153 10.9477 16.1676 11.5 16.1676H14.3C14.8523 16.1676 15.3 16.6153 15.3 17.1676V20.5C15.3 20.7761 15.5239 21 15.8 21H20.4C20.6762 21 20.9 20.7761 20.9 20.5Z" stroke="#EAF0F1" strokeWidth="1.5" fill={isActive ? '#EAF0F1' : 'none'}/>
    </svg>
    )
}



const AddIcon = () => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.59998 14L8.59998 2" stroke="#EAF0F1" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
<path d="M14.6 8H2.59998" stroke="#EAF0F1" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
</svg>

)

const AssistanceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31" viewBox="0 0 32 31" fill="none">
  <g filter="url(#filter0_f_1575_35926)">
    <path d="M15.8849 5.55516C15.9146 5.32224 16.2519 5.32224 16.2816 5.55516L16.8948 10.3614C17.183 12.621 18.9622 14.4002 21.2219 14.6885L26.0281 15.3016C26.261 15.3313 26.261 15.6687 26.0281 15.6984L21.2219 16.3115C18.9622 16.5998 17.183 18.379 16.8948 20.6386L16.2816 25.4448C16.2519 25.6778 15.9146 25.6778 15.8849 25.4448L15.2717 20.6386C14.9835 18.379 13.2043 16.5998 10.9446 16.3115L6.13841 15.6984C5.90549 15.6687 5.90549 15.3313 6.13841 15.3016L10.9446 14.6885C13.2043 14.4002 14.9835 12.621 15.2717 10.3614L15.8849 5.55516Z" fill="#FFC266"/>
  </g>
  <path d="M14.8849 6.55516C14.9146 6.32224 15.2519 6.32224 15.2816 6.55516L15.8948 11.3614C16.183 13.621 17.9622 15.4002 20.2219 15.6885L25.0281 16.3016C25.261 16.3313 25.261 16.6687 25.0281 16.6984L20.2219 17.3115C17.9622 17.5998 16.183 19.379 15.8948 21.6386L15.2816 26.4448C15.2519 26.6778 14.9146 26.6778 14.8849 26.4448L14.2717 21.6386C13.9835 19.379 12.2043 17.5998 9.94465 17.3115L5.13841 16.6984C4.90549 16.6687 4.90549 16.3313 5.13841 16.3016L9.94465 15.6885C12.2043 15.4002 13.9835 13.621 14.2717 11.3614L14.8849 6.55516Z" fill="white" stroke="white" strokeWidth="1.5"/>
  <line x1="22.5833" y1="8.5" x2="27.5833" y2="8.5" stroke="white" strokeWidth="1.5"/>
  <line x1="25.0833" y1="11" x2="25.0833" y2="6" stroke="white" strokeWidth="1.5"/>
  <defs>
    <filter id="filter0_f_1575_35926" x="0.763745" y="0.18086" width="30.639" height="30.6383" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="2.6" result="effect1_foregroundBlur_1575_35926"/>
    </filter>
  </defs>
</svg>
)


const DealsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
  <path d="M7.10718 4.70354C7.10719 3.91233 8.68961 2.59366 10.5357 3.12113C10.272 4.17606 8.16212 5.23101 7.10718 4.70354Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M4.72607 7.24133C3.98603 6.96141 3.31243 5.01482 4.4589 3.47466C5.35231 4.09454 5.59263 6.4412 4.72607 7.24133Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M3.18308 10.5087C2.39293 10.5495 0.994263 9.03736 1.42566 7.16643C2.49281 7.37532 3.65534 9.42789 3.18308 10.5087Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M3.43973 14.3366C2.75454 14.7322 0.82128 14.0213 0.35488 12.1588C1.40033 11.8597 3.36897 13.1593 3.43973 14.3366Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M5.66048 17.1938C5.30234 17.8994 3.29443 18.359 1.88698 17.053C2.59964 16.2317 4.95853 16.246 5.66048 17.1938Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M8.29793 19.8306C7.93979 20.5361 5.93188 20.9957 4.52443 19.6897C5.2371 18.8684 7.59598 18.8827 8.29793 19.8306Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M4.51194 7.61295C4.75088 6.87425 6.35278 6.03241 7.59782 6.97908C7.07864 7.89912 5.15514 8.36496 4.51194 7.61295Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M3.58232 11.1251C3.50447 10.3526 4.63341 8.93832 6.15382 9.30527C6.04785 10.3564 4.47338 11.5555 3.58232 11.1251Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M3.58242 13.7613C3.50418 12.985 4.59142 11.5684 6.06418 11.9428C5.96557 12.9983 4.44636 14.197 3.58242 13.7613Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M5.10807 17.3525C4.90345 16.9352 5.4449 15.807 6.6109 15.5802C6.73665 16.2087 5.83283 17.3421 5.10807 17.3525Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M7.99267 19.4412C7.4416 19.1869 6.90547 17.9346 7.71892 17.1378C8.38996 17.615 8.61494 19.0586 7.99267 19.4412Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M10.2718 20.2639C8.35548 19.9193 6.61762 18.8862 5.36357 17.3459C4.10952 15.8056 3.41949 13.8568 3.4147 11.8418C3.40991 9.82673 4.09067 7.87439 5.33739 6.32775C6.58411 4.7811 8.31703 3.73909 10.2317 3.38477" stroke="white" strokeWidth="0.527473" strokeLinecap="round"/>
  <path d="M17.3926 4.70354C17.3926 3.91233 15.8102 2.59366 13.964 3.12113C14.2277 4.17606 16.3376 5.23101 17.3926 4.70354Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M19.7737 7.24133C20.5137 6.96141 21.1873 5.01482 20.0409 3.47466C19.1474 4.09454 18.9071 6.4412 19.7737 7.24133Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M21.3167 10.5087C22.1068 10.5495 23.5055 9.03736 23.0741 7.16643C22.0069 7.37532 20.8444 9.42789 21.3167 10.5087Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M21.06 14.3366C21.7452 14.7322 23.6785 14.0213 24.1449 12.1588C23.0994 11.8597 21.1308 13.1593 21.06 14.3366Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M18.8393 17.1938C19.1974 17.8994 21.2053 18.359 22.6128 17.053C21.9001 16.2317 19.5412 16.246 18.8393 17.1938Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M16.2018 19.8306C16.56 20.5361 18.5679 20.9957 19.9753 19.6897C19.2627 18.8684 16.9038 18.8827 16.2018 19.8306Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M19.9878 7.61295C19.7489 6.87425 18.147 6.03241 16.9019 6.97908C17.4211 7.89912 19.3446 8.36496 19.9878 7.61295Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M20.9174 11.1251C20.9953 10.3526 19.8663 8.93832 18.3459 9.30527C18.4519 10.3564 20.0264 11.5555 20.9174 11.1251Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M20.9173 13.7613C20.9956 12.985 19.9083 11.5684 18.4356 11.9428C18.5342 12.9983 20.0534 14.197 20.9173 13.7613Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M19.3917 17.3525C19.5963 16.9352 19.0549 15.807 17.8889 15.5802C17.7631 16.2087 18.6669 17.3421 19.3917 17.3525Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M16.5071 19.4412C17.0582 19.1869 17.5943 17.9346 16.7808 17.1378C16.1098 17.615 15.8848 19.0586 16.5071 19.4412Z" fill="white" stroke="white" strokeWidth="0.527473"/>
  <path d="M14.2279 20.2639C16.1443 19.9193 17.8821 18.8862 19.1362 17.3459C20.3902 15.8056 21.0803 13.8568 21.0851 11.8418C21.0898 9.82673 20.4091 7.87439 19.1624 6.32775C17.9156 4.7811 16.1827 3.73909 14.268 3.38477" stroke="white" strokeWidth="0.527473" strokeLinecap="round"/>
</svg>
)



const MoreIcon = () => {
    return (<svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="4.06531" y1="7.25" x2="20.7459" y2="7.25" stroke="#EAF0F1" strokeWidth="1.5" strokeLinecap="round"/>
    <line x1="4.06531" y1="13.75" x2="20.7459" y2="13.75" stroke="#EAF0F1" strokeWidth="1.5" strokeLinecap="round"/>
    <line x1="4.06531" y1="20.25" x2="20.7459" y2="20.25" stroke="#EAF0F1" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
    )
}
