import styled from "@emotion/styled";
import { colors } from "../../../../../common/styles/theme.styles";
import { flexCenter } from "../../../../../common/styles/combos.styles";
import PopupBg from '../../../assets/mobile-popup-bg.png'


export const WelcomeModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0 24px;
`;

export const WelcomeModalContent = styled.div`
    height: calc(100% - 92px);
    width: 100%;
    border:1px solid ${colors.darkGray2};
    border-radius: 12px;
    background: #000;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:24px 40px 32px 40px;
`;

export const WelcomeModalBg = styled.div`
    left:0;
    right:0;
    height: 250px;
    background: #000;
    background: url(${PopupBg});
    position: absolute;
    bottom:66px;
    background-size: cover;
    background-position:top right;
    opacity: ${props=>props.opacity || 0.8};
`;

export const CloseButtonWrapper = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #25272C;
    ${flexCenter}
    svg{
        path{
            stroke: #ACB1BB;
        }
    }
`;

export const StepsIndicationsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
`;

export const StepIndicatorLineBg = styled.div`
    width: 82px;
    height: 1px;
    background: #25272C;
    position: relative;
`;

export const StepIndicatorLineFiller = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    background: #fff;
    width: ${props=>props.isActive ? '100%' : '0%'};
    transition: width 0.3s ease-in-out;
`;

export const StepContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex:1;
    z-index:1;
    padding-top: 40px;
`;

export const NextStepButtonWrapper = styled.div`
    align-self:stretch;;
    z-index:1;
`;

export const NextStepButton = styled.button`
    font-family: 'Circular';
    border-radius: 8px;
    background: #FFF;
    height: 48px;
    width: 100%;
    box-shadow: 0px 10px 13.3px 0px rgba(255, 194, 102, 0.25);
    ${flexCenter}
    font-size: 16px;
    font-weight: 500;
    color: ${colors.darkGray1};
    border:none;
`;

export const StepBigTitle = styled.h2`
    font-family: 'Circular';
    font-size: 38px;
    line-height: 32px;
    font-weight: 450;
    color: #fff;
    letter-spacing: -0.5px;
    margin:0;
`;

export const StepSubTitle = styled.p`
    font-family: 'Circular';
    font-size: 16px;
    line-height: 26px;
    font-weight: 450;
    color: ${colors.gray2};
`;

export const StepSmallTitle = styled.div`
    font-family: 'Circular';
    font-size: 14px;
    line-height: 20px;
    font-weight: 450;
    color: ${colors.gray2};
`;

export const Step2DataBoxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    padding:16px 0;
`;
export const Step2DataBoxIconWrapper = styled.div`
    ${flexCenter}
`;

export const Step2DataBoxTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const Step2DataBoxTextTitle = styled.div`
    font-family: 'Circular';
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    color: #fff;
`;

export const Step2DataBoxTextDescription = styled.div`
    font-family: 'Circular';
    font-size: 14px;
    line-height: 20px;
    font-weight: 450;
    color: ${colors.gray2};
`;